// Clears the localStorage except the enterprise
// Also checks if has enterprise on localStorage, if is so then it saves it
export function clearLocalStorage(clearAllBool) {
  const enterprise = localStorage.getItem("enterprise");

  if (clearAllBool) {
    localStorage.clear();
  } else {
    localStorage.clear();

    if (enterprise !== null) localStorage.setItem("enterprise", enterprise);
  }
}

// Clears the localStorage
// But if is logged in it saves that authentication data si it doesnt logout
// Also checks if has enterprise on localStorage, if is so then it saves it
export function clearLocalStorageExceptLogin() {
  const enterprise = localStorage.getItem("enterprise");
  if (localStorage.getItem("username") !== null) {
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    const firstName = localStorage.getItem("first_name");

    localStorage.clear();
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("first_name", firstName);

    if (enterprise !== null) localStorage.setItem("enterprise", enterprise);
  } else {
    localStorage.clear();

    if (enterprise !== null) localStorage.setItem("enterprise", enterprise);
  }
}
