import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import CantidadAdherentes from './CantidadAdherentes';

class AgregarAdherentes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.showModal = this.showModal.bind(this);
    this.handleModalOk = this.handleModalOk.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  handleModalOk() {
    this.setState({
      showModal: false,
    });
  }

  handleModalCancel() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    return (
      <div className="questions">
        <h5 className="card-title card-title--show-info-on-mobile">
          {this.props.title}
        </h5>
        <p className="card-subtitle hide-on-mobile">{this.props.description}</p>
        <button type="button" onClick={this.showModal}>
          <i className="fas fa-question-circle" />
        </button>
        <Modal
          visible={this.state.showModal}
          centered
          closable={false}
          title={this.props.title}
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleModalOk}>
              Entendido
            </Button>,
          ]}
        >
          <p>{this.props.description}</p>
        </Modal>
        <CantidadAdherentes
          onBackQuestion={this.props.onBackQuestion}
          onNextQuestion={this.props.onNextQuestion}
        />
      </div>
    );
  }
}

AgregarAdherentes.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default AgregarAdherentes;
