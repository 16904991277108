import React from "react";
import PropTypes from "prop-types";
import PagofacilLogo from "../../../../assets/img/pagofacil.png";
import RapipagoLogo from "../../../../assets/img/rapipago.png";

class Cash extends React.Component {
  renderCash() {
    return (
      <ul
        className={`cash-wrapper ${this.props.cashPayment ? "d-block" : null}`}
      >
        <li>
          <button onClick={() => this.props.onSelectCashMethod("pagofacil")}>
            <img
              className="efectivo-logo pagofacil"
              src={PagofacilLogo}
              alt="Imagen Pago Facil"
              title="Pago Facil"
            />
          </button>
          <button onClick={() => this.props.onSelectCashMethod("rapipago")}>
            <img
              className="efectivo-logo rapipago"
              src={RapipagoLogo}
              alt="Imagen Rapipago"
              title="Rapipago"
            />
          </button>
        </li>
      </ul>
    );
  }

  render() {
    return this.renderCash();
  }
}

Cash.propTypes = {
  onSelectCashMethod: PropTypes.func,
  cashPayment: PropTypes.bool,
};

export default Cash;
