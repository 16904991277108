import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../Loader/index";
import OtherPaymentsMethodButtons from "./OtherPaymentsMethodButtons";
import Cash from "./Cash";
import DebitCard from "./DebitCard";
import { submitPaymentMethod } from "../../../../apis";

class OtherPayments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      axiosError: "",
      ticketCode: "",
      ticketUrl: "",
      isLoading: false,
      debitCard: false,
      cashPayment: false,
    };

    this.toggleClass = this.toggleClass.bind(this);
    this.onSelectCashMethod = this.onSelectCashMethod.bind(this);
  }

  toggleClass(e) {
    const buttonId = e.currentTarget.id;

    if (buttonId === "btn-debit-card") {
      this.setState({
        debitCard: true,
        cashPayment: false,
      });
    } else {
      this.setState({
        debitCard: false,
        cashPayment: true,
      });
    }

    this.props.renderSelectAnOption(
      this.state.debitCard,
      this.state.cashPayment
    );
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : null;
  }
  renderMessage() {
    return this.props.paymentMethod === "btn-other-payment" ? (
      <div>
        <p className="other-payments-text">
          IMPORTANTE: al pagar en efectivo o con tarjeta de débito (y no estar
          suscripto mensualmente) el precio del servicio es de{" "}
          <strong>
            {!this.props.payingInscription ? (
              <u>
                ${this.props.basePriceOtherPayment} y cubre{" "}
                {this.props.monthsOfServiceToOffer} meses del servicio de
                facturación.
              </u>
            ) : (
              <u>${this.props.basePriceOtherPayment}.</u>
            )}
          </strong>
        </p>
      </div>
    ) : null;
  }
  renderError() {
    return this.state.axiosError !== "" ? (
      <h5>{this.state.axiosError}</h5>
    ) : null;
  }

  async onSelectCashMethod(method) {
    this.setState({
      isLoading: true,
    });

    try {
      const form = JSON.stringify({
        paymentMethodId: method,
        serviceToPay: this.props.serviceToPay,
      });
      const response = await submitPaymentMethod(form);

      if (response.data.error) {
        this.setState({
          isLoading: false,
          axiosError: response.data.description,
        });
      } else {
        this.setState(
          {
            ticketCode: response.data.ticket_code,
            ticketUrl: response.data.ticket_url,
          },
          () =>
            this.props.handleTicket(
              this.state.ticketCode,
              this.state.ticketUrl,
              method
            )
        );
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  renderOtherPayments() {
    return (
      <div
        className={`other-payments-wrapper
            ${this.props.otherPaymentState ? "d-block" : null}`}
      >
        {this.renderError()}
        {this.renderLoader()}
        {this.renderMessage()}
        <OtherPaymentsMethodButtons
          debitCardState={this.state.debitCard}
          cashPaymentState={this.state.cashPayment}
          toggleClass={this.toggleClass}
          serviceToPay={this.props.serviceToPay}
        />
        <DebitCard
          debitCardState={this.state.debitCard}
          onNextQuestion={this.props.onNextQuestion}
          payingInscription={this.props.payingInscription}
          serviceToPay={this.props.serviceToPay}
        />
        <Cash
          cashPayment={this.state.cashPayment}
          onSelectCashMethod={this.onSelectCashMethod}
        />
      </div>
    );
  }

  render() {
    return this.renderOtherPayments();
  }
}

OtherPayments.propTypes = {
  handleTicket: PropTypes.func,
  otherPaymentState: PropTypes.bool,
  onNextQuestion: PropTypes.func,
  renderSelectAnOption: PropTypes.func,
  payingInscription: PropTypes.bool,
  serviceToPay: PropTypes.object,
};

export default OtherPayments;
