import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AnswerWrapper from "../../Partials/AnswerWrapper";
import CuitDniForm from "../CuitDniForm/index";
import Response from "../Response/index";
import EnvioDeEmailConInstrucciones from "../EnvioDeEmailConInstrucciones/index";
import { sendEmailGuide } from "../../../../apis";

class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
    };
  }

  componentWillMount() {
    document.title = "Gestorando";
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.questionData !== nextProps.questionData) {
      return true;
    }
    if (this.props.questions !== nextProps.questions) {
      return true;
    }
    if (this.state.token !== nextState.token) {
      return true;
    }
    return false;
  }

  renderBackButton() {
    if (this.props.showBackButton) {
      return (
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
      );
    }

    return null;
  }

  renderNextButton() {
    const question = this.props.questionData;
    const buttonText =
      "nextButtonText" in question ? question.nextButtonText : "Siguiente";

    if (this.props.showNextButton) {
      let buttonIsDisabled;

      // Checking if has posible answers
      if (question.posibleAnswers) {
        if (
          question.type === "multipleChoice" ||
          question.type === "uniqueChoice"
        ) {
          buttonIsDisabled =
            question.posibleAnswers.filter((answer) => answer.selected === true)
              .length === 0;
        } else {
          buttonIsDisabled = false;
        }
      }

      return (
        <button
          className={`
              ${buttonIsDisabled ? false : "remove-disable"} btn-next`}
          type="button"
          onClick={this.props.onNextQuestion}
          disabled={buttonIsDisabled}
        >
          {buttonText}
          <i className="fas fa-chevron-right" />
        </button>
      );
    }

    return null;
  }

  async sendEmailGuide(code) {
    try {
      const form = { code };
      await sendEmailGuide(form);
    } catch (error) {}
  }

  renderQuestion() {
    const question = this.props.questionData;
    const { questions } = this.props;

    /* CUIT or DNI Form Component (id: 1) */
    if (question.id === 1) {
      return (
        <CuitDniForm
          question={question}
          onBackQuestion={this.props.onBackQuestion}
        />
      );
    }

    /* Enviar emails de instrucciones para sacar CUIT si no tiene CUIT (id: 8) */
    /* Si tiene CUIT se saltea la pregunta */
    if (question.id === 8) {
      if (JSON.parse(localStorage.getItem("Q3")).id === 1) {
        this.props.onNextQuestion();
      } else {
        let emailContent;

        if (
          JSON.parse(localStorage.getItem("Q5")).id === 2 ||
          (JSON.parse(localStorage.getItem("Q5")).id === 1 &&
            JSON.parse(localStorage.getItem("Q6")).id === 2)
        ) {
          /* Enviar email de guia para sacar CUIT y clave fiscal presencial */
          emailContent =
            "Te enviamos un email con instrucciones paso a paso para sacar la CUIT y clave fiscal de forma presencial en la sucursal de la AFIP.";
          this.sendEmailGuide("O");
        } else {
          /* Enviar email de guia para sacar CUIT y clave fiscal por internet */
          emailContent =
            "Te enviamos un email con instrucciones paso a paso para sacar la CUIT y clave fiscal en el sitio web de la AFIP.";
          this.sendEmailGuide("T");
        }

        return (
          <EnvioDeEmailConInstrucciones
            question={question}
            description={emailContent}
            onNextQuestion={this.props.onNextQuestion}
          />
        );
      }
    }

    return (
      <div className="questions" key={question.id}>
        <Response question={question} questions={questions} />
        <AnswerWrapper question={question} questions={questions} />
        <div className="btns-next-back-wrapper">
          {this.renderBackButton()}
          {this.renderNextButton()}
        </div>
      </div>
    );
  }

  render() {
    return this.renderQuestion();
  }
}

Question.propTypes = {
  questions: PropTypes.array,
  questionData: PropTypes.object,
  onNextQuestion: PropTypes.func,
  onBackQuestion: PropTypes.func,
  showNextButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    questions: state.tramitesQuestionnaire,
  };
}

export default connect(mapStateToProps)(Question);
