import React from 'react';
import PropTypes from 'prop-types';
import CheckboxEQ from './CheckboxEQ';
import InputEQ from './InputEQ';
import SelectEQ from './SelectEQ';

class PosibleAnswer extends React.Component {
  renderCheckBox() {
    return (
      <CheckboxEQ
        question={this.props.question}
        onAnswerSelected={this.props.onAnswerSelected}
      />
    );
  }

  renderInput() {
    return (
      <InputEQ
        question={this.props.question}
        handleDisablingOfNextButton={this.props.handleDisablingOfNextButton}
      />
    );
  }

  renderSelect() {
    return (
      <SelectEQ
        question={this.props.question}
        handleDisablingOfNextButton={this.props.handleDisablingOfNextButton}
      />
    );
  }

  render() {
    if (
      this.props.type === 'multipleChoice' ||
      this.props.type === 'uniqueChoice'
    ) {
      return this.renderCheckBox();
    } else if (this.props.type === 'input') {
      return this.renderInput();
    } else if (this.props.type === 'select') {
      return this.renderSelect();
    }
  }
}

PosibleAnswer.propTypes = {
  question: PropTypes.object,
  type: PropTypes.string,
  onAnswerSelected: PropTypes.func,
  handleDisablingOfNextButton: PropTypes.func,
};

export default PosibleAnswer;
