import React from "react";
import PropTypes from "prop-types";

class ProgressBar extends React.Component {
  renderProgressBar() {
    return (
      <div className="container">
        <div className="progress">
          <div style={{ width: `${this.props.progress}%` }} />
          <p style={{ lineHeight: "normal" }}>{this.props.progress}%</p>
        </div>
      </div>
    );
  }
  render() {
    return this.renderProgressBar();
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;
