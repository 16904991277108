import React from "react";
import { Modal, Spin } from "antd";
import PropTypes from "prop-types";
import { clearLocalStorageExceptLogin } from "../../../../actions/clearLocalStorage";

class ModalIfUserWantsToResetEQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({
      visible: this.props.visible,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      this.setState({
        visible: nextProps.visible,
      });
    }
  }

  handleStartAgain = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        clearLocalStorageExceptLogin();
        window.location.reload();
      }
    );
  };

  renderModalIfUserWantToResetEQ() {
    return (
      <Modal
        title={
          <p className="m-0 text-center">
            ¡Ya habías empezado el <br /> cuestionario!
          </p>
        }
        centered
        visible={this.state.visible}
        maskClosable={false}
        closable={false}
        className="modal-user-want-to-reset-eq"
        footer={[
          <div key={Math.random()}>
            <Spin spinning={this.state.loading} size="large">
              <button
                className="btn btn-next btn-next--modal"
                type="button"
                key="back"
                onClick={this.props.handleCancel}
              >
                Reanudar el cuestionario
              </button>
              <button
                className="btn btn-next"
                type="button"
                key="submit"
                onClick={this.handleStartAgain}
              >
                Empezar de nuevo en cero
              </button>
            </Spin>
          </div>,
        ]}
      >
        <h6 className="m-0 text-center">
          ¿Queres volver al cuestionario donde lo dejaste?
        </h6>
      </Modal>
    );
  }

  render() {
    return this.renderModalIfUserWantToResetEQ();
  }
}

ModalIfUserWantsToResetEQ.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export default ModalIfUserWantsToResetEQ;
