import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import Nav from '../components/Nav/index';
import SimulateEQ from '../components/EQ/SimulateEQ/index';
import ProgressBar from '../components/EQ/ProgressBar/index';
import Loader from '../components/Loader/index';
import ModalIfUserWantsToResetEQ from '../components/EQ/Partials/ModalIfUserWantsToResetEQ';
import { createHistoryBrowser } from '../actions/createHistoryBrowser';
import { logout } from '../actions/authentication';
import {
  nextQuestionSimulateEQ,
  selectSpecificQuestion,
} from '../actions/nextQuestion';
import { beforeQuestionSimulateEQ } from '../actions/beforeQuestion';
import { clearLocalStorage } from '../actions/clearLocalStorage';

class SimulateCategoryQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModalIfUserWantsToResetEQ: false,
      loadingMessage: '',
    };

    this.initLogout = this.initLogout.bind(this);
    this.handleCancelModalIfUserWantsToResetEQ = this.handleCancelModalIfUserWantsToResetEQ.bind(
      this,
    );
  }

  componentWillMount() {
    // If is coming from another path then clear the localStorage
    if (localStorage.getItem('path') !== 'simulateCategory') {
      clearLocalStorage(true);
    }

    // Setting on the localStorage the current path that is doing
    localStorage.setItem('path', 'simulateCategory');

    this.setState({
      showModalIfUserWantsToResetEQ: this.props.activeQuestion > 1,
    });
  }

  componentDidMount() {
    const history = createBrowserHistory();
    const { questions } = this.props;
    const actualQuestion = questions.filter(
      questionData => questionData.id === this.props.activeQuestion,
    )[0];

    createHistoryBrowser(`/simular-categoria${actualQuestion.path}`);

    history.listen((location, action) => {
      if (action === 'POP') {
        const locationPathnameId = parseInt(
          location.pathname.replace(/[^0-9 -]+/g, ''),
        );

        // If the question to redirect is not the actual question (unnecessary redirect)
        // And if is not leaving the page
        if (
          locationPathnameId !== actualQuestion.id &&
          !isNaN(locationPathnameId)
        ) {
          this.props.selectSpecificQuestion(locationPathnameId);
        }
      }
    });
  }

  handleCancelModalIfUserWantsToResetEQ() {
    this.setState({ showModalIfUserWantsToResetEQ: false });
  }

  showNextButton() {
    return true;
  }

  showBackButton(question) {
    let bool = false;
    if ('beforeQuestion' in question) {
      bool = true;
    }

    return bool;
  }

  calculateProgress(question) {
    const EQLength = this.props.questions.length;
    const { activeQuestion } = this.props;
    let percentage;

    if (question.id !== 1) {
      percentage = Math.round((activeQuestion * 100) / EQLength);
    } else {
      percentage = 0;
    }

    return percentage;
  }

  initLogout() {
    this.setState(
      {
        isLoading: true,
        loadingMessage: 'Saliendo...',
      },
      this.finishingLogout(),
    );
  }

  finishingLogout() {
    this.props.logout();
  }

  renderLoader() {
    if (this.state.isLoading)
      return <Loader loadingMessage={this.state.loadingMessage} />;
  }

  renderQuestion() {
    const activeQuestionData = this.props.questions.filter(
      questionData => questionData.id === this.props.activeQuestion,
    )[0];

    return (
      <React.Fragment>
        {this.renderLoader()}
        <ModalIfUserWantsToResetEQ
          visible={this.state.showModalIfUserWantsToResetEQ}
          handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
        />
        <Nav initLogout={this.initLogout} />
        <div className="content">
          <ProgressBar progress={this.calculateProgress(activeQuestionData)} />
          <SimulateEQ
            questionData={activeQuestionData}
            onNextQuestion={() =>
              this.props.nextQuestionSimulateEQ(activeQuestionData)
            }
            onBackQuestion={() =>
              this.props.beforeQuestionSimulateEQ(activeQuestionData)
            }
            showNextButton={this.showNextButton()}
            showBackButton={this.showBackButton(activeQuestionData)}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.renderQuestion();
  }
}

SimulateCategoryQuestionnaire.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  activeQuestion: PropTypes.number,
  nextQuestionSimulateEQ: PropTypes.func,
  beforeQuestionSimulateEQ: PropTypes.func,
  selectSpecificQuestion: PropTypes.func,
  match: PropTypes.object,
  logout: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    questions: state.simulateCategoryQuestionnaire,
    activeQuestion: state.simulateCategoryActiveQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionSimulateEQ,
      beforeQuestionSimulateEQ,
      selectSpecificQuestion,
      logout,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimulateCategoryQuestionnaire);
