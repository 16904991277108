// States in backend
// 1 - Catamarca
// 2 - Chaco
// 3 - Chubut
// 4 - Corrientes
// 5 - Entre Rios
// 6 - Formosa
// 7 - Jujuy
// 8 - La Pampa
// 9 - La Rioja
// 10 - Mendoza
// 11 - Misiones
// 12 - Neuquen
// 13 - Rio Negro
// 14 - Salta
// 15 - San Juan
// 16 - San Luis
// 17 - Santa Cruz
// 18 - Santa Fe
// 19 - Santiago Del Estero
// 20 - Tierra Del Fuego
// 21 - Tucuman
// 22 - Buenos Aires
// 23 - Cordoba
// 24 - C.A.B.A.
import { enterprises } from "../actions/listEnterprises";
const storage = localStorage;

const posibleAnswersRepartosYa = [
  {
    id: 1,
    text: "CABA - Ciudad de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "caba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "caba",
    stateId: 24,
    nextQuestion: 2,
  },
  {
    id: 2,
    text: "BUENOS AIRES - Prov.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "provBsAs"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "provBsAs",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 3,
    text: "MENDOZA - Prov. de Mendoza",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "mendoza"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "mendoza",
    stateId: 10,
    nextQuestion: 2,
  },
  {
    id: 4,
    text: "ROSARIO - Prov. de Santa Fe",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "rosario"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "rosario",
    stateId: 18,
    nextQuestion: 2,
  },
];

const posibleAnswersMoova = [
  {
    id: 1,
    text: "CABA - Ciudad de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "caba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "caba",
    stateId: 24,
    nextQuestion: 2,
  },
  {
    id: 2,
    text: "BUENOS AIRES - Prov.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "provBsAs"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "provBsAs",
    stateId: 22,
    nextQuestion: 2,
  },
];

const posibleAnswersGlovo = [
  {
    id: 1,
    text: "CABA - Ciudad de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "caba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "300",
    location: "caba",
    stateId: 24,
    nextQuestion: 2,
  },
  {
    id: 2,
    text: "CÓRDOBA - Prov. de Córdoba",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "cordoba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "cordoba",
    stateId: 23,
    nextQuestion: 2,
  },
  {
    id: 3,
    text: "LA PLATA - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "laPlata"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "laPlata",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 4,
    text: "LOMAS DE ZAMORA - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "lomasDeZamora"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "lomasDeZamora",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 5,
    text: "MAR DEL PLATA - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "marDelPlata"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "marDelPlata",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 6,
    text: "MENDOZA - Prov. de Mendoza",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "mendoza"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "mendoza",
    stateId: 10,
    nextQuestion: 2,
  },
  {
    id: 7,
    text: "PINAMAR - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "pinamar"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "pinamar",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 8,
    text: "QUILMES - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "quilmes"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "quilmes",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 9,
    text: "ROSARIO - Prov. de Santa Fe",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "rosario"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "rosario",
    stateId: 18,
    nextQuestion: 2,
  },
  {
    id: 10,
    text: "ZONA NORTE - Prov. de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "zonaNorte"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "zonaNorte",
    stateId: 22,
    nextQuestion: 2,
  },
  {
    id: 11,
    text: "NEUQUEN",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "neuquen"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "neuquen",
    stateId: 12,
    nextQuestion: 2,
  },
];

const posibleAnswersOthers = [
  {
    id: 1,
    text: "CABA - Ciudad de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "caba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "caba",
    stateId: 24,
    nextQuestion: 2,
  },
  {
    id: 2,
    text: "CÓRDOBA - Prov. de Córdoba",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "cordoba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "cordoba",
    stateId: 23,
    nextQuestion: 2,
  },
  {
    id: 3,
    text: "ROSARIO - Prov. de Santa Fe",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "rosario"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "rosario",
    stateId: 18,
    nextQuestion: 2,
  },
];

const posibleAnswersEquipoCangur = [
  {
    id: 1,
    text: "CABA - Ciudad de Bs. As.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "caba"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "caba",
    stateId: 24,
    nextQuestion: 2,
  },
  {
    id: 2,
    text: "BUENOS AIRES - Prov.",
    selected:
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).location === "provBsAs"
          ? JSON.parse(storage.getItem("Q1")).selected
          : false
        : false,
    amount: "200",
    location: "provBsAs",
    stateId: 22,
    nextQuestion: 2,
  },
];

// For Answers
function updatePosibleAnswers(question, answer) {
  let localAnswer;

  if (!answer.showAlert) {
    localAnswer = {
      id: answer.id,
      text: answer.text,
      selected: !answer.selected,
      amount: answer.amount,
      location: answer.location,
      nextQuestion: answer.nextQuestion,
      stateId: answer.stateId,
    };
  } else {
    localAnswer = {
      id: answer.id,
      text: answer.text,
      selected: false,
      amount: answer.amount,
      location: answer.location,
      nextQuestion: answer.nextQuestion,
      stateId: answer.stateId,
      showAlert: answer.showAlert,
    };
  }

  return question.posibleAnswers.map((posibleAnswer) => {
    if (question.type === "uniqueChoice") {
      if (localAnswer.selected) {
        const newAnswer = posibleAnswer;
        newAnswer.selected = false;
        return posibleAnswer.id === answer.id ? localAnswer : newAnswer;
      }
    }
    return posibleAnswer.id === answer.id ? localAnswer : posibleAnswer;
  });
}

export function selectAnswer(questions, question, answer) {
  const localQuestion = question;
  localQuestion.posibleAnswers = updatePosibleAnswers(question, answer);

  if (answer.showAlert) {
    alert(
      "Elegiste 'Otra provincia' y por el momento no realizamos la inscripción en ingresos brutos."
    );
  } else {
    return {
      type: SELECT_ANSWER,
      payload: questions.map((questionObject) =>
        questionObject.id !== question.id
          ? questionObject
          : Object.assign(questionObject, localQuestion)
      ),
    };
  }
}

export function changePosibleAnswers(questions, question) {
  const localQuestion = question;
  const enterpriseName = localStorage.getItem("enterprise");

  if (enterpriseName === "glovo") {
    localQuestion.posibleAnswers = posibleAnswersGlovo;
    localQuestion.updated = true;
  } else if (enterpriseName === "moova") {
    localQuestion.posibleAnswers = posibleAnswersMoova;
    localQuestion.updated = true;
  } else if (enterpriseName === "pedidosya") {
    localQuestion.posibleAnswers = posibleAnswersRepartosYa;
    localQuestion.updated = true;
  } else if (enterpriseName === "cangur") {
    localQuestion.posibleAnswers = posibleAnswersEquipoCangur;
    localQuestion.updated = true;
  } else if (enterprises.includes(enterpriseName)) {
    localQuestion.updated = true;
  } else {
    localQuestion.posibleAnswers = posibleAnswersOthers;
    localQuestion.updated = true;
  }

  return {
    type: CHANGE_POSIBLE_ANSWERS,
    payload: questions.map((questionObject) =>
      questionObject.id === question.id
        ? Object.assign(questionObject, localQuestion)
        : questionObject
    ),
  };
}

export const NEXT_QUESTION = "NEXT_QUESTION";
export const NEXT_QUESTION_CUIT_VALIDATED = "NEXT_QUESTION_CUIT_VALIDATED";
export const NEXT_QUESTION_TICKET_GENERATED = "NEXT_QUESTION_TICKET_GENERATED";
export const BEFORE_QUESTION = "BEFORE_QUESTION";
export const SELECT_ANSWER = "SELECT_ANSWER";
export const CHANGE_POSIBLE_ANSWERS = "CHANGE_POSIBLE_ANSWERS";
export const CHANGE_ENTERPRISE_IN_QUESTIONS = "CHANGE_ENTERPRISE_IN_QUESTIONS";
export const LOGIN = "LOGIN";
