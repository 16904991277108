import { clearLocalStorage } from "./clearLocalStorage";
import { redirectUrl } from "./redirectUrl";

// Login
export function login() {
  localStorage.setItem("loggedIn", true);
  var expiration = new Date();
  expiration.setHours(expiration.getHours() + 24);
  localStorage.setItem("expire", expiration);

  redirectUrl(window.location.pathname.replace(/\/ingresar/g, ""));
}

// Logout
export function logout() {
  clearLocalStorage(false);
  window.location.href = process.env.REACT_APP_API_BASE_URL;
}
