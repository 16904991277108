// Save questions to localStorage
export function saveStateToLocalStorage(question) {
  if (question.id === 17) {
    let i = 0;
    let selectedQuestion = null;

    while (selectedQuestion === null && i < question.posibleAnswers.length) {
      if (question.posibleAnswers[i].selected === true) {
        selectedQuestion = question.posibleAnswers[i];
      } else {
        i += 1;
      }
    }

    const n = selectedQuestion.text.split(' ');
    localStorage.setItem('Q17', n[n.length - 1]);
  } else if (question.posibleAnswers) {
    let i = 0;
    let selectedQuestion = null;

    while (selectedQuestion === null && i < question.posibleAnswers.length) {
      if (question.posibleAnswers[i].selected === true) {
        selectedQuestion = question.posibleAnswers[i];
      } else {
        i += 1;
      }
    }

    localStorage.setItem(`Q${question.id}`, JSON.stringify(selectedQuestion));
  } else {
    const i = question.id;

    localStorage.setItem(`Q${i}`, JSON.stringify(question));
  }
}