import { SELECT_ANSWER } from "../../actions/index";

const storage = localStorage;
let Q1value;
let Q2value;
let Q3posibleAnswers;
let Q4posibleAnswers;
let Q7posibleAnswers;
let Q8posibleAnswers;

// Checking if some questions had been answered
// And create new object based on what they answered
// If is only coming from empresas path then save the result
if (storage.getItem("path") === "simulateCategory") {
  if (storage.getItem("Q1")) {
    Q1value =
      JSON.parse(storage.getItem("Q1")) !== null
        ? JSON.parse(storage.getItem("Q1")).value
        : null;
  }

  if (storage.getItem("Q2")) {
    Q2value =
      JSON.parse(storage.getItem("Q2")) !== null
        ? JSON.parse(storage.getItem("Q2")).value
        : null;
  }

  if (storage.getItem("Q3")) {
    Q3posibleAnswers = [
      {
        id: 1,
        text: "Prestación de servicios",
        selected:
          JSON.parse(storage.getItem("Q3")) !== null
            ? JSON.parse(storage.getItem("Q3")).id === 1
              ? JSON.parse(storage.getItem("Q3")).selected
              : false
            : false,
        nextQuestion: 4
      },
      {
        id: 2,
        text: "Venta de mercaderías",
        selected:
          JSON.parse(storage.getItem("Q3")) !== null
            ? JSON.parse(storage.getItem("Q3")).id === 2
              ? JSON.parse(storage.getItem("Q3")).selected
              : false
            : false,
        nextQuestion: 4
      },
      {
        id: 3,
        text: "Alquiler de propiedades y/o maquinaria de trabajo",
        selected:
          JSON.parse(storage.getItem("Q3")) !== null
            ? JSON.parse(storage.getItem("Q3")).id === 3
              ? JSON.parse(storage.getItem("Q3")).selected
              : false
            : false,
        nextQuestion: 4
      }
    ];
  }

  if (storage.getItem("Q4")) {
    Q4posibleAnswers = [
      {
        id: 1,
        text: "Prov. de Bs. As.",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "provBsAs"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "200",
        location: "provBsAs",
        stateId: 22,
        nextQuestion: 5
      },
      {
        id: 2,
        text: "CABA - Ciudad de Bs. As.",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "caba"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "200",
        location: "caba",
        stateId: 24,
        nextQuestion: 5
      },
      {
        id: 3,
        text: "CÓRDOBA - Prov. de Córdoba",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "cordoba"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "200",
        location: "cordoba",
        stateId: 23,
        nextQuestion: 5
      },
      {
        id: 4,
        text: "MENDOZA - Prov. de Mendoza",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "mendoza"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "200",
        location: "mendoza",
        stateId: 10,
        nextQuestion: 5
      },
      {
        id: 5,
        text: "ROSARIO - Prov. de Santa Fe",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "rosario"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "200",
        location: "rosario",
        stateId: 18,
        nextQuestion: 5
      },
      {
        id: 6,
        text: "Otra provincia",
        selected:
          JSON.parse(storage.getItem("Q4")) !== null
            ? JSON.parse(storage.getItem("Q4")).location === "otraProvincia"
              ? JSON.parse(storage.getItem("Q4")).selected
              : false
            : false,
        amount: "0",
        location: "otraProvincia",
        stateId: 0,
        showAlert: true
      }
    ];
  }

  if (storage.getItem("Q7")) {
    Q7posibleAnswers = [
      {
        id: 1,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q7")) !== null
            ? JSON.parse(storage.getItem("Q7")).id === 1
              ? JSON.parse(storage.getItem("Q7")).selected
              : false
            : false,
        nextQuestion: 8
      },
      {
        id: 2,
        text: "Si, trabajo en relación de dependencia",
        selected:
          JSON.parse(storage.getItem("Q7")) !== null
            ? JSON.parse(storage.getItem("Q7")).id === 2
              ? JSON.parse(storage.getItem("Q7")).selected
              : false
            : false,
        nextQuestion: 11
      },
      {
        id: 3,
        text: "Si, aporto a cajas de previsión para profesionales",
        selected:
          JSON.parse(storage.getItem("Q7")) !== null
            ? JSON.parse(storage.getItem("Q7")).id === 3
              ? JSON.parse(storage.getItem("Q7")).selected
              : false
            : false,
        nextQuestion: 11
      },
      {
        id: 4,
        text: "Si, recibo una jubilación",
        selected:
          JSON.parse(storage.getItem("Q7")) !== null
            ? JSON.parse(storage.getItem("Q7")).id === 4
              ? JSON.parse(storage.getItem("Q7")).selected
              : false
            : false,
        nextQuestion: 11
      }
    ];
  }

  if (storage.getItem("Q8")) {
    Q8posibleAnswers = [
      {
        id: 1,
        text: "Si",
        selected:
          JSON.parse(storage.getItem("Q8")) !== null
            ? JSON.parse(storage.getItem("Q8")).id === 1
              ? JSON.parse(storage.getItem("Q8")).selected
              : false
            : false,
        nextQuestion: 9
      },
      {
        id: 2,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q8")) !== null
            ? JSON.parse(storage.getItem("Q8")).id === 2
              ? JSON.parse(storage.getItem("Q8")).selected
              : false
            : false,
        nextQuestion: 10
      }
    ];
  }
}

// All Questions object
const questions = [
  {
    id: 1,
    title: "¿A qué te vas a dedicar al inscribirte en el Monotributo?",
    type: "input",
    inputDescription: "Actividad principal",
    name: "actividad",
    activities: [
      "comercio",
      "comerciales",
      "prestacion de servicios",
      "servicios",
      "vendedor",
      "venta de mercaderías",
      "venta por internet"
    ],
    placeholder: "(ej. venta de accesorios de teléfonos)",
    path: "/EQ/1",
    value: Q1value !== undefined ? Q1value : "",
    nextQuestion: 3
  },
  {
    id: 2,
    title: `Necesitamos tener más información acerca de tu actividad para poder encuadrarte en la actividad que te corresponda:`,
    type: "input",
    name: "actividadMasInfo",
    path: "/EQ/2",
    value: Q2value !== undefined ? Q2value : "",
    beforeQuestion: 1,
    nextQuestion: 3
  },
  {
    id: 3,
    title: "¿A qué rubro pertenece tu actividad principal?",
    path: "/EQ/3",
    type: "uniqueChoice",
    beforeQuestion: 1,
    posibleAnswers:
      Q3posibleAnswers !== undefined
        ? Q3posibleAnswers
        : [
            {
              id: 1,
              text: "Prestación de servicios",
              selected: false,
              nextQuestion: 4
            },
            {
              id: 2,
              text: "Venta de mercaderías",
              selected: false,
              nextQuestion: 4
            },
            {
              id: 3,
              text: "Alquiler de propiedades y/o maquinaria de trabajo",
              selected: false,
              nextQuestion: 4
            }
          ]
  },
  {
    id: 4,
    title:
      "Seleccioná la provincia (o jurisdicción) dónde vas a ejercer tu actividad",
    description:
      "El lugar donde ejercés tu actividad es aquel donde prestás el servicio o termina la venta y obtenés ingresos. En el caso de distribuir a otras provincias, el envío de la mercadería o del servicio queda excluido siempre que esté a cargo del comprador.",
    path: "/EQ/4",
    type: "uniqueChoice",
    beforeQuestion: 3,
    posibleAnswers:
      Q4posibleAnswers !== undefined
        ? Q4posibleAnswers
        : [
            {
              id: 1,
              text: "Prov. de Bs. As.",
              selected: false,
              amount: "200",
              location: "provBsAs",
              stateId: 22,
              nextQuestion: 5
            },
            {
              id: 2,
              text: "CABA - Ciudad de Bs. As.",
              selected: false,
              amount: "200",
              location: "caba",
              stateId: 24,
              nextQuestion: 5
            },
            {
              id: 3,
              text: "CÓRDOBA - Prov. de Córdoba",
              selected: false,
              amount: "200",
              location: "cordoba",
              stateId: 23,
              nextQuestion: 5
            },
            {
              id: 4,
              text: "MENDOZA - Prov. de Mendoza",
              selected: false,
              amount: "200",
              location: "mendoza",
              stateId: 10,
              nextQuestion: 5
            },
            {
              id: 5,
              text: "ROSARIO - Prov. de Santa Fe",
              selected: false,
              amount: "200",
              location: "rosario",
              stateId: 18,
              nextQuestion: 5
            },
            {
              id: 6,
              text: "Otra provincia",
              selected: false,
              amount: "0",
              location: "otraProvincia",
              stateId: 0,
              showAlert: true
            }
          ]
  },
  {
    id: 5,
    title:
      "¿Vas a destinar un local, oficina o espacio físico para la atención al público?",
    description:
      "Si destinás espacio físico a tu actividad quiere decir que recibís clientes en tu oficina o local comercial (atención al público). \nImportante: en el caso de tener una oficina para trabajar o un galpón para almacenar tu mercadería esto no se considera destinar un espacio físico a tu actividad.",
    path: "/EQ/5",
    type: "uniqueChoice",
    beforeQuestion: 4,
    nextQuestion: 6
  },
  {
    id: 6,
    title: "¿Cuál es el mínimo que estimás facturar por mes?",
    path: "/EQ/6",
    description:
      "Solo debes considerar los ingresos del Monotributo, no tengas en cuenta relación de dependencia o jubilación, etc",
    placeholder: "Seleccioná tus ingresos",
    beforeQuestion: 5,
    nextQuestion: 7
  },
  {
    id: 7,
    title:
      "¿Recibís ingresos por otra actividad distinta al Monotributo por la que realizás aportes jubilatorios?",
    path: "/EQ/7",
    type: "uniqueChoice",
    beforeQuestion: 6,
    posibleAnswers:
      Q7posibleAnswers !== undefined
        ? Q7posibleAnswers
        : [
            {
              id: 1,
              text: "No",
              selected: false,
              nextQuestion: 8
            },
            {
              id: 2,
              text: "Si, trabajo en relación de dependencia",
              selected: false,
              nextQuestion: 11
            },
            {
              id: 3,
              text: "Si, aporto a cajas de previsión para profesionales",
              selected: false,
              nextQuestion: 11
            },
            {
              id: 4,
              text: "Si, recibo una jubilación",
              selected: false,
              nextQuestion: 11
            }
          ]
  },
  {
    id: 8,
    title:
      "¿Querés adherir familiares (de primer grado de parentesco) a tu obra social del Monotributo?",
    path: "/EQ/8",
    description:
      "Cada adherente va a aumentar $1408,87 a tu cuota mensual del Monotributo",
    type: "uniqueChoice",
    beforeQuestion: 7,
    posibleAnswers:
      Q8posibleAnswers !== undefined
        ? Q8posibleAnswers
        : [
            {
              id: 1,
              text: "Si",
              selected: false,
              nextQuestion: 9
            },
            {
              id: 2,
              text: "No",
              selected: false,
              nextQuestion: 10
            }
          ]
  },
  {
    id: 9,
    title: "¿Cuántos adherentes querés agregar?",
    path: "/EQ/9",
    description:
      "Solo podrás adherir a tu cónyuge si estás legalmente casado o en unión convivencial, menor tutelado o hijo (menor de 21 años o de 25 en caso de que sea estudiante)",
    selected: 0,
    beforeQuestion: 8,
    nextQuestion: 10
  },
  {
    id: 10,
    title: "Al inscribirme en el Monotributo, declaro que:",
    path: "/EQ/10",
    beforeQuestion: 8,
    nextQuestion: 11
  },
  {
    id: 11,
    title: "Resultado de la simulación según tus respuestas",
    path: "/EQ/11",
    categoryResult: true,
    makeLink: true,
    nextQuestion: "/inscripcion",
    nextButtonText: "INSCRIBIRME AHORA"
  }
];

export default function(state = questions, action) {
  switch (action.type) {
    case SELECT_ANSWER:
      return action.payload;
    default:
      return state;
  }
}
