import {
  NEXT_QUESTION,
  NEXT_QUESTION_TICKET_GENERATED,
  NEXT_QUESTION_CUIT_VALIDATED,
  BEFORE_QUESTION,
  LOGIN,
} from '../../actions/index';

// Checking if answered based on localStorage()
function loadStateBasedOnLocalStorage() {
  const state = 1;

  return state;
}

export default function(state = loadStateBasedOnLocalStorage(), action) {
  switch (action.type) {
    case NEXT_QUESTION:
      return action.payload;
    case BEFORE_QUESTION:
      return action.payload;
    case NEXT_QUESTION_CUIT_VALIDATED:
      return action.payload;
    case NEXT_QUESTION_TICKET_GENERATED:
      return action.payload;
    case LOGIN:
      return action.payload;
    default:
      return state;
  }
}
