import React from 'react';

class SecureServicesText extends React.Component {
  renderSecureServiceText() {
    return (
      <div className="secure-services-text">
        <hr />
        <div className="secure-icon">
          <i className="fas fa-shield-alt" />
        </div>
        <div className="secure-text">
          <p>
            Utilizamos una conexi&oacute;n segura&nbsp;
            <a
              href="https://en.wikipedia.org/wiki/Elliptic_Curve_Digital_Signature_Algorithm"
              target="_blank"
              rel="noopener noreferrer"
            >
              ECDSA with SHA-256
            </a>
            &nbsp;para proteger su informaci&oacute;n
          </p>
        </div>
      </div>
    );
  }

  render() {
    return this.renderSecureServiceText();
  }
}

export default SecureServicesText;
