import React from "react";
import PropTypes from "prop-types";
import { Alert, Modal, Button } from "antd";
import NumberFormat from "react-number-format";
import CategoriesModal from "../../Partials/CategoriesModal";
import { enterprises } from "../../../../actions/listEnterprises";
import { categoriesPromise, commaStringToFloat } from '../../../../apis';

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      categorias: {},
      enterprise_name:
        localStorage.getItem("enterprise") === "pedidosya"
          ? "RepartosYa"
          : localStorage.getItem("enterprise").charAt(0).toUpperCase() +
            localStorage.getItem("enterprise").substr(1),
    };

    this.showModal = this.showModal.bind(this);
    this.handleModalOk = this.handleModalOk.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.getCategoryAmount = this.getCategoryAmount.bind(this);
  }

  componentDidMount() {
    categoriesPromise.then((categorias) => {
      this.setState({categorias});
    });
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  handleModalOk() {
    this.setState({
      showModal: false,
    });
  }

  handleModalCancel() {
    this.setState({
      showModal: false,
    });
  }

  getCategoryAmount() {
    let amount;

    // Queres adherir adherentes pregunta
    let question3 = this.props.questions.filter(
      (questionData) => questionData.id === 3
    )[0];
    if (enterprises.includes(this.state.enterprise_name.toLowerCase())) {
      question3 = this.props.questions.filter(
        (questionData) => questionData.id === 7
      )[0];
    }

    // Cantidad adherentes pregunta
    let question4 = this.props.questions.filter(
      (questionData) => questionData.id === 4
    )[0];
    if (enterprises.includes(this.state.enterprise_name.toLowerCase())) {
      question4 = this.props.questions.filter(
        (questionData) => questionData.id === 8
      )[0];
    }

    // Trabajas en relacion de dependencia pregunta
    let question2 = this.props.questions.filter(
      (questionData) => questionData.id === 2
    )[0];
    if (enterprises.includes(this.state.enterprise_name.toLowerCase())) {
      question2 = this.props.questions.filter(
        (questionData) => questionData.id === 6
      )[0];
      const answer = JSON.parse(localStorage.getItem("Q6"));
      question2.posibleAnswers[answer.id - 1] = answer;
    }

    // La opcion 1 es relacion dependencia
    const relacionDependencia =
      question2.posibleAnswers.filter((answer) => answer.selected)[0].id === 1;

    // La opcion 1 es que quiere adherir familiares
    const quiereAdherirFamiliares =
      question3.posibleAnswers.filter((answer) => answer.selected).length > 0
        ? question3.posibleAnswers.filter((answer) => answer.selected)[0].id ===
          1
        : false;

    // Cantidad de adherentes que selecciono
    const adherentes = question4.selected;

    if (relacionDependencia) {
      console.log(this.state.categorias);
      amount = this.state.categorias.hasOwnProperty('A') ?  commaStringToFloat(this.state.categorias['A'].servicios) : 0.00;
    } else if (quiereAdherirFamiliares) {
      amount = this.state.categorias.hasOwnProperty('A') ? commaStringToFloat(this.state.categorias['A'].total) + adherentes * 1408.87 : 0.00;
    } else {
      amount = this.state.categorias.hasOwnProperty('A') ? commaStringToFloat(this.state.categorias['A'].total) : 0.00;
    }

    return (
      <NumberFormat
        value={amount}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        isNumericString
        displayType="text"
        renderText={(value) => <span>$ {value}</span>}
      />
    );
  }

  isRelacionDependencia() {
    // Trabajas en relacion de dependencia pregunta
    if (!enterprises.includes(this.state.enterprise_name.toLowerCase())) {
      const question2 = this.props.questions.filter(
        (questionData) => questionData.id === 2
      )[0];

      // La opcion 2 es relacion dependencia
      return (
        question2.posibleAnswers.filter((answer) => answer.selected)[0].id === 1
      );
    } else {
      const question6 = this.props.questions.filter(
        (questionData) => questionData.id === 6
      )[0];

      // La opcion 6 es relacion dependencia
      return (
        question6.posibleAnswers.filter((answer) => answer.selected)[0].id === 1
      );
    }
  }

  getDescription(description) {
    let obj = {};
    const cuponDescriptionRelacionDependencia =
      "Para inscribirte en el Monotributo vamos a necesitar: \n\n1. CUIT\n 2. Clave fiscal (nivel 3)\n 3. La CUIT de tu empleador (tal como figura en tu recibo de sueldo).\n\n Respondé las preguntas a continuación para averiguar los próximos pasos, una vez realizado el pago, un asesor te va a estar llamando por teléfono para responder tus dudas.";
    if (this.props.question.id === 9) {
      const question2 = this.props.questions.filter((q) => q.id === 2)[0];
      const relacionDependencia =
        question2.posibleAnswers.filter((answer) => answer.selected)[0].id ===
        1;

      if (relacionDependencia) {
        description = cuponDescriptionRelacionDependencia;
      }
    }

    if (description.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, "<br />"),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, "<br /><br />"),
      };
    }

    if (description.match(/(<span>)/g)) {
      // Replace the '<span>' to <span> HTML tag
      // Replace the '</span>' to </span> HTML tag
      obj = {
        __html: description
          .replace(/(<span>)/g, "<span>")
          .replace(/(<\/span>)/g, "</span>"),
      };
    }

    return obj;
  }

  getTitle(title) {
    let obj;

    if (title.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, "<br />"),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, "<br /><br />"),
      };
    }

    return obj;
  }

  renderRespone() {
    const { question } = this.props;
    const { description, icon, moreInfoIcon } = question;
    const title =
      question.id === 1 &&
      !enterprises.includes(this.state.enterprise_name.toLowerCase())
        ? question.title + this.state.enterprise_name
        : question.title;
    const isTitle = "title" in question;
    const isDescription = "description" in question;
    const isIcon = "icon" in question;
    const isCategoryResult = "categoryResult" in question;
    let iconOfAlert;
    let response;

    if (isIcon) {
      if (icon === "success") {
        iconOfAlert = <i className="fas fa-check-circle" />;
      } else if (icon === "error") {
        iconOfAlert = <i className="fas fa-times-circle" />;
      } else if (icon === "warning") {
        iconOfAlert = <i className="fas fa-exclamation-triangle" />;
      }

      if (isTitle && !isDescription) {
        response = (
          <div className="response-wrapper">
            <Alert
              message={
                <p
                  className="title bold m-0"
                  dangerouslySetInnerHTML={this.getTitle(title)}
                />
              }
              type="success"
              icon={iconOfAlert}
              showIcon
            />
          </div>
        );
      } else if (isTitle && isDescription) {
        response = (
          <div className="response-wrapper">
            <Alert
              message={
                <p
                  className="title bold m-0"
                  dangerouslySetInnerHTML={this.getTitle(title)}
                />
              }
              type="success"
              icon={iconOfAlert}
              showIcon
            />
            <p
              className="description"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
          </div>
        );
      } else if (!isTitle && isDescription) {
        response = (
          <div className="response-wrapper">
            <p
              className="description"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
          </div>
        );
      }
    } else if (isCategoryResult) {
      response = (
        <div>
          <h5
            className="card-title"
            dangerouslySetInnerHTML={this.getTitle(title)}
          />
          <p className="card-subtitle">Categoría del Monotributo:</p>
          <div className="category-result-wrapper">
            <div>
              <ul>
                <li>
                  Categor&iacute;a <span>A</span>
                </li>
                <li>Cuota mensual de {this.getCategoryAmount()}</li>
                <li>
                  Límite de facturaci&oacute;n anual de <span>$ {this.state.categorias.hasOwnProperty('A') ? this.state.categorias['A'].iibb : "0,00"}</span>
                </li>
              </ul>
            </div>
            <button
              type="button"
              className="open-modal-categories-btn"
              onClick={this.showModal}
            >
              ¿Quer&eacute;s saber mas sobre la categor&iacute;a en la que
              est&aacute;s?
            </button>
            <Modal
              className="categories-modal"
              visible={this.state.showModal}
              centered
              closable={false}
              title="Tu categoría"
              onCancel={this.handleModalCancel}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleModalOk}
                >
                  Entendido
                </Button>,
              ]}
            >
              <CategoriesModal
                isRelacionDependencia={this.isRelacionDependencia()}
              />
            </Modal>
          </div>
        </div>
      );
    } else {
      if (isTitle && !isDescription) {
        if (moreInfoIcon) {
          response = (
            <React.Fragment>
              <Modal
                visible={this.state.showModal}
                centered
                closable={false}
                title="¿Tenés CUIT y clave fiscal?"
                onCancel={this.handleModalCancel}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleModalOk}
                  >
                    Entendido
                  </Button>,
                ]}
              >
                <p>
                  La CUIT es una clave de identificación tributaria. Contiene 11
                  números: dos dígitos iniciales, tu número de DNI y un dígito
                  final. Si bien es igual a la CUIL se obtiene en AFIP (por
                  internet o de forma presencial, dependiendo si tenés un DNI
                  argentino vigente o no).
                </p>
              </Modal>
              <h5
                className="card-title card-title--with-info"
                dangerouslySetInnerHTML={this.getTitle(title)}
              />
              <button type="button" onClick={this.showModal}>
                <i className="fas fa-question-circle" />
              </button>
            </React.Fragment>
          );
        } else {
          response = (
            <h5
              className="card-title"
              dangerouslySetInnerHTML={this.getTitle(title)}
            />
          );
        }
      } else if (isTitle && isDescription) {
        response = (
          <React.Fragment>
            <h5
              className="card-title"
              dangerouslySetInnerHTML={this.getTitle(title)}
            />
            <p
              className="card-subtitle"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
          </React.Fragment>
        );
      }
      if (!isTitle && isDescription) {
        response = (
          <p
            className="card-subtitle"
            dangerouslySetInnerHTML={this.getDescription(description)}
          />
        );
      }
    }

    return <div>{response}</div>;
  }

  render() {
    return this.renderRespone();
  }
}

Response.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  question: PropTypes.object,
};

export default Response;
