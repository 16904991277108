import React from 'react';
import PropTypes from 'prop-types';

class MasDeTresActividades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noTengoMasDe3ActividadesSelected: true,
      noImportoBienesSelected: true,
      noMeInscriboParaIngresosSociedadSelected: true,
      elPrecioDeVentaUnitarioNoEsSuperiorSelected: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checkbox) {
    this.setState(prevState => ({
      [`${checkbox}Selected`]: !prevState[`${checkbox}Selected`],
    }));
  }

  renderNextButtonVentaMuebles() {
    // If its Venta muebles on activity question
    if (JSON.parse(localStorage.getItem('Q3')).id === 2) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className={`${
              this.state.noImportoBienesSelected &&
              this.state.noTengoMasDe3ActividadesSelected &&
              this.state.noMeInscriboParaIngresosSociedadSelected &&
              this.state.elPrecioDeVentaUnitarioNoEsSuperiorSelected
                ? 'remove-disable'
                : false
            } btn-next`}
            type="button"
            onClick={this.props.onNextQuestion}
            disabled={
              this.state.noImportoBienesSelected === false ||
              this.state.noTengoMasDe3ActividadesSelected === false ||
              this.state.noMeInscriboParaIngresosSociedadSelected === false ||
              this.state.elPrecioDeVentaUnitarioNoEsSuperiorSelected === false
            }
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    }
  }

  renderNextButtonPrestacionServicio() {
    // If its not Venta muebles on activity question
    if (JSON.parse(localStorage.getItem('Q3')).id !== 2) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className={`${
              this.state.noImportoBienesSelected &&
              this.state.noTengoMasDe3ActividadesSelected &&
              this.state.noMeInscriboParaIngresosSociedadSelected
                ? 'remove-disable'
                : false
            } btn-next`}
            type="button"
            onClick={this.props.onNextQuestion}
            disabled={
              this.state.noImportoBienesSelected === false ||
              this.state.noTengoMasDe3ActividadesSelected === false ||
              this.state.noMeInscriboParaIngresosSociedadSelected === false
            }
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    }
  }

  renderOptionsVentaMuebles() {
    // If its Venta muebles on activity question
    if (JSON.parse(localStorage.getItem('Q3')).id === 2) {
      return (
        <React.Fragment>
          <div>
            <div
              className={
                this.state.elPrecioDeVentaUnitarioNoEsSuperiorSelected
                  ? 'selected'
                  : null
              }
            >
              <label htmlFor="elPrecioDeVentaUnitarioNoEsSuperior">
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={
                    this.state.elPrecioDeVentaUnitarioNoEsSuperiorSelected
                  }
                  id="elPrecioDeVentaUnitarioNoEsSuperior"
                  onChange={() =>
                    this.handleChange('elPrecioDeVentaUnitarioNoEsSuperior')
                  }
                />
                El precio de venta unitario de mis productos NO es superior a
                $19.269,14
              </label>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderDefaultOptions() {
    return (
      <React.Fragment>
        <div>
          <div
            className={
              this.state.noTengoMasDe3ActividadesSelected ? 'selected' : null
            }
          >
            <label htmlFor="noTengoMasDe3Actividades">
              <input
                className="mr-2"
                type="checkbox"
                checked={this.state.noTengoMasDe3ActividadesSelected}
                id="noTengoMasDe3Actividades"
                onChange={() => this.handleChange('noTengoMasDe3Actividades')}
              />
              No tengo más de 3 actividades, fuentes de ingresos y/o unidades de
              explotación
            </label>
          </div>
        </div>
        <div className={this.state.noImportoBienesSelected ? 'selected' : null}>
          <label htmlFor="noImportoBienes">
            <input
              className="mr-2"
              type="checkbox"
              checked={this.state.noImportoBienesSelected}
              id="noImportoBienes"
              onChange={() => this.handleChange('noImportoBienes')}
            />
            No importo bienes y/o servicios (ni importé en los últimos 12 meses)
            para su comercialización
          </label>
        </div>
        <div>
          <div
            className={
              this.state.noMeInscriboParaIngresosSociedadSelected
                ? 'selected'
                : null
            }
          >
            <label htmlFor="noMeInscriboParaIngresosSociedad">
              <input
                className="mr-2"
                type="checkbox"
                checked={this.state.noMeInscriboParaIngresosSociedadSelected}
                id="noMeInscriboParaIngresosSociedad"
                onChange={() =>
                  this.handleChange('noMeInscriboParaIngresosSociedad')
                }
              />
              No me inscribo para recibir ingresos de una sociedad (S.A., S.R.L,
              etc) ni por ser administrador o director de la misma
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="questions">
        <h5 className="card-title">{this.props.question.title}</h5>
        {this.renderDefaultOptions()}
        {this.renderOptionsVentaMuebles()}
        {this.renderNextButtonPrestacionServicio()}
        {this.renderNextButtonVentaMuebles()}
      </div>
    );
  }
}

MasDeTresActividades.propTypes = {
  question: PropTypes.object,
  title: PropTypes.string,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default MasDeTresActividades;
