import { SELECT_ANSWER } from "../../actions/index";

// All Questions object
const questions = [
  {
    id: 1,
    title: "Completá tus datos para que podamos ayudarte con tu trámite",
    path: "/EQ/1/",
    nextQuestion: 2,
  },
  {
    id: 2,
    title: "Creá tu cuenta de Gestorando",
    path: "/registro/",
    description:
      "Crear una cuenta en Gestorando te va a permitir volver a nuestro sitio y tener toda tu información en un lugar",
    nextQuestion: 11,
  },
  {
    id: 11,
  },
];

export default function (state = questions, { type, payload }) {
  switch (type) {
    case SELECT_ANSWER:
      return payload;
    default:
      return state;
  }
}
