import { SELECT_ANSWER } from "./index";
import { enterprises } from "../actions/listEnterprises";
// Cantidad de adherentes EmpresasEQ
export function updateAdherentesQuantityEmpresasEQ(questions, selected) {
  const enterprise = localStorage.getItem("enterprise");
  if (enterprises.includes(enterprise)) {
    const question8 = questions.filter(
      (questionData) => questionData.id === 8
    )[0];
    question8.selected = selected;

    return {
      type: SELECT_ANSWER,
      payload: questions.map((questionObject) =>
        questionObject.id !== 8
          ? questionObject
          : Object.assign(questionObject, question8)
      ),
    };
  }
  const question4 = questions.filter(
    (questionData) => questionData.id === 4
  )[0];
  question4.selected = selected;

  return {
    type: SELECT_ANSWER,
    payload: questions.map((questionObject) =>
      questionObject.id !== 4
        ? questionObject
        : Object.assign(questionObject, question4)
    ),
  };
}

// Cantidad de adherentes SimulateEQ
export function updateAdherentesQuantitySimulateEQ(questions, selected) {
  const question9 = questions.filter(
    (questionData) => questionData.id === 9
  )[0];
  question9.selected = selected;

  return {
    type: SELECT_ANSWER,
    payload: questions.map((questionObject) =>
      questionObject.id !== 9
        ? questionObject
        : Object.assign(questionObject, question9)
    ),
  };
}
