import {
  TOGGLE_DASHBOARD_LOADING,
  TOGGLE_SHOW_REQUEST_PROGRESS_BAR,
  HANDLE_NOTIFICATION_MESSAGE,
  WEB_APP_ONLINE,
} from './types';

/*
* Action creators
*/

export const toggleDashboardLoading = bool => ({
  type: TOGGLE_DASHBOARD_LOADING,
  loading: bool,
});

export const toggleShowRequestProgressBar = bool => ({
  type: TOGGLE_SHOW_REQUEST_PROGRESS_BAR,
  show: bool,
});

export const handleNotificationMessage = (
  show,
  notificationType,
  notificationMessage,
) => ({
  type: HANDLE_NOTIFICATION_MESSAGE,
  show,
  notificationType,
  notificationMessage,
});

export const navigatorOnline = () => ({
  type: WEB_APP_ONLINE,
  payload: navigator.onLine,
});
