export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

function calculateCategoryCuota(baseAmount, jubilacion) {
  // Actividad: Prestacion de Servicios y Venta de cosas Muebles
  // Ingresos por otra actividad: Jubilacion
  if (baseAmount !== undefined && jubilacion === undefined)
    return Math.round((baseAmount - VALOR_OBRA_SOCIAL) * 100) / 100;

  // Actividad: Prestacion de Servicios, Venta de cosas Muebles, y Alquiler de propeidades
  // Ingresos por otra actividad: Relacion Dependencia y Caja de profesionales
  if (baseAmount !== undefined && jubilacion !== undefined)
    return (
      Math.round((baseAmount - jubilacion - VALOR_OBRA_SOCIAL) * 100) / 100
    );
}

export const GTM_PRODUCCION = "GTM-K7V38L";
export const GTM_DEVELOPMENT = "GTM-W99RJPP";

export const VALOR_OBRA_SOCIAL = 1775.18;

export const CATEGORIAS_SUPERFICIE = [
  {
    value: "30",
    label: "Hasta 30m²",
    categoria: "1",
  },
  {
    value: "45",
    label: "Hasta 45m²",
    categoria: "2",
  },
  {
    value: "60",
    label: "Hasta 60m²",
    categoria: "3",
  },
  {
    value: "85",
    label: "Hasta 85m²",
    categoria: "4",
  },
  {
    value: "110",
    label: "Hasta 110m²",
    categoria: "5",
  },
  {
    value: "150",
    label: "Hasta 150m²",
    categoria: "6",
  },
  {
    value: "200",
    label: "Hasta 200m²",
    categoria: "7",
  },
];

export const CATEGORIAS_ALQUILER = [
  {
    value: "no",
    label: "No alquilo",
    categoria: "1",
  },
  {
    value: "105.916,77",
    label: "Hasta $ 105.916,77",
    categoria: "1",
  },
  {
    value: "211.833,52",
    label: "Hasta $ 211.833,52",
    categoria: "3",
  },
  {
    value: "263.951,28",
    label: "Hasta $ 263.951,28",
    categoria: "5",
  },
  {
    value: "264.791,88",
    label: "Hasta $ 264.791,88",
    categoria: "6",
  },
  {
    value: "317.750,28",
    label: "Hasta $ 317.750,28",
    categoria: "7",
  },
  {
    value: "423.667,03",
    label: "Hasta $ 423.667,03",
    categoria: "8",
  },
];

export const CATEGORIAS_ENERGIA = [
  {
    value: "no",
    label: "No aplica para mi actividad",
    categoria: "1",
  },
  {
    value: "3300",
    label: "Hasta 3330 Kw",
    categoria: "1",
  },
  {
    value: "5000",
    label: "Hasta 5000 Kw",
    categoria: "2",
  },
  {
    value: "6700",
    label: "Hasta 6700 Kw",
    categoria: "3",
  },
  {
    value: "10000",
    label: "Hasta 10000 Kw",
    categoria: "4",
  },
  {
    value: "13000",
    label: "Hasta 13000 Kw",
    categoria: "5",
  },
  {
    value: "16500",
    label: "Hasta 16500 Kw",
    categoria: "6",
  },
  {
    value: "20000",
    label: "Hasta 20000 Kw",
    categoria: "7",
  },
];

export const CATEGORIAS = [
  {
    value: "1",
    categoria: "A",
    label: "$ 282.444",
    facturacionMensual: "$ 23.537",
    impuestoJubilacion: 1008.72,
    valorCuota: 2646.22,
    valorCuotaVentaMuebles: 2646.22,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(2646.22),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      2646.22,
      1008.72
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      2646.22,
      1008.72
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(2646.22),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      2646.22,
      1008.72
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      2646.22,
      1008.72
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(2646.22, 1008.72),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "2",
    categoria: "B",
    label: "$ 423.667",
    facturacionMensual: "$ 35.305",
    impuestoJubilacion: 1109.59,
    valorCuota: 2958.95,
    valorCuotaVentaMuebles: 2958.95,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(2958.95),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      2958.95,
      1109.59
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      2958.95,
      1109.59
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(2958.95),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      2958.95,
      1109.59
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      2958.95,
      1109.59
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(2958.95, 1109.59),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "3",
    categoria: "C",
    label: "$ 564.889",
    facturacionMensual: "$ 47.074",
    impuestoJubilacion: 1220.56,
    valorCuota: 3382.62,
    valorCuotaVentaMuebles: 3325.44,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(3382.62),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      3382.62,
      1220.56
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      3382.62,
      1220.56
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(3325.44),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      3325.44,
      1220.56
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      3325.44,
      1220.56
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(3382.62, 1220.56),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "4",
    categoria: "D",
    label: "$ 847.334",
    facturacionMensual: "$ 70.611",
    impuestoJubilacion: 1342.61,
    valorCuota: 3988.85,
    valorCuotaVentaMuebles: 3894.71,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(3988.85),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      3988.85,
      1342.61
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      3988.85,
      1342.61
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(3894.71),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      3894.71,
      1342.61
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      3894.71,
      1342.61
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(3988.85, 1342.61),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "5",
    categoria: "E",
    label: "$ 1.129.778",
    facturacionMensual: "$ 94.148",
    impuestoJubilacion: 1476.88,
    valorCuota: 5239.44,
    valorCuotaVentaMuebles: 4711.54,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(5239.44),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      5239.44,
      1476.88
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      5239.44,
      1476.88
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(4711.54),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      4711.54,
      1476.88
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      4711.54,
      1476.88
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(5239.44, 1476.88),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "6",
    categoria: "F",
    label: "$ 1.412.223",
    facturacionMensual: "$ 117.685",
    impuestoJubilacion: 1624.56,
    valorCuota: 6271.46,
    valorCuotaVentaMuebles: 5417.38,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(6271.46),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      6271.46,
      1624.56
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      6271.46,
      1624.56
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(5417.38),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      5417.38,
      1624.56
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      5417.38,
      1624.56
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(6271.46, 1624.56),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "7",
    categoria: "G",
    label: "$ 1.694.668",
    facturacionMensual: "$ 141.222",
    impuestoJubilacion: 1787.01,
    valorCuota: 7314.87,
    valorCuotaVentaMuebles: 6168.24,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(7314.87),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      7314.87,
      1787.01
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      7314.87,
      1787.01
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(6168.24),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      6168.24,
      1787.01
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      6168.24,
      1787.01
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(7314.87, 1787.01),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "8",
    categoria: "H",
    label: "$ 2.353.705",
    facturacionMensual: "$ 196.142",
    impuestoJubilacion: 1965.71,
    valorCuota: 12789.38,
    valorCuotaVentaMuebles: 10671.08,
    valorCuotaPrestacionServicioJubilacion: calculateCategoryCuota(12789.38),
    valorCuotaPrestacionServicioRelacionDependencia: calculateCategoryCuota(
      12789.38,
      1965.71
    ),
    valorCuotaPrestacionServicioCajaProfesionales: calculateCategoryCuota(
      12789.38,
      1965.71
    ),
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(10671.08),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      10671.08,
      1965.71
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      10671.08,
      1965.71
    ),
    valorCuotaAlquilerPropiedades: calculateCategoryCuota(12789.38, 1965.71),
    categoriaVentaCosasMuebles: false,
  },
  {
    value: "9",
    categoria: "I",
    label: "$ 2.765.604",
    facturacionMensual: "$ 230.467",
    impuestoJubilacion: 2162.29,
    valorCuotaVentaMuebles: 15339.68,
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(15339.68),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      15339.68,
      2162.29
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      15339.68,
      2162.29
    ),
    categoriaVentaCosasMuebles: true,
  },
  {
    value: "10",
    categoria: "J",
    label: "$ 3.177.502",
    facturacionMensual: "$ 264.791",
    impuestoJubilacion: 2378.53,
    valorCuotaVentaMuebles: 17617.1,
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(17617.1),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      17617.1,
      2378.53
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      17617.1,
      2378.53
    ),
    categoriaVentaCosasMuebles: true,
  },
  {
    value: "11",
    categoria: "K",
    label: "$ 3.530.558",
    facturacionMensual: "$ 294.213",
    impuestoJubilacion: 2616.36,
    valorCuotaVentaMuebles: 19912.74,
    valorCuotaVentaCosasMueblesJubilacion: calculateCategoryCuota(19912.74),
    valorCuotaVentaCosasMueblesRelacionDependencia: calculateCategoryCuota(
      19912.74,
      2616.36
    ),
    valorCuotaVentaCosasMueblesCajaProfesionales: calculateCategoryCuota(
      19912.74,
      2616.36
    ),
    categoriaVentaCosasMuebles: true,
  },
];
