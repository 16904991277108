/* Action types */

/*
 * General
 */
export const DISABLE_ALL_FIELDS = "DISABLE_ALL_FIELDS";
export const ENABLE_ALL_FIELDS = "ENABLE_ALL_FIELDS";
export const SHOW_ERRORS_GENERATED_BY_GENERATING_INVOICE =
  "SHOW_ERRORS_GENERATED_BY_GENERATING_INVOICE";
export const FETCH_CHECK_EMAIL_VALIDATION = "FETCH_CHECK_EMAIL_VALIDATION";
export const SHOW_MODAL_WHEN_INVOICE_CREATED =
  "SHOW_MODAL_WHEN_INVOICE_CREATED";

/*
 * Steps next and previous button
 */
export const HANDLE_NEXT_STEP = "HANDLE_NEXT_STEP";
export const HANDLE_PREVIOUS_STEP = "HANDLE_PREVIOUS_STEP";

/*
 * First Step fields handling
 */
export const HANDLE_SELECT_CONCEPT_CHANGE = "HANDLE_INPUT_CONCEPT_CHANGE";
export const HANDLE_DATE_PICKER_CHANGE = "HANDLE_DATE_PICKER_CHANGE";
export const HANDLE_DATE_RANGE_CHANGE = "HANDLE_DATE_RANGE_CHANGE";
export const HANDLE_CHECKBOX_SALE_CONDITION_CHANGE =
  "HANDLE_CHECKBOX_SALE_CONDITION_CHANGE";

/*
 * Second Step fields handling
 */
export const HANDLE_ADD_ITEM = "HANDLE_ADD_ITEM";
export const HANDLE_REMOVE_ITEM = "HANDLE_REMOVE_ITEM";
export const HANDLE_INPUT_ITEM_CHANGE = "HANDLE_INPUT_ITEM_CHANGE";

/*
 * Third Step fields handling
 */
export const HANDLE_INPUT_COMERCIAL_ADDRESS_CHANGE =
  "HANDLE_INPUT_COMERCIAL_ADDRESS_CHANGE";
export const HANDLE_INPUT_NUMBER_DOCUMENT_CHANGE =
  "HANDLE_INPUT_NUMBER_DOCUMENT_CHANGE";
export const HANDLE_SELECT_TYPE_DOCUMENT_CHANGE =
  "HANDLE_SELECT_TYPE_DOCUMENT_CHANGE";
export const HANDLE_INPUT_SOCIAL_NAME_CHANGE =
  "HANDLE_INPUT_SOCIAL_NAME_CHANGE";
export const HANDLE_CHECKBOX_EMAIL_CLIENT_CHANGE =
  "HANDLE_CHECKBOX_EMAIL_CLIENT_CHANGE";
export const HANDLE_INPUT_EMAIL_CLIENT_CHANGE =
  "HANDLE_INPUT_EMAIL_CLIENT_CHANGE";
export const HANDLE_RADIO_COMERCIAL_ADDRESS_CHANGE =
  "HANDLE_RADIO_COMERCIAL_ADDRESS_CHANGE";
export const HANDLE_SELECT_GLOVO_PERIOD_INVOICE_CHANGE =
  "HANDLE_SELECT_GLOVO_PERIOD_INVOICE_CHANGE";
export const HANDLE_RADIO_MULTIPLE_USERS_FOUND_BY_DOCUMENT_CHANGE =
  "HANDLE_RADIO_MULTIPLE_USERS_FOUND_BY_DOCUMENT_CHANGE";
export const HANDLE_CLEAR_SOCIAL_NAME_AND_COMERCIAL_ADDRESS =
  "HANDLE_CLEAR_SOCIAL_NAME_AND_COMERCIAL_ADDRESS";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_INVOICE_GENERATION = "FETCH_INVOICE_GENERATION";
export const CHECK_GENERATED_INVOICE_STATUS = "CHECK_GENERATED_INVOICE_STATUS";
export const DISABLE_EMAIL_FIELDS = "DISABLE_EMAIL_FIELDS";
export const ENABLE_EMAIL_FIELDS = "ENABLE_EMAIL_FIELDS";
