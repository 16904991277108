import {
  NEXT_QUESTION,
  NEXT_QUESTION_TICKET_GENERATED,
  NEXT_QUESTION_CUIT_VALIDATED,
  BEFORE_QUESTION,
  LOGIN,
} from "../../actions/index";

// Checking if answered based on localStorage()
function loadStateBasedOnLocalStorage() {
  const storage = localStorage;
  let state = 1;

  if (storage.getItem("Q10")) {
    state = 11;
  } else if (storage.getItem("Q9")) {
    if (JSON.parse(storage.getItem("Q9")).metodoDePago === "ticket") {
      state = 11;
    } else {
      state = 10;
    }
  } else if (storage.getItem("Q8")) {
    state = 9;
  } else if (storage.getItem("Q7")) {
    state = 8;
  } else if (storage.getItem("Q6")) {
    state = 7;
  } else if (storage.getItem("Q5")) {
    state = JSON.parse(storage.getItem("Q5")).nextQuestion;
  } else if (storage.getItem("Q4")) {
    if (JSON.parse(storage.getItem("Q4")).cuitValidated === true) {
      state = 7;
    } else {
      state = 5;
    }
  } else if (storage.getItem("Q3")) {
    state = JSON.parse(storage.getItem("Q3")).nextQuestion;
  } else if (storage.getItem("Q2")) {
    state = 3;
  } else if (storage.getItem("Q1")) {
    state = 2;
  }

  // If is coming from another path then set to 1
  if (storage.getItem("path") !== "plans") {
    state = 1;
  }

  return state;
}

export default function (
  state = loadStateBasedOnLocalStorage(),
  { type, payload }
) {
  switch (type) {
    case NEXT_QUESTION:
      return payload;
    case BEFORE_QUESTION:
      return payload;
    case NEXT_QUESTION_CUIT_VALIDATED:
      return payload;
    case NEXT_QUESTION_TICKET_GENERATED:
      return payload;
    case LOGIN:
      return payload;
    default:
      return state;
  }
}
