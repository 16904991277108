import React from "react";
import PropTypes from "prop-types";
import LogoAmex from "../../../../assets/img/logo-amex.png";
import LogoMastercard from "../../../../assets/img/logo-mastercard.svg";
import LogoVisa from "../../../../assets/img/logo-visa.svg";

class Buttons extends React.Component {
  renderButtons() {
    return (
      <div className="btns-payment-wrapper">
        <div>
          <button
            className={`btn
            ${this.props.creditCardState ? "selected" : false}`}
            type="button"
            id="btn-credit-card"
            onClick={e => this.props.toggleClass(e)}
          >
            <p>
              Tarjeta de cr&eacute;ditos{" "}
              <strong>($ {this.props.basePriceCreditCard})</strong>
            </p>
            <div className="images">
              <img
                className="visa img-fluid"
                src={LogoVisa}
                alt="Logo Visa"
                title="Logo Visa"
              />
              <img
                className="mastercard img-fluid"
                src={LogoMastercard}
                alt="Logo de MasterCard"
                title="Logo de MasterCard"
              />
              <img
                className="amex img-fluid"
                src={LogoAmex}
                alt="Logo de AmericanExpress"
                title="Logo de AmericanExpress"
              />
            </div>
          </button>
        </div>
        <div>
          <button
            className={`btn
                  ${this.props.otherPaymentState ? "selected" : false}`}
            type="button"
            id="btn-other-payment"
            onClick={e => this.props.toggleClass(e)}
          >
            <p>
              Otros medios de pagos <br />
              <strong>($ {this.props.basePriceOtherPayment})</strong>
            </p>
          </button>
        </div>
      </div>
    );
  }

  render() {
    return this.renderButtons();
  }
}

Buttons.propTypes = {
  toggleClass: PropTypes.func,
  creditCardState: PropTypes.bool,
  otherPaymentState: PropTypes.bool,
  basePriceCreditCard: PropTypes.any,
  basePriceOtherPayment: PropTypes.string
};

export default Buttons;
