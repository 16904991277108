import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import EmpresasQuestionnaire from "./containers/EmpresasQuestionnaire";
import InscripcionQuestionnaire from "./containers/InscripcionQuestionnaire";
import PlansQuestionnaire from "./containers/PlansQuestionnaire";
import TramitesQuestionnaire from "./containers/TramitesQuestionnaire";
import SimulateCategoryQuestionnarie from "./containers/SimulateCategoryQuestionnaire";
import UserAuthentication from "./components/UserAuthentication/";
import Dashboard from "./containers/Dashboard/";
import NoMatch from "./components/NoMatch";
import { logout } from "./actions/authentication";

const isLoggedIn = localStorage.getItem("loggedIn")
  ? !!localStorage.getItem("loggedIn")
  : !!localStorage.getItem("token");
if (isLoggedIn) {
  let now = new Date();
  let expire_session = new Date(localStorage.getItem("expire"));
  if (now > expire_session) {
    logout();
  }
}
export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Inscripcion path */}
        <Route
          path="/inscripcion/"
          render={(routeProps) =>
            isLoggedIn ? (
              <Redirect to="/panel/" />
            ) : (
              <>
                {/*<InscripcionQuestionnaire {...routeProps} />*/}
                <Redirect to="/planes-productos/" />
                </>
            )
          }
        />
        <Route
          path="/planes-productos/"
          component={() => {
              window.location.href = 'https://gestorando.com/planes-productos/';
              return null;
          }}
        />
        {/* Empresas path */}
        <Route
          path="/suscripcion/:enterprise/EQ/:question/"
          render={(routeProps) =>
            isLoggedIn ? (
              <Redirect to="/panel/" />
            ) : (
              <EmpresasQuestionnaire {...routeProps} />
            )
          }
        />
        <Route
          path="/suscripcion/:enterprise/"
          render={(routeProps) =>
            isLoggedIn ? (
              <Redirect to="/panel/" />
            ) : (
              <EmpresasQuestionnaire {...routeProps} />
            )
          }
        />
        {/* Plans EQ */}
        <Route
          path={["/plans/EQ/:question/:identifier/", "/plans/EQ/:question/"]}
          render={(routeProps) =>
            isLoggedIn ? (
              <Redirect to="/panel/" />
            ) : (
              <PlansQuestionnaire {...routeProps} />
            )
          }
        />
        {/* Tramites EQ */}
        <Route
          path={[
            "/tramites/EQ/:question/:identifier/",
            "/tramites/EQ/:question/",
          ]}
          render={(routeProps) =>
            isLoggedIn ? (
              <Redirect to="/panel/" />
            ) : (
              <TramitesQuestionnaire {...routeProps} />
            )
          }
        />
        {/* Simulate Category path */}
        <Route
          path="/simular-categoria/"
          component={SimulateCategoryQuestionnarie}
        />
        {/* Common URL's */}
        <Route
          path="/panel"
          render={({ location }) =>
            isLoggedIn ? (
              <Dashboard />
            ) : (
              <Redirect to={`/ingresar${location.pathname}`} />
            )
          }
        />
        {/* If its logged in, redirect to the panel */}
        <Route
          path={["/ingresar/:redirect", "/ingresar/"]}
          render={({ location }) =>
            isLoggedIn ? (
              <Redirect to={location.pathname.replace(/\/ingresar/g, "")} />
            ) : (
              <UserAuthentication />
            )
          }
        />
        <Route
          path="/login/"
          exact
          render={() =>
            isLoggedIn ? <Redirect to="/panel/" /> : <UserAuthentication />
          }
        />
        <Route
          path="/olvide-contrasena/"
          exact
          render={() =>
            isLoggedIn ? <Redirect to="/panel/" /> : <UserAuthentication />
          }
        />
        <Route
          path="/restaurar-contrasena/:identifier/"
          exact
          render={() =>
            isLoggedIn ? <Redirect to="/panel" /> : <UserAuthentication />
          }
        />
        <Route
          path="/restore-password/:identifier/"
          exact
          render={() =>
            isLoggedIn ? <Redirect to="/panel/" /> : <UserAuthentication />
          }
        />
        {/* Error 404 page */}
        <Route
          render={() => (isLoggedIn ? <Redirect to="/panel/" /> : <NoMatch />)}
        />
      </Switch>
    </BrowserRouter>
  );
}
