import React from 'react';
import PropTypes from 'prop-types';
import CantidadAdherentes from './CantidadAdherentes';

class AgregarAdherentes extends React.Component {
  renderAgregarAdherentes() {
    return (
      <div className="questions">
        <h5 className="card-title">{this.props.title}</h5>
        <p className="card-subtitle">{this.props.description}</p>
        <CantidadAdherentes
          onBackQuestion={this.props.onBackQuestion}
          onNextQuestion={this.props.onNextQuestion}
        />
      </div>
    );
  }

  render() {
    return this.renderAgregarAdherentes();
  }
}

AgregarAdherentes.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default AgregarAdherentes;
