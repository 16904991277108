import { SELECT_ANSWER } from "../../actions/index";
import { changeEnterpriseInQuestions } from "../../actions/changeEnterpriseInQuestions";
import { enterprises } from "../../actions/listEnterprises";
const storage = localStorage;
let Q1posibleAnswers,
  Q2posibleAnswers,
  Q3posibleAnswers,
  Q10posibleAnswers,
  Q12posibleAnswers,
  Q13posibleAnswers;

// Checking if some questions had been answered
// And create new object based on what they answered
// If is only coming from empresas path then save the result
if (storage.getItem("path") === "empresas") {
  if (storage.getItem("Q1")) {
    Q1posibleAnswers = [
      {
        id: 1,
        text: "CABA - Ciudad de Bs. As.",
        selected:
          JSON.parse(storage.getItem("Q1")) !== null
            ? JSON.parse(storage.getItem("Q1")).location === "caba"
              ? JSON.parse(storage.getItem("Q1")).selected
              : false
            : false,
        amount: "300",
        location: "caba",
        stateId: 24,
        nextQuestion: 2,
      },
      {
        id: 2,
        text: "CÓRDOBA - Prov. de Córdoba",
        selected:
          JSON.parse(storage.getItem("Q1")) !== null
            ? JSON.parse(storage.getItem("Q1")).location === "cordoba"
              ? JSON.parse(storage.getItem("Q1")).selected
              : false
            : false,
        amount: "300",
        location: "cordoba",
        stateId: 23,
        nextQuestion: 2,
      },
      {
        id: 3,
        text: "ROSARIO - Prov. de Bs. As.",
        selected:
          JSON.parse(storage.getItem("Q1")) !== null
            ? JSON.parse(storage.getItem("Q1")).location === "rosario"
              ? JSON.parse(storage.getItem("Q1")).selected
              : false
            : false,
        amount: "300",
        location: "rosario",
        stateId: 18,
        nextQuestion: 2,
      },
    ];
  }

  if (storage.getItem("Q2")) {
    Q2posibleAnswers = [
      {
        id: 1,
        text: "Si, recibo un sueldo en blanco",
        selected:
          JSON.parse(storage.getItem("Q2")) !== null
            ? JSON.parse(storage.getItem("Q2")).id === 1
              ? JSON.parse(storage.getItem("Q2")).selected
              : false
            : false,
        nextQuestion: 5,
      },
      {
        id: 2,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q2")) !== null
            ? JSON.parse(storage.getItem("Q2")).id === 2
              ? JSON.parse(storage.getItem("Q2")).selected
              : false
            : false,
        nextQuestion: 3,
      },
    ];
  }

  if (storage.getItem("Q3")) {
    Q3posibleAnswers = [
      {
        id: 1,
        text: "Si",
        selected:
          JSON.parse(storage.getItem("Q3")) !== null
            ? JSON.parse(storage.getItem("Q3")).id === 1
              ? JSON.parse(storage.getItem("Q3")).selected
              : false
            : false,
        nextQuestion: 4,
      },
      {
        id: 2,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q3")) !== null
            ? JSON.parse(storage.getItem("Q3")).id === 2
              ? JSON.parse(storage.getItem("Q3")).selected
              : false
            : false,
        nextQuestion: 5,
      },
    ];
  }

  if (storage.getItem("Q10")) {
    Q10posibleAnswers = [
      {
        id: 1,
        text: "Si",
        selected:
          JSON.parse(storage.getItem("Q10")) !== null
            ? JSON.parse(storage.getItem("Q10")).id === 1
              ? JSON.parse(storage.getItem("Q10")).selected
              : false
            : false,
        nextQuestion: 11,
      },
      {
        id: 2,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q10")) !== null
            ? JSON.parse(storage.getItem("Q10")).id === 2
              ? JSON.parse(storage.getItem("Q10")).selected
              : false
            : false,
        nextQuestion: 12,
      },
    ];
  }

  if (storage.getItem("Q12")) {
    Q12posibleAnswers = [
      {
        id: 1,
        text: "DNI argentino",
        selected:
          JSON.parse(storage.getItem("Q12")) !== null
            ? JSON.parse(storage.getItem("Q12")).id === 1
              ? JSON.parse(storage.getItem("Q12")).selected
              : false
            : false,
        nextQuestion: 13,
      },
      {
        id: 2,
        text: "Precaria",
        selected:
          JSON.parse(storage.getItem("Q12")) !== null
            ? JSON.parse(storage.getItem("Q12")).id === 2
              ? JSON.parse(storage.getItem("Q12")).selected
              : false
            : false,
        nextQuestion: 14.1,
      },
    ];
  }

  if (storage.getItem("Q13")) {
    Q13posibleAnswers = [
      {
        id: 1,
        text: "Si",
        selected:
          JSON.parse(storage.getItem("Q13")) !== null
            ? JSON.parse(storage.getItem("Q13")).id === 1
              ? JSON.parse(storage.getItem("Q13")).selected
              : false
            : false,
        nextQuestion: 14,
      },
      {
        id: 2,
        text: "No",
        selected:
          JSON.parse(storage.getItem("Q13")) !== null
            ? JSON.parse(storage.getItem("Q13")).id === 2
              ? JSON.parse(storage.getItem("Q13")).selected
              : false
            : false,
        nextQuestion: 14.1,
      },
    ];
  }
}

// All Questions object
const questions = [
  {
    id: 1,
    title: `Seleccioná la zona donde vas a colaborar con `,
    path: "/EQ/1/",
    type: "uniqueChoice",
    posibleAnswers:
      Q1posibleAnswers !== undefined
        ? Q1posibleAnswers
        : [
            {
              id: 1,
              text: "CABA - Ciudad de Bs. As.",
              selected: false,
              amount: "300",
              location: "caba",
              stateId: 24,
              nextQuestion: 2,
            },
            {
              id: 2,
              text: "CÓRDOBA - Prov. de Córdoba",
              selected: false,
              amount: "300",
              location: "cordoba",
              stateId: 23,
              nextQuestion: 2,
            },
            {
              id: 3,
              text: "ROSARIO - Prov. de Bs. As.",
              selected: false,
              amount: "300",
              location: "rosario",
              stateId: 18,
              nextQuestion: 2,
            },
          ],
    updated: false,
  },
  {
    id: 2,
    title: `Además de colaborar con ENTERPRISE, ¿estás trabajando en relación de dependencia?`,
    path: "/EQ/2/",
    type: "uniqueChoice",
    beforeQuestion: 1,
    posibleAnswers:
      Q2posibleAnswers !== undefined
        ? Q2posibleAnswers
        : [
            {
              id: 1,
              text: "Si, recibo un sueldo en blanco",
              selected: false,
              nextQuestion: 5,
            },
            {
              id: 2,
              text: "No",
              selected: false,
              nextQuestion: 3,
            },
          ],
  },
  {
    id: 3,
    title:
      "¿Querés adherir familiares (de primer grado de parentesco a tu obra social del Monotributo)?",
    path: "/EQ/3/",
    description:
      "Cada adherente va a aumentar $1408,87 a tu cuota mensual del Monotributo",
    type: "uniqueChoice",
    beforeQuestion: 2,
    posibleAnswers:
      Q3posibleAnswers !== undefined
        ? Q3posibleAnswers
        : [
            {
              id: 1,
              text: "Si",
              selected: false,
              nextQuestion: 4,
            },
            {
              id: 2,
              text: "No",
              selected: false,
              nextQuestion: 5,
            },
          ],
  },
  {
    id: 4,
    title: "¿Cuántos adherentes querés agregar?",
    path: "/EQ/4/",
    description:
      "Solo podrás adherir a tu cónyuge si estás legalmente casado o en unión convivencial, menor tutelado o hijo (menor de 21 años o de 25 en caso de que sea estudiante)",
    selected: 0,
    nextButtonText: "Volver",
    beforeQuestion: 3,
    nextQuestion: 5,
  },
  {
    id: 5,
    title: "Resultado de la simulación según tus respuestas",
    path: "/EQ/5/",
    description: "A",
    categoryResult: true,
    nextButtonText: "Inscribirme",
    beforeQuestion: 2,
    nextQuestion: 6,
  },
  {
    id: 6,
    title: "Creá tu cuenta de Gestorando",
    path: "/registro/",
    description:
      "Crear una cuenta en Gestorando te va a permitir volver a nuestro sitio y tener toda tu información en un lugar",
    beforeQuestion: 5,
    nextQuestion: 7,
  },
  {
    id: 7,
    path: "/metodo-de-pago/",
    nextQuestion: 8,
  },
  {
    id: 8,
    title: "Muchas gracias,\n ¡recibimos tu pago!",
    icon: "success",
    path: "/EQ/8/",
    description:
      "Para inscribirte en el Monotributo vamos a necesitar: \n\n1. CUIT\n 2. Clave fiscal (nivel 3)\n 3. Elección de obra social.\n\n Respondé las preguntas a continuación para averiguar los próximos pasos, luego un asesor te va a estar llamando por teléfono para responder tus dudas.",
    nextQuestion: 10,
  },
  {
    id: 9,
    title: "Te enviamos el cupón con un código de Mercado Pago por email.",
    icon: "success",
    path: "/EQ/9/",
    description:
      "Para inscribirte en el Monotributo vamos a necesitar: \n\n1. CUIT\n 2. Clave fiscal (nivel 3)\n 3. Elección de obra social.\n\n Respondé las preguntas a continuación para averiguar los próximos pasos, una vez realizado el pago, un asesor te va a estar llamando por teléfono para responder tus dudas.",
    nextQuestion: 10,
  },
  {
    id: 10,
    title: "¿Tenés CUIT?",
    path: "/EQ/10/",
    moreInfoIcon: true,
    type: "uniqueChoice",
    posibleAnswers:
      Q10posibleAnswers !== undefined
        ? Q10posibleAnswers
        : [
            {
              id: 1,
              text: "Si",
              selected: false,
              nextQuestion: 11,
            },
            {
              id: 2,
              text: "No",
              selected: false,
              nextQuestion: 12,
            },
          ],
  },
  {
    id: 11,
    title: "Ingresá tu CUIT",
    path: "/EQ/11/",
    description: "Recordá que es el mismo número que la CUIL",
    nextQuestion: 17,
    beforeQuestion: 10,
  },
  {
    id: 12,
    title: "Seleccioná el tipo de documento que tenés",
    path: "/EQ/12/",
    type: "uniqueChoice",
    beforeQuestion: 10,
    posibleAnswers:
      Q12posibleAnswers !== undefined
        ? Q12posibleAnswers
        : [
            {
              id: 1,
              text: "DNI argentino",
              selected: false,
              nextQuestion: 13,
            },
            {
              id: 2,
              text: "Precaria",
              selected: false,
              nextQuestion: 14.1,
            },
          ],
  },
  {
    id: 13,
    title: "¿Tu DNI está vigente y tiene fecha de vencimiento?",
    path: "/EQ/13/",
    type: "uniqueChoice",
    beforeQuestion: 12,
    posibleAnswers:
      Q13posibleAnswers !== undefined
        ? Q13posibleAnswers
        : [
            {
              id: 1,
              text: "Si",
              selected: false,
              nextQuestion: 14,
            },
            {
              id: 2,
              text: "No",
              selected: false,
              nextQuestion: 14.1,
            },
          ],
  },
  {
    id: 14,
    icon: "success",
    path: "/EQ/14/",
    description:
      "Te enviamos un email con instrucciones paso a paso para sacar la CUIT y clave fiscal en el <span>sitio web</span> de la AFIP.\n\nSi ya fuiste Monotributista, solo deberás recuperar tu clave fiscal.",
    nextButtonText: "Continuar",
    nextQuestion: 17,
  },
  {
    id: 14.1,
    icon: "success",
    path: "/EQ/14-1/",
    description:
      "Te enviamos un email con instrucciones paso a paso para sacar la CUIT y clave fiscal en AFIP. Al no haber recibido tu DNI aún, deberás solicitarlo de forma <span>presencial</span> en AFIP ya que deberás confirmar tus datos biométricos (foto y huellas digitales) y presentar dos constancias de domicilio.\n\nUna vez que que tengas CUIT y clave, tardamos 24 horas en hacer efectiva la inscripción.",
    nextButtonText: "Continuar",
    nextQuestion: 17,
  },
  {
    id: 17,
  },
];

// Enterprise Beat Questions
const beat_questions = [
  {
    id: 1,
    title: `¿Cuál es tu condición actual?`,
    path: "/EQ/1/",
    type: "uniqueChoice",
    posibleAnswers: [
      {
        id: 1,
        text: "Soy monotributista",
        selected: false,
        nextQuestion: 2,
      },
      {
        id: 2,
        text: "Quiero ser monotributista",
        selected: false,
        nextQuestion: 2,
      },
    ],
    updated: false,
  },
  {
    id: 2,
    title: "Ingresá tu CUIT/CUIL",
    path: "/EQ/2/",
    nextQuestion: 3,
    beforeQuestion: 1,
  },
  {
    id: 3,
    title: "Verificamos tus datos en AFIP",
    description:
      "No te encontrás inscripto en el Monotributo y no tenés habilitado el CUIT. Primero vamos a ayudarte con el proceso de inscripción para luego habilitarte la facturación.",
    path: "/EQ/3/",
    nextQuestion: 6,
    beforeQuestion: 2,
  },
  {
    id: 4,
    title: "Verificamos tus datos en AFIP",
    description:
      "No te encontrás inscripto en el Monotributo. Primero vamos a ayudarte con el proceso de inscripción para luego habilitarte la facturación.",
    path: "/EQ/4/",
    nextQuestion: 6,
    beforeQuestion: 2,
  },
  {
    id: 5,
    title: "Verificamos tus datos en AFIP",
    // "Ya te encontrás inscripto en el Monotributo en la categoría {X}. Primero vamos a ayudarte con el proceso de facturación.",
    description:
      "Ya te encontrás inscripto en el Monotributo. Primero vamos a ayudarte con el proceso de facturación.",
    path: "/EQ/5/",
    nextQuestion: 10,
    beforeQuestion: 2,
  },
  {
    id: 6,
    title: `Además de colaborar con ENTERPRISE, ¿estás trabajando en relación de dependencia?`,
    path: "/EQ/6/",
    type: "uniqueChoice",
    beforeQuestion: 2,
    posibleAnswers: [
      {
        id: 1,
        text: "Si, recibo un sueldo en blanco",
        selected: false,
        nextQuestion: 9,
      },
      {
        id: 2,
        text: "No",
        selected: false,
        nextQuestion: 7,
      },
    ],
  },
  {
    id: 7,
    title:
      "¿Querés adherir familiares (de primer grado de parentesco a tu obra social del Monotributo)?",
    path: "/EQ/7/",
    description:
      "Cada adherente va a aumentar $1408,87 a tu cuota mensual del Monotributo",
    type: "uniqueChoice",
    beforeQuestion: 6,
    posibleAnswers: [
      {
        id: 1,
        text: "Si",
        selected: false,
        nextQuestion: 8,
      },
      {
        id: 2,
        text: "No",
        selected: false,
        nextQuestion: 9,
      },
    ],
  },
  {
    id: 8,
    title: "¿Cuántos adherentes querés agregar?",
    path: "/EQ/8/",
    description:
      "Solo podrás adherir a tu cónyuge si estás legalmente casado o en unión convivencial, menor tutelado o hijo (menor de 21 años o de 25 en caso de que sea estudiante)",
    selected: 0,
    nextButtonText: "Volver",
    beforeQuestion: 7,
    nextQuestion: 9,
  },
  {
    id: 9,
    title: "Resultado de la simulación según tus respuestas",
    path: "/EQ/9/",
    description: "A",
    categoryResult: true,
    nextButtonText: "Continuar",
    beforeQuestion: 6,
    nextQuestion: 10,
  },
  {
    id: 10,
    title: "Creá tu cuenta de Gestorando",
    path: "/registro/",
    description:
      "Crear una cuenta en Gestorando te va a permitir volver a nuestro sitio y tener toda tu información en un lugar",
    beforeQuestion: 9,
    nextQuestion: 11,
  },
  {
    id: 11,
    path: "/metodo-de-pago/",
    nextQuestion: 12,
  },
  {
    id: 12,
    title: "Muchas gracias,\n ¡recibimos tu pago!",
    icon: "success",
    path: "/EQ/8/",
    nextQuestion: 17,
  },
  {
    id: 17,
  },
];

// Checking if the first question or the second has been answered AND if there is enterprise in localStorage
// If so then replace the ENTERPRISE words in the Q1 or Q2
if (
  (!!storage.getItem("Q1") || !!storage.getItem("Q2")) &&
  storage.getItem("enterprise") !== null
) {
  if (!enterprises.includes(storage.enterprise)) {
    changeEnterpriseInQuestions(beat_questions);
  } else {
    changeEnterpriseInQuestions(questions);
  }
}
export default function (
  state = enterprises.includes(window.location.pathname.split("/")[2])
    ? beat_questions
    : questions,
  action
) {
  switch (action.type) {
    case SELECT_ANSWER:
      return action.payload;
    default:
      return state;
  }
}
