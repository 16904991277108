import React from "react";
import { Modal, Spin } from "antd";
import PropTypes from "prop-types";
import { redirectUrl } from "../../../../actions/redirectUrl";

class ModalIfUserWantsToRedirectToLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      existsEmail: "",
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({
      visible: this.props.visible,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      this.setState({
        visible: nextProps.visible,
      });
    }

    if (this.props.existsEmail !== nextProps.existsEmail) {
      this.setState({
        existsEmail: nextProps.existsEmail,
      });
    }
  }

  handleRedirect = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        // Setting the email on the localStorage then it is used on the Login
        localStorage.setItem("emailAlreadyExists", this.state.existsEmail);
        redirectUrl("/ingresar/");
      }
    );
  };

  renderModalIfUserWantsToRedirectToLogin() {
    return (
      <Modal
        title={
          <p className="m-0 text-center">
            Ya estás registrado con <br />
            <span className="bold">{this.state.existsEmail}</span>
          </p>
        }
        centered
        visible={this.state.visible}
        maskClosable={false}
        closable={false}
        className="modal-user-wants-to-redirect-to-login"
        footer={[
          <div key={Math.random()} className="user-may--login">
            <Spin spinning={this.state.loading} size="large">
              <button
                className="btn btn-next btn-next--modal"
                type="button"
                key="back"
                onClick={this.handleRedirect}
              >
                Ingresá a tu cuenta
              </button>
              <button
                className="btn btn-next"
                type="button"
                key="submit"
                onClick={this.props.handleCancel}
              >
                Cerrar
              </button>
            </Spin>
          </div>,
        ]}
      />
    );
  }

  render() {
    return this.renderModalIfUserWantsToRedirectToLogin();
  }
}

ModalIfUserWantsToRedirectToLogin.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  existsEmail: PropTypes.string,
};

export default ModalIfUserWantsToRedirectToLogin;
