import React from "react";
import PropTypes from "prop-types";
import { Spin, Icon } from "antd";

class Loader extends React.Component {
  renderLoader() {
    const { loadingMessage } = this.props;
    return (
      <div className="loader sk-circle d-block">
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />}
          tip={loadingMessage}
        />
      </div>
    );
  }
  render() {
    return this.renderLoader();
  }
}

Loader.propTypes = {
  loadingMessage: PropTypes.string,
};

export default Loader;
