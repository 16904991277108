import React from "react";
import PropTypes from "prop-types";
import { mpGetPreference } from "../../../../apis/index";
import { Spin } from "antd";
class OtherPaymentsMethodButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPreferenceID: false,
      errorMpMoney: false,
      codeMpPreference: "",
      preferenceIdMP: "",
    };
    this.getPreference = this.getPreference.bind(this);
  }
  componentDidMount() {
    this.setState({ loadingPreferenceID: true });
    if (!this.state.preferenceIdMP && this.props.serviceToPay) {
      this.getPreference();
    }
  }
  async getPreference() {
    try {
      const response = await mpGetPreference({
        serviceToPay: this.props.serviceToPay,
      });
      if (response) {
        this.setState({
          preferenceIdMP: response.data.id,
          loadingPreferenceID: false,
        });
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
        script.dataset.preferenceId = response.data.id;
        script.dataset.buttonLabel = "MERCADOPAGO";
        const formulario = document.getElementById("form-script");
        formulario.appendChild(script);
      }
    } catch (error) {
      this.setState({
        loadingPreferenceID: false,
        errorMpMoney: true,
      });
    }
  }
  renderOtherPaymentsMethodButtons() {
    return (
      <Spin
        spinning={this.state.loadingPreferenceID}
        tip="Cargando medios de pagos."
      >
        <div className="btns-other-payment-wrapper">
          <div className="button-others-payments">
            <button
              className={`btn
            ${this.props.debitCardState ? "selected" : false}`}
              type="button"
              id="btn-debit-card"
              onClick={(e) => this.props.toggleClass(e)}
            >
              <p>Tarjeta de débito</p>
              <div className="images" />
            </button>
          </div>
          <div className="button-others-payments">
            <button
              className={`btn
                  ${this.props.cashPaymentState ? "selected" : false}`}
              type="button"
              id="btn-cash-payment"
              onClick={(e) => this.props.toggleClass(e)}
            >
              <p>Efectivo</p>
            </button>
          </div>
          {!this.errorMpMoney ? (
            <div className="button-others-payments">
              <form action="/panel/preferences/" id="form-script"></form>
            </div>
          ) : (
            <div class="button-others-payments">
              <button onClick={this.getPreference} class="mercadopago-button">
                MERCADOPAGO
              </button>
            </div>
          )}
        </div>
      </Spin>
    );
  }

  render() {
    return this.renderOtherPaymentsMethodButtons();
  }
}

OtherPaymentsMethodButtons.propTypes = {
  debitCardState: PropTypes.bool,
  cashPaymentState: PropTypes.bool,
  toggleClass: PropTypes.func,
};

export default OtherPaymentsMethodButtons;
