import React from "react";
import PropTypes from "prop-types";
import HeaderTitle from "./HeaderTitle";
import HeaderBody from "./HeaderBody";

class Header extends React.Component {
  renderHeader() {
    return (
      <div className="card-header">
        <HeaderTitle
          classDisplay="d-none d-md-block"
          logoMercadopago={this.props.logoMercadopago}
        />
        <div className="d-md-none">
          <div className="service-description">
            <div className="card">
              <HeaderBody
                basePriceCreditCard={this.props.basePriceCreditCard}
                basePriceOtherPayment={this.props.basePriceOtherPayment}
                installments={this.props.installments}
                total_amount={this.props.total_amount}
                total_cuotas_amount={this.props.total_cuotas_amount}
                ptf={this.props.ptf}
                tea={this.props.tea}
                cft={this.props.cft}
                paymentMethod={this.props.paymentMethod}
                monthsOfServiceToOffer={this.props.monthsOfServiceToOffer}
                payingInscription={this.props.payingInscription}
                goBackArrow={this.props.goBackArrow}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderHeader();
  }
}

Header.propTypes = {
  basePriceCreditCard: PropTypes.any,
  basePriceOtherPayment: PropTypes.string,
  installments: PropTypes.string,
  total_amount: PropTypes.string,
  total_cuotas_amount: PropTypes.string,
  ptf: PropTypes.string,
  tea: PropTypes.string,
  cft: PropTypes.string,
  logoMercadopago: PropTypes.string,
  paymentMethod: PropTypes.string,
  monthsOfServiceToOffer: PropTypes.any,
  payingInscription: PropTypes.bool,
};

export default Header;
