import { createBrowserHistory } from 'history';
import { scrollWindowToTop } from './scrollWindowToTop';

// Creates history on the navigation
export function createHistoryBrowser(path, title) {
  const history = createBrowserHistory();
  const location = {
    pathname: path,
  };

  history.push(location);

  if (title !== '' && title !== undefined && title !== null) {
    document.title = title;
  }
  // else {
  //   document.title = 'Gestorando';
  // }
  scrollWindowToTop();
}
