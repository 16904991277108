import React from 'react';
import PropTypes from 'prop-types';

export default function CheckboxEQ(props) {
  return (
    <div className="checkbox-wrapper">
      <div className={props.question.selected ? 'selected' : null}>
        <label htmlFor={`answer${props.question.id}`}>
          <input
            type="checkbox"
            id={`answer${props.question.id}`}
            onClick={props.onAnswerSelected}
          />
          {props.question.text}
          <i className="fas fa-check-circle" />
        </label>
      </div>
    </div>
  );
}

CheckboxEQ.propTypes = {
  question: PropTypes.object,
  onAnswerSelected: PropTypes.func,
};
