import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const AUTHORIZATION_JWT = process.env.REACT_APP_AUTHORIZATION_JWT;
export const MP_PUBLIC_KEY = process.env.REACT_APP_MP_PUBLIC_KEY;

// Gestorando API default values
const gestorandoApi = useCustomJWT =>
  axios.create({
    withCredentials: true,
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: useCustomJWT
        ? `JWT ${localStorage.getItem("token")}`
        : AUTHORIZATION_JWT
    }
  });

const gestorandoApiNS = useCustomJWT =>
  axios.create({
    withCredentials: true,
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

// Get code areas
export const codeAreas = () => gestorandoApi().get("/api/area_codes/");

// Get enterprises
export const getEnterprises = () => gestorandoApi().get("/api/enterprises/");

// Get medical insurances
export const getMedicalInsurances = () =>
  gestorandoApi(true).get("/api/medical_insurances/");

// Get invoices filters
export const getInvoicesFilters = () =>
  gestorandoApi(true).get("/api/get_invoices_report_filter_options/");

// Get all user data
export const getUserData = () => gestorandoApi(true).get("/api/get_data/");

// Get user info
export const getProfileInfo = () =>
  gestorandoApi(true).get("/api/profile_data/");

// Restore password with identifier
export const restorePasswordIdentifier = identifier =>
  gestorandoApi().get(
    `/api/restore-password-identifier/?identifier=${identifier}`
  );

// Get pre payments medical
export const getPrePaymentsMedical = () =>
  gestorandoApi(true).get("/api/pre_payments_medical/");

// Get profile payments
export const getProfilePayments = () =>
  gestorandoApi(true).get("/api/get_payments/");

// Get info from a user with CUIT or DNI
export const getInfoDocument = value =>
  gestorandoApi().post("/api/get_info_from_document/", value);

// Submit enterprises to modify
export const submitModifyEnterprises = form =>
  gestorandoApi(true).post("api/modify_enterprises/", form);

// Create invoice C
export const createInvoiceC = form =>
  gestorandoApi(true).post("/api/create_invoice_c/", form);

// Check invoice status
export const invoiceStatus = invoiceId =>
  gestorandoApi(true).post("/api/create_invoice_status/", invoiceId);

// Submit payment method
export const submitPaymentMethod = form =>
  gestorandoApi(true).post("/api/payment_method/", form);

// Signup
export const signup = form => gestorandoApi().post("/api/users/", form);

// Generate identifiers
export const generateIdentifiers = form =>
  gestorandoApi().post("/api/generate_identifiers/", form);

// Change Afip Cuit and FiscalPass
export const submitAfipDataForm = form =>
  gestorandoApi(true).post("/api/modify-cuit-fiscal/", form);

// Forgot password
export const forgotPassword = form =>
  gestorandoApi().post("/api/forgot-password/", form);

// Restore password
export const restorePassword = form =>
  gestorandoApi().post("/api/restore-password/", form);

// Login
export const login = form => gestorandoApi().post("/api/login/", form);

// Send email guide from EQ
export const sendEmailGuide = form =>
  gestorandoApi(true).post("/api/send_mail/", form);

// Validate CUIT
export const validateCUIT = form =>
  gestorandoApi().post("/api/validate/cuit/", form);

// Validate CUIT from afip
export const validateAfipCUIT = form =>
  gestorandoApi(true).post("/api/validate-afip/cuit/", form);

// Download specific individual invoice
export const downloadIndividualInvoice = form =>
  gestorandoApi(true).post("/api/download_invoice/", form);

// Download excel invoices
export const downloadExcelInvocies = form =>
  gestorandoApi(true).post("/api/download_invoices_excel/", form);

// Download zip invoices
export const downloadZipInvoices = form =>
  gestorandoApi(true).post("/api/download_invoices/", form);

// Get invoices
export const getInvoices = form =>
  gestorandoApi(true).post("/api/get_invoices_report/", form);

// Donwload specific individual invoice
export const downloadInvoicePDF = form =>
  gestorandoApi(true).post("/api/get_afip_file/", form);

// Save monotributo data of user
export const saveMonotributoData = form =>
  gestorandoApi(true).post("/api/save_monotributo_data/", form);

// Save cuit data of user
export const saveCuitData = form =>
  gestorandoApi(true).post("/api/save_cuit_data/", form);

// Check if email domain is correct
export const checkEmailValidation = email =>
  gestorandoApi().post("/api/is_email_domain_correct/", email);

// Check debts of monotributo
export const monotributoDebts = () =>
  gestorandoApi(true).get("/api/monotributo_debts/");

// Send data to generate VEPs for users
export const generateVEPData = form =>
  gestorandoApi(true).post("/api/generate_afip_vep/", form);

// Check if email domain is correct
export const monotributoVEPStaus = () =>
  gestorandoApi(true).get("/api/status_afip_vep_generation/");

// User update his monotributo debt
export const monotributoDebtUserUpdate = () =>
  gestorandoApi(true).get("/api/update_user_monotributo_debt/");

// Check status of updating user monotributo
export const monotributodebtUpdatingStatus = () =>
  gestorandoApi(true).get("/api/status_update_debt_monotributo/");

// Send data to update a given cbu
export const updateCBU = form =>
  gestorandoApi(true).post("/api/update_user_cbu/", form);

// Check status of updating cbu
export const updatingCBUStatus = () =>
  gestorandoApi(true).get("/api/verify_update_cbu_status/");

// Send email to create a payment plan
export const sendPlanPagosEmail = form =>
  gestorandoApi(true).post("/api/send_plan_pagos_email/", form);

// Get all afip categories
export const afipCategories = () =>
  gestorandoApi(true).get("/api/afip-categories/");

// Get all afip categories ns
export const afipCategoriesns = () =>
  gestorandoApiNS(true).get("/api/afip-categories-ns/");

export const stringToFloat = (string) => {return parseFloat(string)};

export const commaStringToFloat = (string) => {return parseFloat(string.replace(".","").replace(",","."))};

export const floatToString = (float) => {return float.toLocaleString("es-AR")};

export const categoriesPromise = afipCategoriesns()
.then(function(response) {
  if (response.status > 400) {
    throw new Error('error')
  }
  return response.data;
})
.then(function(data) {
  let categorias = {};
  data.category.map(categoria =>
    {
      let name = categoria.name;
      let servicios = categoria.tax_services;
      let ventas = categoria.tax_commerce;
      let sipa = categoria["SIPA"];
      let obra = categoria.med;
      let iibb = categoria.gross_income.value_max;
      let kw = categoria.space_kw.value_max;
      let rent = categoria.space_rent.value_max;
      let sqft = categoria.space_sqft.value_max;
      let localCategory = {
        name:name,
        servicios:floatToString(stringToFloat(servicios)),
        ventas:floatToString(stringToFloat(ventas)),
        sipa:floatToString(stringToFloat(sipa)),
        obra:floatToString(stringToFloat(obra)),
        iibb:floatToString(stringToFloat(iibb)),
        total:floatToString(stringToFloat(servicios) + stringToFloat(sipa) + stringToFloat(obra)),
        vtotal:floatToString(stringToFloat(ventas) + stringToFloat(sipa) + stringToFloat(obra)),
        jubilados:floatToString(stringToFloat(servicios) + stringToFloat(sipa)),
        vjubilados:floatToString(stringToFloat(ventas) + stringToFloat(sipa)),
        kw:kw.toString(),
        rent:floatToString(stringToFloat(rent)),
        sqft:sqft.toString()
      };
      Object.assign(categorias,{[name] : localCategory});
    });
  return categorias;
});

// Get preferences
export const mpGetPreference = form =>
  gestorandoApi(true).post("/api/preferences/", form);

//Subscribe a BEAT driver is ok 201
export const SubscribeBEATDriver = form =>
  gestorandoApi(true).post("/api/subscribe-beat/", form);

// Get tramites
export const getProfileTramites = () =>
  gestorandoApi(true).get("/api/tramites/");

// POST tramites
export const profileTramites = form =>
  gestorandoApi(true).post("/api/tramites/", form);

// Get plans
export const getProfilePlans = query =>
  gestorandoApi(true).get(`/api/plans/${query}`);

// POST plans
export const profilePlans = form =>
  gestorandoApi(true).post("/api/plans/", form);
