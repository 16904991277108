import { NEXT_QUESTION, BEFORE_QUESTION, LOGIN } from '../../actions/index';

// Checking if answered based on localStorage()
function loadStateBasedOnLocalStorage() {
  const storage = localStorage;
  let state = 1;

  if (storage.getItem('Q11')) {
    state = 11;
  } else if (storage.getItem('Q10')) {
    state = 11;
  } else if (storage.getItem('Q9')) {
    state = 10;
  } else if (storage.getItem('Q8')) {
    state = JSON.parse(storage.getItem('Q8')).nextQuestion;
  } else if (storage.getItem('Q7')) {
    state = JSON.parse(storage.getItem('Q7')).nextQuestion;
  } else if (storage.getItem('Q6')) {
    state = 7;
  } else if (storage.getItem('Q5')) {
    state = 6;
  } else if (storage.getItem('Q4')) {
    state = 5;
  } else if (storage.getItem('Q3')) {
    state = 4;
  } else if (storage.getItem('Q2')) {
    state = 3;
  } else if (storage.getItem('Q1')) {
    if (JSON.parse(storage.getItem('Q1')).activities !== undefined) {
      state =
        JSON.parse(storage.getItem('Q1')).activities.indexOf(
          JSON.parse(storage.getItem('Q1')).value,
        ) !== -1
          ? 2
          : 3;
    }
  }

  // If is coming from another path then set to 1
  if (storage.getItem('path') !== 'simulateCategory') {
    state = 1;
  }

  return state;
}

export default function(state = loadStateBasedOnLocalStorage(), action) {
  switch (action.type) {
    case NEXT_QUESTION:
      return action.payload;
    case BEFORE_QUESTION:
      return action.payload;
    case LOGIN:
      return action.payload;
    default:
      return state;
  }
}
