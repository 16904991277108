import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import Select from "react-select";
import Animated from "react-select/animated";
import Loader from "../Loader/index";
import { notificationAlert } from "../../actions/notificationAlert";
import { enterprises } from "../../actions/listEnterprises";
import {
  codeAreas,
  signup,
  generateIdentifiers as generateIdentifiersAPI,
} from "../../apis";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCodes: [],
      phoneCompanies: [
        {
          type: "companiaTelefono",
          value: "Claro",
          label: "Claro",
        },
        {
          type: "companiaTelefono",
          value: "Movistar",
          label: "Movistar",
        },
        {
          type: "companiaTelefono",
          value: "Nextel",
          label: "Nextel",
        },
        {
          type: "companiaTelefono",
          value: "Personal",
          label: "Personal",
        },
        {
          type: "companiaTelefono",
          value: "Tuenti",
          label: "Tuenti",
        },
      ],
      nombre: "",
      apellido: "",
      celular: "",
      email: "",
      password: "",
      checkboxTerms: false,
      nombreError: "",
      apellidoError: "",
      codigoAreaError: "",
      companiaTelefonoError: "",
      celularError: "",
      emailError: "",
      passwordError: "",
      formValid: false,
      nombreValid: false,
      apellidoValid: false,
      codigoAreaValid: false,
      companiaTelefonoValid: false,
      celularValid: false,
      emailValid: false,
      passwordValid: false,
      checkboxTermsValid: false,
      loadingSignupFetch: false,
      loading: true,
      identifier: null,
      selectOnFocus: false,
      showPassword: false,
      phoneNumberOnFocus: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.handleCheckboxTerms = this.handleCheckboxTerms.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.fetchSignup = this.fetchSignup.bind(this);
    this.generateIdentifiers = this.generateIdentifiers.bind(this);
    this.selectOnFocus = this.selectOnFocus.bind(this);
    this.selectOnBlur = this.selectOnBlur.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  componentWillMount() {
    document.title = "Gestorando | Registro";
    this.fetchCodeAreas();
  }

  async fetchCodeAreas() {
    try {
      const response = await codeAreas();
      this.setState({
        areaCodes: response.data.map((areaCode) => ({
          type: "codigoArea",
          value: areaCode.id,
          label: areaCode.code,
        })),
        loading: false,
      });
    } catch (error) {
      notificationAlert(
        "error",
        "Ups :(",
        "Disculpe las molestias. Surgio un error tratando de obtener algunos datos, intentelo mas tarde"
      );

      setTimeout(() => {
        this.fetchCodeAreas();
      }, 60000);
    }
  }

  async handleSignup(e) {
    e.preventDefault();

    this.setState({
      loadingSignupFetch: true,
    });

    const form = {};
    const enterprise = localStorage.getItem("enterprise");

    form.first_name = this.state.nombre;
    form.last_name = this.state.apellido;
    form.tel_company = this.state.companiaTelefono;
    form.area_code_id = this.state.codigoArea;
    form.tel = this.state.celular;
    form.username = this.state.email;
    form.email = this.state.email;
    form.password = this.state.password;

    // If the signup is from EmpresasEQ, InscripcionEQ and TramitesEQ
    if (this.props.api === "empresas") {
      form.state_id = this.getCity();

      if (
        localStorage.getItem("Q4") !== null &&
        !enterprises.includes(enterprise)
      ) {
        form.cuit = JSON.parse(localStorage.getItem("Q4")).value;
      }

      if (
        localStorage.getItem("Q2") !== null &&
        enterprises.includes(enterprise)
      ) {
        form.cuit = JSON.parse(localStorage.getItem("Q2")).value;
      }

      if (
        localStorage.getItem("Q2") !== null &&
        !enterprises.includes(enterprise)
      ) {
        form.relacionDependencia = JSON.parse(localStorage.getItem("Q2")).text;
      }
      if (
        localStorage.getItem("Q6") !== null &&
        enterprises.includes(enterprise)
      ) {
        form.relacionDependencia = JSON.parse(localStorage.getItem("Q6")).text;
      }

      // If has identifier then append on the fomr normally
      // If not then we generate a new one
      if (this.state.identifier) {
        form.identifier = this.state.identifier;

        this.fetchSignup(form);
      } else {
        try {
          const generateIdentifiers = await this.generateIdentifiers();

          if (generateIdentifiers.data.status) {
            this.setState(
              {
                identifier: generateIdentifiers.data.identifier,
              },
              () => {
                localStorage.setItem(
                  "identifier",
                  generateIdentifiers.data.identifier
                );
                form.identifier = generateIdentifiers.data.identifier;

                this.fetchSignup(form);
              }
            );
          } else {
            throw new Error();
          }
        } catch (error) {
          notificationAlert(
            "error",
            "Ups :(",
            "Disculpe las molestias. Surgio un error inesperado, intentelo mas tarde"
          );

          this.setState({
            loadingSignupFetch: false,
          });
        }
      }
    } else if (this.props.api === "inscripcion") {
      // If the user answered that has CUIT on the Question
      if (localStorage.getItem("Q4") !== null) {
        form.cuit = JSON.parse(localStorage.getItem("Q4")).value;
      }
      if (localStorage.getItem("Q2") !== null) {
        form.relacionDependencia = JSON.parse(localStorage.getItem("Q2")).text;
      }

      this.fetchSignup(form);
    } else if (this.props.api === "tramites") {
      // if we validate the cuit
      let quest = JSON.parse(localStorage.getItem("Q1"));
      form.tramites_identifier = localStorage.getItem("tramites_identifier");
      if (quest.cuitValidated) {
        form.cuit = quest.value;
      }
      this.fetchSignup(form);
    } else if (this.props.api === "plans") {
      let quest = JSON.parse(localStorage.getItem("Q1"));
      form.plans_tramites = localStorage.getItem("plans_tramites");
      if (quest.cuitValidated) {
        form.cuit = quest.value;
      }
      this.fetchSignup(form);
    }
  }

  generateIdentifiers() {
    const form = { enterprise: localStorage.getItem("enterprise") };

    return generateIdentifiersAPI(form);
  }

  async fetchSignup(form) {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 24);
    try {
      const response = await signup(form);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.username);
      localStorage.setItem("first_name", response.data.first_name);
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("expire", expiration);

      this.props.onNextQuestion();
    } catch (error) {
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.username) {
            this.setState({
              emailError: error.response.data.username[0],
              emailValid: false,
              loadingSignupFetch: false,
            });
          }

          if (error.response.data.password) {
            this.setState({
              passwordError: error.response.data.password[0],
              passwordValid: false,
              loadingSignupFetch: false,
            });
          }

          if (!error.response.data.username && !error.response.data.password) {
            notificationAlert(
              "error",
              "Ups :(",
              "Disculpe las molestias. Surgio un error inesperado, intentelo mas tarde"
            );
          }
        }
      } else if (["empresas", "plans", "tramites"].includes(this.props.api)) {
        // If doesn't have identifier
        if (!this.state.identifier) {
          // If it doesn't have identifier then we execute again this function
          // So on the form append data we generateIdentifiers() again
          this.handleSignup();
        } else {
          notificationAlert(
            "error",
            "Ups :(",
            "Disculpe las molestias. Surgio un error inesperado, intentelo mas tarde"
          );
        }
      } else {
        notificationAlert(
          "error",
          "Ups :(",
          "Disculpe las molestias. Surgio un error inesperado, intentelo mas tarde"
        );
      }
    }
  }

  selectOnFocus(e) {
    if (e.target.name === "celular") {
      this.setState({
        phoneNumberOnFocus: true,
        selectOnFocus: true,
      });
    } else {
      this.setState({
        selectOnFocus: true,
      });
    }
  }

  selectOnBlur(e) {
    if (e.target.name === "celular") {
      this.setState({
        phoneNumberOnFocus: false,
        selectOnFocus: false,
      });
    } else {
      this.setState({
        selectOnFocus: false,
      });
    }
  }

  handleChange(e) {
    if (e.type === "codigoArea" || e.type === "companiaTelefono") {
      const { type, value } = e;

      this.setState({ [type]: value }, () => {
        this.validateField(type, value);
      });
    } else {
      const { name } = e.target;
      let value;

      if (name === "nombre" || name === "apellido") {
        // Replace to white space everything that is not a letter
        value = e.target.value
          .replace(/[\d\t\n,./<>?;:~"'`!@#$%^&*()\\{}_+=|\\-]/g, "")
          .replace(/^\s*/g, "");
      } else if (name === "celular") {
        // Replace all none digits
        value = e.target.value.replace(/\D/g, "");
      } else if (name === "email") {
        // Replace whitespace's and the ' - '
        value = e.target.value.replace(/[\s]/g, "").toLowerCase();
      } else {
        // Replace whitespace's
        value = e.target.value.replace(/\s/g, "");
      }

      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  }

  handleEnterKey(e) {
    if (this.state.formValid) {
      if (e.key === "Enter") {
        this.handleSignup(e);
      }
    }
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleCheckboxTerms() {
    this.setState({ checkboxTerms: !this.state.checkboxTerms }, () => {
      this.validateField("checkboxTerms", this.state.checkboxTerms);
    });
  }

  validateField(fieldName, value) {
    let {
      nombreValid,
      apellidoValid,
      codigoAreaValid,
      companiaTelefonoValid,
      celularValid,
      emailValid,
      passwordValid,
      checkboxTermsValid,
    } = this.state;

    switch (fieldName) {
      case "nombre":
        nombreValid = value.match(
          /^[Ñña-zA-Z]+(([',.-][Ñña-zA-Z])?[Ñña-zA-Z ]*)*$/g
        );
        break;
      case "apellido":
        apellidoValid = value.match(
          /^[Ñña-zA-Z]+(([',.-][Ñña-zA-Z])?[Ñña-zA-Z ]*)*$/g
        );
        break;
      case "codigoArea":
        codigoAreaValid = value !== "";
        break;
      case "companiaTelefono":
        companiaTelefonoValid = value !== "";
        break;
      case "celular":
        celularValid = value.length >= 6;
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        break;
      case "password":
        passwordValid = value.length >= 6;
        break;
      case "checkboxTerms":
        checkboxTermsValid = value;
        break;
      default:
        break;
    }

    this.setState(
      {
        nombreValid,
        apellidoValid,
        codigoAreaValid,
        companiaTelefonoValid,
        celularValid,
        emailValid,
        passwordValid,
        checkboxTermsValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.nombreValid &&
        this.state.apellidoValid &&
        this.state.codigoAreaValid &&
        this.state.companiaTelefonoValid &&
        this.state.celularValid &&
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.checkboxTermsValid,
    });
  }

  renderLoader() {
    return this.state.loadingSignupFetch ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : null;
  }

  getCity() {
    return JSON.parse(localStorage.getItem("Q1")).stateId;
  }

  renderSignup() {
    return (
      <div
        className={`signup box-styles ${
          this.state.phoneNumberOnFocus ? "phone-number-on-focus" : null
        } ${this.state.selectOnFocus ? "extra-padding" : null}`}
      >
        <Spin spinning={this.state.loading} size="large">
          {this.renderLoader()}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">{this.props.title}</h2>
              <hr />
              <p className="card-subtitle">{this.props.description}</p>
              <div>
                <div className="input-wrapper">
                  <div
                    className={`${
                      this.state.nombreValid
                        ? "valid"
                        : [this.state.nombreError ? "invalid" : ""]
                    }`}
                  >
                    <p>Nombre</p>
                    <label htmlFor="nombre">
                      <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre"
                        value={this.state.nombre}
                        onChange={this.handleChange}
                        onFocus={this.selectOnFocus}
                        onBlur={this.selectOnBlur}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">{this.state.nombreError}</p>
                      <i className="fas fa-user" />
                      <i className="fas fa-check-circle" />
                      <i className="fas fa-times-circle" />
                    </label>
                  </div>
                  <div
                    className={`${
                      this.state.apellidoValid
                        ? "valid"
                        : [this.state.apellidoError ? "invalid" : ""]
                    }`}
                  >
                    <p>Apellido</p>
                    <label htmlFor="apellido">
                      <input
                        type="text"
                        id="apellido"
                        name="apellido"
                        placeholder="Apellido"
                        value={this.state.apellido}
                        onChange={this.handleChange}
                        onFocus={this.selectOnFocus}
                        onBlur={this.selectOnBlur}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">
                        {this.state.apellidoError}
                      </p>
                      <i className="fas fa-user" />
                      <i className="fas fa-check-circle" />
                      <i className="fas fa-times-circle" />
                    </label>
                  </div>
                </div>
                <div className="phone-wrapper select-wrapper input-wrapper">
                  <div
                    className={`${
                      this.state.companiaTelefonoValid
                        ? "valid"
                        : [this.state.companiaTelefonoError ? "invalid" : ""]
                    } custom-select-styles`}
                  >
                    <p>Compañ&iacute;a de tel&eacute;fono</p>
                    <Select
                      closeMenuOnSelect
                      components={Animated()}
                      isMulti={false}
                      placeholder="Compañia"
                      onChange={this.handleChange}
                      onFocus={this.selectOnFocus}
                      onBlur={this.selectOnBlur}
                      options={this.state.phoneCompanies}
                      tabIndex="0"
                    />
                    <p className="error-message">
                      {this.state.companiaTelefonoError}
                    </p>
                  </div>
                  <div
                    className={`${
                      this.state.codigoAreaValid
                        ? "valid"
                        : [this.state.codigoAreaError ? "invalid" : ""]
                    } custom-select-styles`}
                  >
                    <p>C&oacute;digo de &aacute;rea</p>
                    <Select
                      closeMenuOnSelect
                      components={Animated()}
                      isMulti={false}
                      placeholder="Código"
                      onChange={this.handleChange}
                      onFocus={this.selectOnFocus}
                      onBlur={this.selectOnBlur}
                      options={this.state.areaCodes}
                      tabIndex="0"
                    />
                    <p className="error-message">
                      {this.state.codigoAreaError}
                    </p>
                  </div>
                  <div
                    className={`${
                      this.state.celularValid
                        ? "valid"
                        : [this.state.celularError ? "invalid" : ""]
                    }`}
                  >
                    <p>N&uacute;mero de celular</p>
                    <label htmlFor="celular">
                      <input
                        type="text"
                        id="celular"
                        name="celular"
                        placeholder="Celular"
                        value={this.state.celular}
                        pattern="\d*"
                        onChange={this.handleChange}
                        onFocus={this.selectOnFocus}
                        onBlur={this.selectOnBlur}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">{this.state.celularError}</p>
                      <i className="fas fa-phone" />
                      <i className="fas fa-check-circle" />
                      <i className="fas fa-times-circle" />
                    </label>
                  </div>
                </div>
                <div className="input-wrapper">
                  <div
                    className={`${
                      this.state.emailValid
                        ? "valid"
                        : [this.state.emailError ? "invalid" : ""]
                    }`}
                  >
                    <p>Email</p>
                    <label htmlFor="email">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onFocus={this.selectOnFocus}
                        onBlur={this.selectOnBlur}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">{this.state.emailError}</p>
                      <i className="fas fa-at" />
                    </label>
                  </div>
                  <div
                    className={`${
                      this.state.passwordValid
                        ? "valid"
                        : [this.state.passwordError ? "invalid" : null]
                    }`}
                  >
                    <p>Crear contraseña</p>
                    <label
                      className={
                        this.state.showPassword
                          ? "show-password"
                          : "hide-password"
                      }
                      htmlFor="contrasena"
                    >
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        id="contrasena"
                        name="password"
                        placeholder="Contraseña"
                        value={this.state.password}
                        onChange={this.handleChange}
                        onFocus={this.selectOnFocus}
                        onBlur={this.selectOnBlur}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">
                        {this.state.passwordError}
                      </p>
                      <i className="fas fa-lock" />
                      <button
                        className="btn-show-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye" />
                      </button>
                      <button
                        className="btn-hide-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye-slash" />
                      </button>
                    </label>
                    <small style={{ color: "#666" }}>Minimo 6 caracteres</small>
                  </div>
                </div>
                <div className="checkbox-terminos-wrapper">
                  <div>
                    <label htmlFor="terminos-condiciones">
                      <input
                        type="checkbox"
                        id="terminos-condiciones"
                        name="checkboxTerms"
                        checked={this.state.checkboxTerms}
                        value={this.state.checkboxTerms}
                        onChange={this.handleCheckboxTerms}
                        tabIndex="0"
                        className="largerCheckbox"
                      />
                      Le&iacute; y acepto los&nbsp;
                      <a
                        href="https://gestorando.com/terminos-de-uso/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        T&eacute;rminos y condiciones
                      </a>
                      &nbsp;y la&nbsp;
                      <a
                        href="https://gestorando.com/politica-de-privacidad/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pol&iacute;tica de privacidad
                      </a>
                      &nbsp;de Gestorando.
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={`btns-next-back-wrapper ${
                  this.state.selectOnFocus ? "not-fixed" : null
                }`}
              >
                <button
                  className={`
                  ${this.state.formValid ? "remove-disable" : null} btn-next`}
                  type="submit"
                  onClick={this.handleSignup}
                  disabled={this.state.formValid ? null : true}
                >
                  Crear mi cuenta
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }

  render() {
    return this.renderSignup();
  }
}

Signup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onNextQuestion: PropTypes.func,
  api: PropTypes.string,
};

export default Signup;
