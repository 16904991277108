export function formatNumberTextToFloat(number) {
  return parseFloat(
    number
      .replace(/[.]/g, '')
      .replace(/[,]/g, '.')
      .replace(/[$ ]/g, ''),
  );
}

export function formatNumberTextToThousands(number) {
  return number
    .toString()
    .replace(/[.]/g, ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
