import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { nextQuestionEmpresasEQCuitValidated } from "../../../../actions/nextQuestion";
import { nextQuestionEmpresasEQCuitNotValidated } from "../../../../actions/nextQuestion";
import Loader from "../../../Loader/index";
import ModalIfUserWantsToRedirectToLogin from "../../Partials/ModalIfUserWantsToRedirectToLogin/index";
import { validateCUIT as validateCUITAPI } from "../../../../apis";
import { Spin, Icon } from "antd";
class CuitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      cuit: "",
      monotributo: false,
      cuitWithDashes: "",
      errorMessage: false,
      showNextButton: false,
      isLoading: false,
      loadingEQCUIT: false,
      loadingMessage:
        "Por favor aguardá unos instantes, estamos verificando tus datos en AFIP",
      existsEmail: "",
      firstName: "",
      lastName: "",
      showModalIfUserWantsToRedirectToLogin: false,
    };

    this.handleChangeCuit = this.handleChangeCuit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancelModalIfUserWantsToRedirectToLogin = this.handleCancelModalIfUserWantsToRedirectToLogin.bind(
      this
    );
    this.clearInput = this.clearInput.bind(this);
  }
  componentDidMount() {
    if (localStorage.Q2 && JSON.parse(localStorage.Q2).value) {
      let value = JSON.parse(localStorage.Q2).value;
      let replaceValueWithDash = value
        .replace(/\D/g, "")
        .replace(/(^\d{2})/g, "$&-")
        .replace(/(\d{1}$)/g, "-$&");
      this.validateCuit(value);
      this.setState({
        loadingEQCUIT: true,
        cuitWithDashes: replaceValueWithDash,
      });
    }
  }
  handleChangeCuit(event) {
    const VALUE = event.target.value.replace(/\D/g, "");
    let replaceValueWithDash;

    this.setState({
      cuit: VALUE,
      cuitWithDashes: VALUE,
      firstName: "",
      lastName: "",
      errorMessage: false,
      showNextButton: false,
    });

    if (VALUE.length === 11) {
      replaceValueWithDash = VALUE.replace(/(^\d{2})/g, "$&-").replace(
        /(\d{1}$)/g,
        "-$&"
      );

      this.setState(
        {
          cuit: VALUE,
          cuitWithDashes: replaceValueWithDash,
          cuitValidated: true,
        },
        () => {
          this.handleSubmit(null);
        }
      );
    }
  }

  handleSubmit(event) {
    if (event !== null) event.preventDefault();

    document.getElementById("cuit").blur();

    this.validateCuit(this.state.cuit);

    this.setState({
      isLoading: true,
      showNextButton: false,
    });
  }

  handleCancelModalIfUserWantsToRedirectToLogin() {
    this.setState({
      showModalIfUserWantsToRedirectToLogin: false,
      cuit: "",
      cuitWithDashes: "",
    });
  }

  clearInput() {
    this.setState({
      cuit: "",
      cuitWithDashes: "",
      errorMessage: false,
    });

    document.getElementById("cuit").focus();
  }

  async validateCuit(cuit) {
    try {
      const form = { cuit };
      const response = await validateCUITAPI(form);

      if (response.data.status === "found") {
        if (response.data.inscripto_monotributo) {
          this.setState({
            showNextButton: true,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            monotributo: true,
          });
        } else {
          this.setState({
            showNextButton: true,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            monotributo: false,
          });
        }
      } else if (response.data.status === "not-found") {
        this.setState({
          errorMessage: true,
          existsEmail: "",
        });
      } else if (response.data.status === "already-exists") {
        this.setState({
          errorMessage: false,
          existsEmail: response.data.exists_email,
          showModalIfUserWantsToRedirectToLogin: true,
        });
      }

      this.setState({
        isLoading: false,
        loadingEQCUIT: false,
      });
    } catch (error) {
      this.setState(
        {
          isLoading: false,
          loadingEQCUIT: false,
          showNextButton: false,
          errorMessage: false,
          existsEmail: "",
        },
        () =>
          this.props.nextQuestionEmpresasEQCuitNotValidated(
            this.props.question,
            this.state.cuit
          )
      );
    }
  }

  renderLoader() {
    if (this.state.isLoading)
      return <Loader loadingMessage={this.state.loadingMessage} />;
  }

  renderErrorMessage() {
    if (this.state.errorMessage)
      return (
        <React.Fragment>
          <p>
            El número que ingresaste no corresponde a una CUIT activa en AFIP.
            Si te equivocaste{" "}
            <button
              className="bold p-0 link"
              type="btn"
              onClick={this.clearInput}
            >
              <u>volvé a ingresarlo</u>
            </button>
            .
          </p>
        </React.Fragment>
      );
  }

  renderUserName() {
    if (this.state.lastName !== "" && this.state.firstName !== "")
      return (
        <React.Fragment>
          <p>Nombre: {this.state.firstName}</p>
          <p>Apellido: {this.state.lastName}</p>
        </React.Fragment>
      );
  }

  renderNextButton() {
    if (this.state.showNextButton) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className={`
              ${this.state.showNextButton ? "remove-disable" : false} btn-next`}
            type="button"
            disabled={this.state.showNextButton ? false : "disabled"}
            onClick={() =>
              this.props.nextQuestionEmpresasEQCuitValidated(
                this.props.question,
                this.state.cuit,
                this.state.monotributo
              )
            }
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    } else if (this.state.errorMessage) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className="btn-next"
            type="button"
            disabled={"disabled"}
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    }
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
      </div>
    );
  }

  renderForm() {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <div className="questions">
        {this.renderLoader()}
        <form onSubmit={this.handleSubmit}>
          {process.env.jwtToken}
          <h5 className="card-title">{this.props.question.title}</h5>
          <p className="card-subtitle">{this.props.question.description}</p>
          <div className="input-wrapper">
            <div>
              <Spin indicator={antIcon} spinning={this.state.loadingEQCUIT}>
                <label htmlFor="cuit">
                  <input
                    type="text"
                    id="cuit"
                    name="cuit"
                    placeholder="12-34567890-1"
                    minLength="11"
                    maxLength="13"
                    pattern="\d*"
                    value={this.state.cuitWithDashes}
                    onChange={this.handleChangeCuit}
                  />

                  <i className="fas fa-check-circle" />
                  <i className="fas fa-times-circle" />
                </label>
              </Spin>
            </div>
          </div>
          {this.renderErrorMessage()}
          <ModalIfUserWantsToRedirectToLogin
            visible={this.state.showModalIfUserWantsToRedirectToLogin}
            handleCancel={this.handleCancelModalIfUserWantsToRedirectToLogin}
            existsEmail={this.state.existsEmail}
          />
          {this.renderUserName()}
          {this.renderNextButton()}
        </form>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

CuitForm.propTypes = {
  question: PropTypes.object,
  onBackQuestion: PropTypes.func,
  nextQuestionEmpresasEQCuitValidated: PropTypes.func,
  nextQuestionEmpresasEQCuitNotValidated: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionEmpresasEQCuitValidated,
      nextQuestionEmpresasEQCuitNotValidated,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(CuitForm);
