import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingEmptyComponent from "../../components/LoadingEmptyComponent";
/*
 * React.lazy() lets you define a component that is loaded dynamically.
 * This helps reduce the bundle size to delay loading components that aren’t used during the initial render.
 */
const Inicio = React.lazy(() => import("./Inicio"));
const Monotributo = React.lazy(() => import("./Monotributo"));
const Impuesto = React.lazy(() => import("./Impuestos"));
const IIBB = React.lazy(() => import("./IIBB"));
const EmitirFacturasOptions = React.lazy(() =>
  import("./EmitirFacturas/CardOptions/")
);
const FacturaCForm = React.lazy(() => import("./EmitirFacturas/"));
const VerFacturas = React.lazy(() => import("./VerFacturas"));
const Formularios = React.lazy(() => import("./Formularios"));
const Perfil = React.lazy(() => import("./Perfil"));
const Faq = React.lazy(() => import("./FAQ"));

function SubscriptionTypeRedirect(type,returned) {
  if (type === "CabifyFree" || type === 'PedidosYaFree') {
    return (returned) //(<Redirect to="/panel/ver-facturas/" />)
  }
  else {
    return (returned)
  }
}

export default function MainContent(props) {
  return (
    <Layout.Content className="main-content">
      <React.Suspense fallback={<LoadingEmptyComponent loading />}>
        <Switch>
          {/* Inicio path */}
          <Route
            path="/panel/inicio/"
            render={() => (
              <Inicio
                basePriceOtherPayment={props.basePriceOtherPayment}
                toggleMenu={props.toggleMenu}
                isRelacionDependencia={props.isRelacionDependencia}
                cuit={props.cuit}
                ivaCondition={props.ivaCondition}
                cuitCajaProfesional={props.cuitCajaProfesional}
                fiscalPass={props.fiscalPass}
                fiscalPassLevel={props.fiscalPassLevel}
                fiscalPassStatus={props.fiscalPassStatus}
                cuitEmpleador={props.cuitEmpleador}
                sinObraSocial={props.sinObraSocial}
                cuitConyugeTrabajaRelacionDependencia={
                  props.cuitConyugeTrabajaRelacionDependencia
                }
                situacion={props.situacion}
                invoiceBeginMonth={props.invoiceBeginMonth}
                jubilacion={props.jubilacion}
                adherentes={props.adherentes}
                cantidadAdherentes={props.cantidadAdherentes}
                conMedicinaPrepaga={props.conMedicinaPrepaga}
                getData={props.getData}
                basePriceCreditCard={props.basePriceCreditCard}
                monthsOfServiceToOffer={props.monthsOfServiceToOffer}
                profileIdentifier={props.profileIdentifier}
                isInscriptoMonotributo={props.isInscriptoMonotributo}
                inscriptoMonotributoMonth={props.inscriptoMonotributoMonth}
                isSubscribed={props.isSubscribed}
                isRappi={props.isRappi}
                rappiId={props.rappiId}
                esperandoInscripcion={props.esperandoInscripcion}
                userPaid={props.userPaid}
                paymentInProcess={props.paymentInProcess}
                pendingPayments={props.pendingPayments}
                uniquePayment={props.uniquePayment}
                isInscripcionProduct={props.isInscripcionProduct}
                isUniquePayment={props.isUniquePayment}
                owesPayment={props.owesPayment}
                wasSubscribed={props.wasSubscribed}
                subscriptionDown={props.subscriptionDown}
                afipCondition={props.afipCondition}
                afipExpirationMonth={props.afipExpirationMonth}
                afipMonthlyAmount={props.afipMonthlyAmount}
                afipMonthlyDebt={props.afipMonthlyDebt}
                afipCategory={props.afipCategory}
                totalInvoice={props.totalInvoice}
                totalInvoiced={props.totalInvoiced}
                lastMonthComparation={props.lastMonthComparation}
                lastYearComparation={props.lastYearComparation}
                notEnoughData={props.notEnoughData}
                categories={props.categories}
                invoiceStartDate={props.invoiceStartDate}
                invoiceEndDate={props.invoiceEndDate}
                availbleToInvoice={props.availbleToInvoice}
                alreadyInvoicedBar={props.alreadyInvoicedBar}
                invoiceOverPass={props.invoiceOverPass}
                realCategoryName={props.realCategoryName}
                categoryProjectionStatus={props.categoryProjectionStatus}
                nextRecategorizationDate={props.nextRecategorizationDate}
                realTotalInvoicedBar={props.realTotalInvoicedBar}
                anuallyInvoiced={props.anuallyInvoiced}
                afipMonthlyImpuestosAmount={props.afipMonthlyImpuestosAmount}
                afipMonthlyObraSocialAmount={props.afipMonthlyObraSocialAmount}
                afipMonthlyJubilacionAmount={props.afipMonthlyJubilacionAmount}
                adherentesAfip={props.adherentesAfip}
                obraSocialAfip={props.obraSocialAfip}
                contributionStatus={props.contributionStatus}
                anuallyMonthsBase={props.anuallyMonthsBase}
                serviceType={props.serviceType}
                beforeInscription={props.beforeInscription}
                afipCategoryLimit={props.afipCategoryLimit}
                afipCategoryID={props.afipCategoryID}
                subscriptionType={props.subscriptionType}
                enterprisesWorksWith={props.enterprisesWorksWith}
                beatBasePricePlans={props.beatBasePricePlans}
                thirdPartySubscription={props.thirdPartySubscription}
                lastTicketUrl={props.lastTicketUrl}
                tramite={props.tramite}
              />
            )}
          />
          {/* Monotributo path */}
          <Route
            path="/panel/simulador/"
            render={() => (
              <Monotributo
                afipCategory={props.afipCategory}
                afipMonthlyAmount={props.afipMonthlyAmount}
                afipMonthlyImpuestosAmount={props.afipMonthlyImpuestosAmount}
                afipMonthlyObraSocialAmount={props.afipMonthlyObraSocialAmount}
                afipMonthlyJubilacionAmount={props.afipMonthlyJubilacionAmount}
                adherentesAfip={props.adherentesAfip}
                obraSocialAfip={props.obraSocialAfip}
                isRelacionDependencia={props.isRelacionDependencia}
                isInscriptoMonotributo={props.isInscriptoMonotributo}
                userPaid={props.userPaid}
                contributionStatus={props.contributionStatus}
                anuallyInvoiced={props.anuallyInvoiced}
                alreadyInvoicedBar={props.alreadyInvoicedBar}
                realCategoryName={props.realCategoryName}
                anuallyMonthsBase={props.anuallyMonthsBase}
                serviceType={props.serviceType}
                afipCategoryLimit={props.afipCategoryLimit}
                afipCategoryID={props.afipCategoryID}
              />
            )}
          />
          {/* Pagar impuestos path */}
          <Route
            path="/panel/pagar-impuestos/"
            render={() => (
              <Impuesto
                runningScrapingScript={props.runningScrapingScript}
                afipCategory={props.afipCategory}
                afipMonthlyAmount={props.afipMonthlyAmount}
                afipMonthlyImpuestosAmount={props.afipMonthlyImpuestosAmount}
                afipMonthlyObraSocialAmount={props.afipMonthlyObraSocialAmount}
                afipMonthlyJubilacionAmount={props.afipMonthlyJubilacionAmount}
                adherentesAfip={props.adherentesAfip}
                obraSocialAfip={props.obraSocialAfip}
                isRelacionDependencia={props.isRelacionDependencia}
                isInscriptoMonotributo={props.isInscriptoMonotributo}
                userPaid={props.userPaid}
                afipExpirationMonth={props.afipExpirationMonth}
                afipExpirationMonthText={props.afipExpirationMonthText}
                lastUpdateDebt={props.lastUpdateDebt}
                cbuNumber={props.cbuNumber}
                cbuBank={props.cbuBank}
                cbuStatus={props.cbuStatus}
                cbuSubscribed={props.cbuSubscribed}
                vepPaidOut={props.vepPaidOut}
                alreadySendEmailToPlan={props.alreadySendEmailToPlan}
                beforeInscriptionDate={props.beforeInscriptionDate}
              />
            )}
          />
          {/* IIBB path */}
          <Route path="/panel/iibb/" render={() => <IIBB />} />
          {/* Emitir Facturas path */}
          <Route
            path="/panel/emitir-facturas/"
            exact
            render={() => (
              <EmitirFacturasOptions
                beforeInscriptionDate={props.beforeInscriptionDate}
              />
            )}
          />
          <Route
            path="/panel/emitir-facturas/factura-c/"
            render={() => (
              <React.Fragment>
                <FacturaCForm
                  isInscriptoMonotributo={props.isInscriptoMonotributo}
                  userPaid={props.userPaid}
                  subscriptionDown={props.subscriptionDown}
                  isSubscribed={props.isSubscribed}
                  enterprisesWorksWith={props.enterprisesWorksWith}
                />
              </React.Fragment>
            )}
          />
          {/* Ver facturas path */}
          <Route
            path="/panel/ver-facturas/"
            render={() => (
              <VerFacturas
                subscriptionDown={props.subscriptionDown}
                isSubscribed={props.isSubscribed}
                cuit={props.cuit}
              />
            )}
          />
          {/* Formularios path */}
          <Route
            path="/panel/formularios/"
            render={() => (
              <Formularios
                isInscriptoMonotributo={props.isInscriptoMonotributo}
                userPaid={props.userPaid}
              />
            )}
          />
          {/* FAQ path */}
          <Route
            path="/panel/faq/"
            render={() => (
              <Faq
                cleanSubMenusRedirect={props.cleanSubMenusRedirect}
                subMenuName={props.subMenuName}
                subMenuItemName={props.subMenuItemName}
                toggleMenu={props.toggleMenu}
                isInscriptoMonotributo={props.isInscriptoMonotributo}
              />
            )}
          />
          {/* Profile path */}
          <Route path="/panel/plan/" render={() => SubscriptionTypeRedirect(props.subscriptionType,<Perfil />)} />
          <Route path="/panel/payment/" render={() => SubscriptionTypeRedirect(props.subscriptionType,<Perfil />)} />
          <Route path="/panel/mi-cuenta/" render={() => SubscriptionTypeRedirect(props.subscriptionType,<Perfil />)} />
          <Route path="/panel/perfil/" render={() => SubscriptionTypeRedirect(props.subscriptionType,<Perfil />)} />
          {/* No match path is Inicio */}
          <Route render={() => SubscriptionTypeRedirect(props.subscriptionType,<Redirect to="/panel/inicio/" />)} />
        </Switch>
      </React.Suspense>
    </Layout.Content>
  );
}

MainContent.propTypes = {
  afipCondition: PropTypes.string,
  cleanSubMenusRedirect: PropTypes.func,
  toggleMenu: PropTypes.func,
  subMenuName: PropTypes.string,
  subMenuItemName: PropTypes.string,
  isInscriptoMonotributo: PropTypes.bool,
  inscriptoMonotributoMonth: PropTypes.object,
  afipCategory: PropTypes.string,
  afipMonthlyAmount: PropTypes.any,
  afipMonthlyImpuestosAmount: PropTypes.any,
  afipMonthlyObraSocialAmount: PropTypes.any,
  afipMonthlyJubilacionAmount: PropTypes.any,
  adherentesAfip: PropTypes.any,
  obraSocialAfip: PropTypes.object,
  isRelacionDependencia: PropTypes.bool,
  invoiceBeginMonth: PropTypes.number,
  cuit: PropTypes.string,
  cuitCajaProfesional: PropTypes.string,
  situacion: PropTypes.any,
  jubilacion: PropTypes.any,
  fiscalPass: PropTypes.string,
  fiscalPassLevel: PropTypes.bool,
  cuitEmpleador: PropTypes.string,
  sinObraSocial: PropTypes.any,
  cuitConyugeTrabajaRelacionDependencia: PropTypes.string,
  adherentes: PropTypes.array,
  cantidadAdherentes: PropTypes.any,
  conMedicinaPrepaga: PropTypes.any,
  esperandoInscripcion: PropTypes.bool,
  userPaid: PropTypes.bool,
  subscriptionDown: PropTypes.bool,
  getData: PropTypes.func,
  basePriceCreditCard: PropTypes.any,
  monthsOfServiceToOffer: PropTypes.any,
  isSubscribed: PropTypes.bool,
  isRappi: PropTypes.bool,
  rappiId: PropTypes.string,
  profileIdentifier: PropTypes.string,
  enterprisesWorksWith: PropTypes.array,
  thirdPartySubscription: PropTypes.bool,
  lastTicketUrl: PropTypes.string,
  tramite: PropTypes.object,
};
