import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createHistoryBrowser } from "../../../../actions/createHistoryBrowser";
import { nextQuestionTicketGeneratedInscripcionEQ } from "../../../../actions/nextQuestion";

class Ticket extends React.Component {
  componentWillMount() {
    document.title = "Gestorando | Cupón de pago";
    createHistoryBrowser(`/inscripcion/cupon-de-pago/`);
  }

  renderCode() {
    const TICKET_CODE = this.props.ticketCode;

    return (
      <div>
        <span>{TICKET_CODE[0]}</span>
        <span>{TICKET_CODE[1]}</span>
      </div>
    );
  }

  renderTicket() {
    return (
      <div className="ticket-generated">
        <div className="card box-styles">
          <div className="first-block">
            <div>
              <i className="fas fa-dollar-sign" />
              <p>
                Listo, ahora ve a un {this.props.method} y paga ${" "}
                {this.props.basePriceOtherPayment}
              </p>
            </div>
            <div>
              <p>Dictale estos n&uacute;meros al cajero y listo.</p>
              {this.renderCode()}
              <a
                href={this.props.ticketUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Imprimir cup&oacute;n
              </a>
            </div>
          </div>
          <div className="last-block">
            <div>
              <p>Tambi&eacute;n te enviamos el c&oacute;digo a tu e-mail.</p>
              <p>
                Si lo abres desde tu tel&eacute;fono, ser&aacute; m&aacute;s
                f&aacute;cil dictarselo al cajero.
              </p>
            </div>
            <div>
              <i className="fa fa-clock-o" aria-hidden="true" />
              <span>El pago se acreditará al instante.</span>
            </div>
          </div>
          <div className="btns-next-back-wrapper">
            <button
              className="btn-back"
              type="button"
              onClick={this.props.toggleTicketToFalse}
            >
              <i className="fas fa-chevron-left" />
              Volver
            </button>
            <button
              className="remove-disabled btn-next"
              type="button"
              onClick={this.props.nextQuestionTicketGeneratedInscripcionEQ}
            >
              Continuar
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderTicket();
  }
}

Ticket.propTypes = {
  method: PropTypes.string,
  ticketCode: PropTypes.array,
  ticketUrl: PropTypes.string,
  toggleTicketToFalse: PropTypes.func,
  nextQuestionTicketGeneratedInscripcionEQ: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionTicketGeneratedInscripcionEQ,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Ticket);
