import { combineReducers } from 'redux';
import empresasQuestionnaire from './Empresas/questions';
import empresasActiveQuestion from './Empresas/activeQuestion';
import empresasRedirectedFromInscripcionQuestionnaire from './EmpresasRedirectedFromInscripcion/questions';
import empresasRedirectedFromInscripcionActiveQuestion from './EmpresasRedirectedFromInscripcion/activeQuestion';
import simulateCategoryQuestionnaire from './SimulateCategory/questions';
import simulateCategoryActiveQuestion from './SimulateCategory/activeQuestion';
import inscripcionQuestionnaire from './Inscripcion/questions';
import inscripcionActiveQuestion from './Inscripcion/activeQuestion';
import plansQuestionnaire from './Plans/questions';
import plansActiveQuestion from './Plans/activeQuestion';
import tramitesQuestionnaire from './Tramites/questions';
import tramitesActiveQuestion from './Tramites/activeQuestion';


export default combineReducers({
  empresasQuestionnaire,
  empresasActiveQuestion,
  empresasRedirectedFromInscripcionQuestionnaire,
  empresasRedirectedFromInscripcionActiveQuestion,
  simulateCategoryQuestionnaire,
  simulateCategoryActiveQuestion,
  inscripcionQuestionnaire,
  inscripcionActiveQuestion,
  plansQuestionnaire,
  plansActiveQuestion,
  tramitesQuestionnaire,
  tramitesActiveQuestion,
});
