import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { connect } from 'react-redux';

class RequestProgressBar extends React.Component {
  state = {
    show: false,
    percent: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.show !== nextProps.show) {
      this.setState(
        {
          show: nextProps.show,
          percent: 0,
        },
        () => {
          this.initDefaultProgress();
        },
      );
    }
  }

  initDefaultProgress = () => {
    if (this.state.show) {
      this.setState({
        percent: 15,
      });
      setTimeout(() => {
        this.setState({
          percent: 45,
        });
      }, 450);
      setTimeout(() => {
        this.setState({
          percent: 75,
        });
      }, 650);
    }
  };

  render() {
    const { percent, show } = this.state;

    return (
      <React.Fragment>
        {show && (
          <Progress
            className="request-progress-bar"
            strokeLinecap="square"
            strokeColor="#fc6158"
            percent={percent}
            status="active"
            showInfo={false}
          />
        )}
      </React.Fragment>
    );
  }
}

RequestProgressBar.propTypes = {
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  show: state.generalStatus.showRequestProgressBar,
});

export default connect(
  mapStateToProps,
  null,
)(RequestProgressBar);
