import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Body from "./Body";

class PaymentMethodWrapper extends React.Component {
  renderPaymentMethod() {
    return (
      <div className="col-md-8 col-lg-9 px-md-0">
        <div className="payment-method">
          <div className="card box-styles">
            <Header
              basePriceCreditCard={this.props.basePriceCreditCard}
              basePriceOtherPayment={this.props.basePriceOtherPayment}
              installments={this.props.installments}
              total_amount={this.props.total_amount}
              total_cuotas_amount={this.props.total_cuotas_amount}
              ptf={this.props.ptf}
              tea={this.props.tea}
              cft={this.props.cft}
              logoMercadopago={this.props.logoMercadopago}
              paymentMethod={this.props.paymentMethod}
              monthsOfServiceToOffer={this.props.monthsOfServiceToOffer}
              payingInscription={this.props.payingInscription}
              goBackArrow={this.props.goBackArrow}
            />
            <Body
              basePriceCreditCard={this.props.basePriceCreditCard}
              basePriceOtherPayment={this.props.basePriceOtherPayment}
              handleTicket={this.props.handleTicket}
              handleBasePrice={this.props.handleBasePrice}
              onNextQuestion={this.props.onNextQuestion}
              logoMercadopago={this.props.logoMercadopago}
              paymentMethod={this.props.paymentMethod}
              monthsOfServiceToOffer={this.props.monthsOfServiceToOffer}
              payingInscription={this.props.payingInscription}
              serviceToPay={this.props.serviceToPay}
              goBackArrow={this.props.goBackArrow}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderPaymentMethod();
  }
}

PaymentMethodWrapper.propTypes = {
  handleTicket: PropTypes.func,
  onNextQuestion: PropTypes.func,
  basePriceCreditCard: PropTypes.any,
  basePriceOtherPayment: PropTypes.string,
  installments: PropTypes.string,
  total_amount: PropTypes.string,
  total_cuotas_amount: PropTypes.string,
  ptf: PropTypes.string,
  tea: PropTypes.string,
  cft: PropTypes.string,
  logoMercadopago: PropTypes.string,
  handleBasePrice: PropTypes.func,
  paymentMethod: PropTypes.string,
  monthsOfServiceToOffer: PropTypes.any,
  payingInscription: PropTypes.bool,
  serviceToPay: PropTypes.object,
};

export default PaymentMethodWrapper;
