import { SELECT_ANSWER } from '../../actions/index';

// Checking if some questions had been answered
// And create new object based on what they answered

// All Questions object
const questions = [
  {
    id: 1,
    title: '¿Ya estás inscripto en el Monotributo?',
    path: '/EQ/1/',
    posibleAnswers: [
      {
        id: 1,
        text: 'Si',
        selected: false,
        nextQuestion: 2,
      },
      {
        id: 2,
        text: 'No',
        selected: false,
        nextQuestion: 3,
      },
    ],
  },
  {
    id: 2,
    title: 'Por favor ingresá tu CUIT',
    path: '/EQ/2/',
    beforeQuestion: 1,
  },
  {
    id: 3,
    title: '¿Tenés CUIT?',
    path: '/EQ/3/',
    moreInfoIcon: true,
    type: 'uniqueChoice',
    beforeQuestion: 2,
    posibleAnswers: [
      {
        id: 1,
        text: 'No',
        selected: false,
        nextQuestion: 5,
      },
      {
        id: 2,
        text: 'Si',
        selected: false,
        nextQuestion: 4,
      },
    ],
  },
  {
    id: 4,
    title: 'Por favor ingresá tu CUIT',
    path: '/EQ/4/',
    nextQuestion: 10,
    beforeQuestion: 3,
  },
  {
    id: 5,
    title: 'Seleccioná el tipo de documento que tenés',
    path: '/EQ/5/',
    type: 'uniqueChoice',
    beforeQuestion: 3,
    posibleAnswers: [
      {
        id: 1,
        text: 'DNI argentino',
        selected: false,
        nextQuestion: 6,
      },
      {
        id: 2,
        text: 'Precaria',
        selected: false,
        nextQUestion: 8,
      },
    ],
  },
  {
    id: 6,
    title: '¿Tu DNI está vigente y tiene fecha de vencimiento?',
    path: '/EQ/6/',
    type: 'uniqueChoice',
    beforeQuestion: 5,
    posibleAnswers: [
      {
        id: 1,
        text: 'Si',
        selected: false,
        nextQuestion: 8,
      },
      {
        id: 2,
        text: 'No',
        selected: false,
        nextQuestion: 8,
      },
    ],
  },
  {
    id: 10,
    title: 'Creá tu cuenta de Gestorando',
    path: '/registro/',
    description:
      'Crear una cuenta en Gestorando te va a permitir volver a nuestro sitio y tener toda tu información en un lugar',
    beforeQuestion: 3,
    nextQuestion: 11,
  },
  {
    id: 11,
    path: '/metodo-de-pago/',
    nextQuestion: 12,
  },
];

export default function(state = questions, action) {
  switch (action.type) {
    case SELECT_ANSWER:
      return action.payload;
    default:
      return state;
  }
}
