import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Icon } from "antd";
import LogoSmallGestorando from "../../../assets/img/logo-gestorando.svg";

class Sidebar extends React.Component {
  state = {
    toggleCollapse: false,
  };

  onCollapse = () => {
    const mainContentLayout = document.querySelectorAll(
      ".main-content.ant-layout-content"
    );
    const headerDashboard = document.querySelectorAll(".header--dashboard");

    mainContentLayout[0].classList.toggle("collapsed");
    headerDashboard[0].classList.toggle("collapsed");
  };

  handleOnClick = (item) => {
    if (item === "salir") {
      this.props.initLogout();
    } else if (item === "toggleCollapse") {
      this.setState(
        (prevState) => ({
          toggleCollapse: !prevState.toggleCollapse,
        }),
        () => this.onCollapse()
      );
    }
  };

  renderSibebar() {
    const HandHoldingUsd = () => (
      <svg
        aria-hidden="true"
        width="1em"
        height="1em"
        data-prefix="fas"
        data-icon="hand-holding-usd"
        role="img"
        viewBox="0 0 544 512"
      >
        <path
          fill="currentColor"
          d="M257.6 144.3l50 14.3c3.6 1 6.1 4.4 6.1 8.1 0 4.6-3.8 8.4-8.4 8.4h-32.8c-3.6 0-7.1-.8-10.3-2.2-4.8-2.2-10.4-1.7-14.1 2l-17.5 17.5c-5.3 5.3-4.7 14.3 1.5 18.4 9.5 6.3 20.3 10.1 31.8 11.5V240c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-17.6c30.3-3.6 53.3-31 49.3-63-2.9-23-20.7-41.3-42.9-47.7l-50-14.3c-3.6-1-6.1-4.4-6.1-8.1 0-4.6 3.8-8.4 8.4-8.4h32.8c3.6 0 7.1.8 10.3 2.2 4.8 2.2 10.4 1.7 14.1-2l17.5-17.5c5.3-5.3 4.7-14.3-1.5-18.4-9.5-6.3-20.3-10.1-31.8-11.5V16c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v17.6c-30.3 3.6-53.3 31-49.3 63 2.9 23 20.7 41.3 42.9 47.7zm276.3 183.8c-11.2-10.7-28.5-10-40.3 0L406.4 402c-10.7 9.1-24 14-37.8 14H256.9c-8.3 0-15.1-7.2-15.1-16s6.8-16 15.1-16h73.9c15.1 0 29-10.9 31.4-26.6 3.1-20-11.5-37.4-29.8-37.4H181.3c-25.5 0-50.2 9.3-69.9 26.3L67.5 384H15.1C6.8 384 0 391.2 0 400v96c0 8.8 6.8 16 15.1 16H352c13.7 0 27-4.9 37.8-14l142.8-121c14.4-12.1 15.5-35.3 1.3-48.9z"
        />
      </svg>
    );

    return (
      <div
        className={`main-sidebar ${
          this.state.toggleCollapse ? "collapse" : ""
        }`}
      >
        <div className="logo">
          <img
            className="img-fluid"
            src={LogoSmallGestorando}
            alt="Gestorando logo"
            title="Gestorando logo"
          />
        </div>
        <div className="menu">
          <ul>
            <li>
              <NavLink hidden={this.props.hideInicio} to="/panel/inicio/" activeClassName="selected">
                <Icon type="home" />
                <span>Inicio</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                hidden={this.props.hidePagarImpuestos}
                disabled={
                  !this.props.enableRoutesSidebar ||
                  this.props.disableRoutesSidebarByBeat ||
                  this.props.disablePagarImpuestos
                }
                to={
                  this.props.subscriptionDown
                    ? false
                    : "/panel/pagar-impuestos/"
                }
                activeClassName="selected"
              >
                <Icon component={HandHoldingUsd} />
                <span>Pagar Impuestos</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                hidden={this.props.hideEmitirFacturas}
                disabled={
                  !this.props.enableRoutesSidebar ||
                  this.props.disableRoutesSidebarByBeat
                }
                to="/panel/emitir-facturas/"
                activeClassName="selected"
              >
                <Icon type="form" />
                <span>Emitir facturas</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/panel/ver-facturas/" activeClassName="selected">
                <Icon type="dollar" />
                <span>Ver mi facturaci&oacute;n</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                hidden={this.props.hideFormularios}
                disabled={
                  this.props.subscriptionDown ||
                  !this.props.enableRoutesSidebar ||
                  this.props.disableRoutesSidebarByBeat
                }
                to="/panel/formularios/"
                activeClassName="selected"
              >
                <Icon type="file-search" />
                <span>
                  {this.props.isInscriptoMonotributo
                    ? "Mis formularios"
                    : "Guías paso a paso"}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink hidden={this.props.hidePerfil} to="/panel/perfil/" activeClassName="selected">
                <Icon type="user" />
                <span>Perfil</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                disabled={this.props.hasEnterprise}
                hidden={this.props.hideAccount}
                to="/panel/mi-cuenta/"
                activeClassName="selected"
              >
                <Icon type="credit-card" />
                <span>Mi Cuenta</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                hidden={this.props.hideHelp}
                disabled={this.props.subscriptionDown}
                to="/panel/faq/"
                activeClassName="selected"
              >
                <Icon type="question-circle" />
                <span>Ayuda</span>
              </NavLink>
            </li>
            <li>
              <button type="button" onClick={() => this.handleOnClick("salir")}>
                <Icon type="logout" />
                <span>Salir</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => this.handleOnClick("toggleCollapse")}
              >
                <Icon type="arrow-left" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return this.renderSibebar();
  }
}

Sidebar.propTypes = {
  initLogout: PropTypes.func,
  isInscriptoMonotributo: PropTypes.bool,
  subscriptionDown: PropTypes.bool,
  enableRoutesSidebar: PropTypes.bool,
  disableRoutesSidebarByBeat: PropTypes.bool,
};

export default Sidebar;
