import React from 'react';
import PropTypes from 'prop-types';
import { Select, LocaleProvider } from 'antd';
import esEs from 'antd/lib/locale-provider/es_ES';
import { categoriesPromise, commaStringToFloat, floatToString } from '../../../../apis';

class FacturacionSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      options: [],
      showMessageError: false,
      maxValue: '',
      categorias: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.secondMount = this.secondMount.bind(this);
  }

  secondMount() {
    // If on the question 2 venta muebles is selected
    const VENTA_MUEBLES_ACTIVITY_SELECTED = this.props.questions[2]
      .posibleAnswers[1].selected;
    let options;
    const optionsFacturacionMensual = [];
    let obj;
    const numObj = {
      'A':1,
      'B':2,
      'C':3,
      'D':4,
      'E':5,
      'F':6,
      'G':7,
      'H':8,
      'I':9,
      'J':10,
      'K':11
    };
    let { categorias } = this.state;
    if (!categorias)
    {
      categorias = {
        'A':{
        name:'A',
        servicios:'0,00',
        ventas:'0,00',
        iibb:'0,00',
        total:'0,00',
      }};
    };
    let keys = Object.keys(categorias);
    keys = keys.sort();


    if (VENTA_MUEBLES_ACTIVITY_SELECTED) {
      options = keys.map((key) => {
        const localCategory = categorias[key];
        let facturacionMensual = floatToString(commaStringToFloat(localCategory.iibb) / 12);
        let categoria = key;
        let value = numObj[key];
        return {facturacionMensual:facturacionMensual,categoria:categoria,value:value};
      });
    } else {
      options = keys.map((key) => {
        if (["I","J","K"].includes(key)) {
          return;
        }
        const localCategory = categorias[key];
        let facturacionMensual = floatToString(commaStringToFloat(localCategory.iibb) / 12);
        let categoria = key;
        let value = numObj[key];
        return {facturacionMensual:facturacionMensual,categoria:categoria,value:value};
      });
      options = options.filter( Boolean );
    }

    // Create the options
    options.forEach(x => {
      // Set specific String on the Category 'A'
      if (x.categoria === 'A') {
        obj = {
          value: x.value,
          facturacionMensual: `Entre $ 0 y ${
            x.facturacionMensual
          } por mes`,
        };

        optionsFacturacionMensual.push(obj);
      } else {
        obj = {
          value: x.value,
          facturacionMensual: `Entre ${
            options[x.value - 2].facturacionMensual
          } y ${options[x.value - 1].facturacionMensual} por mes`,
        };

        optionsFacturacionMensual.push(obj);
      }
    });

    // Add exceeded option
    obj = {
      value: 'exceed',
      facturacionMensual: `Mas de ${
        options[optionsFacturacionMensual.length - 1].facturacionMensual
      }`,
    };

    optionsFacturacionMensual.push(obj);

    // Setting the max value allowed
    this.setState({
      maxValue: categorias[options[optionsFacturacionMensual.length - 2].categoria].iibb,
    });

    // If already has been answered then load the answer
    if (localStorage.getItem('Q6')) {
      // Setting the selected option to the localStorage
      // Then when we press the next button we merge all the data to only 1 answer with redux
      localStorage.setItem(
        'facturacionEstimada',
        JSON.parse(localStorage.getItem('Q6')).facturacionEstimada,
      );

      this.setState({
        options: optionsFacturacionMensual,
        value: JSON.parse(localStorage.getItem('Q6')).facturacionEstimada,
      });
    } else {
      this.setState({
        options: optionsFacturacionMensual,
      });
    }
  }

  componentWillMount() {
    categoriesPromise.then((categorias) => {
      this.setState({categorias},()=>this.secondMount())
    });
    
  }

  handleChange(value, selectName) {
    this.setState({
      value,
      showMessageError: value === 'exceed',
    });

    // Setting the selected option to the localStorage
    // Then when we press the next button we merge all the data to only 1 answer with redux
    localStorage.setItem(selectName, value);
  }

  getSelectOptions() {
    return this.state.options.map(x => (
      <Select.Option key={Math.random()} value={x.value}>
        {x.facturacionMensual}
      </Select.Option>
    ));
  }

  renderNextButton() {
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
        <button
          className={`${
            this.state.value !== '' && this.state.value !== 'exceed'
              ? 'remove-disable'
              : false
          } btn-next`}
          type="button"
          onClick={this.props.onNextQuestion}
          disabled={this.state.value === '' || this.state.value === 'exceed'}
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <hr />
        <div className="select-wrapper">
          <div className="custom-select-styles--antd">
            <LocaleProvider locale={esEs}>
              <Select
                className={this.state.value !== '' ? 'valid' : null}
                showSearch
                placeholder={this.props.question.placeholder}
                optionFilterProp="children"
                onChange={e => this.handleChange(e, 'facturacionEstimada')}
                value={this.state.value}
                filterOptions={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option value="" disabled>
                  Seleccioná tus ingresos
                </Select.Option>
                {this.getSelectOptions()}
              </Select>
            </LocaleProvider>
          </div>
        </div>
        <p
          className={`${
            this.state.showMessageError ? null : 'd-none'
          } text-danger mb-5`}
        >
          Según esa facturacion, no podés inscribirte en el Monotributo. La ley
          indica que tus ingresos{' '}
          <strong>
            <u>no pueden superar los {this.state.maxValue} anuales</u>
          </strong>, en la prestación de servicios. La categoria mas alta del
          Monotributo establece que no podes facturar mas de{' '}
          {this.state.maxValue} anuales, por lo que{' '}
          <strong>
            <u>
              debes inscribirte como responsable inscripto en IVA y Ganancias
            </u>
          </strong>
        </p>
        {this.renderNextButton()}
      </React.Fragment>
    );
  }
}

FacturacionSelect.propTypes = {
  question: PropTypes.object,
  questions: PropTypes.array,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default FacturacionSelect;
