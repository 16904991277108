import {
  NEXT_QUESTION,
  NEXT_QUESTION_TICKET_GENERATED,
  NEXT_QUESTION_CUIT_VALIDATED,
  BEFORE_QUESTION,
  LOGIN
} from "../../actions/index";

// Checking if answered based on localStorage()
function loadStateBasedOnLocalStorage() {
  const storage = localStorage;
  let state = 1;

  if (storage.getItem("Q14.1")) {
    state = 17;
  } else if (storage.getItem("Q14")) {
    state = 17;
  } else if (storage.getItem("Q13")) {
    if (JSON.parse(storage.getItem("Q13")).id === 1) {
      state = 14;
    } else {
      state = 14.1;
    }
  } else if (storage.getItem("Q12")) {
    if (JSON.parse(storage.getItem("Q12")).id === 1) {
      state = 13;
    } else if (JSON.parse(storage.getItem("Q12")).id === 2) {
      state = 14.1;
    }
  } else if (storage.getItem("Q11")) {
    if (JSON.parse(storage.getItem("Q11")).cuitValidated === true) {
      state = 17;
    } else {
      state = 12;
    }
  } else if (storage.getItem("Q10")) {
    if (JSON.parse(storage.getItem("Q10")).id === 1) {
      state = 11;
    } else {
      state = 12;
    }
  } else if (storage.getItem("Q9")) {
    state = 9;
  } else if (storage.getItem("Q8")) {
    state = 8;
  } else if (storage.getItem("Q7")) {
    state = 8;
  } else if (storage.getItem("Q6")) {
    state = 7;
  } else if (storage.getItem("Q5")) {
    state = 6;
  } else if (storage.getItem("Q4")) {
    state = 5;
  } else if (storage.getItem("Q3")) {
    if (JSON.parse(storage.getItem("Q3")).id === 1) {
      state = 4;
    } else {
      state = 5;
    }
  } else if (storage.getItem("Q2")) {
    if (JSON.parse(storage.getItem("Q2")).id === 1) {
      state = 5;
    } else {
      state = 3;
    }
  } else if (storage.getItem("Q1")) {
    state = 2;
  }

  // If is coming from another path then set to 1
  if (storage.getItem("path") !== "empresas") {
    state = 1;
  }

  return state;
}

export default function(state = loadStateBasedOnLocalStorage(), action) {
  switch (action.type) {
    case NEXT_QUESTION:
      return action.payload;
    case BEFORE_QUESTION:
      return action.payload;
    case NEXT_QUESTION_CUIT_VALIDATED:
      return action.payload;
    case NEXT_QUESTION_TICKET_GENERATED:
      return action.payload;
    case LOGIN:
      return action.payload;
    default:
      return state;
  }
}
