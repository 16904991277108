import React from 'react';
import PropTypes from 'prop-types';

class EnvioDeEmailConInstrucciones extends React.Component {
  renderNextButton() {
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-next"
          type="button"
          onClick={this.props.onNextQuestion}
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  render() {
    const { question } = this.props;
    const { title } = question;
    const { description } = this.props;

    return (
      <div className="questions">
        <div>
          <div className="response-wrapper">
            <p className="title text-left">{title}</p>
            <p className="description">
              1. CUIT
              <br />
              2. Clave fiscal
              <br />
              3. Datos sobre tus aportes (obra social y/o jubilación)
              <br />
              <br />
              {description}
            </p>
          </div>
        </div>
        {this.renderNextButton()}
      </div>
    );
  }
}

EnvioDeEmailConInstrucciones.propTypes = {
  question: PropTypes.object,
  description: PropTypes.string,
  onNextQuestion: PropTypes.func,
};

export default EnvioDeEmailConInstrucciones;
