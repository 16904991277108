import { BEFORE_QUESTION } from "./index";
import { createHistoryBrowser } from "./createHistoryBrowser";
import { enterprises } from "./listEnterprises";
// When you select before question on the EmpresasEQ
export function beforeQuestionEmpresasEQ(question) {
  const enterprise = localStorage.getItem("enterprise");
  if (!enterprises.includes(enterprise)) {
    if (question.beforeQuestion === 6) {
      createHistoryBrowser(`/suscripcion/${enterprise}/registro/`);
    } else if (question.beforeQuestion === 7) {
      createHistoryBrowser(`/suscripcion/${enterprise}/metodo-de-pago/`);
    } else {
      createHistoryBrowser(
        `/suscripcion/${enterprise}/EQ/${question.beforeQuestion}/`
      );
    }
  } else {
    if (question.beforeQuestion === 10) {
      createHistoryBrowser(`/suscripcion/${enterprise}/registro/`);
    } else if (question.beforeQuestion === 11) {
      createHistoryBrowser(`/suscripcion/${enterprise}/metodo-de-pago/`);
    } else {
      createHistoryBrowser(
        `/suscripcion/${enterprise}/EQ/${question.beforeQuestion}/`
      );
    }
  }

  return {
    type: BEFORE_QUESTION,
    payload: question.beforeQuestion,
  };
}

// When you select before question on the SimulateEQ
export function beforeQuestionSimulateEQ(question) {
  createHistoryBrowser(`/simular-categoria/EQ/${question.beforeQuestion}/`);

  return {
    type: BEFORE_QUESTION,
    payload: question.beforeQuestion,
  };
}

// When you select before question on the InscripcionEQ
export function beforeQuestionInscripcionEQ(question) {
  if (question.beforeQuestion === 9) {
    createHistoryBrowser("/inscripcion/registro");
  } else if (question.beforeQuestion === 10) {
    createHistoryBrowser("/inscripcion/metodo-de-pago");
  } else {
    createHistoryBrowser(`/inscripcion/EQ/${question.beforeQuestion}/`);
  }

  return {
    type: BEFORE_QUESTION,
    payload: question.beforeQuestion,
  };
}

// When you select before question on the PlansEQ
export function beforeQuestionPlansEQ(question) {
  if (question.beforeQuestion === 9) {
    createHistoryBrowser("/plans/registro");
  } else if (question.beforeQuestion === 10) {
    createHistoryBrowser("/plans/metodo-de-pago");
  } else {
    createHistoryBrowser(`/plans/EQ/${question.beforeQuestion}/`);
  }

  return {
    type: BEFORE_QUESTION,
    payload: question.beforeQuestion,
  };
}


// When you select before question on the TramitesEQ
export function beforeQuestionTramitesEQ(question) {
  if (question.beforeQuestion === 9) {
    createHistoryBrowser("/tramites/registro");
  } else if (question.beforeQuestion === 10) {
    createHistoryBrowser("/tramites/metodo-de-pago");
  } else {
    createHistoryBrowser(`/tramites/EQ/${question.beforeQuestion}/`);
  }

  return {
    type: BEFORE_QUESTION,
    payload: question.beforeQuestion,
  };
}
