import React from "react";
import PropTypes from "prop-types";
import { Card, Carousel, Checkbox, Icon, Radio, Button } from "antd";
import {
  enterprises,
  freeProducts,
  inscriptionsProducts,
} from "../../../../actions/listEnterprises";

class BeatPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConfirm: false,
      confirmButtonDisabled: false,
      confirmChecked: true,
      enterprise: "",
      fromPanel: false,
    };
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.onInscripcionClick = this.onInscripcionClick.bind(this);
    this.onInscripcionBack = this.onInscripcionBack.bind(this);
    this.onInscripcionCheckChange = this.onInscripcionCheckChange.bind(this);
  }

  componentDidMount() {
    let enterprise;
    let enterprisesWorksWith = this.props.enterprisesWorksWith;
    if (enterprisesWorksWith) {
      for (var i in enterprisesWorksWith) {
        if (
          enterprises.includes(enterprisesWorksWith[i].legal_name.toLowerCase())
        ) {
          enterprise = enterprisesWorksWith[i].legal_name.toLowerCase();
        }
      }
    } else {
      enterprise = localStorage.getItem("enterprise").toLocaleLowerCase();
    }
    this.setState({
      enterprise: enterprise
        ? enterprise === "timejobs"
          ? "Time Jobs"
          : enterprise.toUpperCase()
        : null,
      fromPanel: window.location.pathname === "/panel/inicio/" ? true : false,
    });
  }

  handlePositionChange(value) {
    if (!freeProducts.includes(this.props.subscriptionType)) {
      this.slider.goTo(value.target.value);
    } else {
      this.slider.goTo(value.target.value - 1);
    }
  }

  onInscripcionClick() {
    this.setState({ showConfirm: true });
  }

  onInscripcionBack() {
    this.setState({ showConfirm: false });
  }

  onInscripcionCheckChange(e) {
    this.setState({ confirmChecked: !this.state.confirmChecked });
    this.setState({ confirmButtonEnabled: this.state.confirmChecked });
  }

  renderGratisCard() {
    return (
      <div
        className="plan-card-beat-gstrnd"
        style={{
          margin: `${this.props.inscriptoMonotributo ? "0" : "0 auto"}`,
        }}
      >
        <div className="plan-header-beat-gstrnd">
          <h4 className="plan-header--title-beat-gstrnd">Gratis</h4>
        </div>
        <div className="plan-promo-beat-gstrnd">
          <p className="text-center">
            Facturación de {this.state.enterprise} <br />
            <span className="span-plans-pricing">$0</span>/mes
          </p>
        </div>
        <div className="plan-items-beat-gstrnd">
          <ul className="plan-items-ul">
            <li>
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Facturación de {this.state.enterprise}
            </li>
          </ul>
        </div>
        <div className="plan-footer-beat-gstrnd">
          <Button
            type="primary"
            className="plan-footer--button-beat-gstrnd"
            onClick={this.props.onClick}
            value="free-plan"
          >
            Empezar ahora!
          </Button>
        </div>
      </div>
    );
  }

  renderAbonoCard() {
    return (
      <div
        className="plan-card-beat-gstrnd"
        style={{
          margin: `${this.props.inscriptoMonotributo ? "0" : "0 auto"}`,
        }}
      >
        <div className="plan-header-beat-gstrnd">
          <h4 className="plan-header--title-beat-gstrnd">Abono</h4>
        </div>
        <div className="plan-promo-beat-gstrnd">
          <p className="text-center">
            Inscripción y gestión{" "}
            {this.state.enterprise === enterprises[0] ? "de" : null} impuestos{" "}
            <br />
            <span className="span-plans-pricing">$300</span>/Por mes
          </p>
        </div>
        <div className="plan-items-beat-gstrnd">
          <ul className="plan-items-ul">
            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Facturación de {this.state.enterprise}
            </li>

            <li>
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Inscripción al Monotributo
            </li>

            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Constancias Monotributo
            </li>

            <li>
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Gestión Monotributo
            </li>

            <li>
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Facturación otros clientes
            </li>

            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Inscripción Ingresos Brutos
            </li>

            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Gestión Ingresos Brutos
            </li>
          </ul>
        </div>
        <div className="plan-footer-beat-gstrnd">
          <Button
            type="primary"
            className="plan-footer--button-beat-gstrnd"
            onClick={this.props.onClick}
            value="complete-plan"
          >
            Empezar ahora!
          </Button>
        </div>
      </div>
    );
  }

  renderInscricionCard() {
    return (
      <div className="plan-card-beat-gstrnd">
        <div className="plan-header-beat-gstrnd">
          <h4 className="plan-header--title-beat-gstrnd">Inscripción</h4>
        </div>
        <div className="plan-promo-beat-gstrnd">
          <p className="text-center">
            Inscripción Monotributo <br />
            {this.state.enterprise.toLowerCase() === enterprises[0] ? (
              <>
                <span className="tachado">$600</span>
                <span className="percent-off"> 50% off</span>
                <br />
                <span className="span-plans-pricing">$300</span>
              </>
            ) : (
              <>
                <span className="span-plans-pricing">$600</span>
              </>
            )}
            /Por única vez
          </p>
        </div>
        <div className="plan-items-beat-gstrnd">
          <ul className="plan-items-ul">
            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Facturación de {this.state.enterprise}
            </li>

            <li>
              {" "}
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Inscripción al Monotributo
            </li>

            <li>
              <Icon
                className="icon-checks-plans"
                style={{
                  fontSize: "18px",
                  color: "rgb(66, 183, 42)",
                }}
                type="check-circle"
                theme="filled"
              />
              Constancias Monotributo
            </li>
          </ul>
          {this.props.needInscriptionNow ? (
            <p
              className="justify-text"
              style={{
                display:
                  this.state.enterprise.toLowerCase() === enterprises[0]
                    ? "block"
                    : "none",
              }}
            >
              Gracias al acuerdo con BEAT, el pago de éste trámite te lo vamos a
              descontar de tu primer liquidación de BEAT
            </p>
          ) : null}
        </div>
        <div className="plan-footer-beat-gstrnd">
          <Button
            type="primary"
            className="plan-footer--button-beat-gstrnd"
            onClick={
              this.state.enterprise.toLowerCase() === enterprises[0]
                ? this.onInscripcionClick
                : this.props.onClick
            }
            value="discount-plan"
          >
            Empezar ahora!
          </Button>
        </div>
      </div>
    );
  }

  renderInscripcionConfirm() {
    return (
      <Card className="card-inscripcion-confirmation">
        {this.state.fromPanel ? (
          <div className="button-title-header">
            <button
              onClick={this.onInscripcionBack}
              className="go-back-payment-button"
            >
              <Icon type="arrow-left" />
            </button>
            <h5 className="header-invitation-title">
              Selecciona el servicio que buscás
            </h5>
          </div>
        ) : null}
        <div className="row">
          {!this.state.fromPanel ? (
            <div className="col-12">
              <h4 className="text-center">Selecciona el servico que buscás</h4>
            </div>
          ) : null}
          <div className="col-12">
            <p className="text-center">
              Gracias a nuestro acuerdo con {this.state.enterprise}, el pago de
              éste trámite lo vas a realizar cuando tengas la primer liquidación
              de {this.state.enterprise}.
            </p>
          </div>
          <div className="col-12">
            <Checkbox
              onChange={this.onInscripcionCheckChange}
              checked={this.state.confirmChecked}
            >
              Estoy de acuerdo, quiero que me lo debiten de mi primer
              liquidación de {this.state.enterprise}
            </Checkbox>
          </div>
        </div>
        <div className="btns-next-back-wrapper">
          {!this.state.fromPanel ? (
            <button
              className="btn-back"
              type="button"
              onClick={this.onInscripcionBack}
            >
              <i className="fas fa-chevron-left" />
              Volver
            </button>
          ) : null}
          <button
            className="btn-next"
            type="button"
            onClick={this.props.onClick}
            value="discount-plan"
            disabled={this.state.confirmButtonEnabled}
          >
            Continuar
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </Card>
    );
  }

  render() {
    return (
      <>
        {!this.state.showConfirm ? (
          <Card className="mobile-carouse-plans">
            <div className="row select-carousel-slider">
              <Radio.Group
                defaultValue={
                  this.props.renderAllBeatPlans
                    ? !freeProducts.includes(this.props.subscriptionType) &
                      !this.props.isSubscribed
                      ? "0"
                      : !this.props.inscriptoMonotributo &&
                        !inscriptionsProducts.includes(
                          this.props.subscriptionType
                        )
                      ? "1"
                      : "0"
                    : "2"
                }
                buttonStyle="solid"
                onChange={this.handlePositionChange}
              >
                {this.props.renderAllBeatPlans ? (
                  this.props.needInscriptionNow ? (
                    <Radio.Button value="1">Inscripción</Radio.Button>
                  ) : (
                    <>
                      {!freeProducts.includes(this.props.subscriptionType) &
                      !this.props.isSubscribed ? (
                        <Radio.Button value="0">Gratis</Radio.Button>
                      ) : (
                        ""
                      )}
                      {!this.props.inscriptoMonotributo &&
                      !inscriptionsProducts.includes(
                        this.props.subscriptionType
                      ) ? (
                        <Radio.Button value="1">Inscripción</Radio.Button>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
                {!this.props.needInscriptionNow ? (
                  <Radio.Button value="2">Abono</Radio.Button>
                ) : null}
              </Radio.Group>
            </div>
            {this.props.renderAllBeatPlans ? (
              this.props.needInscriptionNow ? (
                <Carousel
                  ref={(slider) => (this.slider = slider)}
                  effect="fade"
                >
                  {this.renderInscricionCard()}
                </Carousel>
              ) : (
                <Carousel
                  ref={(slider) => (this.slider = slider)}
                  effect="fade"
                  swipe={false}
                >
                  {!freeProducts.includes(this.props.subscriptionType) &
                  !this.props.isSubscribed
                    ? this.renderGratisCard()
                    : ""}
                  {!this.props.inscriptoMonotributo
                    ? this.renderInscricionCard()
                    : ""}
                  {this.renderAbonoCard()}
                </Carousel>
              )
            ) : (
              <Carousel ref={(slider) => (this.slider = slider)} effect="fade">
                {this.renderAbonoCard()}
              </Carousel>
            )}
          </Card>
        ) : (
          ""
        )}
        {/* END MOBILE */}

        {/* DESKTOP */}
        {this.props.renderAllBeatPlans ? (
          <>
            {this.state.showConfirm ? (
              this.renderInscripcionConfirm()
            ) : (
              <Card className="plan-cards-beat-gstrnd">
                <div className="button-title-header">
                  <button
                    style={{
                      display: `${!this.state.fromPanel ? "none" : "block"}`,
                    }}
                    onClick={this.props.goBackArrow}
                    className="go-back-payment-button"
                  >
                    <Icon type="arrow-left" />
                  </button>
                  <h5 className="header-invitation-title">
                    Selecciona el servicio que buscás
                  </h5>
                </div>
                {this.props.needInscriptionNow ? (
                  <div className="sub-card-body">
                    {this.renderInscricionCard()}
                  </div>
                ) : (
                  <div className="sub-card-body">
                    {!freeProducts.includes(this.props.subscriptionType) &
                    !this.props.isSubscribed
                      ? this.renderGratisCard()
                      : null}

                    {!this.props.inscriptoMonotributo &&
                    !inscriptionsProducts.includes(this.props.subscriptionType)
                      ? this.renderInscricionCard()
                      : null}
                    {this.renderAbonoCard()}
                  </div>
                )}
              </Card>
            )}
          </>
        ) : (
          <>
            <Card className="plan-cards-beat-gstrnd">
              <div className="button-title-header">
                <button
                  onClick={this.props.goBackArrow}
                  className="go-back-payment-button"
                >
                  <Icon type="arrow-left" />
                </button>
                <h5 className="header-invitation-title">
                  Selecciona el servicio que buscás
                </h5>
              </div>

              <div className="sub-card-body">{this.renderAbonoCard()}</div>
            </Card>
          </>
        )}
      </>
    );
  }
}

BeatPlans.propTypes = {
  onClick: PropTypes.func,
  inscriptoMonotributo: PropTypes.bool,
  renderAllBeatPlans: PropTypes.bool,
  subscriptionType: PropTypes.string,
  isSubscribed: PropTypes.bool,
  goBackArrow: PropTypes.func,
  enterprisesWorksWith: PropTypes.array,
  needInscriptionNow: PropTypes.bool,
};
export default BeatPlans;
