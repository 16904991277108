import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import Nav from "../components/Nav/index";
import InscripcionEQ from "../components/EQ/InscripcionEQ/index";
import ProgressBar from "../components/EQ/ProgressBar/index";
import Signup from "../components/Signup/index";
import PaymentMethod from "../components/EQ/InscripcionEQ/PaymentMethod/index";
import Loader from "../components/Loader/index";
import ModalIfUserWantsToResetEQ from "../components/EQ/Partials/ModalIfUserWantsToResetEQ";
import { createHistoryBrowser } from "../actions/createHistoryBrowser";
import { logout } from "../actions/authentication";
import {
  nextQuestionInscripcionEQ,
  selectSpecificQuestion,
} from "../actions/nextQuestion";
import { beforeQuestionInscripcionEQ } from "../actions/beforeQuestion";
import { redirectUrl } from "../actions/redirectUrl";
import { clearLocalStorage } from "../actions/clearLocalStorage";

class InscripcionQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: !!localStorage.getItem("token"),
      loggedIn: !!localStorage.getItem("token"),
      isLoading: false,
      showModalIfUserWantsToResetEQ: false,
      loadingMessage: "",
    };

    this.initLogout = this.initLogout.bind(this);
    this.handleCancelModalIfUserWantsToResetEQ = this.handleCancelModalIfUserWantsToResetEQ.bind(
      this
    );
  }

  componentWillMount() {
    // If is coming from another path then clear the localStorage
    if (localStorage.getItem("path") !== "inscripcion") {
      clearLocalStorage(true);
    }

    // Setting on the localStorage the current path that is doing
    localStorage.setItem("path", "inscripcion");

    this.setState({
      showModalIfUserWantsToResetEQ: this.props.activeQuestion > 1,
    });
  }

  componentDidUpdate() {
    if (this.state.loggedIn !== !!localStorage.getItem("token")) {
      this.setState({
        loggedIn: !!localStorage.getItem("token"),
      });
    }
  }

  componentDidMount() {
    const history = createBrowserHistory();
    const { questions } = this.props;
    const actualQuestion = questions.filter(
      (questionData) => questionData.id === this.props.activeQuestion
    )[0];

    if (actualQuestion.id === 11 || !!localStorage.getItem("loggedIn")) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          redirectUrl("/panel/");
        }
      );
    } else {
      createHistoryBrowser(`/inscripcion${actualQuestion.path}`);
    }

    history.listen((location, action) => {
      if (action === "POP") {
        let locationPathnameId;

        if (location.pathname.indexOf("registro") !== -1) {
          locationPathnameId = 7;
        } else if (location.pathname.indexOf("metodo-de-pago") !== -1) {
          locationPathnameId = 9;
        } else if (location.pathname.indexOf("cupon-de-pago") !== -1) {
          locationPathnameId = actualQuestion.id;
        } else {
          locationPathnameId = parseInt(
            location.pathname.replace(/[^0-9 -]+/g, "")
          );
        }

        // If the question to redirect is not the actual question (unnecessary redirect)
        // And if is not leaving the page
        if (
          locationPathnameId !== actualQuestion.id &&
          !isNaN(locationPathnameId)
        ) {
          this.props.selectSpecificQuestion(locationPathnameId);
        }
      }
    });
  }

  handleCancelModalIfUserWantsToResetEQ() {
    this.setState({ showModalIfUserWantsToResetEQ: false });
  }

  showNextButton() {
    return true;
  }

  showBackButton(question) {
    let bool = false;
    if ("beforeQuestion" in question) {
      bool = true;
    }

    return bool;
  }

  calculateProgress(question) {
    const EQLength = this.props.questions.length;
    const { activeQuestion } = this.props;
    let percentage;

    if (question.id !== 1) {
      percentage = Math.round((activeQuestion * 100) / EQLength);
    } else {
      percentage = 0;
    }

    return percentage;
  }

  initLogout() {
    this.setState(
      {
        isLoading: true,
        loadingMessage: "Saliendo...",
      },
      this.finishingLogout()
    );
  }

  finishingLogout() {
    this.props.logout();
  }

  renderLoader() {
    if (this.state.isLoading)
      return <Loader loadingMessage={this.state.loadingMessage} />;
  }

  renderQuestion() {
    const activeQuestionData = this.props.questions.filter(
      (questionData) => questionData.id === this.props.activeQuestion
    )[0];

    /* Signup Component (id: 7) */
    if (activeQuestionData.id === 7) {
      if (this.state.loggedIn) {
        this.props.nextQuestionInscripcionEQ(activeQuestionData);
      } else {
        return (
          <React.Fragment>
            {this.renderLoader()}
            <ModalIfUserWantsToResetEQ
              visible={this.state.showModalIfUserWantsToResetEQ}
              handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
            />
            <Nav loggedIn={this.state.loggedIn} initLogout={this.initLogout} />
            <div className="content">
              <ProgressBar
                progress={this.calculateProgress(activeQuestionData)}
              />
              <Signup
                api="inscripcion"
                onNextQuestion={() =>
                  this.props.nextQuestionInscripcionEQ(activeQuestionData)
                }
                title={activeQuestionData.title}
                description={activeQuestionData.description}
              />
            </div>
          </React.Fragment>
        );
      }
    }

    /* PaymentMethod Component (id: 9) */
    if (activeQuestionData.id === 9) {
      return (
        <React.Fragment>
          {this.renderLoader()}
          <ModalIfUserWantsToResetEQ
            visible={this.state.showModalIfUserWantsToResetEQ}
            handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
          />
          <Nav loggedIn={this.state.loggedIn} initLogout={this.initLogout} />
          <div className="content">
            <ProgressBar
              progress={this.calculateProgress(activeQuestionData)}
            />
            <PaymentMethod
              onNextQuestion={() =>
                this.props.nextQuestionInscripcionEQ(activeQuestionData)
              }
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.renderLoader()}
        <ModalIfUserWantsToResetEQ
          visible={this.state.showModalIfUserWantsToResetEQ}
          handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
        />
        <Nav loggedIn={this.state.loggedIn} initLogout={this.initLogout} />
        <div className="content">
          <ProgressBar progress={this.calculateProgress(activeQuestionData)} />
          <InscripcionEQ
            questionData={activeQuestionData}
            onNextQuestion={() =>
              this.props.nextQuestionInscripcionEQ(activeQuestionData)
            }
            onBackQuestion={() =>
              this.props.beforeQuestionInscripcionEQ(activeQuestionData)
            }
            showNextButton={this.showNextButton()}
            showBackButton={this.showBackButton(activeQuestionData)}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.renderQuestion();
  }
}

InscripcionQuestionnaire.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  activeQuestion: PropTypes.number,
  nextQuestionInscripcionEQ: PropTypes.func,
  beforeQuestionInscripcionEQ: PropTypes.func,
  selectSpecificQuestion: PropTypes.func,
  match: PropTypes.object,
  logout: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    questions: state.inscripcionQuestionnaire,
    activeQuestion: state.inscripcionActiveQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionInscripcionEQ,
      beforeQuestionInscripcionEQ,
      selectSpecificQuestion,
      logout,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InscripcionQuestionnaire);
