import React from "react";
import PropTypes from "prop-types";
import HeaderBody from "./HeaderBody";

class ServiceDescription extends React.Component {
  renderServiceDescription() {
    return (
      <div className="d-none d-md-block col-md-4 col-lg-3 pr-0">
        <div className="service-description">
          <div className="card box-styles p-0">
            <HeaderBody
              paymentMethod={this.props.paymentMethod}
              basePriceCreditCard={this.props.basePriceCreditCard}
              basePriceOtherPayment={this.props.basePriceOtherPayment}
              installments={this.props.installments}
              total_amount={this.props.total_amount}
              total_cuotas_amount={this.props.total_cuotas_amount}
              ptf={this.props.ptf}
              tea={this.props.tea}
              cft={this.props.cft}
              logoMercadopago={this.props.logoMercadopago}
              show_mercadopago_logo
              monthsOfServiceToOffer={this.props.monthsOfServiceToOffer}
              payingInscription={this.props.payingInscription}
            />
          </div>
        </div>
      </div>
    );
  }
  render() {
    return this.renderServiceDescription();
  }
}

ServiceDescription.propTypes = {
  basePriceCreditCard: PropTypes.any,
  basePriceOtherPayment: PropTypes.string,
  installments: PropTypes.string,
  total_amount: PropTypes.string,
  total_cuotas_amount: PropTypes.string,
  ptf: PropTypes.string,
  tea: PropTypes.string,
  cft: PropTypes.string,
  logoMercadopago: PropTypes.string,
  monthsOfServiceToOffer: PropTypes.any,
  payingInscription: PropTypes.bool,
};

export default ServiceDescription;
