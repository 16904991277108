import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AnswerWrapper from '../../Partials/AnswerWrapper';
import AgregarAdherentes from '../AgregarAdherentes/index';
import EspacioFisico from '../EspacioFisico/index';
import EstimasFacturar from '../EstimasFacturar/index';
import Response from '../Response/index';
import MasDeTresActividades from '../MasDeTresActividades/index';
import { redirectUrl } from '../../../../actions/redirectUrl';

class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem('token'),
      buttonNextDisabled: true,
    };

    this.handleDisablingOfNextButton = this.handleDisablingOfNextButton.bind(
      this,
    );
    this.handleNextQuestionRedirectToInscripcion = this.handleNextQuestionRedirectToInscripcion.bind(
      this,
    );
    this.handleNextQuestion = this.handleNextQuestion.bind(this);
  }

  componentWillMount() {
    document.title = 'Gestorando';
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.questionData !== nextProps.questionData) {
      return true;
    }
    if (this.props.questions !== nextProps.questions) {
      return true;
    }
    if (this.state.token !== nextState.token) {
      return true;
    }
    if (this.state.buttonNextDisabled !== nextState.buttonNextDisabled) {
      return true;
    }
    return false;
  }

  renderBackButton() {
    if (this.props.showBackButton) {
      return (
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
      );
    }

    return null;
  }

  // Handle next question click when the question is a input
  // After going to the next question, it sets the button to disabled
  handleNextQuestion() {
    this.props.onNextQuestion();

    this.setState({
      buttonNextDisabled: true,
    });
  }

  // Handle next question click on the last question
  handleNextQuestionRedirectToInscripcion(path) {
    redirectUrl(path);
  }

  renderNextButton() {
    let buttonNextDisabled;
    const question = this.props.questionData;
    const buttonText =
      'nextButtonText' in question ? question.nextButtonText : 'Siguiente';

    if (this.props.showNextButton) {
      if (question.posibleAnswers) {
        // Rendering the button for all except inputs
        if (
          question.type === 'multipleChoice' ||
          question.type === 'uniqueChoice'
        ) {
          buttonNextDisabled =
            question.posibleAnswers.filter(answer => answer.selected === true)
              .length === 0;
          return (
            <button
              className={`${
                buttonNextDisabled ? false : 'remove-disable'
              } btn-next`}
              type="button"
              onClick={this.props.onNextQuestion}
              disabled={buttonNextDisabled}
            >
              {buttonText}
              <i className="fas fa-chevron-right" />
            </button>
          );
        }
      } else {
        if (question.makeLink) {
          // Rendering the button for the last question that is the simulation
          return (
            <button
              onClick={() =>
                this.handleNextQuestionRedirectToInscripcion(
                  question.nextQuestion,
                )
              }
              className="btn-next"
            >
              {buttonText}
              <i className="fas fa-chevron-right" />
            </button>
          );
        }
        // Rendering the button for the inputs
        return (
          <button
            className={`${
              this.state.buttonNextDisabled ? false : 'remove-disable'
            } btn-next`}
            type="button"
            onClick={this.handleNextQuestion}
            disabled={this.state.buttonNextDisabled}
          >
            {buttonText}
            <i className="fas fa-chevron-right" />
          </button>
        );
      }
    }

    return null;
  }

  handleDisablingOfNextButton(bool) {
    let buttonNextDisabled = true;
    const question = this.props.questionData;

    // Checking if has posible answers
    if (question.posibleAnswers) {
      // Checking if selected something or the input is not empty
      // So in base of that we disable the next button
      if (
        question.type === 'multipleChoice' ||
        question.type === 'uniqueChoice'
      ) {
        buttonNextDisabled =
          question.posibleAnswers.filter(answer => answer.selected === true)
            .length === 0;
      }
    } else {
      buttonNextDisabled = bool;
    }

    this.setState({
      buttonNextDisabled,
    });
  }

  renderQuestion() {
    const question = this.props.questionData;
    const { questions } = this.props;
    // const Q3 = questions.filter(x => x.id === 3)[0];

    /* Espacio Fisico Component (id: 5) */
    if (question.id === 5) {
      return (
        <EspacioFisico
          question={question}
          title={question.title}
          description={question.description}
          onBackQuestion={this.props.onBackQuestion}
        />
      );
    }

    /* Estimas Facturar Component (id: 6) */
    if (question.id === 6) {
      return (
        <EstimasFacturar
          question={question}
          questions={this.props.questions}
          title={question.title}
          description={question.description}
          onBackQuestion={this.props.onBackQuestion}
        />
      );
    }

    /* Agregar Adherentes Component (id: 9) */
    if (question.id === 9) {
      return (
        <AgregarAdherentes
          title={question.title}
          description={question.description}
          onBackQuestion={this.props.onBackQuestion}
          onNextQuestion={this.props.onNextQuestion}
        />
      );
    }

    /* Declaro que no me inscribo en el Monotributo para realizar mas de tres actividades Component (id: 10) */
    if (question.id === 10) {
      return (
        <MasDeTresActividades
          question={question}
          title={question.title}
          onBackQuestion={this.props.onBackQuestion}
          onNextQuestion={this.props.onNextQuestion}
        />
      );
    }

    // if (Q3.posibleAnswers.find(x => x.id === 3).selected === true) {
    //   return (
    //     <div className="questions" key={question.id}>
    //       <Response
    //         question={question}
    //         questions={questions}
    //       />
    //       <div className="btns-next-back-wrapper">
    //         {this.renderBackButton()}
    //         {this.renderNextButton()}
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div className="questions" key={question.id}>
        <Response question={question} questions={questions} />
        <AnswerWrapper
          question={question}
          questions={questions}
          handleDisablingOfNextButton={this.handleDisablingOfNextButton}
        />
        <div className="btns-next-back-wrapper">
          {this.renderBackButton()}
          {this.renderNextButton()}
        </div>
      </div>
    );
  }

  render() {
    return this.renderQuestion();
  }
}

Question.propTypes = {
  questions: PropTypes.array,
  questionData: PropTypes.object,
  onNextQuestion: PropTypes.func,
  onBackQuestion: PropTypes.func,
  showNextButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    questions: state.simulateCategoryQuestionnaire,
  };
}

export default connect(mapStateToProps)(Question);
