import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAdherentesQuantitySimulateEQ } from '../../../../actions/cantidadAdherentesAnswer';

class CantidadAdherentes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidadAdherentes: '',
    };

    this.handleAdherentesQuantity = this.handleAdherentesQuantity.bind(this);
  }

  componentWillMount() {
    // If already has been answered then load the answer
    if (localStorage.getItem('Q9')) {
      this.setState({
        cantidadAdherentes: JSON.parse(localStorage.getItem('Q9')).selected,
      });
    }
  }

  handleAdherentesQuantity(e) {
    this.setState({
      cantidadAdherentes: e.target.value,
    });

    this.props.updateAdherentesQuantitySimulateEQ(
      this.props.questions,
      e.target.value,
    );
  }

  renderNextButton() {
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
        <button
          className={`${
            this.state.cantidadAdherentes.length !== 0
              ? 'remove-disable'
              : false
          } btn-next`}
          type="button"
          onClick={this.props.onNextQuestion}
          disabled={this.state.cantidadAdherentes.length === 0}
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <hr />
        <div className="select-wrapper">
          <div>
            <label htmlFor="select-members-quantity">
              <select
                className="form-control"
                name="select-members-quantity"
                id="select-members-quantity"
                value={this.state.cantidadAdherentes}
                onChange={e => this.handleAdherentesQuantity(e)}
              >
                <option value="" disabled>
                  Seleccioná una opción
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <i className="fas fa-check-circle" />
              <i className="fas fa-times-circle" />
            </label>
          </div>
        </div>
        {this.renderNextButton()}
      </React.Fragment>
    );
  }
}

CantidadAdherentes.propTypes = {
  questions: PropTypes.array,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
  updateAdherentesQuantitySimulateEQ: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    questions: state.simulateCategoryQuestionnaire,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAdherentesQuantitySimulateEQ,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CantidadAdherentes);
