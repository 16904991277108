import React from "react";
import PropTypes from "prop-types";
import { Layout, ConfigProvider, message } from "antd";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import moment from "moment";
import "moment/locale/es";
import esEs from "antd/lib/locale-provider/es_ES";
import reducers from "../../reducers/Dashboard";
import { logout } from "../../actions/authentication";
import Loader from "../../components/Loader/index";
import RequestProgressBar from "../../components/RequestProgressBar";
import NotificationMessage from "../../components/NotificationMessage";
import InternetConnectionMessage from "../../components/InternetConnectionMessage";
import Sidebar from "./Sidebar/index";
import MainContent from "./MainContent";
import Header from "./Header";
import { getUserData } from "../../apis";
import { nonPayableProducts } from "../../actions/listEnterprises";
import { enterprises } from "../../actions/listEnterprises";
const store = createStore(reducers, applyMiddleware(thunk));

moment.locale("es");

// Message global configuration
message.config({
  top: 50,
  duration: 0,
  maxCount: 1
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subMenuName: "",
      subMenuItemName: "",
      loadingLogout: false,
      loadingLogoutMessage: "Saliendo...",
      cuit: "",
      ivaCondition: false,
      fiscalPass: "",
      fiscalPassLevel: false,
      fiscalPassStatus: 3,
      cuitCajaProfesional: "",
      cuitEmpleador: "",
      isInscriptoMonotributo: null,
      inscriptoMonotributoMonth: {},
      isSubscribed: null,
      invoiceBeginMonth: 0,
      isRappi: false,
      situacion: {},
      jubilacion: {},
      rappiId: "",
      hasCuit: false,
      hasFicalPass: false,
      enableRoutesSidebar: false,
      esperandoInscripcion: null,
      userPaid: false,
      basePriceCreditCard: "0",
      basePriceOtherPayment: "0",
      monthsOfServiceToOffer: "1",
      subscriptionDown: null, // Significa que se dió de baja (Estuvo suscripto al menos una vez)
      sinObraSocial: {},
      cuitConyugeTrabajaRelacionDependencia: "",
      adherentes: [],
      cantidadAdherentes: 0,
      conMedicinaPrepaga: {},
      afipCategory: "",
      afipMonthlyAmount: 0,
      afipMonthlyImpuestosAmount: 0,
      afipMonthlyObraSocialAmount: 0,
      afipMonthlyJubilacionAmount: 0,
      adherentesAfip: 0,
      obraSocialAfip: {},
      isRelacionDependencia: null,
      profileIdentifier: "",
      enterprisesWorksWith: [],
      paymentInProcess: false,
      pendingPayments: false,
      uniquePayment: false,
      owesPayment: false,
      wasSubscribed: false,
      afipCondition: "",
      isInscripcionProduct: false,
      isUniquePayment: false,
      afipExpirationMonth: "",
      cbuNumber: "",
      cbuBank: "",
      cbuStatus: "",
      cbuSubscribed: "",
      totalInvoice: {},
      invoiceEndDate: "",
      invoiceOverPass: false,
      availbleToInvoice: 0,
      alreadyInvoicedBar: 0,
      runningScrapingScript: false,
      realCategoryName: "A",
      alreadySendEmailToPlan: false,
      nextRecategorizationDate: "",
      realTotalInvoicedBar: 0,
      thirdPartySubscription: true,
      subscriptionType: "CabifyFree"
    };

    this.getData = this.getData.bind(this);
    this.initLogout = this.initLogout.bind(this);
  }

  componentWillUnmount() {
    document.querySelector("body").style.backgroundColor = "inherit";
  }

  componentDidMount() {
    this.getData();

    setInterval(() => {
      this.getData();
    }, 60000);

    document.querySelector("body").style.backgroundColor = "#f2f2f2";
    // If is coming from the InscripcionEQ
    if (this.props.isSubscribed) {
      this.setState({
        isSubscribed: true
      });
    }
  }

  async getData() {
    try {
      const response = await getUserData();
      // Google Ads Conversion
      if (response.data.untracked_order_id) {
        window.gtag("event", "conversion", {
          send_to: "AW-879224319/ui02CP_Z8vQBEP_Ln6MD",
          value: 1.0,
          currency: "ARS",
          transaction_id: response.data.untracked_order_id
        });
      }
      // End Google Ads Conversion
      this.setState({
        afipExpirationMonth: response.data.expiration_date,
        afipExpirationMonthText: response.data.expiration_date_text,
        afipCategory: response.data.afipCategory,
        afipMonthlyAmount: response.data.afipMonthlyAmount,
        afipMonthlyImpuestosAmount: response.data.afipMonthlyImpuestosAmount,
        afipMonthlyObraSocialAmount: response.data.afipMonthlyObraSocialAmount,
        afipMonthlyJubilacionAmount: response.data.afipMonthlyJubilacionAmount,
        fiscalPassStatus: response.data.fiscalPassStatus,
        adherentes: response.data.adherentes,
        adherentesAfip: response.data.adherentesAfip,
        basePriceCreditCard: response.data.basePriceCreditCard,
        basePriceOtherPayment: response.data.basePriceOtherPayment,
        cuit: response.data.cuit,
        cuitCajaProfesional: response.data.cuitCajaProfesional,
        cuitEmpleador: response.data.cuitEmpleador,
        cuitConyugeTrabajaRelacionDependencia:
          response.data.cuitConyugeTrabajaRelacionDependencia,
        cantidadAdherentes: response.data.cantidadAdherentes,
        conMedicinaPrepaga: response.data.conMedicinaPrepaga,
        enterprisesWorksWith: response.data.enterprises,
        esperandoInscripcion: response.data.esperandoInscripcion,
        fiscalPass: response.data.fiscalPass,
        fiscalPassLevel: response.data.fiscal_pass_level,
        ivaCondition: response.data.cuit_inactivo,
        isInscriptoMonotributo: response.data.inscriptoMonotributo,
        inscriptoMonotributoMonth: response.data.inscriptoMonotributoMonth,
        invoiceBeginMonth: response.data.invoiceBeginMonth,
        isRappi: response.data.isRappi,
        isSubscribed: response.data.isSubscribed,
        isRelacionDependencia: response.data.relacionDependencia,
        jubilacion: response.data.jubilacion,
        monthsOfServiceToOffer:
          response.data.first_subscription_quantity_months,
        obraSocialAfip: response.data.obraSocialAfip,
        profileIdentifier: response.data.profileIdentifier,
        rappiId: response.data.rappiId,
        situacion: response.data.situacion,
        sinObraSocial: response.data.sinObraSocial,
        subscriptionDown: response.data.subscriptionDown,
        lastUpdateDebt: response.data.last_update_debt,

        userPaid: response.data.userPaid,
        paymentInProcess: response.data.payment_in_process,
        pendingPayments: response.data.pending_payment,
        uniquePayment: response.data.isInscripcionProduct,
        owesPayment: response.data.owes_payment,
        isInscripcionProduct: response.data.isInscripcionProduct,
        wasSubscribed: response.data.was_subscribed,
        afipCondition: response.data.iva_condition,
        isUniquePayment: response.data.is_unique_payment,

        // cbu for automatic debit
        cbuNumber: response.data.cbu_number,
        cbuBank: response.data.cbu_bank,
        cbuStatus: response.data.cbu_status,
        cbuSubscribed: response.data.cbu_subscribed,
        vepPaidOut: response.data.vep_paid_out,
        totalDebt: response.data.total_debt,
        totalInvoice: response.data.invoice_data.total_invoice,
        totalInvoiced: response.data.invoice_data.total_invoiced,
        lastMonthComparation: response.data.invoice_data.last_month_comparation,
        lastYearComparation: response.data.invoice_data.last_year_comparation,
        notEnoughData: response.data.invoice_data.not_enough_data,
        categories: response.data.invoice_data.categories,
        invoiceStartDate: response.data.invoice_data.from_to_date,
        invoiceEndDate: response.data.invoice_data.up_to_date,
        availbleToInvoice: response.data.invoice_data.available_to_invoice,
        invoiceOverPass: response.data.invoice_data.overpass,
        alreadyInvoicedBar: response.data.invoice_data.already_invoiced_bar,
        realCategoryName: response.data.invoice_data.real_category_name,
        anuallyMonthsBase: response.data.invoice_data.months,
        runningScrapingScript: response.data.running_script,
        alreadySendEmailToPlan: response.data.already_send_email_to_plan,
        categoryProjectionStatus:
          response.data.invoice_data.category_projection_status,
        nextRecategorizationDate:
          response.data.invoice_data.next_recategorization_date,
        realTotalInvoicedBar:
          response.data.invoice_data.real_total_invoiced_bar,
        contributionStatus: response.data.contribution_status,
        anuallyInvoiced: response.data.anually_invoiced,
        serviceType: response.data.invoice_data.service_type,
        beforeInscription: response.data.before_inscription,
        beforeInscriptionDate: response.data.before_inscription.before,
        afipCategoryLimit: response.data.invoice_data.afip_category_limit,
        afipCategoryID: response.data.invoice_data.afip_category_pk,
        subscriptionType: response.data.subscription_type,
        beatBasePricePlans: response.data.beat_base_price_other_payment,
        lastTicketUrl: response.data.last_ticket_url,
        tramite: response.data.tramite
      });
      if (
        this.state.isSubscribed &&
        response.data.cuit &&
        response.data.fiscal_pass_level
      ) {
        this.setState({ enableRoutesSidebar: true });
      }

      if (this.state.enterprisesWorksWith) {
        for (var i in this.state.enterprisesWorksWith) {
          if (
            enterprises.includes(
              this.state.enterprisesWorksWith[i].legal_name.toLowerCase()
            )
          ) {
            localStorage.setItem(
              "enterprise",
              this.state.enterprisesWorksWith[i].legal_name.toLowerCase()
            );
          }
        }
      } else {
        localStorage.removeItem("enterprise");
      }
      this.setState({
        thirdPartySubscription: nonPayableProducts.includes(
          response.data.subscription_type
        )
          ? true
          : false
      });

      document.title = `Gestorando | ${response.data.firstName} ${response.data.lastName}`;
    } catch (error) {
      if (error.response ? error.response.status === 401 : false) {
        if (error.response.data.detail === "Signature has expired.") {
          this.initLogout();
        }
      }
    }
  }

  initLogout() {
    this.setState(
      {
        loadingLogout: true
      },
      () => {
        logout();
      }
    );
  }

  render() {
    const div = (
      <div className="d-none" hidden="hidden">
        <NotificationMessage />
      </div>
    );
    let enterpriseFreeSubscription = this.state.subscriptionType === "CabifyFree" || this.state.subscriptionType === "PedidosYaFree";
    return (
      <Provider store={store}>
        <ConfigProvider locale={esEs}>
          <div className="h-100 position-relative">
            <InternetConnectionMessage />
            {div}
            {this.state.loadingLogout ? (
              <Loader loadingLogoutMessage={this.state.loadingLogoutMessage} />
            ) : null}
            <Layout>
              <Sidebar
                disablePagarImpuestos={
                  this.state.subscriptionType === "plan_facturacion"
                    ? true
                    : false
                }
                hidePagarImpuestos={false}
                hideEmitirFacturas={false}
                hideFormularios={false}
                hidePerfil={false}
                hideHelp={false}
                hideInicio={false}
                hideAccount={false}
                enableRoutesSidebar={this.state.enableRoutesSidebar}
                disableRoutesSidebarByBeat={this.state.thirdPartySubscription}
                initLogout={this.initLogout}
                subscriptionDown={this.subscriptionDown}
                isInscriptoMonotributo={this.state.isInscriptoMonotributo}
                hasEnterprise={
                  this.state.enterprisesWorksWith.length ? true : false
                }
              />
              <Header
              hidePagarImpuestos={false}
              hideEmitirFacturas={false}
              hideFormularios={false}
              hidePerfil={false}
              hideInicio={false}
              hideHelp={false}
              hideAccount={false}
                disablePagarImpuestos={
                  this.state.subscription_type === "plan_facturacion"
                    ? true
                    : false
                }
                initLogout={this.initLogout}
                subscriptionDown={this.state.subscriptionDown}
                isInscriptoMonotributo={this.state.isInscriptoMonotributo}
                enableRoutesSidebar={this.state.enableRoutesSidebar}
                disableRoutesSidebarByBeat={this.state.thirdPartySubscription}
                hasEnterprise={
                  this.state.enterprisesWorksWith.length ? true : false
                }
                userPaid={this.state.userPaid}
              />
              <RequestProgressBar />
              <MainContent
                basePriceOtherPayment={this.state.basePriceOtherPayment}
                toggleMenu={this.toggleMenu}
                cleanSubMenusRedirect={this.cleanSubMenusRedirect}
                afipCategory={this.state.afipCategory}
                afipMonthlyAmount={this.state.afipMonthlyAmount}
                afipExpirationMonth={this.state.afipExpirationMonth}
                afipExpirationMonthText={this.state.afipExpirationMonthText}
                afipMonthlyImpuestosAmount={
                  this.state.afipMonthlyImpuestosAmount
                }
                afipMonthlyObraSocialAmount={
                  this.state.afipMonthlyObraSocialAmount
                }
                afipMonthlyJubilacionAmount={
                  this.state.afipMonthlyJubilacionAmount
                }
                adherentesAfip={this.state.adherentesAfip}
                obraSocialAfip={this.state.obraSocialAfip}
                isRelacionDependencia={this.state.isRelacionDependencia}
                cuit={this.state.cuit}
                ivaCondition={this.state.ivaCondition}
                invoiceBeginMonth={this.state.invoiceBeginMonth}
                jubilacion={this.state.jubilacion}
                fiscalPassStatus={this.state.fiscalPassStatus}
                cuitCajaProfesional={this.state.cuitCajaProfesional}
                situacion={this.state.situacion}
                fiscalPass={this.state.fiscalPass}
                fiscalPassLevel={this.state.fiscalPassLevel}
                cuitEmpleador={this.state.cuitEmpleador}
                sinObraSocial={this.state.sinObraSocial}
                cuitConyugeTrabajaRelacionDependencia={
                  this.state.cuitConyugeTrabajaRelacionDependencia
                }
                adherentes={this.state.adherentes}
                cantidadAdherentes={this.state.cantidadAdherentes}
                conMedicinaPrepaga={this.state.conMedicinaPrepaga}
                getData={this.getData}
                basePriceCreditCard={this.state.basePriceCreditCard}
                monthsOfServiceToOffer={this.state.monthsOfServiceToOffer}
                profileIdentifier={this.state.profileIdentifier}
                isInscriptoMonotributo={this.state.isInscriptoMonotributo}
                inscriptoMonotributoMonth={this.state.inscriptoMonotributoMonth}
                isSubscribed={this.state.isSubscribed}
                isRappi={this.state.isRappi}
                rappiId={this.state.rappiId}
                esperandoInscripcion={this.state.esperandoInscripcion}
                userPaid={this.state.userPaid}
                subscriptionDown={this.state.subscriptionDown}
                enterprisesWorksWith={this.state.enterprisesWorksWith}
                paymentInProcess={this.state.paymentInProcess}
                pendingPayments={this.state.pendingPayments}
                uniquePayment={this.state.uniquePayment}
                isInscripcionProduct={this.state.isInscripcionProduct}
                isUniquePayment={this.state.isUniquePayment}
                owesPayment={this.state.owesPayment}
                wasSubscribed={this.state.wasSubscribed}
                afipCondition={this.state.afipCondition}
                lastUpdateDebt={this.state.lastUpdateDebt}
                cbuNumber={this.state.cbuNumber}
                cbuBank={this.state.cbuBank}
                cbuStatus={this.state.cbuStatus}
                cbuSubscribed={this.state.cbuSubscribed}
                vepPaidOut={this.state.vepPaidOut}
                afipMonthlyDebt={this.state.totalDebt}
                totalInvoice={this.state.totalInvoice}
                totalInvoiced={this.state.totalInvoiced}
                lastMonthComparation={this.state.lastMonthComparation}
                lastYearComparation={this.state.lastYearComparation}
                notEnoughData={this.state.notEnoughData}
                categories={this.state.categories}
                invoiceStartDate={this.state.invoiceStartDate}
                invoiceEndDate={this.state.invoiceEndDate}
                availbleToInvoice={this.state.availbleToInvoice}
                alreadyInvoicedBar={this.state.alreadyInvoicedBar}
                invoiceOverPass={this.state.invoiceOverPass}
                runningScrapingScript={this.state.runningScrapingScript}
                realCategoryName={this.state.realCategoryName}
                alreadySendEmailToPlan={this.state.alreadySendEmailToPlan}
                categoryProjectionStatus={this.state.categoryProjectionStatus}
                nextRecategorizationDate={this.state.nextRecategorizationDate}
                realTotalInvoicedBar={this.state.realTotalInvoicedBar}
                contributionStatus={this.state.contributionStatus}
                anuallyInvoiced={this.state.anuallyInvoiced}
                anuallyMonthsBase={this.state.anuallyMonthsBase}
                serviceType={this.state.serviceType}
                beforeInscription={this.state.beforeInscription}
                beforeInscriptionDate={this.state.beforeInscriptionDate}
                afipCategoryLimit={this.state.afipCategoryLimit}
                afipCategoryID={this.state.afipCategoryID}
                subscriptionType={this.state.subscriptionType}
                beatBasePricePlans={this.state.beatBasePricePlans}
                thirdPartySubscription={this.state.thirdPartySubscription}
                lastTicketUrl={this.state.lastTicketUrl}
                tramite={this.state.tramite}
              />
            </Layout>
          </div>
        </ConfigProvider>
      </Provider>
    );
  }
}

Dashboard.propTypes = {
  inscripcionProduct: PropTypes.bool
};

export default Dashboard;
