import {
  TOGGLE_DASHBOARD_LOADING,
  TOGGLE_SHOW_REQUEST_PROGRESS_BAR,
  HANDLE_NOTIFICATION_MESSAGE,
  WEB_APP_ONLINE,
} from '../../../actions/Dashboard/generalStatus/types';

const generalStatusTree = {
  loading: false,
  showRequestProgressBar: false,
  notificationMessage: {
    success: {
      message: '',
      title: '',
      show: false,
    },
    error: {
      message: '',
      title: '',
      show: false,
    },
    warning: {
      message: '',
      title: '',
      show: false,
    },
    info: {
      message: '',
      title: '',
      show: false,
    },
  },
  inscriptoMonotributo: false,
  suscribed: false,
  inscriptoProduct: false,
  paid: false,
  navigatorOnline: null,
};

export const generalStatus = (prevState = generalStatusTree, action) => {
  switch (action.type) {
    case TOGGLE_DASHBOARD_LOADING:
      return {
        ...prevState,
        loading: action.loading,
      };
    case TOGGLE_SHOW_REQUEST_PROGRESS_BAR:
      return {
        ...prevState,
        showRequestProgressBar: action.show,
      };
    case HANDLE_NOTIFICATION_MESSAGE:
      return {
        ...prevState,
        notificationMessage: {
          ...prevState.notificationMessage,
          [action.notificationType]: {
            message: action.notificationMessage,
            title: action.notificationTitle,
            show: action.show,
          },
        },
      };
    case WEB_APP_ONLINE:
      return {
        ...prevState,
        navigatorOnline: action.payload,
      };
    default:
      return prevState;
  }
};
