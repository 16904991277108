import React from 'react';
import PropTypes from 'prop-types';
import LogoGestorando from '../../assets/img/logo-gestorando.svg';
import UserInfo from './UserInfo';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
    };
  }

  // Checking if is loggedIn
  isLoggedIn() {
    const { loggedIn } = this.props;
    const username = localStorage.getItem('username');
    const firstname = localStorage.getItem('first_name');

    if (loggedIn) {
      this.setState({
        username,
        firstname,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loggedIn !== prevProps.loggedIn) {
      this.isLoggedIn();
    }
  }

  componentWillMount() {
    this.isLoggedIn();
  }

  renderNav() {
    return (
      <div className="header">
        <div className="container">
          <nav className="navbar">
            <a className="navbar-brand" href={API_BASE_URL}>
              <img
                className="img-fluid"
                src={LogoGestorando}
                alt="Gestorando logo"
                title="Gestorando"
              />
            </a>
            <UserInfo
              loggedIn={this.props.loggedIn}
              username={this.state.username}
              firstname={this.state.firstname}
              initLogout={this.props.initLogout}
            />
          </nav>
        </div>
      </div>
    );
  }

  render() {
    return this.renderNav();
  }
}

Nav.propTypes = {
  loggedIn: PropTypes.bool,
  initLogout: PropTypes.func,
};

export default Nav;
