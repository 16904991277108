import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

export default function LoadingEmptyComponent(props) {
  return (
    <Skeleton
      className="container loading-empty-component"
      loading={props.loading}
      active
    >
      {props.children}
    </Skeleton>
  );
}

LoadingEmptyComponent.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};
