import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import Nav from "../components/Nav/index";
import PlansEQ from "../components/EQ/PlansEQ/index";
import ProgressBar from "../components/EQ/ProgressBar/index";
import Signup from "../components/Signup/index";
import Loader from "../components/Loader/index";
import ModalIfUserWantsToResetEQ from "../components/EQ/Partials/ModalIfUserWantsToResetEQ";
import { createHistoryBrowser } from "../actions/createHistoryBrowser";
import { logout } from "../actions/authentication";
import {
  nextQuestionPlansEQ,
  selectSpecificQuestion,
} from "../actions/nextQuestion";
import { beforeQuestionPlansEQ } from "../actions/beforeQuestion";
import { redirectUrl } from "../actions/redirectUrl";
import { clearLocalStorage } from "../actions/clearLocalStorage";

class PlansQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: !!localStorage.getItem("token"),
      loggedIn: !!localStorage.getItem("token"),
      isLoading: false,
      showModalIfUserWantsToResetEQ: false,
      loadingMessage: "",
    };

    this.initLogout = this.initLogout.bind(this);
    this.handleCancelModalIfUserWantsToResetEQ = this.handleCancelModalIfUserWantsToResetEQ.bind(
      this
    );
  }

  componentWillMount() {
    // If is coming from another path then clear the localStorage
    let { identifier } = this.props.match.params;
    if (!identifier) {
      identifier = localStorage.getItem("plans_identifier");
    }
    if (
      !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        identifier
      )
    ) {
      // no questionnair identifier provided
      window.location.assign("/");
    }
    if (localStorage.getItem("path") !== "plans") {
      clearLocalStorage(true);
    }

    // Setting on the localStorage the current path that is doing
    localStorage.setItem("path", "plans");
    localStorage.setItem("plans_identifier", identifier);

    this.setState({
      showModalIfUserWantsToResetEQ: this.props.activeQuestion > 1,
    });
  }

  componentDidUpdate() {
    if (this.state.loggedIn !== !!localStorage.getItem("token")) {
      this.setState({
        loggedIn: !!localStorage.getItem("token"),
      });
    }
  }

  componentDidMount() {
    const history = createBrowserHistory();
    const { questions } = this.props;
    const actualQuestion = questions.filter(
      (questionData) => questionData.id === this.props.activeQuestion
    )[0];

    if (actualQuestion.id === 11 || !!localStorage.getItem("loggedIn")) {
      localStorage.clear();
      this.setState(
        {
          isLoading: true,
        },
        () => {
          redirectUrl("/panel/");
        }
      );
    } else {
      createHistoryBrowser(`/plans${actualQuestion.path}`);
    }

    history.listen((location, action) => {
      if (action === "POP") {
        let locationPathnameId;

        if (location.pathname.indexOf("registro") !== -1) {
          locationPathnameId = 2;
        } else {
          locationPathnameId = parseInt(
            location.pathname.replace(/[^0-9 -]+/g, "")
          );
        }

        // If the question to redirect is not the actual question (unnecessary redirect)
        // And if is not leaving the page
        if (
          locationPathnameId !== actualQuestion.id &&
          !isNaN(locationPathnameId)
        ) {
          this.props.selectSpecificQuestion(locationPathnameId);
        }
      }
    });
  }

  handleCancelModalIfUserWantsToResetEQ() {
    this.setState({ showModalIfUserWantsToResetEQ: false });
  }

  showNextButton() {
    return true;
  }

  showBackButton(question) {
    let bool = false;
    if ("beforeQuestion" in question) {
      bool = true;
    }

    return bool;
  }

  calculateProgress(question) {
    const EQLength = this.props.questions.length;
    const { activeQuestion } = this.props;
    let percentage;

    if (question.id !== 1) {
      percentage = Math.round((activeQuestion * 100) / EQLength);
    } else {
      percentage = 0;
    }

    return percentage;
  }

  initLogout() {
    this.setState(
      {
        isLoading: true,
        loadingMessage: "Saliendo...",
      },
      this.finishingLogout()
    );
  }

  finishingLogout() {
    this.props.logout();
  }

  renderLoader() {
    if (this.state.isLoading)
      return <Loader loadingMessage={this.state.loadingMessage} />;
  }

  renderQuestion() {
    const activeQuestionData = this.props.questions.filter(
      (questionData) => questionData.id === this.props.activeQuestion
    )[0];
    /* Signup Component (id: 2) */
    // check redux TypeError can't read property 'id' of undifined
    if (activeQuestionData.id === 2) {
      if (this.state.loggedIn) {
        this.props.nextQuestionPlansEQ(activeQuestionData);
      } else {
        return (
          <React.Fragment>
            {this.renderLoader()}
            <ModalIfUserWantsToResetEQ
              visible={this.state.showModalIfUserWantsToResetEQ}
              handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
            />
            <Nav loggedIn={this.state.loggedIn} initLogout={this.initLogout} />
            <div className="content">
              <ProgressBar
                progress={this.calculateProgress(activeQuestionData)}
              />
              <Signup
                api="plans"
                onNextQuestion={() =>
                  this.props.nextQuestionPlansEQ(activeQuestionData)
                }
                title={activeQuestionData.title}
                description={activeQuestionData.description}
              />
            </div>
          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        {this.renderLoader()}
        <ModalIfUserWantsToResetEQ
          visible={this.state.showModalIfUserWantsToResetEQ}
          handleCancel={this.handleCancelModalIfUserWantsToResetEQ}
        />
        <Nav loggedIn={this.state.loggedIn} initLogout={this.initLogout} />
        <div className="content">
          <ProgressBar progress={this.calculateProgress(activeQuestionData)} />
          <PlansEQ
            questionData={activeQuestionData}
            onNextQuestion={() =>
              this.props.nextQuestionPlansEQ(activeQuestionData)
            }
            onBackQuestion={() =>
              this.props.beforeQuestionPlansEQ(activeQuestionData)
            }
            showNextButton={this.showNextButton()}
            showBackButton={this.showBackButton(activeQuestionData)}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.renderQuestion();
  }
}

PlansQuestionnaire.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  activeQuestion: PropTypes.number,
  nextQuestionPlansEQ: PropTypes.func,
  beforeQuestionPlansEQ: PropTypes.func,
  selectSpecificQuestion: PropTypes.func,
  match: PropTypes.object,
  logout: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    questions: state.plansQuestionnaire,
    activeQuestion: state.plansActiveQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionPlansEQ,
      beforeQuestionPlansEQ,
      selectSpecificQuestion,
      logout,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansQuestionnaire);
