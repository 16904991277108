import { SELECT_ANSWER } from '../../actions/index';

const storage = localStorage;
let Q1posibleAnswers, Q3posibleAnswers, Q5posibleAnswers, Q6posibleAnswers;

// Checking if some questions had been answered
// And create new object based on what they answered
// If is only coming from inscripcion path then save the result
if (storage.getItem('path') === 'inscripcion') {
  if (storage.getItem('Q1')) {
    Q1posibleAnswers = [
      {
        id: 1,
        text: 'Si',
        selected:
          JSON.parse(storage.getItem('Q1')) !== null
            ? JSON.parse(storage.getItem('Q1')).id === 1
              ? JSON.parse(storage.getItem('Q1')).selected
              : false
            : false,
        nextQuestion: 2,
      },
      {
        id: 2,
        text: 'No',
        selected:
          JSON.parse(storage.getItem('Q1')) !== null
            ? JSON.parse(storage.getItem('Q1')).id === 2
              ? JSON.parse(storage.getItem('Q1')).selected
              : false
            : false,
        nextQuestion: 3,
      },
    ];
  }

  if (storage.getItem('Q3')) {
    Q3posibleAnswers = [
      {
        id: 1,
        text: 'Si',
        selected:
          JSON.parse(storage.getItem('Q3')) !== null
            ? JSON.parse(storage.getItem('Q3')).id === 1
              ? JSON.parse(storage.getItem('Q3')).selected
              : false
            : false,
        nextQuestion: 4,
      },
      {
        id: 2,
        text: 'No',
        selected:
          JSON.parse(storage.getItem('Q3')) !== null
            ? JSON.parse(storage.getItem('Q3')).id === 2
              ? JSON.parse(storage.getItem('Q3')).selected
              : false
            : false,
        nextQuestion: 5,
      },
    ];
  }

  if (storage.getItem('Q5')) {
    Q5posibleAnswers = [
      {
        id: 1,
        text: 'DNI argentino',
        selected:
          JSON.parse(storage.getItem('Q5')) !== null
            ? JSON.parse(storage.getItem('Q5')).id === 1
              ? JSON.parse(storage.getItem('Q5')).selected
              : false
            : false,
        nextQuestion: 6,
      },
      {
        id: 2,
        text: 'Precaria',
        selected:
          JSON.parse(storage.getItem('Q5')) !== null
            ? JSON.parse(storage.getItem('Q5')).id === 2
              ? JSON.parse(storage.getItem('Q5')).selected
              : false
            : false,
        nextQuestion: 7,
      },
    ];
  }

  if (storage.getItem('Q6')) {
    Q6posibleAnswers = [
      {
        id: 1,
        text: 'Si',
        selected:
          JSON.parse(storage.getItem('Q6')) !== null
            ? JSON.parse(storage.getItem('Q6')).id === 1
              ? JSON.parse(storage.getItem('Q6')).selected
              : false
            : false,
        nextQuestion: 7,
      },
      {
        id: 2,
        text: 'No',
        selected:
          JSON.parse(storage.getItem('Q6')) !== null
            ? JSON.parse(storage.getItem('Q6')).id === 2
              ? JSON.parse(storage.getItem('Q6')).selected
              : false
            : false,
        nextQuestion: 7,
      },
    ];
  }
}

// All Questions object
const questions = [
  {
    id: 1,
    title: '¿Vas a colaborar con una aplicación móvil?',
    path: '/EQ/1/',
    description: 'Como por ejemplo: Glovo, Rappi, Cabify, Uber, etc.',
    type: 'uniqueChoice',
    posibleAnswers:
      Q1posibleAnswers !== undefined
        ? Q1posibleAnswers
        : [
            {
            id: 1,
            text: 'Si',
            selected: false,
            nextQuestion: 2,
          },
            {
            id: 2,
            text: 'No',
            selected: false,
            nextQuestion: 3,
            },
        ],
  },
  {
    id: 2,
    title: 'Seleccioná la aplicación con la cual vas a colaborar',
    path: '/EQ/2/',
    type: 'uniqueChoice',
    nextQuestion: 3,
    beforeQuestion: 1,
  },
  {
    id: 3,
    title: '¿Tenés CUIT?',
    path: '/EQ/3/',
    moreInfoIcon: true,
    type: 'uniqueChoice',
    beforeQuestion: 1,
    posibleAnswers:
      Q3posibleAnswers !== undefined
        ? Q3posibleAnswers
        : [
            {
              id: 1,
              text: 'Si',
            selected: false,
            nextQuestion: 4,
          },
          {
              id: 2,
            text: 'No',
            selected: false,
            nextQuestion: 5,
          },
        ],
  },
  {
    id: 4,
    title: 'Ingresá tu CUIT',
    path: '/EQ/4/',
    description: 'Recordá que es el mismo número que la CUIL',
    nextQuestion: 7,
    beforeQuestion: 3,
  },
  {
    id: 5,
    title: 'Seleccioná el tipo de documento que tenés',
    path: '/EQ/5/',
    type: 'uniqueChoice',
    beforeQuestion: 3,
    posibleAnswers:
      Q5posibleAnswers !== undefined
        ? Q5posibleAnswers
        : [
            {
              id: 1,
            text: 'DNI argentino',
            selected: false,
              nextQuestion: 6,
          },
            {
            id: 2,
            text: 'Precaria',
            selected: false,
            nextQuestion: 7,
          },
        ],
  },
  {
    id: 6,
    title: '¿Tu DNI está vigente y tiene fecha de vencimiento?',
    path: '/EQ/6/',
    type: 'uniqueChoice',
    beforeQuestion: 5,
    posibleAnswers:
      Q6posibleAnswers !== undefined
        ? Q6posibleAnswers
        : [
          {
            id: 1,
              text: 'Si',
            selected: false,
            nextQuestion: 7,
          },
          {
              id: 2,
            text: 'No',
              selected: false,
              nextQuestion: 7,
            },
        ],
  },
  {
    id: 7,
    title: 'Creá tu cuenta de Gestorando',
    path: '/registro/',
    description:
      'Crear una cuenta en Gestorando te va a permitir volver a nuestro sitio y tener toda tu información en un lugar',
    nextQuestion: 8,
  },
  {
    id: 8,
    title: 'Para inscribirte en el Monotributo vamos a necesitar:',
    path: '/EQ/8/',
    nextQuestion: 9,
  },
  {
    id: 9,
    path: '/metodo-de-pago/',
    nextQuestion: 10,
  },
  {
    id: 10,
    title: 'Muchas gracias, \n¡recibimos tu pago!',
    icon: 'success',
    path: '/EQ/10/',
    description:
      'Para inscribirte en el Monotributo vamos a necesitar: \n\n1. CUIT\n 2. Clave fiscal (nivel 3)\n 3. Elección de obra social.\n\n Respondé las preguntas a continuación para averiguar los próximos pasos, luego un asesor te va a estar llamando por teléfono para responder tus dudas.',
    nextQuestion: 11,
  },
  {
    id: 11,
  },
];

export default function(state = questions, action) {
  switch (action.type) {
    case SELECT_ANSWER:
      return action.payload;
    default:
      return state;
  }
}
