import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loader from '../../Loader/index';
import Nav from '../../Nav/index';
import { redirectUrl } from '../../../actions/redirectUrl';
import { restorePassword, restorePasswordIdentifier } from '../../../apis';

class RestorePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password1Valid: false,
      password1Error: '',
      password2: '',
      password2Valid: false,
      password2Error: '',
      passwordRestored: false,
      isAllValid: false,
      buttonValid: false,
      isLoading: false,
      loadingMessage: '',
      showPassword: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRestorePassword = this.handleRestorePassword.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  async componentWillMount() {
    document.title = 'Gestorando | Restablecé tu contraseña';

    if (this.props.match.params.identifier) {
      this.setState({
        isLoading: true,
        loadingMessage: 'Cargando...',
      });

      try {
        const { identifier } = this.props.match.params;
        const response = await restorePasswordIdentifier(identifier);

        if (response.data.success) {
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });

          alert('La URL ha expirado, vuelve a intentarlo');
          redirectUrl('/ingresar/');
        }
      } catch (error) {
        alert('Surgio un error, vuelve a intentarlo');
        redirectUrl('/ingresar/');
      }
    } else {
      redirectUrl('/ingresar/');
    }
  }

  handleChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleEnterKey(e) {
    if (this.state.buttonValid) {
      if (e.key === 'Enter') {
        this.handleRestorePassword();
      }
    }
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  validateField(fieldName, value) {
    const { password1, password2 } = this.state;
    let {
      password1Valid,
      password1Error,
      password2Valid,
      password2Error,
      isAllValid,
      buttonValid,
    } = this.state;

    if (value.length < 6) {
      password1Error = 'Ingresá minimo 6 caracteres';
      password2Error = 'Ingresá minimo 6 caracteres';
      password1Valid = false;
      password2Valid = false;
    } else if (password1 !== password2) {
      password1Error = 'Ingresá contraseñas iguales por favor';
      password2Error = 'Ingresá contraseñas iguales por favor';
      password1Valid = false;
      password2Valid = false;
    } else {
      password1Error = '';
      password2Error = '';
      password1Valid = true;
      password2Valid = true;
      isAllValid = true;
      buttonValid = true;
    }

    this.setState({
      password1Error,
      password2Error,
      password1Valid,
      password2Valid,
      isAllValid,
      buttonValid,
    });
  }

  async handleRestorePassword() {
    this.setState({
      isLoading: true,
      loadingMessage: 'Generando tu nueva contraseña 🙂',
    });

    try {
      const form = {
        password: this.state.password2,
        identifier: this.props.match.params.identifier,
      };
      const response = await restorePassword(form);

      if (response.data.success) {
        this.setState({
          isLoading: false,
          passwordRestored: true,
        });
      } else {
        this.setState({
          isLoading: false,
        });

        alert('Lo URL ha expirado, vuelve a intentarlo');
        redirectUrl('/ingresar/');
      }
    } catch (error) {
      alert('Surgio un error, vuelve a intentarlo');
      redirectUrl('/ingresar/');
    }
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : (
      false
    );
  }

  renderRestorePassword() {
    return (
      <div>
        <Nav />
        <div className="restore-password box-styles">
          {this.renderLoader()}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">
                Restablec&eacute; tu contrase&ntilde;a
              </h2>
              <hr />
              <div>
                <div className="input-wrapper">
                  <div
                    className={`${
                      this.state.password1Valid
                        ? 'valid'
                        : [this.state.password1Error ? 'invalid' : '']
                    }`}
                  >
                    <p>Nueva contrase&ntilde;a</p>
                    <label
                      className={
                        this.state.showPassword
                          ? 'show-password'
                          : 'hide-password'
                      }
                      htmlFor="password1"
                    >
                      <input
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password1"
                        name="password1"
                        placeholder="Nueva contraseña"
                        value={this.state.password1}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">
                        {this.state.password1Error}
                      </p>
                      <i className="fas fa-user" />
                      <button
                        className="btn-show-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye" />
                      </button>
                      <button
                        className="btn-hide-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye-slash" />
                      </button>
                    </label>
                  </div>
                  <div
                    className={`${
                      this.state.password2Valid
                        ? 'valid'
                        : [this.state.password2Error ? 'invalid' : '']
                    }`}
                  >
                    <p>Repetir nueva contrase&ntilde;a</p>
                    <label
                      className={
                        this.state.showPassword
                          ? 'show-password'
                          : 'hide-password'
                      }
                      htmlFor="password2"
                    >
                      <input
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password2"
                        name="password2"
                        placeholder="Repetir nueva contraseña"
                        value={this.state.password2}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">
                        {this.state.password2Error}
                      </p>
                      <i className="fas fa-user" />
                      <button
                        className="btn-show-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye" />
                      </button>
                      <button
                        className="btn-hide-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye-slash" />
                      </button>
                    </label>
                  </div>
                </div>
              </div>
              <div className="btns-next-back-wrapper">
                <button
                  className={`
                  ${this.state.isAllValid ? 'remove-disable' : false} btn-next`}
                  type="submit"
                  onClick={this.handleRestorePassword}
                  disabled={this.state.buttonValid ? null : true}
                >
                  Restablecer
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAfterRestoredPassword() {
    return (
      <div>
        <Nav />
        <div className="restore-password box-styles">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">
                Restablec&eacute; tu contrase&ntilde;a
              </h2>
              <hr />
              <div className="alert alert-success" role="alert">
                ¡Tu contraseña ha sido actualizada correctamente!
              </div>
              <div className="btns-next-back-wrapper">
                <Link className="btn-next" to="/ingresar">
                  Ingresar a mi cuenta
                  <i className="fas fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.passwordRestored) {
      return this.renderAfterRestoredPassword();
    }
    return this.renderRestorePassword();
  }
}

RestorePassword.propTypes = {
  match: PropTypes.object,
};

export default RestorePassword;
