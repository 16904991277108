import { notification } from 'antd';

export function notificationAlert(
  type,
  message,
  description,
  placement,
  duration,
) {
  function getPlacement() {
    let p = '';

    if (placement !== undefined) {
      p = window.innerWidth > 767 ? placement : 'topRight';
    } else {
      p = window.innerWidth > 767 ? 'bottomRight' : 'topRight';
    }

    return p;
  }

  function getDuration() {
    let d;

    // If duration is not passed and the type is not an error
    // Because on error we don't set duration
    if (duration === undefined && type !== 'error') {
      d = 12;
    } else if (type === 'error') {
      d = 30;
    } else {
      d = duration;
    }

    return d;
  }

  notification[type]({
    message,
    description,
    placement: getPlacement(),
    duration: getDuration(),
  });
}
