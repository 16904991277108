import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../Nav/index';
import { createHistoryBrowser } from '../../actions/createHistoryBrowser';
import notFoundIllustration from '../../assets/img/not-found-illustration.png';

class NoMatch extends React.Component {
  componentWillMount() {
    createHistoryBrowser('/404/', '404 Pagina no encontrada ☹️');
  }

  render() {
    return (
      <div className="no-match-page">
        <Nav />
        <div className="container">
          <div>
            <div className="row">
              <div className="col-12 col-md-5">
                <h1>¡Ups!</h1>
                <h2>
                  Parece que no podemos encontrar la página que estás buscando.
                </h2>
                <h6>Código de error: 404</h6>
                <p>Aquí hay algunos enlaces útiles en su lugar:</p>
                <ul>
                  <li>
                    <Link to="/ingresar/">Ingres&aacute; a tu cuenta</Link>
                  </li>
                  <li>
                    <Link to="/inscripcion/">
                      Comenzar la inscripci&oacute;n
                    </Link>
                  </li>
                  <li>
                    <Link to="/simular-categoria/">
                      Simulá tu categoría y cuota
                    </Link>
                  </li>
                  <li>
                    <Link to="/olvide-contrasena/">
                      Restablec&eacute; tu contrase&ntilde;a
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://gestorando.com/mapa-del-sitio/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mapa del sitio
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gestorando.com/politica-de-privacidad/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de privacidad
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gestorando.com/terminos-de-uso/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos de uso
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-7">
                <img
                  className="img-fluid"
                  src={notFoundIllustration}
                  title="Pagina no encontrada"
                  alt="Imagen de Pagina no encontrada"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoMatch;
