import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../Loader/index";
import Nav from "../../Nav/index";
import { login } from "../../../actions/authentication";
import { login as loginApi } from "../../../apis";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailValid: false,
      emailError: "",
      password: "",
      passwordValid: false,
      passwordError: "",
      isAllValid: false,
      buttonValid: false,
      isLoading: false,
      loadingMessage: "Verificando tus datos...",
      failedSessionTimes: 0,
      showPassword: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  componentWillMount() {
    document.title = "Gestorando | Ingresar";

    // Setting the email that alreadyExists on the CUIT verificacion based on the localStorage
    // Then we remove it
    if (localStorage.getItem("emailAlreadyExists") !== null) {
      this.setState({
        email: localStorage.getItem("emailAlreadyExists"),
        emailValid: localStorage
          .getItem("emailAlreadyExists")
          .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
      });

      localStorage.removeItem("emailAlreadyExists");
    }
  }

  handleChange(e) {
    const { name } = e.target;
    const value = e.target.value.replace(/\s/g, "");

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleEnterKey(e) {
    if (this.state.buttonValid) {
      if (e.key === "Enter") {
        this.handleLogin();
      }
    }
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  validateField(fieldName, value) {
    let { emailValid, passwordValid } = this.state;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        passwordValid = this.state.password.length >= 6;
        break;
      case "password":
        emailValid = this.state.email.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        passwordValid = value.length >= 6;
        break;
      default:
        break;
    }

    this.setState({ emailValid, passwordValid });

    if (emailValid && passwordValid) {
      this.setState({
        isAllValid: true,
        buttonValid: true,
        emailError: "",
        passwordError: "",
      });
    } else {
      this.setState({
        isAllValid: false,
        buttonValid: false,
        emailError: "",
        passwordError: "",
      });
    }
  }

  async handleLogin() {
    this.setState({
      isLoading: true,
    });

    try {
      const form = {
        username: this.state.email,
        password: this.state.password,
      };
      const response = await loginApi(form);

      localStorage.setItem("token", response.data.token);
      this.props.login();
    } catch (error) {
      if (error.response.data.non_field_errors[0]) {
        this.setState({
          isLoading: false,
          emailValid: false,
          passwordValid: false,
          emailError: error.response.data.non_field_errors,
          passwordError: error.response.data.non_field_errors,
          failedSessionTimes: this.state.failedSessionTimes + 1,
        });
      }

      if (error.response.data.username) {
        this.setState({
          isLoading: false,
          emailValid: false,
          emailError: error.response.data.username,
          failedSessionTimes: this.state.failedSessionTimes + 1,
        });
      }

      if (error.response.data.password) {
        this.setState({
          isLoading: false,
          passwordValid: false,
          passwordError: error.response.data.password,
          failedSessionTimes: this.state.failedSessionTimes + 1,
        });
      }
    }
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : (
      false
    );
  }

  renderLogin() {
    return (
      <div>
        <Nav />
        <div className="login box-styles">
          {this.renderLoader()}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Ingres&aacute; a tu cuenta</h2>
              <hr />
              <div>
                <div className="input-wrapper">
                  <div
                    className={`${
                      this.state.emailValid
                        ? "valid"
                        : [this.state.emailError ? "invalid" : ""]
                    }`}
                  >
                    <p>Email</p>
                    <label htmlFor="email">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">{this.state.emailError}</p>
                      <i className="fas fa-user" />
                      <i className="fas fa-check-circle" />
                      <i className="fas fa-times-circle" />
                    </label>
                  </div>
                  <div
                    className={`${
                      this.state.passwordValid
                        ? "valid"
                        : [this.state.passwordError ? "invalid" : ""]
                    }`}
                  >
                    <p>Contrase&ntilde;a</p>
                    <label
                      className={
                        this.state.showPassword
                          ? "show-password"
                          : "hide-password"
                      }
                      htmlFor="password"
                    >
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Contraseña"
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">
                        {this.state.passwordError}
                      </p>
                      <i className="fas fa-user" />
                      <button
                        className="btn-show-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye" />
                      </button>
                      <button
                        className="btn-hide-password"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <i className="fas fa-eye-slash" />
                      </button>
                    </label>
                  </div>
                </div>
              </div>
              <div className="btns-next-back-wrapper">
                <button
                  className={`
                  ${this.state.isAllValid ? "remove-disable" : false} btn-next`}
                  type="button"
                  onClick={this.handleLogin}
                  disabled={this.state.buttonValid ? null : true}
                >
                  Ingresar
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
              <hr />
              <Link className="link" to="/olvide-contrasena">
                Si olvidaste tu contrase&ntilde;a o necesit&aacute;s generar una, hac&eacute; clic ac&aacute;
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderLogin();
  }
}

Login.propTypes = {
  login: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    activeQuestion: state.activeQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
