import React from "react";
import PropTypes from "prop-types";
import LogoMercadopago from "../../../../assets/img/logo-mercado-pago.png";
import PaymentMethodWrapper from "./PaymentMethodWrapper";
import ServiceDescription from "./ServiceDescription";
import Ticket from "./Ticket";
import BeatPlans from "../Plans";
import { redirectUrl } from "../../../../actions/redirectUrl";
import { createHistoryBrowser } from "../../../../actions/createHistoryBrowser";
import { getUserData, SubscribeBEATDriver } from "../../../../apis";
import { Spin } from "antd";
import {
  enterprises,
  nonPayableProducts,
  products,
} from "../../../../actions/listEnterprises";

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basePriceCreditCard: "",
      basePriceOtherPayment: "",
      installments: "",
      total_amount: "",
      total_cuotas_amount: "",
      ptf: "",
      tea: "",
      cft: "",
      isTicket: false,
      ticketCode: [],
      ticketUrl: "",
      method: "",
      paymentMethod: "btn-credit-card",
      monthsOfServiceToOffer: 0,
      renderPlans: false,
      spinningBeat: false,
      inscriptoMonotributo: false,
      loadingInscriptoMonotributo: true,
      enterprise: "",
      payingInscription: false,
      serviceToPay: { type: "", code: "" },
    };

    this.handleTicket = this.handleTicket.bind(this);
    this.handleBasePrice = this.handleBasePrice.bind(this);
    this.toggleTicketToFalse = this.toggleTicketToFalse.bind(this);
    this.handlePlanSelected = this.handlePlanSelected.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.goBackArrow = this.goBackArrow.bind(this);
  }

  componentWillMount() {
    document.title = "Gestorando | Metodo de pago";
  }

  componentDidMount() {
    this.setState({ serviceToPay: this.props.serviceToPay });
    const enterprise = localStorage.getItem("enterprise");
    if (enterprises.includes(enterprise)) {
      this.setState({
        renderPlans: true,
        enterprise: enterprise.toLowerCase(),
      });
    }
    this.getData();
    setInterval(() => {
      this.getData();
    }, 60000);
  }

  async getData() {
    if (!this.state.payingInscription) {
      try {
        const response = await getUserData();
        this.setState({
          basePriceOtherPayment: response.data.basePriceOtherPayment,
          basePriceCreditCard: response.data.basePriceCreditCard,
          monthsOfServiceToOffer:
            response.data.first_subscription_quantity_months,
          inscriptoMonotributo: response.data.inscriptoMonotributo,
          loadingInscriptoMonotributo: false,
        });

        document.title = `Gestorando | ${response.data.firstName} ${response.data.lastName}`;
        localStorage.setItem("prices", this.state.basePriceCreditCard);
        localStorage.setItem("prices_other", this.state.basePriceOtherPayment);
      } catch (error) {}
    }
  }

  handleBasePrice(method) {
    if (method === "btn-credit-card") {
      this.setState({
        basePriceCreditCard: this.state.basePriceCreditCard,
        installments: "",
        total_amount: "",
        total_cuotas_amount: "",
        ptf: "",
        tea: "",
        cft: "",
        paymentMethod: method,
      });
    } else {
      this.setState({
        basePriceOtherPayment: this.state.basePriceOtherPayment,
        installments: "",
        total_amount: "",
        total_cuotas_amount: "",
        ptf: "",
        tea: "",
        cft: "",
        paymentMethod: method,
      });
    }
  }

  handleChangeInstallments(
    VALUE,
    TOTAL_AMOUNT,
    TOTAL_CUOTAS_AMOUNT,
    PTF,
    TEA,
    CFT
  ) {
    this.setState({
      installments: VALUE,
      total_amount: TOTAL_AMOUNT,
      total_cuotas_amount: TOTAL_CUOTAS_AMOUNT,
      ptf: PTF,
      tea: TEA,
      cft: CFT,
    });
  }

  handleTicket(ticketCode, ticketUrl, method) {
    let methodText;

    switch (method) {
      case "pagofacil":
        methodText = "Pago Facil";
        break;
      case "rapipago":
        methodText = "Rapipago";
        break;
      default:
    }

    this.setState({
      isTicket: true,
      ticketCode,
      ticketUrl,
      method: methodText,
    });
  }

  toggleTicketToFalse() {
    document.title = "Gestorando | Método de pago";
    createHistoryBrowser(`/inscripcion/metodo-de-pago`);

    this.setState({
      isTicket: false,
    });
  }

  setLoginUser() {
    localStorage.setItem("loggedIn", true);
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 24);
    localStorage.setItem("expire", expiration);
  }

  async subscribeThirParty(value) {
    try {
      const form = { product_code: value };
      const response = await SubscribeBEATDriver(form);
      if (response) {
        this.setState({
          renderPlans: true,
        });
        this.setLoginUser();
        if (nonPayableProducts.includes(value)) {
          redirectUrl("/panel/");
        } else {
          this.props.onNextQuestion();
        }
      }
    } catch (error) {}
  }

  handlePlanSelected(event) {
    if (event.target.value === "discount-plan") {
      this.setState({
        spinningBeat: true,
      });
      // if enterprise is timejobs price is 600 else is AbonoBEAT-IN
      if (this.state.enterprise === enterprises[1]) {
        this.setLoginUser();
        this.setState({
          renderPlans: false,
          basePriceCreditCard: "600",
          basePriceOtherPayment: "600",
          monthsOfServiceToOffer: 1,
          payingInscription: true,
          serviceToPay: { type: "inscriptions", code: "AbonoTimeJobs-IN" },
        });
      } else {
        this.subscribeThirParty(products[1]);
      }
    } else if (event.target.value === "free-plan") {
      this.setState({
        spinningBeat: true,
      });

      if (this.state.enterprise === enterprises[1]) {
        this.subscribeThirParty(products[2]);
      } else {
        this.subscribeThirParty(products[0]);
      }

      // go and subscribe the client
    } else {
      this.setState({
        renderPlans: false,
        basePriceCreditCard: "300",
        basePriceOtherPayment: "600",
        monthsOfServiceToOffer: 2,
        serviceToPay: {
          type: "inscriptions",
          code:
            this.state.enterprise === enterprises[1]
              ? "AbonoTimeJobs"
              : "AbonoIIBB-CABA",
        },
      });
    }
  }

  goBackArrow() {
    this.setState({
      renderPlans: true,
      spinningBeat: false,
      payingInscription: false,
    });
  }

  renderPayment() {
    return (
      <div className="container">
        <div className="row">
          {this.state.renderPlans ? (
            <Spin
              className="spin-pricing-plans"
              spinning={
                this.state.spinningBeat ||
                this.state.loadingInscriptoMonotributo
              }
              tip={
                this.state.loadingInscriptoMonotributo
                  ? "Cargando planes."
                  : "Procesando tu información"
              }
            >
              <BeatPlans
                onClick={this.handlePlanSelected}
                inscriptoMonotributo={this.state.inscriptoMonotributo}
                renderAllBeatPlans={true}
                enterprisesWorksWith={this.props.enterprisesWorksWith}
              ></BeatPlans>
            </Spin>
          ) : (
            <>
              <PaymentMethodWrapper
                handleTicket={this.handleTicket}
                onNextQuestion={this.props.onNextQuestion}
                basePriceCreditCard={this.state.basePriceCreditCard}
                basePriceOtherPayment={this.state.basePriceOtherPayment}
                installments={this.state.installments}
                total_amount={this.state.total_amount}
                total_cuotas_amount={this.state.total_cuotas_amount}
                ptf={this.state.ptf}
                tea={this.state.tea}
                cft={this.state.cft}
                logoMercadopago={LogoMercadopago}
                handleBasePrice={this.handleBasePrice}
                paymentMethod={this.state.paymentMethod}
                monthsOfServiceToOffer={this.state.monthsOfServiceToOffer}
                payingInscription={this.state.payingInscription}
                goBackArrow={this.goBackArrow}
                serviceToPay={this.state.serviceToPay}
              />
              <ServiceDescription
                basePriceCreditCard={this.state.basePriceCreditCard}
                basePriceOtherPayment={this.state.basePriceOtherPayment}
                installments={this.state.installments}
                total_amount={this.state.total_amount}
                paymentMethod={this.state.paymentMethod}
                total_cuotas_amount={this.state.total_cuotas_amount}
                ptf={this.state.ptf}
                tea={this.state.tea}
                cft={this.state.cft}
                logoMercadopago={LogoMercadopago}
                monthsOfServiceToOffer={this.state.monthsOfServiceToOffer}
                payingInscription={this.state.payingInscription}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  renderTicket() {
    return (
      <Ticket
        basePriceOtherPayment={this.state.basePriceOtherPayment}
        method={this.state.method}
        ticketCode={this.state.ticketCode}
        ticketUrl={this.state.ticketUrl}
        toggleTicketToFalse={this.toggleTicketToFalse}
      />
    );
  }

  render() {
    if (this.state.isTicket) {
      return this.renderTicket();
    }

    return this.renderPayment();
  }
}

Payment.propTypes = {
  onNextQuestion: PropTypes.func,
};

export default Payment;
