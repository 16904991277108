import {
  NEXT_QUESTION,
  NEXT_QUESTION_CUIT_VALIDATED,
  NEXT_QUESTION_TICKET_GENERATED,
} from "./index";
import { createHistoryBrowser } from "./createHistoryBrowser";
import { saveStateToLocalStorage } from "./saveStateToLocalStorage";
import { redirectUrl } from "./redirectUrl";
import { enterprises } from "./listEnterprises";
// When you select the next question on the EmpresasEQ
export function nextQuestionEmpresasEQ(question) {
  saveStateToLocalStorage(question);

  const enterprise = localStorage.getItem("enterprise");
  let nextQuestionId = 1;

  if ("nextQuestion" in question) {
    nextQuestionId = question.nextQuestion;
  } else {
    nextQuestionId = question.posibleAnswers.find((answer) => answer.selected)
      .nextQuestion;
  }

  if (!enterprises.includes(enterprise)) {
    if (nextQuestionId === 6) {
      createHistoryBrowser(`/suscripcion/${enterprise}/registro/`);
    } else if (nextQuestionId === 7) {
      createHistoryBrowser(`/suscripcion/${enterprise}/metodo-de-pago/`);
    } else if (nextQuestionId === 17) {
      redirectUrl("/panel/");
    } else {
      createHistoryBrowser(`/suscripcion/${enterprise}/EQ/${nextQuestionId}/`);
    }
  } else {
    if (nextQuestionId === 10) {
      createHistoryBrowser(`/suscripcion/${enterprise}/registro/`);
    } else if (nextQuestionId === 11) {
      createHistoryBrowser(`/suscripcion/${enterprise}/metodo-de-pago/`);
    } else if (nextQuestionId === 17) {
      redirectUrl("/panel/");
    } else {
      createHistoryBrowser(`/suscripcion/${enterprise}/EQ/${nextQuestionId}/`);
    }
  }

  // If the nexQuestion is the panel then don't do nothing
  // Because it will be redirected with the URL
  if (nextQuestionId !== 17) {
    return {
      type: NEXT_QUESTION,
      payload: nextQuestionId,
    };
  }
}

// Select specific question when uses back or forward button of the browser
export function selectSpecificQuestion(id) {
  return {
    type: NEXT_QUESTION,
    payload: id,
  };
}

// When we generate the ticket on the EmpresasEQ
export function nextQuestionTicketGeneratedEmpresasEQ() {
  const enterprise = localStorage.getItem("enterprise");

  saveStateToLocalStorage({ id: 9, metodoDePago: "ticket" });
  createHistoryBrowser(`/suscripcion/${enterprise}/EQ/9/`);

  return {
    type: NEXT_QUESTION_TICKET_GENERATED,
    payload: 9,
  };
}

// When the CUIT is validated on the EmpresasEQ
export function nextQuestionEmpresasEQCuitValidated(
  question,
  cuit,
  monotributo
) {
  const enterprise = localStorage.getItem("enterprise");
  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: true,
    value: cuit,
  });

  if (enterprises.includes(enterprise)) {
    if (monotributo) {
      return {
        type: NEXT_QUESTION,
        payload: 5,
      };
    }
    return {
      type: NEXT_QUESTION,
      payload: 4,
    };
  } else {
    redirectUrl("/panel/");
  }
}

// When the CUIT is wrong on the EmpresasEQ
export function nextQuestionEmpresasEQCuitNotValidated(question, cuit) {
  const enterprise = localStorage.getItem("enterprise");

  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: false,
    value: cuit,
  });

  if (enterprises.includes(enterprise)) {
    return {
      type: NEXT_QUESTION,
      payload: 3,
    };
  } else {
    createHistoryBrowser(`/suscripcion/${enterprise}/EQ/12/`);

    return {
      type: NEXT_QUESTION,
      payload: 12,
    };
  }
}

// When you select the next question on the SimulateEQ
export function nextQuestionSimulateEQ(question) {
  saveStateToLocalStorage(question);

  let nextQuestionId = 1;

  if ("nextQuestion" in question) {
    if ("activities" in question) {
      nextQuestionId =
        question.activities.indexOf(question.value) !== -1 ? 2 : 3;
    } else {
      nextQuestionId = question.nextQuestion;
    }
  } else {
    nextQuestionId = question.posibleAnswers.find((answer) => answer.selected)
      .nextQuestion;
  }

  createHistoryBrowser(`/simular-categoria/EQ/${nextQuestionId}/`);

  return {
    type: NEXT_QUESTION,
    payload: nextQuestionId,
  };
}

// When you select the Espacio fisico question on SimulateEQ
export function nextQuestionEspacioFisico(question) {
  const nextQuestionId = question.nextQuestion;

  // Assigning new values from localStorage
  question.alquiler = localStorage.getItem("alquiler");
  question.superficie = localStorage.getItem("superficie");
  question.energia = localStorage.getItem("energia");
  question.destinaEspacioFisico =
    localStorage.getItem("alquiler") !== null &&
    localStorage.getItem("superficie") !== null &&
    localStorage.getItem("energia") !== null;

  // Removing unnecessary data from localStorage
  localStorage.removeItem("superficie");
  localStorage.removeItem("alquiler");
  localStorage.removeItem("energia");

  saveStateToLocalStorage(question);
  createHistoryBrowser(`/simular-categoria/EQ/${nextQuestionId}/`);

  return {
    type: NEXT_QUESTION,
    payload: nextQuestionId,
  };
}

// When you select the Facturacion Estimada question on SimulateEQ
export function nextQuestionEstimasFacturar(question) {
  const nextQuestionId = question.nextQuestion;

  // Assigning new values from localStorage
  question.facturacionEstimada = localStorage.getItem("facturacionEstimada");

  // Removing unnecessary data from localStorage
  localStorage.removeItem("facturacionEstimada");

  saveStateToLocalStorage(question);
  createHistoryBrowser(`/simular-categoria/EQ/${nextQuestionId}/`);

  return {
    type: NEXT_QUESTION,
    payload: nextQuestionId,
  };
}

// When you select the next question on the InscripcionEQ
export function nextQuestionInscripcionEQ(question) {
  saveStateToLocalStorage(question);

  let nextQuestionId = 1;

  if ("nextQuestion" in question) {
    nextQuestionId = question.nextQuestion;
  } else {
    nextQuestionId = question.posibleAnswers.find((answer) => answer.selected)
      .nextQuestion;
  }

  if (nextQuestionId === 7) {
    createHistoryBrowser("/inscripcion/registro/");
  } else if (nextQuestionId === 9) {
    createHistoryBrowser("/inscripcion/metodo-de-pago/");
  } else if (nextQuestionId === 11) {
    redirectUrl("/panel/");
  } else {
    createHistoryBrowser(`/inscripcion/EQ/${nextQuestionId}/`);
  }

  // If the nexQuestion is the panel then don't do nothing
  // Because it will be redirected with the URL
  if (nextQuestionId !== 11) {
    return {
      type: NEXT_QUESTION,
      payload: nextQuestionId,
    };
  }
}

// When we generate the ticket on the InscripcionEQ
export function nextQuestionTicketGeneratedInscripcionEQ() {
  saveStateToLocalStorage({ id: 9, metodoDePago: "ticket" });

  redirectUrl("/panel/");
}

// When the CUIT is validated on the InscripcionEQ
export function nextQuestionInscripcionEQCuitValidated(question, cuit) {
  const nextQuestionId = question.nextQuestion;

  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: true,
    value: cuit,
  });
  createHistoryBrowser("/inscripcion/registro/");

  return {
    type: NEXT_QUESTION_CUIT_VALIDATED,
    payload: nextQuestionId,
  };
}

// When the CUIT is wrong on the InscripcionEQ
export function nextQuestionInscripcionEQCuitNotValidated(question, cuit) {
  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: false,
    value: cuit,
  });
  createHistoryBrowser("/inscripcion/EQ/5/");

  return {
    type: NEXT_QUESTION,
    payload: 5,
  };
}

// When you select the next question on the PlansEQ
export function nextQuestionPlansEQ(question) {
  saveStateToLocalStorage(question);

  let nextQuestionId = 1;

  if ("nextQuestion" in question) {
    nextQuestionId = question.nextQuestion;
  } else {
    nextQuestionId = question.posibleAnswers.find((answer) => answer.selected)
      .nextQuestion;
  }

  if (nextQuestionId === 2) {
    createHistoryBrowser("/plans/registro/");
  } else if (nextQuestionId === 11) {
    redirectUrl("/panel/");
  } else {
    createHistoryBrowser(`/plans/EQ/${nextQuestionId}/`);
  }

  // If the nexQuestion is the panel then don't do nothing
  // Because it will be redirected with the URL
  if (nextQuestionId !== 11) {
    return {
      type: NEXT_QUESTION,
      payload: nextQuestionId,
    };
  }
}
// When the CUIT is validated on the PlansEQ
export function nextQuestionPlansEQCuitValidated(question, cuit) {
  const nextQuestionId = question.nextQuestion;

  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: true,
    value: cuit,
  });
  createHistoryBrowser("/plans/registro/");

  return {
    type: NEXT_QUESTION_CUIT_VALIDATED,
    payload: nextQuestionId,
  };
}

// When the CUIT is wrong on the PlansEQ
export function nextQuestionPlansEQCuitNotValidated(question, cuit) {
  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: false,
    value: cuit,
  });
  createHistoryBrowser("/plans/registro/");

  return {
    type: NEXT_QUESTION,
    payload: 2,
  };
}

// When you select the next question on the TramitesEQ
export function nextQuestionTramitesEQ(question) {
  saveStateToLocalStorage(question);

  let nextQuestionId = 1;
  if ("nextQuestion" in question) {
    nextQuestionId = question.nextQuestion;
  } else {
    nextQuestionId = question.posibleAnswers.find((answer) => answer.selected)
      .nextQuestion;
  }

  if (nextQuestionId === 2) {
    createHistoryBrowser("/tramites/registro/");
  } else if (nextQuestionId === 11) {
    redirectUrl("/panel/");
  } else {
    createHistoryBrowser(`/tramites/EQ/${nextQuestionId}/`);
  }

  // If the nexQuestion is the panel then don't do nothing
  // Because it will be redirected with the URL
  if (nextQuestionId !== 11) {
    return {
      type: NEXT_QUESTION,
      payload: nextQuestionId,
    };
  }
}

// When the CUIT is validated on the TramitesEQ
export function nextQuestionTramitesEQCuitValidated(question, cuit) {
  const nextQuestionId = question.nextQuestion;

  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: true,
    value: cuit,
  });
  createHistoryBrowser("/tramites/registro/");

  return {
    type: NEXT_QUESTION_CUIT_VALIDATED,
    payload: nextQuestionId,
  };
}

// When the CUIT is wrong on the TramitesEQ
export function nextQuestionTramitesEQCuitNotValidated(question, cuit) {
  saveStateToLocalStorage({
    id: question.id,
    cuitValidated: false,
    value: cuit,
  });
  createHistoryBrowser("/tramites/registro/");

  return {
    type: NEXT_QUESTION,
    payload: 2,
  };
}
