/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../Loader/index";
import LogoSecurityCode from "../../../../assets/img/security-code.png";
import { submitPaymentMethod, MP_PUBLIC_KEY } from "../../../../apis";

const SDK_ERRORS = {
  310: {
    error: "internal_client_id",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  200: {
    error: 'The parameter "public_key" can not be null or empty',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  205: {
    error: "Ingresa el número de tu tarjeta",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  302: {
    error: 'Invalid parameter "public_key"',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  219: {
    error: 'The parameter "client_id" can not be null or empty',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  315: {
    error: 'Invalid parameter "site_id"',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  222: {
    error: 'The parameter "site_id" is a required field (not null or empty)',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  318: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  304: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  703: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  319: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  701: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  317: {
    error: "Card id inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  320: {
    error: 'Invalid parameter "luhn_validation"',
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E111: {
    error: "Invalid json",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E115: {
    error: "Public Key inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E202: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E213: {
    error: "Invalid parameter card_present",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E301: {
    error: "Número de tarjeta inválido",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  E501: {
    error: "Not found public_key",
    input: "debitCardNumber",
    errorElement: "errorDebitCardNumber",
  },
  208: {
    error: "Elige un mes",
    input: "debitCardExpirationMonth",
    errorElement: "errorDebitCardExpirationMonth",
  },
  325: {
    error: "Mes inválido",
    input: "debitCardExpirationMonth",
    errorElement: "errorDebitCardExpirationMonth",
  },
  209: {
    error: "Elige un año",
    input: "debitCardExpirationYear",
    errorElement: "errorDebitCardExpirationYear",
  },
  326: {
    error: "Año inválido",
    input: "debitCardExpirationYear",
    errorElement: "errorDebitCardExpirationYear",
  },
  702: {
    error: "Año inválido",
    input: "debitCardExpirationYear",
    errorElement: "errorDebitCardExpirationYear",
  },
  301: {
    error: "Fecha inválida",
    input: "debitCardExpirationYear",
    errorElement: "errorDebitCardExpirationYear",
  },
  321: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  700: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  307: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  704: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  224: {
    error: "Ingresa el código de seguridad",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  E203: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  E302: {
    error: "Código de seguridad inválido",
    input: "debitCardSecurityCode",
    errorElement: "errorDebitCardSecurityCode",
  },
  305: {
    error: "Invalid cardholder structure",
    input: "debitCardholderName",
    errorElement: "errorDebitCardholderName",
  },
  210: {
    error: "Faltan datos del propietario",
    input: "debitCardholderName",
    errorElement: "errorDebitCardholderName",
  },
  221: {
    error: "Ingresá el nombre y apellido",
    input: "debitCardholderName",
    errorElement: "errorDebitCardholderName",
  },
  316: {
    error: "El nombre no puede ser vacío",
    input: "debitCardholderName",
    errorElement: "errorDebitCardholderName",
  },
  E114: {
    error: "El nombre es inválido",
    input: "debitCardholderName",
    errorElement: "errorDebitCardholderName",
  },
  322: {
    error: "El tipo de doc. es inválido",
    input: "debitCardDocType",
    errorElement: "errorDebitCardDocType",
  },
  323: {
    error: "El tipo de doc. es inválido",
    input: "debitCardDocType",
    errorElement: "errorDebitCardDocType",
  },
  324: {
    error: "El doc. es inválido",
    input: "debitCardDocType",
    errorElement: "errorDebitDocType",
  },
  E305: {
    error: "El tipo de doc. es inválido",
    input: "debitCardDocType",
    errorElement: "errorDebitCardDocType",
  },
  212: {
    error: "El tipo de doc. es inválido",
    input: "debitCardDocType",
    errorElement: "errorDebitCardDocType",
  },
  213: {
    error: "Ingresá tu documento",
    input: "debitCardDocNumber",
    errorElement: "errorDebitCardDocNumber",
  },
  214: {
    error: "Ingresá tu documento",
    input: "debitCardDocNumber",
    errorElement: "errorDebitCardDocNumber",
  },
  211: {
    error: "El documento no puede ser vacío",
    input: "debitCardDocNumber",
    errorElement: "errorDebitCardDocNumber",
  },
  220: {
    error: "Ingresá tu banco emisor",
    input: "debitCardIssuerVal",
    errorElement: "errorDebitCardIssuerVal",
  },
};

class DebitCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitDisabled: true,
      showIssuerWrapper: false,
      disabledIssuerWrapper: true,
      issuerOptions: [],
      debitCardIssuerVal: "",
      debitCardNumber: "",
      debitCardExpirationMonth: "",
      debitCardExpirationYear: "",
      debitCardSecurityCode: "",
      debitCardholderName: "",
      debitCardDocType: "",
      debitCardDocNumber: "",
      debitCardEmail: "",
      paymentMethod: "debit_card",
      axiosError: "",
      amount: 600,
    };

    this.handleCardNumberKeyUp = this.handleCardNumberKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
    this.setPaymentMethodInfo = this.setPaymentMethodInfo.bind(this);
    this.showCardIssuers = this.showCardIssuers.bind(this);
    this.clearOptions = this.clearOptions.bind(this);
    this.getBin = this.getBin.bind(this);
    this.sdkResponseHandler = this.sdkResponseHandler.bind(this);
  }

  componentDidMount() {
    window.Mercadopago.setPublishableKey(MP_PUBLIC_KEY);
  }

  getBin() {
    return this.state.debitCardNumber.replace(/[ .-]/g, "").slice(0, 6);
  }

  clearOptions() {
    const BIN = this.getBin();

    if (BIN.length < 6) {
      this.setState({
        showIssuerWrapper: false,
        disabledIssuerWrapper: true,
        submitDisabled: true,
        issuerOptions: [],
        debitCardIssuerVal: "",
      });
    }
  }

  showCardIssuers(status, issuers) {
    if (issuers.length === 0) {
      this.setState({
        showIssuerWrapper: false,
      });
    } else {
      this.setState({
        issuerOptions: issuers,
        showIssuerWrapper: true,
      });
    }
    this.setState({
      disabledIssuerWrapper: false,
    });
  }

  setPaymentMethodInfo(status, response) {
    if (status === 200) {
      if (response[0].payment_type_id === "credit_card") {
        this.setState({
          submitDisabled: false,
        });
      } else if (response[0].payment_type_id === "debit_card") {
        this.setState({
          submitDisabled: false,
        });
      } else if (
        response[0].payment_type_id !== "credit_card" &&
        response[0].payment_type_id !== "debit_card"
      ) {
        this.setState({
          submitDisabled: true,
        });
      }

      this.setState({
        paymentMethod: response[0].id,
      });

      // check if the issuer is necessary to pay
      let issuerMandatory = false;
      const ADDITIONAL_INFO = response[0].additional_info_needed;
      for (let i = 0; i < ADDITIONAL_INFO.length; i += 1) {
        if (ADDITIONAL_INFO[i] === "issuer_id") {
          issuerMandatory = true;
        }
      }
      if (issuerMandatory) {
        this.setState({
          disabledIssuerWrapper: false,
        });
        window.Mercadopago.getIssuers(response[0].id, this.showCardIssuers);
      } else {
        this.setState({
          disabledIssuerWrapper: true,
          debitCardIssuerVal: "",
        });
      }
    } else {
      this.setState({
        submitDisabled: true,
      });
    }
  }

  guessingPaymentMethod(e) {
    const BIN = this.getBin();
    if (e.type === "keyup") {
      if (BIN.length === 6) {
        window.Mercadopago.getPaymentMethod(
          {
            bin: BIN,
          },
          this.setPaymentMethodInfo
        );
      } else {
        this.clearOptions();
      }
    } else {
      setTimeout(() => {
        if (BIN.length >= 6) {
          window.Mercadopago.getPaymentMethod(
            {
              bin: BIN,
            },
            this.setPaymentMethodInfo
          );
        }
      }, 100);
    }
  }

  async handleSubmit() {
    try {
      const form = JSON.stringify({
        email: this.state.debitCardEmail,
        installments: 1,
        issuer: this.state.debitCardIssuerVal,
        paymentMethodId: this.state.paymentMethod,
        cardToken: this.state.cardToken,
        serviceToPay: this.props.serviceToPay,
      });
      const response = await submitPaymentMethod(form);

      if (response.data.error) {
        this.setState({
          isLoading: false,
          axiosError: response.data.description,
        });
      } else {
        this.props.onNextQuestion();
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        axiosError: "Error 500",
      });
    }
  }

  sdkResponseHandler(status, response) {
    if (status !== 200 && status !== 201) {
      if ("cause" in response) {
        response.cause.forEach((cause) => {
          if (SDK_ERRORS[cause.code] !== undefined) {
            const elementTextError = document.getElementById(
              SDK_ERRORS[cause.code].errorElement
            );
            const elementInput = document.getElementById(
              SDK_ERRORS[cause.code].input
            );

            elementInput.classList.add("error-border");
            elementTextError.textContent = SDK_ERRORS[cause.code].error;
          }
        });

        if (
          !this.state.debitCardEmail.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
          )
        ) {
          document
            .getElementById("debitCardEmail")
            .classList.add("error-border");
          document.getElementById("errorDebitCardEmail").textContent =
            "Ingresá un email valido";
        }

        if (
          document.getElementById("debit-card-issuer-wrapper").classList[0] !==
            "d-none" &&
          this.state.debitCardIssuerVal === ""
        ) {
          document
            .getElementById("debitCardIssuerVal")
            .classList.add("error-border");
          document.getElementById("errorDebitCardIssuerVal").textContent =
            SDK_ERRORS["220"].error;
        }
      }
    } else if (status === 200 || status === 201) {
      // We are doing this verifications manually here because from MP it looks like its not required
      let allValidated = false;

      // If the email is not correct then verify it
      if (
        !this.state.debitCardEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        document.getElementById("debitCardEmail").classList.add("error-border");
        document.getElementById("errorDebitCardEmail").textContent =
          "Ingresá un email valido";
        allValidated = false;
      } else {
        allValidated = true;
      }

      // If issuer is visible and empty then verify it
      if (
        document.getElementById("debit-card-issuer-wrapper").classList[0] !==
          "d-none" &&
        this.state.debitCardIssuerVal === ""
      ) {
        document
          .getElementById("debitCardIssuerVal")
          .classList.add("error-border");
        document.getElementById("errorDebitCardIssuerVal").textContent =
          SDK_ERRORS["220"].error;
        allValidated = false;
      } else {
        allValidated = true;
      }

      if (allValidated) {
        this.setState({
          cardToken: response.id,
          isLoading: true,
        });
        this.handleSubmit();
      }
    }
  }

  handleCardNumberKeyUp(e) {
    this.clearOptions();
    this.guessingPaymentMethod(e);
  }

  handleChange(e) {
    const TARGET = e.target;
    const VALUE = TARGET.value;
    const NAME = TARGET.id;

    this.setState({
      [NAME]: VALUE,
    });

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";
  }

  handleCardNumberChange(e) {
    const TARGET = e.target;
    const NAME = TARGET.id;
    const VALUE = TARGET.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    this.setState({
      debitCardNumber: VALUE,
    });

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";
  }

  appendYears() {
    const DATE = new Date();
    const ACTUAL_DATE = DATE.getUTCFullYear();
    const OPTIONS = [];

    for (let i = ACTUAL_DATE; i < ACTUAL_DATE + 11; i += 1) {
      const OPTION = (
        <option key={Math.random()} value={i}>
          {i}
        </option>
      );
      OPTIONS.push(OPTION);
    }
    return OPTIONS.map((e) => e);
  }

  appendIssuers() {
    const ISSUERS = this.state.issuerOptions;
    const OPTIONS = [];

    for (let i = 0; i < ISSUERS.length; i += 1) {
      const ID = ISSUERS[i].id;
      const NAME = ISSUERS[i].name;

      OPTIONS.push({
        id: ID,
        name: NAME,
      });
    }

    const optionsToRender = OPTIONS.map((e) => (
      <option key={e.id} value={e.id}>
        {e.name}
      </option>
    ));

    optionsToRender.unshift(
      <option key={Math.random()} value="" disabled>
        Elegi un banco
      </option>
    );

    return optionsToRender;
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : null;
  }

  doPay(e) {
    e.preventDefault();

    const FORM = {
      cardNumber: this.state.debitCardNumber,
      securityCode: this.state.debitCardSecurityCode,
      cardExpirationMonth: this.state.debitCardExpirationMonth,
      cardExpirationYear: this.state.debitCardExpirationYear,
      cardholderName: this.state.debitCardholderName,
      docType: this.state.debitCardDocType,
      docNumber: this.state.debitCardDocNumber,
      installments: 1,
    };

    if (this.state.issuerVal !== "") {
      FORM.issuer = this.state.debitCardIssuerVal;
    }

    window.Mercadopago.createToken(FORM, this.sdkResponseHandler);
  }

  renderDebitCard() {
    return (
      <div
        className={`debit-card-wrapper
        ${this.props.debitCardState ? "d-block" : false}`}
      >
        {this.state.axiosError !== "" ? (
          <h5>{this.state.axiosError}</h5>
        ) : (
          false
        )}
        {this.renderLoader()}
        <form onSubmit={(e) => this.doPay(e)} id="form-debit-card">
          <ul>
            <li>
              <p>N&uacute;mero de la tarjeta</p>
              <label htmlFor="debitCardNumber">
                <input
                  className={`${
                    this.state.debitCardNumber ? "active-border" : false
                  } form-control`}
                  id="debitCardNumber"
                  type="text"
                  maxLength="19"
                  placeholder="Ingrese los 16 digitos de la tarjeta"
                  onKeyUp={this.handleCardNumberKeyUp}
                  onChange={this.handleCardNumberChange}
                  value={this.state.debitCardNumber}
                />
                <p className="error-message" id="errorDebitCardNumber" />
              </label>
            </li>
            <li>
              <p>Mes de vencimiento</p>
              <label htmlFor="debitCardExpirationMonth">
                <select
                  className={`${
                    this.state.debitCardExpirationMonth
                      ? "active-border"
                      : false
                  } form-control`}
                  id="debitCardExpirationMonth"
                  onChange={this.handleChange}
                  value={this.state.debitCardExpirationMonth}
                >
                  <option value="" disabled>
                    Mes
                  </option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <p
                  className="error-message"
                  id="errorDebitCardExpirationMonth"
                />
              </label>
            </li>
            <li>
              <p>Año de vencimiento</p>
              <label htmlFor="debitCardExpirationYear">
                <select
                  className={`${
                    this.state.debitCardExpirationYear ? "active-border" : false
                  } form-control`}
                  id="debitCardExpirationYear"
                  onChange={this.handleChange}
                  value={this.state.debitCardExpirationYear}
                >
                  <option value="" disabled>
                    Año
                  </option>
                  {this.appendYears()}
                </select>
                <p
                  className="error-message"
                  id="errorDebitCardExpirationYear"
                />
              </label>
            </li>
            <li>
              <p>C&oacute;digo de seguridad</p>
              <label htmlFor="debitCardSecurityCode">
                <input
                  className={`${
                    this.state.debitCardSecurityCode ? "active-border" : false
                  } form-control`}
                  id="debitCardSecurityCode"
                  type="number"
                  placeholder="422"
                  onChange={this.handleChange}
                  value={this.state.debitCardSecurityCode}
                />
                <i className="fa fa-question-circle" />
                <div className="question-security">
                  <img
                    src={LogoSecurityCode}
                    alt="Imagen de codigo de seguridad"
                    title="Codigo de seguridad"
                  />
                  <span>
                    El código de seguridad está localizado en los 3 últimos
                    dígitos detrás de la tarjeta.
                  </span>
                  <div className="triangle" />
                  <div className="close">x</div>
                </div>
                <p className="error-message" id="errorDebitCardSecurityCode" />
              </label>
            </li>
            <li>
              <p>Titular de la tarjeta</p>
              <label htmlFor="debitCardholderName">
                <input
                  className={`${
                    this.state.debitCardholderName ? "active-border" : false
                  } form-control`}
                  id="debitCardholderName"
                  type="text"
                  maxLength="30"
                  placeholder="Santiago Moreyra"
                  onChange={this.handleChange}
                  value={this.state.debitCardholderName}
                />
                <p className="error-message" id="errorDebitCardholderName" />
              </label>
            </li>
            <li>
              <p>Tipo de documento</p>
              <label htmlFor="debitCardDocType">
                <select
                  className={`${
                    this.state.debitCardDocType ? "active-border" : false
                  } form-control`}
                  id="debitCardDocType"
                  onChange={this.handleChange}
                  value={this.state.debitCardDocType}
                >
                  <option value="" disabled>
                    Tipo Documento
                  </option>
                  <option value="DNI">DNI</option>
                  <option value="CI">Cédula</option>
                  <option value="LC">L.C.</option>
                  <option value="LE">L.E.</option>
                  <option value="Otro">Otro</option>
                </select>
                <p className="error-message" id="errorDebitCardDocType" />
              </label>
            </li>
            <li>
              <p>N&uacute;mero de documento</p>
              <label htmlFor="debitCardDocNumber">
                <input
                  className={`${
                    this.state.debitCardDocNumber ? "active-border" : false
                  } form-control`}
                  id="debitCardDocNumber"
                  type="text"
                  placeholder="19000025"
                  onChange={this.handleChange}
                  value={this.state.debitCardDocNumber}
                />
                <p className="error-message" id="errorDebitCardDocNumber" />
              </label>
            </li>
            <li>
              <p>Email</p>
              <label htmlFor="debitCardEmail">
                <input
                  className={`${
                    this.state.debitCardEmail ? "active-border" : false
                  } form-control`}
                  id="debitCardEmail"
                  type="email"
                  placeholder="santiago@gmail.com"
                  onChange={this.handleChange}
                  value={this.state.debitCardEmail}
                />
                <p className="error-message" id="errorDebitCardEmail" />
              </label>
            </li>
            <li
              id="debit-card-issuer-wrapper"
              className={`${this.state.showIssuerWrapper ? null : "d-none"}`}
            >
              <p>Banco</p>
              <label htmlFor="debitCardIssuerVal">
                <select
                  className={`${
                    this.state.debitCardIssuerVal ? "active-border" : false
                  } form-control`}
                  id="debitCardIssuerVal"
                  disabled={this.state.disabledIssuerWrapper}
                  onChange={this.handleChange}
                  value={this.state.debitCardIssuerVal}
                >
                  {this.appendIssuers()}
                </select>
                <p className="error-message" id="errorDebitCardIssuerVal" />
              </label>
            </li>
            <input
              type="hidden"
              id="amount"
              name="amount"
              value={this.state.amount}
            />
          </ul>
          <button
            className={`${
              this.state.submitDisabled ? false : "remove-disabled"
            } btn-next`}
            disabled={this.state.submitDisabled}
            type="submit"
            value="PAGAR"
          >
            PAGAR
          </button>
        </form>
      </div>
    );
  }

  render() {
    return this.renderDebitCard();
  }
}

DebitCard.propTypes = {
  debitCardState: PropTypes.bool,
  onNextQuestion: PropTypes.func,
  payingInscription: PropTypes.bool,
  serviceToPay: PropTypes.object,
};

export default DebitCard;
