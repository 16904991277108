import React from 'react';
import PropTypes from 'prop-types';

class InputEQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.setState({
      value: this.props.question.value,
    });

    if (this.props.question.value !== '') {
      this.props.handleDisablingOfNextButton(false);
    }
  }

  handleChange(event) {
    const { question } = this.props;
    const value = event.target.value.replace(
      /[\d\t\n,./<>?;:"'`!@#$%^&*()\\{}_+=|\\-]/g,
      '',
    );

    // Creates a 'value' key on the object
    question.value = value;
    this.setState({
      value,
    });

    // If its empty or not then set the disable next button
    if (value !== '') {
      this.props.handleDisablingOfNextButton(false);
    } else {
      this.props.handleDisablingOfNextButton(true);
    }
  }

  render() {
    return (
      <div className="input-wrapper">
        <div className={`${this.state.value !== '' ? 'valid' : null}`}>
          <p>{this.props.question.inputDescription}</p>
          <label htmlFor={`answer${this.props.question.id}`}>
            <input
              type={this.props.question.type}
              id={`answer${this.props.question.id}`}
              name={this.props.question.name}
              placeholder={this.props.question.placeholder}
              value={this.state.value}
              onChange={e => this.handleChange(e)}
            />
            <i className="fas fa-check-circle" />
            <i className="fas fa-times-circle" />
          </label>
        </div>
      </div>
    );
  }
}

InputEQ.propTypes = {
  question: PropTypes.object,
  handleDisablingOfNextButton: PropTypes.func,
};

export default InputEQ;
