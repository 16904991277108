import React from "react";
import PropTypes from "prop-types";
import { Alert, Modal, Button } from "antd";

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.showModal = this.showModal.bind(this);
    this.handleModalOk = this.handleModalOk.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  handleModalOk() {
    this.setState({
      showModal: false,
    });
  }

  handleModalCancel() {
    this.setState({
      showModal: false,
    });
  }

  getDescription(description) {
    let obj = {};

    if (description.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, "<br />"),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, "<br /><br />"),
      };
    }

    if (description.match(/(<span>)/g)) {
      // Replace the '<span>' to <span> HTML tag
      // Replace the '</span>' to </span> HTML tag
      obj = {
        __html: description
          .replace(/(<span>)/g, "<span>")
          .replace(/(<\/span>)/g, "</span>"),
      };
    }

    return obj;
  }

  getTitle(title) {
    let obj;

    if (title.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, "<br />"),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, "<br /><br />"),
      };
    }

    return obj;
  }

  renderRespone() {
    const { question } = this.props;
    const { title, description, icon, moreInfoIcon } = question;
    const isTitle = "title" in question;
    const isDescription = "description" in question;
    const isIcon = "icon" in question;
    let iconOfAlert;
    let response;

    if (isIcon) {
      if (icon === "success") {
        iconOfAlert = <i className="fas fa-check-circle" />;
      } else if (icon === "error") {
        iconOfAlert = <i className="fas fa-times-circle" />;
      } else if (icon === "warning") {
        iconOfAlert = <i className="fas fa-exclamation-triangle" />;
      }

      if (isTitle && !isDescription) {
        response = (
          <div className="response-wrapper">
            <Alert
              message={
                <p
                  className="title bold m-0"
                  dangerouslySetInnerHTML={this.getTitle(title)}
                />
              }
              type="success"
              icon={iconOfAlert}
              showIcon
            />
          </div>
        );
      } else if (isTitle && isDescription) {
        response = (
          <div className="response-wrapper">
            <Alert
              message={
                <p
                  className="title bold m-0"
                  dangerouslySetInnerHTML={this.getTitle(title)}
                />
              }
              type="success"
              icon={iconOfAlert}
              showIcon
            />
            <p
              className="description"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
          </div>
        );
      } else if (!isTitle && isDescription) {
        response = (
          <div className="response-wrapper">
            <p
              className="description"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
          </div>
        );
      }
    } else if (isTitle && !isDescription) {
      if (moreInfoIcon) {
        response = (
          <React.Fragment>
            <Modal
              visible={this.state.showModal}
              centered
              closable={false}
              title={title}
              onCancel={this.handleModalCancel}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleModalOk}
                >
                  Entendido
                </Button>,
              ]}
            >
              <p>
                La CUIT es una clave de identificación tributaria. Contiene 11
                números: dos dígitos iniciales, tu número de DNI y un dígito
                final. Si bien es igual a la CUIL se obtiene en AFIP (por
                internet o de forma presencial, dependiendo si tenés un DNI
                argentino vigente o no).
              </p>
            </Modal>
            <h5
              className="card-title card-title--with-info"
              dangerouslySetInnerHTML={this.getTitle(title)}
            />
            <button type="button" onClick={this.showModal}>
              <i className="fas fa-question-circle" />
            </button>
          </React.Fragment>
        );
      } else {
        response = (
          <h5
            className="card-title"
            dangerouslySetInnerHTML={this.getTitle(title)}
          />
        );
      }
    } else if (isTitle && isDescription) {
      response = (
        <React.Fragment>
          <h5
            className="card-title"
            dangerouslySetInnerHTML={this.getTitle(title)}
          />
          <p
            className="card-subtitle"
            dangerouslySetInnerHTML={this.getDescription(description)}
          />
        </React.Fragment>
      );
    } else if (!isTitle && isDescription) {
      response = (
        <p
          className="card-subtitle"
          dangerouslySetInnerHTML={this.getDescription(description)}
        />
      );
    }

    return <div>{response}</div>;
  }

  render() {
    return this.renderRespone();
  }
}

Response.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  question: PropTypes.object,
};

export default Response;
