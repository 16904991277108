import '../../assets/scss/main.scss';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './Login/index';
import RestorePassword from './RestorePassword/index';
import ForgotPassword from './ForgotPassword/index';

export default function UserAuthentication() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/" component={Login} />
        <Route path="/ingresar/" component={Login} />
        <Route path="/olvide-contrasena/" component={ForgotPassword} />
        <Route
          path="/restaurar-contrasena/:identifier/"
          component={RestorePassword}
        />
        <Route
          path="/restore-password/:identifier/"
          component={RestorePassword}
        />
      </Switch>
    </BrowserRouter>
  );
}
