import { CHANGE_ENTERPRISE_IN_QUESTIONS } from "./index";
import { enterprises } from "../actions/listEnterprises";

export function changeEnterpriseInQuestions(questions) {
  let enterpriseName = localStorage.getItem("enterprise");

  if (enterpriseName === "pedidosya") {
    enterpriseName = "RepartosYa";
  } else if (enterpriseName === "timejobs") {
    enterpriseName = "TimeJobs";
  } else {
    enterpriseName =
      localStorage.getItem("enterprise").charAt(0).toUpperCase() +
      localStorage.getItem("enterprise").substr(1);
  }

  if (enterprises.includes(enterpriseName.toLowerCase())) {
    const question6 = questions.filter(
      (questionData) => questionData.id === 6
    )[0];
    question6.title = `Además de colaborar con ${enterpriseName}, ¿estás trabajando en relación de dependencia?`;
    return {
      type: CHANGE_ENTERPRISE_IN_QUESTIONS,
      payload: questions.map((questionObject) =>
        questionObject.id !== 6
          ? questionObject
          : Object.assign(questionObject, question6)
      ),
    };
  } else {
    const question2 = questions.filter(
      (questionData) => questionData.id === 2
    )[0];
    question2.title = `Además de colaborar con ${enterpriseName}, ¿estás trabajando en relación de dependencia?`;
    return {
      type: CHANGE_ENTERPRISE_IN_QUESTIONS,
      payload: questions.map((questionObject) =>
        questionObject.id !== 2
          ? questionObject
          : Object.assign(questionObject, question2)
      ),
    };
  }
}
