import React from 'react';
import PropTypes from 'prop-types';
import { redirectUrl } from '../../../../actions/redirectUrl';

class SelectApplicationToWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      glovoSelected: false,
      moovaSelected: false,
      pedidosYaSelected: false,
      rappiSelected: false,
      cangurSelected: false,
      otroSelected: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNextQuestion = this.handleNextQuestion.bind(this);
  }

  handleNextQuestion() {
    if (this.state.glovoSelected) {
      redirectUrl('/suscripcion/glovo/EQ/1/');
    } else if (this.state.moovaSelected) {
      redirectUrl('/suscripcion/moova/EQ/1/');
    } else if (this.state.pedidosYaSelected) {
      redirectUrl('/suscripcion/pedidosya/EQ/1/');
    } else if (this.state.rappiSelected) {
      redirectUrl('/suscripcion/rappi/EQ/1/');
    } else if (this.state.cangurSelected) {
      redirectUrl('/suscripcion/cangur/EQ/1/');
    } else if (this.state.otroSelected) {
      this.props.onNextQuestion();
    }
  }

  handleChange(e) {
    const { target } = e;
    const { id } = target;
    let glovoSelected,
      moovaSelected,
      pedidosYaSelected,
      rappiSelected,
      cangurSelected,
      otroSelected;

    if (id === 'glovo') {
      glovoSelected = target.checked;
      moovaSelected = false;
      pedidosYaSelected = false;
      rappiSelected = false;
      cangurSelected = false;
      otroSelected = false;
    } else if (id === 'moova') {
      glovoSelected = false;
      moovaSelected = target.checked;
      pedidosYaSelected = false;
      rappiSelected = false;
      cangurSelected = false;
      otroSelected = false;
    } else if (id === 'pedidosYa') {
      glovoSelected = false;
      moovaSelected = false;
      pedidosYaSelected = target.checked;
      rappiSelected = false;
      cangurSelected = false;
      otroSelected = false;
    } else if (id === 'rappi') {
      glovoSelected = false;
      moovaSelected = false;
      pedidosYaSelected = false;
      rappiSelected = target.checked;
      cangurSelected = false;
      otroSelected = false;
    } else if (id === 'cangur') {
      glovoSelected = false;
      moovaSelected = false;
      pedidosYaSelected = false;
      rappiSelected = false;
      cangurSelected = target.checked;
      otroSelected = false;
    } else {
      glovoSelected = false;
      moovaSelected = false;
      pedidosYaSelected = false;
      rappiSelected = false;
      cangurSelected = false;
      otroSelected = target.checked;
    }

    this.setState({
      glovoSelected,
      moovaSelected,
      pedidosYaSelected,
      rappiSelected,
      cangurSelected,
      otroSelected,
    });
  }

  renderOptions() {
    return (
      <React.Fragment>
        <div className="checkbox-wrapper">
          <div className={this.state.glovoSelected ? 'selected' : null}>
            <label htmlFor="glovo">
              <input
                type="checkbox"
                checked={this.state.glovoSelected}
                id="glovo"
                onChange={this.handleChange}
              />
              Glovo
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.moovaSelected ? 'selected' : null}>
            <label htmlFor="moova">
              <input
                type="checkbox"
                checked={this.state.moovaSelected}
                id="moova"
                onChange={this.handleChange}
              />
              Moova
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.pedidosYaSelected ? 'selected' : null}>
            <label htmlFor="pedidosYa">
              <input
                type="checkbox"
                checked={this.state.pedidosYaSelected}
                id="pedidosYa"
                onChange={this.handleChange}
              />
              PedidosYa
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.rappiSelected ? 'selected' : null}>
            <label htmlFor="rappi">
              <input
                type="checkbox"
                checked={this.state.rappiSelected}
                id="rappi"
                onChange={this.handleChange}
              />
              Rappi
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.cangurSelected ? 'selected' : null}>
            <label htmlFor="cangur">
              <input
                type="checkbox"
                checked={this.state.cangurSelected}
                id="cangur"
                onChange={this.handleChange}
              />
              Cangur
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.otroSelected ? 'selected' : null}>
            <label htmlFor="otro">
              <input
                type="checkbox"
                checked={this.state.otroSelected}
                id="otro"
                onChange={this.handleChange}
              />
              Otra
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderNextButton() {
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
        <button
          className={`
            ${
      this.state.glovoSelected ||
              this.state.moovaSelected ||
              this.state.pedidosYaSelected ||
              this.state.rappiSelected ||
              this.state.cangurSelected ||
              this.state.otroSelected
        ? 'remove-disable'
        : false
            } btn-next`}
          type="button"
          disabled={
            this.state.glovoSelected ||
            this.state.moovaSelected ||
            this.state.pedidosYaSelected ||
            this.state.rappiSelected ||
            this.state.cangurSelected ||
            this.state.otroSelected
              ? false
              : 'disabled'
          }
          onClick={this.handleNextQuestion}
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="questions">
        <h5 className="card-title">{this.props.question.title}</h5>
        {this.renderOptions()}
        {this.renderNextButton()}
      </div>
    );
  }
}

SelectApplicationToWork.propTypes = {
  question: PropTypes.object,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default SelectApplicationToWork;
