import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Answer from "./Answer";
import { selectAnswer, changePosibleAnswers } from "../../../actions/index";

class AnswerWrapper extends React.Component {
  renderAnswers(question) {
    let answers;

    if (question.posibleAnswers) {
      answers = question.posibleAnswers.map(answer => (
        <Answer
          key={answer.id}
          question={answer}
          type={question.type}
          onAnswerSelected={() =>
            this.props.selectAnswer(this.props.questions, question, answer)
          }
        />
      ));
    } else {
      answers = (
        <Answer
          question={question}
          type={question.type}
          handleDisablingOfNextButton={this.props.handleDisablingOfNextButton}
        />
      );
    }

    return answers;
  }

  render() {
    const { question } = this.props;

    if ("type" in question) {
      return this.renderAnswers(question);
    }
    return null;
  }
}

AnswerWrapper.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  question: PropTypes.object,
  selectAnswer: PropTypes.func,
  handleDisablingOfNextButton: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectAnswer,
      changePosibleAnswers
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(AnswerWrapper);
