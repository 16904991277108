import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { nextQuestionTramitesEQCuitValidated } from "../../../../actions/nextQuestion";
import { nextQuestionTramitesEQCuitNotValidated } from "../../../../actions/nextQuestion";
import Loader from "../../../Loader/index";
import ModalIfUserWantsToRedirectToLogin from "../../Partials/ModalIfUserWantsToRedirectToLogin/index";
import { validateCUIT as validateCUITAPI } from "../../../../apis";
import { notificationAlert } from "../../../../actions/notificationAlert";
class CuitDniForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cuit: "",
      dni: "",
      dniGenre: null,
      dniValidated: false,
      cuitWithDashes: "",
      errorMessage: false,
      showNextButton: false,
      isLoading: false,
      loadingMessage:
        "Por favor aguardá unos instantes, estamos verificando tus datos en AFIP",
      existsEmail: "",
      firstName: "",
      lastName: "",
      showModalIfUserWantsToRedirectToLogin: false,
      renderDocument: "cuit",
    };

    this.handleChangeCuit = this.handleChangeCuit.bind(this);
    this.handleChangeDni = this.handleChangeDni.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.handleCancelModalIfUserWantsToRedirectToLogin = this.handleCancelModalIfUserWantsToRedirectToLogin.bind(
      this
    );
    this.clearInput = this.clearInput.bind(this);
  }

  handleChangeCuit(event) {
    const VALUE = event.target.value.replace(/\D/g, "");
    let replaceValueWithDash;

    this.setState({
      cuit: VALUE,
      cuitWithDashes: VALUE,
      firstName: "",
      lastName: "",
      errorMessage: false,
      showNextButton: false,
    });

    if (VALUE.length === 11) {
      replaceValueWithDash = VALUE.replace(/(^\d{2})/g, "$&-").replace(
        /(\d{1}$)/g,
        "-$&"
      );

      this.setState(
        {
          cuit: VALUE,
          cuitWithDashes: replaceValueWithDash,
          cuitValidated: true,
        },
        () => {
          this.handleSubmit(null);
        }
      );
    }
  }
  handleChangeDni(event) {
    const VALUE = event.target.value.replace(/\D/g, "");
    this.setState({
      dni: VALUE,
      firstName: "",
      lastName: "",
      errorMessage: false,
      showNextButton: false,
      dniValidated: VALUE.length >= 7,
      dniGenre: VALUE.length < 7 ? null : this.state.dniGenre,
    });
  }
  onChangeGender(event) {
    let sum = 0;
    let cuit;
    let dni = this.state.dni.padStart(8, 0);
    let document;
    let dniGenre = event.target.value;
    let numberToMultiply = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    this.setState({ dniGenre });

    if (dniGenre === "masculino") {
      document = `20${dni}`;
    } else if (dniGenre === "femenino") {
      document = `27${dni}`;
    }
    for (const i in document) {
      sum = sum + document[i] * numberToMultiply[i];
    }

    if (sum % 11 === 0) {
      cuit = `${document}0`;
    } else if (sum % 11 === 1) {
      if (dniGenre === "masculino") {
        cuit = `23${dni}9`;
      } else {
        cuit = `23${dni}4`;
      }
    } else {
      cuit = `${document}${11 - (sum % 11)}`;
    }
    let replaceValueWithDash = cuit
      .replace(/(^\d{2})/g, "$&-")
      .replace(/(\d{1}$)/g, "-$&");

    this.setState(
      {
        cuit,
        cuitWithDashes: replaceValueWithDash,
        cuitValidated: true,
      },
      () => {
        this.handleSubmit(null);
      }
    );
  }

  handleSubmit(event) {
    let isLoading = false;
    let showNextButton = false;
    if (event !== null) event.preventDefault();
    if (this.state.renderDocument === "cuit") {
      document.getElementById("cuit").blur();
      this.validateCuit(this.state.cuit);
      isLoading = true;
      showNextButton = false;
    } else if (this.state.renderDocument === "dni" && this.state.dniGenre) {
      document.getElementById("dni").blur();
      this.validateCuit(this.state.cuit);
      isLoading = true;
      showNextButton = false;
    }

    this.setState({
      isLoading,
      showNextButton,
    });
  }

  handleCancelModalIfUserWantsToRedirectToLogin() {
    this.setState({
      showModalIfUserWantsToRedirectToLogin: false,
      cuit: "",
      dni: "",
      dniGenre: "",
      cuitWithDashes: "",
    });
  }

  clearInput() {
    this.setState({
      cuit: "",
      dni: "",
      dniGenre: null,
      cuitWithDashes: "",
      errorMessage: false,
    });

    document
      .getElementById(this.state.renderDocument === "cuit" ? "cuit" : "dni")
      .focus();
  }

  async validateCuit(cuit) {
    try {
      /* We send the questionnaire identifier, if the profile exist
      we added the profile to the tramites questionnaire.*/
      const tramites_identifier = localStorage.getItem("tramites_identifier");
      const form = { tramites_identifier, cuit };
      const response = await validateCUITAPI(form);

      if (response.data.status === "found") {
        this.setState({
          showNextButton: true,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      } else if (response.data.status === "not-found") {
        this.setState({
          errorMessage: true,
          existsEmail: "",
        });
      } else if (response.data.status === "already-exists") {
        this.setState({
          errorMessage: false,
          existsEmail: response.data.exists_email,
          showModalIfUserWantsToRedirectToLogin: true,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 500) {
        notificationAlert(
          "error",
          "Ups :(",
          "Disculpe las molestias. Surgio un error inesperado, intentelo mas tarde"
        );
        this.setState({
          isLoading: false,
          showNextButton: false,
          errorMessage: false,
          existsEmail: "",
        });
      } else {
        this.setState(
          {
            isLoading: false,
            showNextButton: false,
            errorMessage: false,
            existsEmail: "",
          },
          () =>
            this.props.nextQuestionTramitesEQCuitNotValidated(
              this.props.question,
              this.state.cuit
            )
        );
      }
    }
  }

  renderLoader() {
    if (this.state.isLoading)
      return <Loader loadingMessage={this.state.loadingMessage} />;
  }

  renderErrorMessage() {
    if (this.state.errorMessage)
      return (
        <React.Fragment>
          <p>
            El número que ingresaste no corresponde a{" "}
            {this.state.renderDocument === "cuit"
              ? "una CUIT activa"
              : "un DNI activo"}{" "}
            en AFIP. Si te equivocaste{" "}
            <button
              className="bold p-0 link"
              type="btn"
              onClick={this.clearInput}
            >
              <u>volvé a ingresarlo</u>
            </button>
            .
          </p>
        </React.Fragment>
      );
  }

  renderUserName() {
    if (this.state.lastName !== "" && this.state.firstName !== "")
      return (
        <React.Fragment>
          <p>Nombre: {this.state.firstName}</p>
          <p>Apellido: {this.state.lastName}</p>
        </React.Fragment>
      );
  }

  renderNextButton() {
    if (this.state.showNextButton) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className={`
              ${this.state.showNextButton ? "remove-disable" : false} btn-next`}
            type="button"
            disabled={this.state.showNextButton ? false : "disabled"}
            onClick={() =>
              this.props.nextQuestionTramitesEQCuitValidated(
                this.props.question,
                this.state.cuit
              )
            }
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    } else if (this.state.errorMessage) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-next"
            type="button"
            disabled={"disabled"}
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    }
  }

  renderInputField() {
    return (
      <>
        {this.state.renderDocument === "cuit" ? (
          <div className="input-wrapper">
            <div>
              <label htmlFor="cuit">
                Ingresa tu número de CUIL/CUIT
                <input
                  type="text"
                  id="cuit"
                  name="cuit"
                  placeholder="12-34567890-1"
                  minLength="11"
                  maxLength="13"
                  pattern="\d*"
                  value={this.state.cuitWithDashes}
                  onChange={this.handleChangeCuit}
                />
              </label>
            </div>
          </div>
        ) : (
          <>
            <div className="input-wrapper input--dni">
              <div>
                <label htmlFor="dni">
                  Ingresa tu número de DNI
                  <input
                    type="text"
                    id="dni"
                    name="dni"
                    placeholder="34567890"
                    minLength="7"
                    maxLength="8"
                    pattern="\d*"
                    value={this.state.dni}
                    onChange={this.handleChangeDni}
                  />
                </label>
              </div>
            </div>
            <div className="input-wrapper">
              <div>
                <label htmlFor="genre">
                  Seleccioná el genero de tu DNI
                  <Radio.Group
                    onChange={this.onChangeGender}
                    value={this.state.dniGenre}
                    style={{ display: "flex" }}
                    disabled={!this.state.dniValidated}
                  >
                    <Radio value={"masculino"}>Masculino</Radio>
                    <Radio value={"femenino"}>Femenino</Radio>
                  </Radio.Group>
                </label>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
  renderForm() {
    return (
      <div className="questions">
        {this.renderLoader()}
        <form onSubmit={this.handleSubmit}>
          {process.env.jwtToken}
          <h5 className="card-title">{this.props.question.title}</h5>
          <p className="card-subtitle">{this.props.question.description}</p>
          <Radio.Group
            value={this.state.renderDocument}
            onChange={(e) => {
              this.setState({ renderDocument: e.target.value });
            }}
            className="switch--document"
            buttonStyle={"solid"}
          >
            <Radio.Button value="cuit">CUIT / CUIL</Radio.Button>
            <Radio.Button value="dni">DNI</Radio.Button>
          </Radio.Group>
          {this.renderInputField()}
          {this.renderErrorMessage()}
          <ModalIfUserWantsToRedirectToLogin
            visible={this.state.showModalIfUserWantsToRedirectToLogin}
            handleCancel={this.handleCancelModalIfUserWantsToRedirectToLogin}
            existsEmail={this.state.existsEmail}
          />
          {this.renderUserName()}
          {this.renderNextButton()}
        </form>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

CuitDniForm.propTypes = {
  question: PropTypes.object,
  onBackQuestion: PropTypes.func,
  nextQuestionTramitesEQCuitValidated: PropTypes.func,
  nextQuestionTramitesEQCuitNotValidated: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nextQuestionTramitesEQCuitValidated,
      nextQuestionTramitesEQCuitNotValidated,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(CuitDniForm);
