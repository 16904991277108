import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
class HeaderBody extends React.Component {
  renderHeaderBody() {
    return (
      <div>
        <div className="card-header button-title-header-mobile">
          <button
            onClick={this.props.goBackArrow}
            className="go-back-payment-button-mobile"
          >
            <Icon type="arrow-left" />
          </button>
          <p className="card-title">Suscripción Mensual Gestorando</p>
        </div>
        <div className="card-body">
          <ul>
            {!this.props.payingInscription ? (
              <>
                <li>Instrucciones para obtener la CUIT y clave fiscal</li>
                <li>Inscripción al Monotributo e IIBB</li>
                <li>Alta a la facturación</li>
                <li>Instrucciones para adherirte a la obra social</li>
                <li>Asesoramiento integral</li>
              </>
            ) : (
              <>
                <li>Inscripción al Monotributo</li>
                <li>Alta a la facturación para Time Jobs</li>
                <li>Emisión de facturas para Time Jobs</li>
              </>
            )}
            <hr />
            <li className="price">
              {this.props.basePriceCreditCard !== "" &&
              this.props.paymentMethod === "btn-credit-card" ? (
                <p>
                  Precio {this.props.payingInscription ? "final" : "por mes"}{" "}
                  (*): <span>$ {this.props.basePriceCreditCard}</span>
                </p>
              ) : (
                <p>
                  Precio (*): <span>$ {this.props.basePriceOtherPayment}</span>
                </p>
              )}
              {this.props.installments ? (
                <p className="detalle">
                  {this.props.installments} cuotas de{" "}
                  <span>$ {this.props.total_cuotas_amount}</span>
                </p>
              ) : (
                false
              )}
              {this.props.total_amount ? (
                <p className="total">
                  Costo total <span>$ {this.props.total_amount}</span>
                </p>
              ) : (
                false
              )}
            </li>
            <hr />
            {this.props.ptf ? (
              <li className="tax">
                PTF: $ {this.props.ptf} - {this.props.tea} - {this.props.cft}
              </li>
            ) : (
              false
            )}
          </ul>
          {this.props.show_mercadopago_logo ? (
            <div>
              <p className="text-center">Pago procesado por</p>
              <img
                className="mp-logo mb-3"
                src={this.props.logoMercadopago}
                alt="Logo de Mercado Pago"
                title="Logo de Mercado Pago"
              />
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }

  render() {
    return this.renderHeaderBody();
  }
}

HeaderBody.propTypes = {
  basePriceOtherPayment: PropTypes.string,
  installments: PropTypes.string,
  total_amount: PropTypes.string,
  total_cuotas_amount: PropTypes.string,
  ptf: PropTypes.string,
  tea: PropTypes.string,
  cft: PropTypes.string,
  logoMercadopago: PropTypes.string,
  show_mercadopago_logo: PropTypes.bool,
  payingInscription: PropTypes.bool,
};

export default HeaderBody;
