import React from 'react';
import Loader from '../../Loader/index';
import Nav from '../../Nav/index';
import { createHistoryBrowser } from '../../../actions/createHistoryBrowser';
import { forgotPassword } from '../../../apis';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: false,
      emailError: '',
      emailSent: false,
      isAllValid: false,
      buttonValid: false,
      isLoading: false,
      loadingMessage: 'Enviandote un e-mail...',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
  }

  componentWillMount() {
    document.title = 'Gestorando | Restablecé tu contraseña';
  }

  handleChange(e) {
    const { name } = e.target;
    const value = e.target.value.replace(/\s/g, '');

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleEnterKey(e) {
    if (this.state.buttonValid) {
      if (e.key === 'Enter') {
        this.handleSendEmail();
      }
    }
  }

  validateField(fieldName, value) {
    let { emailValid } = this.state;

    if (fieldName === 'email') {
      emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    this.setState({ emailValid });

    if (emailValid) {
      this.setState({
        isAllValid: true,
        buttonValid: true,
        emailError: '',
      });
    } else {
      this.setState({
        isAllValid: false,
        buttonValid: false,
      });
    }
  }

  async handleSendEmail() {
    this.setState({
      isLoading: false,
    });

    try {
      const form = { email: this.state.email };
      const response = await forgotPassword(form);

      if (response.data.success) {
        this.setState({
          isLoading: false,
          emailSent: true,
        });
      } else {
        this.setState({
          isLoading: false,
          emailSent: false,
          emailValid: false,
          emailError: response.data.error,
        });
      }
    } catch (error) {
      alert('Surgio un error, vuelve a intentarlo');
      createHistoryBrowser('/ingresar');
    }
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : (
      false
    );
  }

  renderAlert() {
    return this.state.emailSent ? (
      <div className="alert alert-success" role="alert">
        ¡Te hemos enviado un correo con las intrucciones, revisa tu casilla para
        restablecer tu contraseña!
      </div>
    ) : (
      false
    );
  }

  renderForgotPassword() {
    return (
      <div>
        <Nav />
        <div className="forgot-password box-styles">
          {this.renderLoader()}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">
                Restablec&eacute; tu contrase&ntilde;a
              </h2>
              <hr />
              {this.renderAlert()}
              <div>
                <div className="input-wrapper">
                  <div
                    className={`${
                      this.state.emailValid
                        ? 'valid'
                        : [this.state.emailError ? 'invalid' : '']
                    }`}
                  >
                    <p>Email</p>
                    <label htmlFor="email">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKey}
                        tabIndex="0"
                      />
                      <p className="error-message">{this.state.emailError}</p>
                      <i className="fas fa-user" />
                      <i className="fas fa-check-circle" />
                      <i className="fas fa-times-circle" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="btns-next-back-wrapper">
                <button
                  className={`
                  ${this.state.isAllValid ? 'remove-disable' : false} btn-next`}
                  type="btn"
                  onClick={this.handleSendEmail}
                  disabled={this.state.buttonValid ? null : true}
                >
                  Restablecer
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderForgotPassword();
  }
}

export default ForgotPassword;
