import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reducers from "./reducers/";
import "sanitize.css/sanitize.css";
import "antd/dist/antd.css";
import "antd-mobile/dist/antd-mobile.css";
import "./assets/scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { createStore } from "redux";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";

// Displaying message on the console
console.log(
  "%c -- Bienvenido señor curioso --",
  "font-size: 16px; color: #2670b3"
);

const store = createStore(reducers);

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
