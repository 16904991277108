import React from 'react';
import PropTypes from 'prop-types';

class HeaderTitle extends React.Component {
  renderHeaderTitle() {
    return (
      <div className={`card-title ${this.props.classDisplay}`}>
        <hr
          className={this.props.classDisplay === 'd-md-none' ? null : 'd-none'}
        />
        <h5>Pago procesado por </h5>
        <img
          className="img-fluid"
          src={this.props.logoMercadopago}
          alt="Logo de Mercado Pago"
          title="Mercado Pago"
        />
        <hr
          className={this.props.classDisplay === 'd-md-none' ? 'd-none' : null}
        />
      </div>
    );
  }

  render() {
    return this.renderHeaderTitle();
  }
}

HeaderTitle.propTypes = {
  classDisplay: PropTypes.string,
  logoMercadopago: PropTypes.string,
};

export default HeaderTitle;
