import moment from "moment";
import {
  HANDLE_NEXT_STEP,
  HANDLE_PREVIOUS_STEP,
  HANDLE_SELECT_CONCEPT_CHANGE,
  HANDLE_DATE_PICKER_CHANGE,
  HANDLE_DATE_RANGE_CHANGE,
  HANDLE_CHECKBOX_SALE_CONDITION_CHANGE,
  HANDLE_ADD_ITEM,
  HANDLE_REMOVE_ITEM,
  HANDLE_INPUT_ITEM_CHANGE,
  HANDLE_INPUT_COMERCIAL_ADDRESS_CHANGE,
  HANDLE_INPUT_NUMBER_DOCUMENT_CHANGE,
  HANDLE_SELECT_TYPE_DOCUMENT_CHANGE,
  HANDLE_INPUT_SOCIAL_NAME_CHANGE,
  HANDLE_CHECKBOX_EMAIL_CLIENT_CHANGE,
  HANDLE_INPUT_EMAIL_CLIENT_CHANGE,
  HANDLE_RADIO_COMERCIAL_ADDRESS_CHANGE,
  HANDLE_SELECT_GLOVO_PERIOD_INVOICE_CHANGE,
  HANDLE_RADIO_MULTIPLE_USERS_FOUND_BY_DOCUMENT_CHANGE,
  HANDLE_CLEAR_SOCIAL_NAME_AND_COMERCIAL_ADDRESS,
  FETCH_USER_DATA,
  FETCH_INVOICE_GENERATION,
  DISABLE_ALL_FIELDS,
  ENABLE_ALL_FIELDS,
  SHOW_ERRORS_GENERATED_BY_GENERATING_INVOICE,
  CHECK_GENERATED_INVOICE_STATUS,
  FETCH_CHECK_EMAIL_VALIDATION,
  SHOW_MODAL_WHEN_INVOICE_CREATED,
  DISABLE_EMAIL_FIELDS,
  ENABLE_EMAIL_FIELDS,
} from "../../../../../actions/Dashboard/ui/emitirFactura/facturaC/types";
import {
  formatNumberTextToFloat,
  formatNumberTextToThousands,
} from "../../../../../utils/formatNumberText";
import { scrollWindowToTop } from "../../../../../actions/scrollWindowToTop";

const facturaCState = {
  form: {
    firstStep: {
      fieldOptions: {
        conceptOptions: [
          {
            text: "Productos",
            disabled: false,
          },
          {
            text: "Servicios",
            disabled: false,
          },
          {
            text: "Productos y Servicios",
            disabled: false,
          },
          {
            text: "Alquiler",
            disabled: false,
          },
        ],
        saleConditionOptions: [
          {
            label: "Efectivo o Transferencia bancaria",
            value: "Efectivo o Transferencia bancaria",
            disabled: false,
          },
          {
            label: "Cheque",
            value: "Cheque",
            disabled: false,
          },
          {
            label: "Tarjeta de Crédito",
            value: "Tarjeta de Crédito",
            disabled: false,
          },
          {
            label: "Ticket",
            value: "Ticket",
            disabled: false,
          },
          {
            label: "Tarjeta de Débito",
            value: "Tarjeta de Débito",
            disabled: false,
          },
          {
            label: "Otra",
            value: "Otra",
            disabled: false,
          },
          {
            label: "Cuenta Corriente",
            value: "Cuenta Corriente",
            disabled: false,
          },
        ],
        saleConditionCreditCardOptions: [
          {
            text: "American Express",
            mask: [
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
            ],
            maxLength: 15,
            disabled: false,
          },
          {
            text: "Visa",
            mask: [
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ],
            maxLength: 16,
            disabled: false,
          },
          {
            text: "Mastercard",
            mask: [
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ],
            maxLength: 16,
            disabled: false,
          },
          {
            text: "Credencial",
            disabled: false,
          },
          {
            text: "Carta Franca",
            disabled: false,
          },
          {
            text: "Cabal",
            disabled: false,
          },
          {
            text: "Diners",
            disabled: false,
          },
          {
            text: "Tarjeta Shopping",
            disabled: false,
          },
          {
            text: "Tarjeta Naranja",
            disabled: false,
          },
          {
            text: "Otra",
            disabled: false,
          },
        ],
      },
      fieldErrorMessages: {
        invoiceDateErrorMessage: "",
        invoiceDateRangeErrorMessage: "",
        conceptErrorMessage: "",
        saleConditionCreditCardErrorMessage: "",
        saleConditionCreditCardTypeErrorMessage: "",
      },
      fieldValues: {
        invoiceDateValue: moment(),
        invoiceDateRangeValue: [moment(), moment()],
        conceptValue: "Servicios",
        saleConditionValue: ["Efectivo o Transferencia bancaria"],
        saleConditionCreditCardValue: null,
        saleConditionCreditCardTypeValue: "Visa",
        saleConditionCreditCardTypeMask: [],
      },
      fieldValidations: {
        invoiceDateValid: true,
        invoiceDateRangeValid: true,
        conceptValid: true,
        saleConditionValid: true,
        saleConditionCreditCardValid: false,
        saleConditionCreditCardTypeValid: false,
      },
    },
    secondStep: {
      fieldErrorMessages: {
        saleConditionErrorMessage: "",
      },
      fieldValues: {
        items: [
          {
            id: 0,
            codeItem: 1,
            descriptionItem: null,
            quantityItem: "1",
            priceItem: null,
            subtotalItem: null,
            codeItemValid: null,
            descriptionItemValid: null,
            quantityItemValid: null,
            priceItemValid: null,
            allValid: false,
          },
        ],
      },
    },
    thirdStep: {
      fieldOptions: {
        typeDocumentOptions: [
          {
            text: "CUIT",
            mask: [
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
            ],
            maxLength: 13,
            disabled: false,
          },
          {
            text: "DNI",
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maxLength: 8,
            disabled: false,
          },
        ],
        comercialAddressOptions: [],
        glovoPeriodInvoiceOptions: null,
        multipleUsersFoundByDocumentOptions: [],
        posibleEmailsOptions: [
          "@gmail.com",
          "@hotmail.com",
          "@outlook.com",
          "@yahoo.com",
          "@live.com",
        ],
      },
      fieldErrorMessages: {
        numberDocumentErrorMessage: "",
        typeDocumentErrorMessage: "",
        socialNameErrorMessage: "",
        comercialAddressErrorMessage: "",
        emailClientCheckboxErrorMessage: "",
        emailClientInputErrorMessage: "",
        multipleUsersFoundByDocumentErrorMessage: "",
      },
      fieldValues: {
        numberDocumentValue: null,
        typeDocumentValue: "CUIT",
        socialNameValue: null,
        comercialAddressRadioValue: null,
        glovoPeriodInvoiceValue: null,
        comercialAddressInputValue: null,
        numberDocumentMask: [
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
        ],
        emailClientCheckboxValue: null,
        emailClientInputValue: null,
        autoCompleteDataSourceEmail: [
          "@gmail.com",
          "@hotmail.com",
          "@outlook.com",
          "@yahoo.com",
          "@live.com",
        ],
        clientFetchedData: {},
        multipleUsersFoundByDocumentValue: null,
      },
      fieldValidations: {
        numberDocumentValid: false,
        typeDocumentValid: true,
        socialNameValid: false,
        comercialAddressRadioValid: false,
        comercialAddressInputValid: false,
        emailClientCheckboxValid: false,
        emailClientInputValid: false,
        multipleUserFoundByDocumentValid: false,
      },
      fieldsDisabled: {
        numberDocumentIsDisabled: false,
        typeDocumentIsDisabled: false,
        socialNameIsDisabled: true,
        comercialAddressIsDisabled: true,
        emailClientInputIsDisabled: false,
        multipleUsersFoundByDocumentDisabled: false,
      },
      fieldsFetching: {
        numberDocumentIsFetching: false,
        typeDocumentIsFetching: false,
        socialNameIsFetching: false,
        comercialAddressIsFetching: false,
        emailClientInputIsFetching: false,
      },
    },
    disableAllFields: false,
    totalImport: 0,
    limitImportToNotRequireDniOrCuit: 10000,
    requireDniOrCuitOnThirdStep: false,
    showSummary: false,
    errorsGeneratedByGeneratingInvoice: false,
    emailFetchedIsValid: null,
    invoiceIdCreated: {
      value: null,
      status: null,
    },
    steps: [
      {
        title: "Datos de emisión",
        number: 1,
      },
      {
        title: "Datos del servicio",
        number: 2,
      },
      {
        title: "Datos del cliente",
        number: 3,
      },
      {
        title: "Confirmar datos",
        number: 4,
      },
    ],
    showModalWhenInvoiceCreated: false,
    currentStep: 0,
  },
};

function checkIfItemsAreValid(items) {
  let itemFiltered = null;

  items.forEach((item) => {
    [itemFiltered] = items.filter((v) => item.id === v.id);

    // If empty then set to false
    itemFiltered.codeItemValid =
      !/^\s*$/.test(itemFiltered.codeItem) && itemFiltered.codeItem !== null;
    itemFiltered.descriptionItemValid =
      !/^\s*$/.test(itemFiltered.descriptionItem) &&
      itemFiltered.descriptionItem !== null;
    itemFiltered.quantityItemValid =
      !/^\s*$/.test(itemFiltered.quantityItem) &&
      (itemFiltered.quantityItem === null
        ? false
        : formatNumberTextToFloat(itemFiltered.quantityItem) > 0);
    itemFiltered.priceItemValid =
      !/^\s*$/.test(itemFiltered.priceItem) && itemFiltered.priceItem !== null;
    // If all field are valid
    itemFiltered.allValid =
      itemFiltered.codeItemValid &&
      itemFiltered.descriptionItemValid &&
      itemFiltered.quantityItemValid &&
      itemFiltered.priceItemValid;
  });

  return items;
}

function getMaskOfTypeDocument(options, docValue) {
  return options.find((option) => docValue === option.text).mask;
}

function calculateItemsTotalImport(items, limitImportToNotRequireDniOrCuit) {
  let totalImport = 0;

  items.forEach(
    (item) =>
      (totalImport +=
        item.subtotalItem === null
          ? 0
          : formatNumberTextToFloat(item.subtotalItem))
  );

  totalImport = formatNumberTextToThousands(totalImport.toFixed(2));

  return {
    totalImport,
    items,
    requireDniOrCuitOnThirdStep:
      formatNumberTextToFloat(totalImport) >= limitImportToNotRequireDniOrCuit,
    showSummary: true,
  };
}

export const facturaC = (prevState = facturaCState, action) => {
  let value = null;
  let name = null;
  let errorMessage = "";
  let valid = false;
  let lastItem = null;
  let newItem = null;
  let modifiedItems = null;
  let itemFiltered = null;
  let modifyItemsWithValidations = null;
  let itemsTotalImportObject = null;
  let mask = [];
  let autoCompleteDataSourceEmail = [];
  let { socialNameValid } = prevState.form.thirdStep.fieldValidations;
  let { socialNameIsDisabled } = prevState.form.thirdStep.fieldsDisabled;
  let { socialNameValue } = prevState.form.thirdStep.fieldValues;
  let { comercialAddressRadioValue } = prevState.form.thirdStep.fieldValues;
  let { glovoPeriodInvoiceValue } = prevState.form.thirdStep.fieldValues;
  let { glovoPeriodInvoiceOptions } = prevState.form.thirdStep.fieldOptions;
  let { comercialAddressIsDisabled } = prevState.form.thirdStep.fieldsDisabled;
  let { comercialAddressOptions } = prevState.form.thirdStep.fieldOptions;

  let {
    multipleUsersFoundByDocumentOptions,
  } = prevState.form.thirdStep.fieldOptions;
  let response = null;

  switch (action.type) {
    case HANDLE_NEXT_STEP:
      scrollWindowToTop();

      return {
        form: {
          ...prevState.form,
          currentStep: prevState.form.currentStep + 1,
        },
      };
    case HANDLE_PREVIOUS_STEP:
      scrollWindowToTop();

      return {
        form: {
          ...prevState.form,
          currentStep: prevState.form.currentStep - 1,
        },
      };
    case HANDLE_SELECT_CONCEPT_CHANGE:
      value = action.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value === undefined) {
        errorMessage = "Por favor selecciona un concepto";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          firstStep: {
            ...prevState.form.firstStep,
            fieldErrorMessages: {
              ...prevState.form.firstStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.firstStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.firstStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
          steps: [
            {
              title: "Datos de emisión",
              number: 1,
            },
            {
              title:
                value === "Productos"
                  ? "Datos del producto"
                  : value === "Servicios"
                  ? "Datos del servicio"
                  : value === "Productos y Servicios"
                  ? "Datos del producto/servicio"
                  : value === "Alquiler"
                  ? "Datos del alquiler"
                  : "Datos de la operación",
              number: 2,
            },
            {
              title: "Datos del cliente",
              number: 3,
            },
            {
              title: "Confirmar datos",
              number: 4,
            },
          ],
        },
      };
    case HANDLE_DATE_PICKER_CHANGE:
      value = action.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value === null) {
        errorMessage = "Por favor elige una fecha de comprobante";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          firstStep: {
            ...prevState.form.firstStep,
            fieldErrorMessages: {
              ...prevState.form.firstStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.firstStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.firstStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case HANDLE_DATE_RANGE_CHANGE:
      value = action.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value.length === 0) {
        errorMessage = "Por favor elige una fecha del servicio";
      } else {
        valid = true;

        if (name === "invoiceDateRangeMobileStart") {
          value = [
            value,
            prevState.form.firstStep.fieldValues.invoiceDateRangeValue[1],
          ];
        } else if (name === "invoiceDateRangeMobileFinish") {
          value = [
            prevState.form.firstStep.fieldValues.invoiceDateRangeValue[0],
            value,
          ];
        }
      }

      if (typeof value === "object") {
        return {
          form: {
            ...prevState.form,
            firstStep: {
              ...prevState.form.firstStep,
              fieldErrorMessages: {
                ...prevState.form.firstStep.fieldErrorMessages,
                invoiceDateRangeErrorMessage: errorMessage,
              },
              fieldValues: {
                ...prevState.form.firstStep.fieldValues,
                invoiceDateRangeValue: value,
              },
              fieldValidations: {
                ...prevState.form.firstStep.fieldValidations,
                invoiceDateRangeValid: valid,
              },
            },
          },
        };
      }
      return {
        form: {
          ...prevState.form,
          firstStep: {
            ...prevState.form.firstStep,
            fieldErrorMessages: {
              ...prevState.form.firstStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.firstStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.firstStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case HANDLE_CHECKBOX_SALE_CONDITION_CHANGE:
      value = action.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value.length === 0) {
        errorMessage = "Por favor selecciona un metodo de pago al menos";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          firstStep: {
            ...prevState.form.firstStep,
            fieldErrorMessages: {
              ...prevState.form.firstStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.firstStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.firstStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case HANDLE_ADD_ITEM:
      lastItem = prevState.form.secondStep.fieldValues.items.length - 1;
      newItem = {
        id: prevState.form.secondStep.fieldValues.items[lastItem].id + 1,
        codeItem:
          prevState.form.secondStep.fieldValues.items[lastItem].codeItem
            .length === 0
            ? 1
            : parseInt(
                prevState.form.secondStep.fieldValues.items[lastItem].codeItem,
                10
              ) + 1,
        descriptionItem: null,
        quantityItem: "1",
        priceItem: null,
        subtotalItem: null,
        codeItemValid: null,
        descriptionItemValid: null,
        quantityItemValid: null,
        priceItemValid: null,
        allValid: false,
      };
      modifyItemsWithValidations = checkIfItemsAreValid([
        ...prevState.form.secondStep.fieldValues.items,
        newItem,
      ]);
      itemsTotalImportObject = calculateItemsTotalImport(
        modifyItemsWithValidations,
        prevState.form.limitImportToNotRequireDniOrCuit
      );

      return {
        form: {
          ...prevState.form,
          secondStep: {
            ...prevState.form.secondStep,
            fieldValues: {
              ...prevState.form.secondStep.fieldValues,
              items: modifyItemsWithValidations,
            },
          },
          ...itemsTotalImportObject,
        },
      };
    case HANDLE_REMOVE_ITEM:
      modifiedItems = prevState.form.secondStep.fieldValues.items.filter(
        (item) => item.id !== action.id
      );
      modifyItemsWithValidations = checkIfItemsAreValid(modifiedItems);
      itemsTotalImportObject = calculateItemsTotalImport(
        modifyItemsWithValidations,
        prevState.form.limitImportToNotRequireDniOrCuit
      );

      return {
        form: {
          ...prevState.form,
          secondStep: {
            ...prevState.form.secondStep,
            fieldValues: {
              ...prevState.form.secondStep.fieldValues,
              items: modifyItemsWithValidations,
            },
          },
          ...itemsTotalImportObject,
        },
      };
    case HANDLE_INPUT_ITEM_CHANGE:
      value = action.value.target.value;
      name = action.name;
      itemFiltered = prevState.form.secondStep.fieldValues.items.filter(
        (item) => item.id === action.id
      )[0];

      if (name === "quantityItem" || name === "priceItem") {
        if ((itemFiltered[name] = name === "quantityItem")) {
          // 1) If starts with 0, has number next to it and a comma then don't format
          // 2) If starts with 0, has number next to it, and has another number then remove the leading 0's to empty
          // 3) Don't modify the value
          if (/^0[0-9][,]/.test(value.replace(/[.]/g, ""))) {
            itemFiltered[name] = value; // 1)
          } else if (/^0[0-9][0-9]/.test(value.replace(/[.]/g, ""))) {
            itemFiltered[name] = value.replace(/^0+/g, ""); // 2)
          } else {
            itemFiltered[name] = value; // 3)
          }
        } else if (/^0[0-9][,]/.test(value.replace(/[ $.]/g, ""))) {
          itemFiltered[name] = value; // 1)
        } else if (/^0[0-9][0-9]/.test(value.replace(/[ $.]/g, ""))) {
          itemFiltered[name] = value.replace(/[$] 0+/g, ""); // 2)
        } else {
          itemFiltered[name] = value; // 3)
        }

        const priceItem = itemFiltered.priceItem
          ? formatNumberTextToFloat(itemFiltered.priceItem)
          : itemFiltered.priceItem;
        const quantityItem = itemFiltered.quantityItem
          ? formatNumberTextToFloat(itemFiltered.quantityItem)
          : itemFiltered.quantityItem;
        let result = 0;

        // Alowing only 2 decimals, this converts the float number to string
        result = (priceItem * quantityItem).toFixed(2);
        // Converting to string and adding thousand format
        result = formatNumberTextToThousands(result);

        itemFiltered.subtotalItem = result;
      } else {
        itemFiltered[name] = value;
      }

      modifyItemsWithValidations = checkIfItemsAreValid(
        prevState.form.secondStep.fieldValues.items
      );
      itemsTotalImportObject = calculateItemsTotalImport(
        modifyItemsWithValidations,
        prevState.form.limitImportToNotRequireDniOrCuit
      );

      return {
        form: {
          ...prevState.form,
          ...itemsTotalImportObject,
        },
      };
    case HANDLE_INPUT_COMERCIAL_ADDRESS_CHANGE:
      value = action.value.target.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value === "") {
        errorMessage = "El campo no puede estar vacio";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              comercialAddressErrorMessage: errorMessage,
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
            fieldsFetching: {
              ...prevState.form.thirdStep.fieldsFetching,
            },
          },
        },
      };
    case HANDLE_INPUT_NUMBER_DOCUMENT_CHANGE:
      value = action.value.target.value.replace(/\s/g, "");
      name = action.name;
      errorMessage = "";
      valid = false;

      // If the value is different from what we actually have
      if (value !== prevState.form.thirdStep.fieldValues.numberDocumentValue) {
        if (value === "") {
          if (prevState.form.requireDniOrCuitOnThirdStep) {
            errorMessage = "El campo no puede estar vacio";
          } else {
            errorMessage = "";
          }
        } else if (
          value.length !==
          prevState.form.thirdStep.fieldValues.numberDocumentMask.length
        ) {
          errorMessage = `El campo requiere ${prevState.form.thirdStep.fieldValues.numberDocumentMask.length} digitos`;
        } else {
          valid = true;
        }

        return {
          form: {
            ...prevState.form,
            thirdStep: {
              ...prevState.form.thirdStep,
              fieldOptions: {
                ...prevState.form.thirdStep.fieldOptions,
                comercialAddressOptions: [],
                multipleUsersFoundByDocumentOptions: [],
              },
              fieldErrorMessages: {
                ...prevState.form.thirdStep.fieldErrorMessages,
                socialNameErrorMessage: "",
                comercialAddressErrorMessage: "",
                [`${name}ErrorMessage`]: errorMessage,
              },
              fieldValues: {
                ...prevState.form.thirdStep.fieldValues,
                socialNameValue: null,
                comercialAddressInputValue: null,
                comercialAddressRadioValue: null,
                multipleUsersFoundByDocumentValue: null,
                [`${name}Value`]: value,
              },
              fieldsDisabled: {
                ...prevState.form.thirdStep.fieldsDisabled,
                numberDocumentIsDisabled: valid,
                socialNameIsDisabled: true,
                comercialAddressIsDisabled: true,
              },
              fieldValidations: {
                ...prevState.form.thirdStep.fieldValidations,
                socialNameValid: false,
                comercialAddressRadioValid: false,
                comercialAddressInputValid: false,
                multipleUserFoundByDocumentValid: false,
                [`${name}Valid`]: valid,
              },
              fieldsFetching: {
                ...prevState.form.thirdStep.fieldsFetching,
                numberDocumentIsFetching: valid,
                typeDocumentIsFetching: valid,
                socialNameIsFetching: valid,
                comercialAddressIsFetching: valid,
              },
            },
          },
        };
      }

      return {
        form: {
          ...prevState.form,
        },
      };
    case HANDLE_SELECT_TYPE_DOCUMENT_CHANGE:
      value = action.value;
      name = action.name;
      errorMessage = "";
      valid = false;
      mask = getMaskOfTypeDocument(
        prevState.form.thirdStep.fieldOptions.typeDocumentOptions,
        value
      );

      if (value === undefined) {
        errorMessage = "Por favor selecciona un tipo de documento";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              numberDocumentErrorMessage: "",
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldOptions: {
              ...prevState.form.thirdStep.fieldOptions,
              comercialAddressOptions: [],
              multipleUsersFoundByDocumentOptions: [],
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              numberDocumentValue: null,
              numberDocumentMask: mask,
              socialNameValue: null,
              comercialAddressRadioValue: null,
              comercialAddressInputValue: null,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              numberDocumentValid: false,
              socialNameValid: false,
              comercialAddressRadioValid: false,
              comercialAddressInputValid: false,
              [`${name}Valid`]: valid,
            },
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              [`${name}IsDisabled`]: !valid,
              socialNameIsDisabled: true,
              comercialAddressIsDisabled: true,
            },
          },
        },
      };
    case HANDLE_INPUT_SOCIAL_NAME_CHANGE:
      value = action.value.target.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value === "") {
        errorMessage = "El campo no puede estar vacio";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case HANDLE_CHECKBOX_EMAIL_CLIENT_CHANGE:
      value = action.value.target.checked;
      name = action.name;
      errorMessage = "";

      if (value) {
        if (prevState.form.thirdStep.fieldValues.emailClientInputValue) {
          if (
            !prevState.form.thirdStep.fieldValues.emailClientInputValue.match(
              /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
            )
          ) {
            errorMessage = "No cumple el formato de email";
          }
        }
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              emailClientInputErrorMessage: errorMessage,
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              [`${name}Valid`]: value,
            },
          },
        },
      };
    case HANDLE_INPUT_EMAIL_CLIENT_CHANGE:
      value = action.value.toLowerCase();
      name = action.name;
      errorMessage = "";
      valid = false;
      autoCompleteDataSourceEmail =
        !action.value || action.value.indexOf("@") >= 0
          ? []
          : prevState.form.thirdStep.fieldOptions.posibleEmailsOptions.map(
              (email) => `${action.value}${email}`
            );

      if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        valid = true;
      } else {
        errorMessage = "No cumple el formato de email";
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              [`${name}ErrorMessage`]: errorMessage,
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
              autoCompleteDataSourceEmail,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              // [`${name}Valid`]: valid && prevState.form.emailFetchedIsValid,
            },
          },
          emailFetchedIsValid: valid && prevState.form.emailFetchedIsValid,
        },
      };
    case HANDLE_RADIO_COMERCIAL_ADDRESS_CHANGE:
      value = action.value.target.value;
      name = action.name;
      errorMessage = "";
      valid = false;

      if (value === "Otro") {
        errorMessage = "Ingrese el domicilio comercial";
      } else {
        valid = true;
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              comercialAddressErrorMessage: errorMessage,
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case HANDLE_SELECT_GLOVO_PERIOD_INVOICE_CHANGE:
      value = action.value;
      name = action.name;
      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              [`${name}Value`]: value,
            },
          },
        },
      };
    case HANDLE_RADIO_MULTIPLE_USERS_FOUND_BY_DOCUMENT_CHANGE:
      value = action.value.target.value;
      name = action.name;
      valid = true;

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              comercialAddressIsDisabled: true,
              socialNameIsDisabled: true,
            },
            fieldOptions: {
              ...prevState.form.thirdStep.fieldOptions,
              comercialAddressOptions: [
                ...prevState.form.thirdStep.fieldOptions.multipleUsersFoundByDocumentOptions
                  .find((user) => user.docNumber === value)
                  .comercialAddress.map(
                    (domicilio) =>
                      `${
                        domicilio.direccion ? `${domicilio.direccion} - ` : ""
                      }${
                        domicilio.localidad ? `${domicilio.localidad} - ` : ""
                      }${domicilio.provincia ? domicilio.provincia : ""}`
                  ),
                "Otro",
              ],
            },
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              socialNameValue: prevState.form.thirdStep.fieldOptions.multipleUsersFoundByDocumentOptions.find(
                (user) => user.docNumber === value
              ).name,
              [`${name}Value`]: value,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              socialNameValid: valid,
              [`${name}Valid`]: valid,
            },
          },
        },
      };
    case FETCH_USER_DATA:
      response = action.payload ? Object.entries(action.payload) : null;

      if (response) {
        if (response.length > 0) {
          // If we found at least 1 user
          if (response.length > 1) {
            // If did find more than 1 person then create multiple options to open a modal
            multipleUsersFoundByDocumentOptions = response.map((user) => ({
              name: user[1].denominacion,
              docNumber: user[0],
              comercialAddress: user[1].domicilios,
            }));
            comercialAddressIsDisabled = false;
            socialNameIsDisabled = false;
          } else {
            // If not more than 1 person then just display the social name
            socialNameValue = response[0][1].denominacion;
            socialNameValid = true;
            comercialAddressOptions = response[0][1].domicilios.map(
              (domicilio) =>
                `${domicilio.direccion ? `${domicilio.direccion} - ` : ""}${
                  domicilio.localidad ? `${domicilio.localidad} - ` : ""
                }${domicilio.provincia ? domicilio.provincia : ""}`
            );
            // TODO FEATURE
            // comercialAddressOptions = [...comercialAddressOptions, 'Otro'];
            comercialAddressOptions = [...comercialAddressOptions];
            glovoPeriodInvoiceOptions = response[0][1].periods;
            if (glovoPeriodInvoiceOptions) {
              glovoPeriodInvoiceValue = glovoPeriodInvoiceOptions[0].id;
            } else {
              glovoPeriodInvoiceValue = null;
            }

            if (comercialAddressOptions.length === 1) {
              comercialAddressRadioValue = comercialAddressOptions[0];
            } else {
              comercialAddressIsDisabled = false;
            }
          }
        } else {
          // If didn't find anything then allow open text fields
          glovoPeriodInvoiceValue = null;
          glovoPeriodInvoiceOptions = null;
          comercialAddressIsDisabled = false;
          socialNameIsDisabled = false;
        }

        return {
          form: {
            ...prevState.form,
            thirdStep: {
              ...prevState.form.thirdStep,
              fieldValues: {
                ...prevState.form.thirdStep.fieldValues,
                comercialAddressRadioValue,
                glovoPeriodInvoiceValue,
                socialNameValue,
              },
              fieldOptions: {
                ...prevState.form.thirdStep.fieldOptions,
                multipleUsersFoundByDocumentOptions,
                comercialAddressOptions,
                glovoPeriodInvoiceOptions,
              },
              fieldsDisabled: {
                ...prevState.form.thirdStep.fieldsDisabled,
                numberDocumentIsDisabled: false,
                comercialAddressIsDisabled,
                socialNameIsDisabled,
              },
              fieldValidations: {
                ...prevState.form.thirdStep.fieldValidations,
                numberDocumentValid: true,
                comercialAddressRadioValid: comercialAddressRadioValue !== null,
                socialNameValid,
              },
              fieldsFetching: {
                ...prevState.form.thirdStep.fieldsFetching,
                numberDocumentIsFetching: false,
                typeDocumentIsFetching: false,
                socialNameIsFetching: false,
                comercialAddressIsFetching: false,
              },
            },
          },
        };
      }
      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              numberDocumentValid: false,
            },
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              numberDocumentIsDisabled: false,
            },
            fieldsFetching: {
              ...prevState.form.thirdStep.fieldsFetching,
              numberDocumentIsFetching: false,
              typeDocumentIsFetching: false,
              socialNameIsFetching: false,
              comercialAddressIsFetching: false,
            },
          },
        },
      };
    case FETCH_INVOICE_GENERATION:
      return {
        form: {
          ...prevState.form,
          invoiceIdCreated: {
            ...prevState.form.invoiceIdCreated,
            value: action.payload.invoice_id,
          },
        },
      };
    case CHECK_GENERATED_INVOICE_STATUS:
      if (action.payload.status === "completed") {
        // Reset all values if successfully generated
        return {
          form: {
            ...prevState.form,
            firstStep: {
              ...prevState.form.firstStep,
              fieldErrorMessages: {
                invoiceDateErrorMessage: "",
                invoiceDateRangeErrorMessage: "",
                conceptErrorMessage: "",
                saleConditionCreditCardErrorMessage: "",
                saleConditionCreditCardTypeErrorMessage: "",
              },
              fieldValues: {
                invoiceDateValue: moment(),
                invoiceDateRangeValue: [moment(), moment()],
                conceptValue: "Servicios",
                saleConditionValue: ["Efectivo o Transferencia bancaria"],
                saleConditionCreditCardValue: null,
                saleConditionCreditCardTypeValue: "Visa",
                saleConditionCreditCardTypeMask: [],
              },
              fieldValidations: {
                invoiceDateValid: true,
                invoiceDateRangeValid: true,
                conceptValid: true,
                saleConditionValid: true,
                saleConditionCreditCardValid: false,
                saleConditionCreditCardTypeValid: false,
              },
            },
            secondStep: {
              ...prevState.form.secondStep,
              fieldErrorMessages: {
                saleConditionErrorMessage: "",
              },
              fieldValues: {
                items: [
                  {
                    id: 0,
                    codeItem: 1,
                    descriptionItem: null,
                    quantityItem: "1",
                    priceItem: null,
                    subtotalItem: null,
                    codeItemValid: null,
                    descriptionItemValid: null,
                    quantityItemValid: null,
                    priceItemValid: null,
                    allValid: false,
                  },
                ],
              },
            },
            thirdStep: {
              ...prevState.form.thirdStep,
              fieldOptions: {
                typeDocumentOptions: [
                  {
                    text: "CUIT",
                    mask: [
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                    ],
                    maxLength: 13,
                    disabled: false,
                  },
                  {
                    text: "DNI",
                    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                    maxLength: 8,
                    disabled: false,
                  },
                ],
                comercialAddressOptions: [],
                multipleUsersFoundByDocumentOptions: [],
                posibleEmailsOptions: [
                  "@gmail.com",
                  "@hotmail.com",
                  "@outlook.com",
                  "@yahoo.com",
                  "@live.com",
                ],
              },
              fieldErrorMessages: {
                numberDocumentErrorMessage: "",
                typeDocumentErrorMessage: "",
                socialNameErrorMessage: "",
                comercialAddressErrorMessage: "",
                emailClientCheckboxErrorMessage: "",
                emailClientInputErrorMessage: "",
                multipleUsersFoundByDocumentErrorMessage: "",
              },
              fieldValues: {
                numberDocumentValue: null,
                typeDocumentValue: "CUIT",
                socialNameValue: null,
                comercialAddressRadioValue: null,
                comercialAddressInputValue: null,
                numberDocumentMask: [
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                ],
                emailClientCheckboxValue: null,
                emailClientInputValue: null,
                autoCompleteDataSourceEmail: [
                  "@gmail.com",
                  "@hotmail.com",
                  "@outlook.com",
                  "@yahoo.com",
                  "@live.com",
                ],
                clientFetchedData: {},
                multipleUsersFoundByDocumentValue: null,
              },
              fieldValidations: {
                numberDocumentValid: false,
                typeDocumentValid: true,
                socialNameValid: false,
                comercialAddressRadioValid: false,
                comercialAddressInputValid: false,
                emailClientCheckboxValid: false,
                emailClientInputValid: false,
                multipleUserFoundByDocumentValid: false,
              },
              fieldsDisabled: {
                numberDocumentIsDisabled: false,
                typeDocumentIsDisabled: false,
                socialNameIsDisabled: true,
                comercialAddressIsDisabled: true,
                emailClientInputIsDisabled: false,
                multipleUsersFoundByDocumentDisabled: false,
              },
              fieldsFetching: {
                numberDocumentIsFetching: false,
                typeDocumentIsFetching: false,
                socialNameIsFetching: false,
                comercialAddressIsFetching: false,
                emailClientInputIsFetching: false,
              },
            },
            disableAllFields: false,
            totalImport: 0,
            requireDniOrCuitOnThirdStep: false,
            showSummary: false,
            errorsGeneratedByGeneratingInvoice: false,
            invoiceIdCreated: {
              ...prevState.form.invoiceIdCreated,
              status: action.payload.status,
            },
            steps: [
              {
                title: "Datos de emisión",
                number: 1,
              },
              {
                title: "Datos del servicio",
                number: 2,
              },
              {
                title: "Datos del cliente",
                number: 3,
              },
              {
                title: "Confirmar datos",
                number: 4,
              },
            ],
            showModalWhenInvoiceCreated: true,
            currentStep: 0,
          },
        };
      }

      return {
        form: {
          ...prevState.form,
          invoiceIdCreated: {
            ...prevState.form.invoiceIdCreated,
            status: action.payload.status,
          },
        },
      };
    case ENABLE_ALL_FIELDS:
      return {
        form: {
          ...prevState.form,
          disableAllFields: false,
        },
      };
    case DISABLE_ALL_FIELDS:
      return {
        form: {
          ...prevState.form,
          disableAllFields: true,
        },
      };
    case SHOW_ERRORS_GENERATED_BY_GENERATING_INVOICE:
      value = action.payload;

      // The keys are the values that are especting from the backend
      // The values are the values assigned on the fron
      const firstStepFieldsToCheck = Object.entries({
        date: {
          fieldErrorMessage: "invoiceDateErrorMessage",
          fieldValidations: "invoiceDateValid",
        },
        date_service_from: {
          fieldErrorMessage: "invoiceDateRangeErrorMessage",
          fieldValidations: "invoiceDateRangeValid",
        },
        date_service_to: {
          fieldErrorMessage: "invoiceDateRangeErrorMessage",
          fieldValidations: "invoiceDateRangeValid",
        },
        concept_type: {
          fieldErrorMessage: "conceptErrorMessage",
          fieldValidations: "conceptValid",
        },
      });
      const secondStepFieldsToCheck = Object.entries({
        sale_condition: {
          fieldErrorMessage: "saleConditionErrorMessage",
          fieldValidations: "saleConditionValid",
        },
      });
      const thirdStepFieldsToCheck = Object.entries({
        to_document: {
          fieldErrorMessage: "numberDocumentErrorMessage",
          fieldValidations: "numberDocumentValid",
        },
      });

      const firstStepErrors = { fieldErrorMessages: {}, fieldValidations: {} };
      const secondStepErrors = { fieldErrorMessages: {}, fieldValidations: {} };
      const thirdStepErrors = { fieldErrorMessages: {}, fieldValidations: {} };

      let findFirstStep = null;
      let findSecondStep = null;
      let findThirdStep = null;

      // Organizing errors on correct step
      Object.entries(value).forEach((error) => {
        findFirstStep = firstStepFieldsToCheck.find(
          (field1) => field1[0] === error[0]
        );
        findSecondStep = secondStepFieldsToCheck.find(
          (field2) => field2[0] === error[0]
        );
        findThirdStep = thirdStepFieldsToCheck.find(
          (field3) => field3[0] === error[0]
        );

        // Checking the incoming error on which step is and adding it
        if (findFirstStep) {
          firstStepErrors.fieldErrorMessages = {
            ...firstStepErrors.fieldErrorMessages,
            [findFirstStep[1].fieldErrorMessage]: error[1][0],
          };
          firstStepErrors.fieldValidations = {
            ...firstStepErrors.fieldValidations,
            [findFirstStep[1].fieldValidations]: false,
          };
        } else if (findSecondStep) {
          secondStepErrors.fieldErrorMessages = {
            ...secondStepErrors.fieldErrorMessages,
            [findSecondStep[1].fieldErrorMessage]: error[1][0],
          };
          secondStepErrors.fieldValidations = {
            ...secondStepErrors.fieldValidations,
            [findSecondStep[1].fieldValidations]: false,
          };
        } else if (findThirdStep) {
          thirdStepErrors.fieldErrorMessages = {
            ...thirdStepErrors.fieldErrorMessages,
            [findThirdStep[1].fieldErrorMessage]: error[1][0],
          };
          thirdStepErrors.fieldValidations = {
            ...thirdStepErrors.fieldValidations,
            [findThirdStep[1].fieldValidations]: false,
          };
        }
      });

      return {
        form: {
          ...prevState.form,
          firstStep: {
            ...prevState.form.firstStep,
            fieldErrorMessages: {
              ...prevState.form.firstStep.fieldErrorMessages,
              ...firstStepErrors.fieldErrorMessages,
            },
            fieldValidations: {
              ...prevState.form.firstStep.fieldValidations,
              ...firstStepErrors.fieldValidations,
            },
          },
          errorsGeneratedByGeneratingInvoice: true,
          currentStep: 0,
        },
      };
    case HANDLE_CLEAR_SOCIAL_NAME_AND_COMERCIAL_ADDRESS:
      // TODO FEATURE
      // Clears and makes it open inputs
      // return {
      //   form: {
      //     ...prevState.form,
      //     thirdStep: {
      //       ...prevState.form.thirdStep,
      //       fieldValues: {
      //         ...prevState.form.thirdStep.fieldValues,
      //         socialNameValue: null,
      //         comercialAddressInputValue: null,
      //         comercialAddressRadioValue: null,
      //       },
      //       fieldValidations: {
      //         ...prevState.form.thirdStep.fieldValidations,
      //         socialNameValid: false,
      //         comercialAddressInputValid: false,
      //       },
      //       fieldsDisabled: {
      //         ...prevState.form.thirdStep.fieldsDisabled,
      //         socialNameIsDisabled: false,
      //         comercialAddressIsDisabled: false,
      //       },
      //       fieldOptions: {
      //         ...prevState.form.thirdStep.fieldOptions,
      //         comercialAddressOptions: [],
      //       },
      //     },
      //   },
      // };
      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldValues: {
              ...prevState.form.thirdStep.fieldValues,
              numberDocumentValue: null,
              socialNameValue: null,
              comercialAddressInputValue: null,
              comercialAddressRadioValue: null,
            },
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              socialNameValid: false,
              numberDocumentValid: false,
              comercialAddressInputValid: false,
            },
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              socialNameIsDisabled: true,
              comercialAddressIsDisabled: true,
            },
            fieldOptions: {
              ...prevState.form.thirdStep.fieldOptions,
              comercialAddressOptions: [],
            },
          },
        },
      };
    case FETCH_CHECK_EMAIL_VALIDATION:
      response = action.payload;

      if (response) {
        return {
          form: {
            ...prevState.form,
            thirdStep: {
              ...prevState.form.thirdStep,
              fieldValidations: {
                ...prevState.form.thirdStep.fieldValidations,
                emailClientInputValid: response.result,
              },
              fieldErrorMessages: {
                ...prevState.form.thirdStep.fieldErrorMessages,
                emailClientInputErrorMessage: response.result
                  ? ""
                  : "Ingresá un email valido",
              },
            },
            emailFetchedIsValid: response.result,
          },
        };
      }

      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldValidations: {
              ...prevState.form.thirdStep.fieldValidations,
              emailClientInputValid: true,
            },
            fieldErrorMessages: {
              ...prevState.form.thirdStep.fieldErrorMessages,
              emailClientInputErrorMessage: "",
            },
          },
          emailFetchedIsValid: true,
        },
      };
    case SHOW_MODAL_WHEN_INVOICE_CREATED:
      return {
        form: {
          ...prevState.form,
          showModalWhenInvoiceCreated: action.show,
          invoiceIdCreated: {
            value: null,
            status: null,
          },
        },
      };
    case DISABLE_EMAIL_FIELDS:
      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              emailClientInputIsDisabled: true,
            },
          },
        },
      };
    case ENABLE_EMAIL_FIELDS:
      return {
        form: {
          ...prevState.form,
          thirdStep: {
            ...prevState.form.thirdStep,
            fieldsDisabled: {
              ...prevState.form.thirdStep.fieldsDisabled,
              emailClientInputIsDisabled: false,
            },
          },
        },
      };
    default:
      return prevState;
  }
};
