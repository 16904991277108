import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAdherentesQuantityEmpresasEQ } from "../../../../actions/cantidadAdherentesAnswer";
import { enterprises } from "../../../../actions/listEnterprises";

class CantidadAdherentes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidadAdherentes: "",
    };

    this.handleAdherentesQuantity = this.handleAdherentesQuantity.bind(this);
  }

  componentWillMount() {
    const enterprise = localStorage.getItem("enterprise");
    // If already has been answered then load the answer
    if (enterprises.includes(enterprise)) {
      if (localStorage.getItem("Q8")) {
        this.setState({
          cantidadAdherentes: JSON.parse(localStorage.getItem("Q8")).selected,
        });
      }
    } else {
      if (localStorage.getItem("Q4")) {
        this.setState({
          cantidadAdherentes: JSON.parse(localStorage.getItem("Q4")).selected,
        });
      }
    }
  }

  handleAdherentesQuantity(e) {
    this.setState({
      cantidadAdherentes: e.target.value,
    });

    this.props.updateAdherentesQuantityEmpresasEQ(
      this.props.questions,
      e.target.value
    );
  }

  renderNextButton() {
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
        <button
          className={`btn-next`}
          type="button"
          onClick={this.props.onNextQuestion}
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  renderCantidadAdherentes() {
    return (
      <div>
        <hr />
        <div className="select-wrapper">
          <div>
            <label htmlFor="select-members-quantity">
              <select
                className="form-control"
                name="select-members-quantity"
                id="select-members-quantity"
                value={this.state.cantidadAdherentes}
                onChange={(e) => this.handleAdherentesQuantity(e)}
              >
                <option value="" disabled>
                  Seleccioná una opción
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <i className="fas fa-check-circle" />
              <i className="fas fa-times-circle" />
            </label>
          </div>
        </div>
        {this.renderNextButton()}
      </div>
    );
  }

  render() {
    return this.renderCantidadAdherentes();
  }
}

CantidadAdherentes.propTypes = {
  questions: PropTypes.array,
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
  updateAdherentesQuantityEmpresasEQ: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    questions: state.empresasQuestionnaire,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAdherentesQuantityEmpresasEQ,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CantidadAdherentes);
