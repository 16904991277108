/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../Loader/index";
import LogoSecurityCode from "../../../../assets/img/security-code.png";
import { submitPaymentMethod, MP_PUBLIC_KEY } from "../../../../apis";

const SDK_ERRORS = {
  310: {
    error: "internal_client_id",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  200: {
    error: 'The parameter "public_key" can not be null or empty',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  205: {
    error: "Ingresa el número de tu tarjeta",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  302: {
    error: 'Invalid parameter "public_key"',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  219: {
    error: 'The parameter "client_id" can not be null or empty',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  315: {
    error: 'Invalid parameter "site_id"',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  222: {
    error: 'The parameter "site_id" is a required field (not null or empty)',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  318: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  304: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  703: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  319: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  701: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  317: {
    error: "Card id inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  320: {
    error: 'Invalid parameter "luhn_validation"',
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E111: {
    error: "Invalid json",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E115: {
    error: "Public Key inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E202: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E213: {
    error: "Invalid parameter card_present",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E301: {
    error: "Número de tarjeta inválido",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  E501: {
    error: "Not found public_key",
    input: "creditCardNumber",
    errorElement: "errorCreditCardNumber",
  },
  208: {
    error: "Elige un mes",
    input: "creditCardExpirationMonth",
    errorElement: "errorCreditCardExpirationMonth",
  },
  325: {
    error: "Mes inválido",
    input: "creditCardExpirationMonth",
    errorElement: "errorCreditCardExpirationMonth",
  },
  209: {
    error: "Elige un año",
    input: "creditCardExpirationYear",
    errorElement: "errorCreditCardExpirationYear",
  },
  326: {
    error: "Año inválido",
    input: "creditCardExpirationYear",
    errorElement: "errorCreditCardExpirationYear",
  },
  702: {
    error: "Año inválido",
    input: "creditCardExpirationYear",
    errorElement: "errorCreditCardExpirationYear",
  },
  301: {
    error: "Fecha inválida",
    input: "creditCardExpirationYear",
    errorElement: "errorCreditCardExpirationYear",
  },
  321: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  700: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  307: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  704: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  224: {
    error: "Ingresa el código de seguridad",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  E203: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  E302: {
    error: "Código de seguridad inválido",
    input: "creditCardSecurityCode",
    errorElement: "errorCreditCardSecurityCode",
  },
  305: {
    error: "Invalid cardholder structure",
    input: "creditCardholderName",
    errorElement: "errorCreditCardholderName",
  },
  210: {
    error: "Faltan datos del propietario",
    input: "creditCardholderName",
    errorElement: "errorCreditCardholderName",
  },
  221: {
    error: "Ingresá el nombre y apellido",
    input: "creditCardholderName",
    errorElement: "errorCreditCardholderName",
  },
  316: {
    error: "El nombre no puede ser vacío",
    input: "creditCardholderName",
    errorElement: "errorCreditCardholderName",
  },
  E114: {
    error: "El nombre es inválido",
    input: "creditCardholderName",
    errorElement: "errorCreditCardholderName",
  },
  322: {
    error: "El tipo de doc. es inválido",
    input: "creditCardDocType",
    errorElement: "errorCreditCardDocType",
  },
  323: {
    error: "El tipo de doc. es inválido",
    input: "creditCardDocType",
    errorElement: "errorCreditCardDocType",
  },
  324: {
    error: "El doc. es inválido",
    input: "creditCardDocType",
    errorElement: "errorCreditCardDocType",
  },
  E305: {
    error: "El tipo de doc. es inválido",
    input: "creditCardDocType",
    errorElement: "errorCreditCardDocType",
  },
  212: {
    error: "El tipo de doc. es inválido",
    input: "creditCardDocType",
    errorElement: "errorCreditCardDocType",
  },
  213: {
    error: "Ingresá tu documento",
    input: "creditCardDocNumber",
    errorElement: "errorCreditCardDocNumber",
  },
  214: {
    error: "Ingresá tu documento",
    input: "creditCardDocNumber",
    errorElement: "errorCreditCardDocNumber",
  },
  211: {
    error: "El documento no puede ser vacío",
    input: "creditCardDocNumber",
    errorElement: "errorCreditCardDocNumber",
  },
  220: {
    error: "Ingresá tu banco emisor",
    input: "creditCardIssuerVal",
    errorElement: "errorCreditCardIssuerVal",
  },
};

class CreditCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitDisabled: true,
      showInstallmentWrapper: true,
      disabledInstallmentWrapper: true,
      showIssuerWrapper: false,
      disabledIssuerWrapper: true,
      issuerOptions: [],
      installmentsOptions: [],
      creditCardIssuerVal: "",
      creditCardInstallmentsVal: "",
      creditCardNumber: "",
      creditCardExpirationMonth: "",
      creditCardExpirationYear: "",
      creditCardSecurityCode: "",
      creditCardholderName: "",
      creditCardDocType: "",
      creditCardDocNumber: "",
      creditCardEmail: "",
      paymentMethod: "credit_card",
      axiosError: "",
      amount: this.props.basePriceCreditCard,
    };

    this.handleCardNumberKeyUp = this.handleCardNumberKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
    this.handleChangeInstallments = this.handleChangeInstallments.bind(this);
    this.handleChangeIssuers = this.handleChangeIssuers.bind(this);
    this.setPaymentMethodInfo = this.setPaymentMethodInfo.bind(this);
    this.showCardIssuers = this.showCardIssuers.bind(this);
    this.clearOptions = this.clearOptions.bind(this);
    this.getBin = this.getBin.bind(this);
    this.sdkResponseHandler = this.sdkResponseHandler.bind(this);
  }

  componentDidMount() {
    window.Mercadopago.setPublishableKey(MP_PUBLIC_KEY);
  }

  getBin() {
    return this.state.creditCardNumber.replace(/[ .-]/g, "").slice(0, 6);
  }

  clearOptions() {
    const BIN = this.getBin();

    if (BIN.length < 6) {
      this.setState({
        submitDisabled: true,
        showInstallmentWrapper: true,
        disabledIssuerWrapper: true,
        showIssuerWrapper: false,
        disabledInstallmentWrapper: true,
        issuerOptions: [],
        creditCardIssuerVal: "",
        installmentsOptions: [],
        creditCardInstallmentsVal: 1,
      });
    }
  }

  // setInstallmentInfo(status, response) {
  //   if (response.length > 0) {
  //     this.setState({
  //       disabledInstallmentWrapper: false,
  //       creditCardInstallmentsVal: "1",
  //       installmentsOptions: response[0].payer_costs
  //     });

  //     // Doing the same on handleChangeInstallments
  //     const TARGET = document.getElementById("creditCardInstallmentsVal");
  //     const SELECTED_INDEX = TARGET.options.selectedIndex;
  //     const VALUE = TARGET.value;
  //     const TOTAL_AMOUNT = TARGET.options[SELECTED_INDEX].getAttribute(
  //       "total_amount"
  //     );
  //     const TOTAL_CUOTAS_AMOUNT = TARGET.options[SELECTED_INDEX].getAttribute(
  //       "total_cuotas_amount"
  //     );
  //     const PTF = TARGET.options[SELECTED_INDEX].getAttribute("ptf");
  //     const TEA = TARGET.options[SELECTED_INDEX].getAttribute("tea");
  //     const CFT = TARGET.options[SELECTED_INDEX].getAttribute("cft");
  //     const NAME = TARGET.id;

  //     this.setState({
  //       [NAME]: VALUE
  //     });

  //     this.props.handleChangeInstallments(
  //       VALUE,
  //       TOTAL_AMOUNT,
  //       TOTAL_CUOTAS_AMOUNT,
  //       PTF,
  //       TEA,
  //       CFT
  //     );
  //   }
  // }

  setInstallmentsByIssuerId(issuerId) {
    const BIN = this.getBin();
    const AMOUNT = this.state.amount;

    if (issuerId === "") {
      return;
    }

    window.Mercadopago.getInstallments(
      {
        bin: BIN,
        amount: AMOUNT,
        issuer_id: issuerId,
      },
      this.setInstallmentInfo
    );
  }

  showCardIssuers(status, issuers) {
    if (issuers.length === 0) {
      this.setState({
        showIssuerWrapper: false,
      });
    } else {
      this.setState({
        issuerOptions: issuers,
        showIssuerWrapper: true,
      });
    }
    this.setState({
      disabledIssuerWrapper: false,
    });
  }

  setPaymentMethodInfo(status, response) {
    // const BIN = this.getBin();
    // const AMOUNT = this.state.amount;

    if (status === 200) {
      if (response[0].payment_type_id === "credit_card") {
        this.setState({
          submitDisabled: false,
        });
      } else if (response[0].payment_type_id === "debit_card") {
        this.setState({
          submitDisabled: false,
        });
      } else if (
        response[0].payment_type_id !== "credit_card" &&
        response[0].payment_type_id !== "debit_card"
      ) {
        this.setState({
          submitDisabled: true,
        });
      }

      this.setState({
        paymentMethod: response[0].id,
      });

      this.setState({
        disabledInstallmentWrapper: false,
      });

      // check if the issuer is necessary to pay
      let issuerMandatory = false;
      const ADDITIONAL_INFO = response[0].additional_info_needed;
      for (let i = 0; i < ADDITIONAL_INFO.length; i += 1) {
        if (ADDITIONAL_INFO[i] === "issuer_id") {
          issuerMandatory = true;
        }
      }

      if (issuerMandatory) {
        this.setState({
          disabledIssuerWrapper: false,
        });
        window.Mercadopago.getIssuers(response[0].id, this.showCardIssuers);
      } else {
        this.setState({
          disabledIssuerWrapper: true,
          creditCardIssuerVal: "",
        });
      }

      // check if installments is necessary
      // window.Mercadopago.getInstallments(
      //   {
      //     bin: BIN,
      //     amount: AMOUNT
      //   },
      //   this.setInstallmentInfo
      // );
    } else {
      this.setState({
        submitDisabled: true,
        disabledInstallmentWrapper: true,
      });
    }
  }

  guessingPaymentMethod(e) {
    const BIN = this.getBin();
    if (e.type === "keyup") {
      if (BIN.length === 6) {
        window.Mercadopago.getPaymentMethod(
          {
            bin: BIN,
          },
          this.setPaymentMethodInfo
        );
      } else {
        this.clearOptions();
      }
    } else {
      setTimeout(() => {
        if (BIN.length >= 6) {
          window.Mercadopago.getPaymentMethod(
            {
              bin: BIN,
            },
            this.setPaymentMethodInfo
          );
        }
      }, 100);
    }
  }

  async handleSubmit() {
    try {
      const form = JSON.stringify({
        email: this.state.creditCardEmail,
        installments: 1,
        issuer: this.state.creditCardIssuerVal,
        paymentMethodId: this.state.paymentMethod,
        cardToken: this.state.cardToken,
        serviceToPay: this.props.serviceToPay,
      });
      const response = await submitPaymentMethod(form);

      if (response.data.error) {
        this.setState({
          isLoading: false,
          axiosError: response.data.description,
        });
      } else {
        this.props.onNextQuestion();
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        axiosError: "Error 500",
      });
    }
  }

  sdkResponseHandler(status, response) {
    if (status !== 200 && status !== 201) {
      if ("cause" in response) {
        response.cause.forEach((cause) => {
          if (SDK_ERRORS[cause.code] !== undefined) {
            const elementTextError = document.getElementById(
              SDK_ERRORS[cause.code].errorElement
            );
            const elementInput = document.getElementById(
              SDK_ERRORS[cause.code].input
            );

            elementInput.classList.add("error-border");
            elementTextError.textContent = SDK_ERRORS[cause.code].error;
          }
        });

        if (
          !this.state.creditCardEmail.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
          )
        ) {
          document
            .getElementById("creditCardEmail")
            .classList.add("error-border");
          document.getElementById("errorCreditCardEmail").textContent =
            "Ingresá un email valido";
        }

        if (
          document.getElementById("credit-card-issuer-wrapper").classList[0] !==
            "d-none" &&
          this.state.creditCardIssuerVal === ""
        ) {
          document
            .getElementById("creditCardIssuerVal")
            .classList.add("error-border");
          document.getElementById("errorCreditCardIssuerVal").textContent =
            SDK_ERRORS["220"].error;
        }

        if (
          document.getElementById("creditCardInstallmentsVal").classList[0] !==
            "d-none" &&
          this.state.creditCardInstallmentsVal === ""
        ) {
          document
            .getElementById("creditCardInstallmentsVal")
            .classList.add("error-border");
          document.getElementById(
            "errorCreditCardInstallmentsVal"
          ).textContent = "Elegí una cuota";
        }
      }
    } else if (status === 200 || status === 201) {
      // We are doing this verifications manually here because from MP it looks like its not required
      let allValidated = false;

      // If the email is not correct then verify it
      if (
        !this.state.creditCardEmail.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
      ) {
        document
          .getElementById("creditCardEmail")
          .classList.add("error-border");
        document.getElementById("errorCreditCardEmail").textContent =
          "Ingresá un email valido";
        allValidated = false;
      } else {
        allValidated = true;
      }

      // If issuer is visible and empty then verify it
      if (
        document.getElementById("credit-card-issuer-wrapper").classList[0] !==
          "d-none" &&
        this.state.creditCardIssuerVal === ""
      ) {
        document
          .getElementById("creditCardIssuerVal")
          .classList.add("error-border");
        document.getElementById("errorCreditCardIssuerVal").textContent =
          SDK_ERRORS["220"].error;
        allValidated = false;
      } else {
        allValidated = true;
      }

      if (allValidated) {
        this.setState({
          cardToken: response.id,
          isLoading: true,
        });
        this.handleSubmit();
      }
    }
  }

  handleCardNumberKeyUp(e) {
    this.clearOptions();
    this.guessingPaymentMethod(e);
  }

  handleChange(e) {
    const TARGET = e.target;
    const VALUE = TARGET.value;
    const NAME = TARGET.id;

    this.setState({
      [NAME]: VALUE,
    });

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";
  }

  handleChangeIssuers(e) {
    const TARGET = e.target;
    const NAME = TARGET.id;
    const VALUE = TARGET.value;

    this.setState({
      [NAME]: VALUE,
    });

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";

    this.setInstallmentsByIssuerId(VALUE);
  }

  handleChangeInstallments(e) {
    const TARGET = e.target;
    const SELECTED_INDEX = TARGET.options.selectedIndex;
    const VALUE = TARGET.value;
    const TOTAL_AMOUNT = TARGET.options[SELECTED_INDEX].getAttribute(
      "total_amount"
    );
    const TOTAL_CUOTAS_AMOUNT = TARGET.options[SELECTED_INDEX].getAttribute(
      "total_cuotas_amount"
    );
    const PTF = TARGET.options[SELECTED_INDEX].getAttribute("ptf");
    const TEA = TARGET.options[SELECTED_INDEX].getAttribute("tea");
    const CFT = TARGET.options[SELECTED_INDEX].getAttribute("cft");
    const NAME = TARGET.id;

    this.setState({
      [NAME]: VALUE,
    });

    this.props.handleChangeInstallments(
      VALUE,
      TOTAL_AMOUNT,
      TOTAL_CUOTAS_AMOUNT,
      PTF,
      TEA,
      CFT
    );

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";
  }

  handleCardNumberChange(e) {
    const TARGET = e.target;
    const NAME = TARGET.id;
    const VALUE = TARGET.value
      .replace(/[^\d]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    this.setState({
      creditCardNumber: VALUE,
    });

    document.getElementById(`${NAME}`).classList.remove("error-border");
    document.getElementById(
      `error${NAME.charAt(0).toUpperCase() + NAME.slice(1)}`
    ).textContent = "";
  }

  appendYears() {
    const DATE = new Date();
    const ACTUAL_DATE = DATE.getUTCFullYear();
    const OPTIONS = [];

    for (let i = ACTUAL_DATE; i < ACTUAL_DATE + 11; i += 1) {
      const OPTION = (
        <option key={Math.random()} value={i}>
          {i}
        </option>
      );
      OPTIONS.push(OPTION);
    }

    return OPTIONS.map((e) => e);
  }

  appendInstallments() {
    const INSTALLMENTS = this.state.installmentsOptions;
    const OPTIONS = [];

    for (let i = 0; i < INSTALLMENTS.length; i += 1) {
      let splitData;
      if (INSTALLMENTS[i].labels[0] === "recommended_installment") {
        splitData = INSTALLMENTS[i].labels[1].split("|");
      } else {
        splitData = INSTALLMENTS[i].labels[0].split("|");
      }
      const CUOTAS = INSTALLMENTS[i].installments;
      const TOTAL_AMOUNT = INSTALLMENTS[i].total_amount
        .toString()
        .replace(".", ",");
      const TOTAL_CUOTAS_AMOUNT = INSTALLMENTS[i].installment_amount
        .toString()
        .replace(".", ",");
      const CFT = splitData[0].split("_");
      const TEA = splitData[1].split("_");

      OPTIONS.push({
        installments: CUOTAS,
        total_amount: TOTAL_AMOUNT,
        total_cuotas_amount: TOTAL_CUOTAS_AMOUNT,
        ptf: TOTAL_AMOUNT,
        tea: `${TEA[0]}: ${TEA[1]}`,
        cft: `${CFT[0]}: ${CFT[1]}`,
      });
    }

    const optionsToRender = OPTIONS.map((e) => (
      <option
        key={Math.random()}
        value={e.installments}
        total_amount={e.total_amount}
        total_cuotas_amount={e.total_cuotas_amount}
        ptf={e.ptf}
        tea={e.tea}
        cft={e.cft}
      >
        {e.installments} cuotas de $ {e.total_cuotas_amount} ($ {e.total_amount}
        )
      </option>
    ));

    optionsToRender.unshift(
      <option key={Math.random()} value="" disabled>
        Elegí una cuota
      </option>
    );

    return optionsToRender;
  }

  appendIssuers() {
    const ISSUERS = this.state.issuerOptions;
    const OPTIONS = [];

    for (let i = 0; i < ISSUERS.length; i += 1) {
      const ID = ISSUERS[i].id;
      const NAME = ISSUERS[i].name;

      OPTIONS.push({
        id: ID,
        name: NAME,
      });
    }

    const optionsToRender = OPTIONS.map((e) => (
      <option key={e.id} value={e.id}>
        {e.name}
      </option>
    ));

    optionsToRender.unshift(
      <option key={Math.random()} value="" disabled>
        Elegi un banco
      </option>
    );

    return optionsToRender;
  }

  renderLoader() {
    return this.state.isLoading ? (
      <Loader loadingMessage={this.state.loadingMessage} />
    ) : null;
  }

  doPay(e) {
    e.preventDefault();

    const FORM = {
      cardNumber: this.state.creditCardNumber,
      securityCode: this.state.creditCardSecurityCode,
      cardExpirationMonth: this.state.creditCardExpirationMonth,
      cardExpirationYear: this.state.creditCardExpirationYear,
      cardholderName: this.state.creditCardholderName,
      docType: this.state.creditCardDocType,
      docNumber: this.state.creditCardDocNumber,
      installments: this.state.creditCardInstallmentsVal,
    };

    if (this.state.creditCardIssuerVal !== "") {
      FORM.issuer = this.state.creditCardIssuerVal;
    }

    window.Mercadopago.createToken(FORM, this.sdkResponseHandler);
  }

  renderCreditCard() {
    return (
      <div
        className={`credit-card-wrapper
        ${this.props.creditCardState ? "d-block" : false}`}
      >
        {this.state.axiosError !== "" ? (
          <h5>{this.state.axiosError}</h5>
        ) : (
          false
        )}
        {this.renderLoader()}
        <p className="mt-4 mb-0 text-left">
          IMPORTANTE: al pagar con tarjeta de crédito, se te facturarán{" "}
          <strong>
            <u>
              ${this.props.basePriceCreditCard}{" "}
              {this.props.payingInscription
                ? "por unica vez"
                : "todos los meses"}
            </u>
          </strong>{" "}
          a través de la forma de pago elegida
          {this.props.payingInscription
            ? "."
            : ", hasta que canceles la suscripción."}
        </p>
        <form onSubmit={(e) => this.doPay(e)} id="form-credit-card">
          <ul>
            <li>
              <p>N&uacute;mero de la tarjeta</p>
              <label htmlFor="creditCardNumber">
                <input
                  className={`${
                    this.state.creditCardNumber ? "active-border" : false
                  } form-control`}
                  id="creditCardNumber"
                  type="text"
                  maxLength="19"
                  placeholder="Ingrese los 16 digitos de la tarjeta"
                  onKeyUp={this.handleCardNumberKeyUp}
                  onChange={this.handleCardNumberChange}
                  value={this.state.creditCardNumber}
                />
                <p className="error-message" id="errorCreditCardNumber" />
              </label>
            </li>
            <li>
              <p>Mes de vencimiento</p>
              <label htmlFor="creditCardExpirationMonth">
                <select
                  className={`${
                    this.state.creditCardExpirationMonth
                      ? "active-border"
                      : false
                  } form-control`}
                  id="creditCardExpirationMonth"
                  onChange={this.handleChange}
                  value={this.state.creditCardExpirationMonth}
                >
                  <option value="" disabled>
                    Mes
                  </option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <p
                  className="error-message"
                  id="errorCreditCardExpirationMonth"
                />
              </label>
            </li>
            <li>
              <p>Año de vencimiento</p>
              <label htmlFor="creditCardExpirationYear">
                <select
                  className={`${
                    this.state.creditCardExpirationYear
                      ? "active-border"
                      : false
                  } form-control`}
                  id="creditCardExpirationYear"
                  onChange={this.handleChange}
                  value={this.state.creditCardExpirationYear}
                >
                  <option value="" disabled>
                    Año
                  </option>
                  {this.appendYears()}
                </select>
                <p
                  className="error-message"
                  id="errorCreditCardExpirationYear"
                />
              </label>
            </li>
            <li>
              <p>C&oacute;digo de seguridad</p>
              <label htmlFor="creditCardSecurityCode">
                <input
                  className={`${
                    this.state.creditCardSecurityCode ? "active-border" : false
                  } form-control`}
                  id="creditCardSecurityCode"
                  type="number"
                  placeholder="422"
                  onChange={this.handleChange}
                  value={this.state.creditCardSecurityCode}
                />
                <i className="fa fa-question-circle" />
                <div className="question-security">
                  <img
                    src={LogoSecurityCode}
                    alt="Imagen de codigo de seguridad"
                    title="Codigo de seguridad"
                  />
                  <span>
                    El código de seguridad está localizado en los 3 últimos
                    dígitos detrás de la tarjeta.
                  </span>
                  <div className="triangle" />
                  <div className="close">x</div>
                </div>
                <p className="error-message" id="errorCreditCardSecurityCode" />
              </label>
            </li>
            <li>
              <p>Titular de la tarjeta</p>
              <label htmlFor="creditCardholderName">
                <input
                  className={`${
                    this.state.creditCardholderName ? "active-border" : false
                  } form-control`}
                  id="creditCardholderName"
                  type="text"
                  maxLength="30"
                  placeholder="Santiago Moreyra"
                  onChange={this.handleChange}
                  value={this.state.creditCardholderName}
                />
                <p className="error-message" id="errorCreditCardholderName" />
              </label>
            </li>
            <li>
              <p>Tipo de documento</p>
              <label htmlFor="creditCardDocType">
                <select
                  className={`${
                    this.state.creditCardDocType ? "active-border" : false
                  } form-control`}
                  id="creditCardDocType"
                  onChange={this.handleChange}
                  value={this.state.creditCardDocType}
                >
                  <option value="" disabled>
                    Tipo documento
                  </option>
                  <option value="DNI">DNI</option>
                  <option value="CI">Cédula</option>
                  <option value="LC">L.C.</option>
                  <option value="LE">L.E.</option>
                  <option value="Otro">Otro</option>
                </select>
                <p className="error-message" id="errorCreditCardDocType" />
              </label>
            </li>
            <li>
              <p>N&uacute;mero de documento</p>
              <label htmlFor="creditCardDocNumber">
                <input
                  className={`${
                    this.state.creditCardDocNumber ? "active-border" : false
                  } form-control`}
                  id="creditCardDocNumber"
                  type="text"
                  placeholder="19000025"
                  onChange={this.handleChange}
                  value={this.state.creditCardDocNumber}
                />
                <p className="error-message" id="errorCreditCardDocNumber" />
              </label>
            </li>
            <li>
              <p>Email</p>
              <label htmlFor="creditCardEmail">
                <input
                  className={`${
                    this.state.creditCardEmail ? "active-border" : false
                  } form-control`}
                  id="creditCardEmail"
                  type="email"
                  placeholder="santiago@gmail.com"
                  onChange={this.handleChange}
                  value={this.state.creditCardEmail}
                />
                <p className="error-message" id="errorCreditCardEmail" />
              </label>
            </li>
            <li
              id="credit-card-issuer-wrapper"
              className={`${this.state.showIssuerWrapper ? null : "d-none"}`}
            >
              <p>Banco</p>
              <label htmlFor="creditCardIssuerVal">
                <select
                  className={`${
                    this.state.creditCardIssuerVal ? "active-border" : false
                  } form-control`}
                  id="creditCardIssuerVal"
                  disabled={this.state.disabledIssuerWrapper}
                  onChange={this.handleChangeIssuers}
                  value={this.state.creditCardIssuerVal}
                >
                  {this.appendIssuers()}
                </select>
                <p className="error-message" id="errorCreditCardIssuerVal" />
              </label>
            </li>
            {/* <li
              id="installment-wrapper"
              className={`${
                this.state.showInstallmentWrapper ? null : "d-none"
              }`}
            >
              <p>Cuotas</p>
              <label htmlFor="creditCardInstallmentsVal">
                <select
                  className={`${
                    this.state.creditCardInstallmentsVal
                      ? "active-border"
                      : false
                  } form-control`}
                  id="creditCardInstallmentsVal"
                  disabled={this.state.disabledInstallmentWrapper}
                  onChange={this.handleChangeInstallments}
                  value={this.state.creditCardInstallmentsVal}
                >
                  {this.appendInstallments()}
                </select>
                <p
                  className="error-message"
                  id="errorCreditCardInstallmentsVal"
                />
              </label>
            </li> */}
            <input
              type="hidden"
              id="amount"
              name="amount"
              value={this.state.amount}
            />
          </ul>
          <button
            className={`${
              this.state.submitDisabled ? false : "remove-disable"
            } btn-next`}
            disabled={this.state.submitDisabled}
            type="submit"
            value="Iniciar suscripción"
          >
            PAGAR
          </button>
        </form>
      </div>
    );
  }

  render() {
    return this.renderCreditCard();
  }
}

CreditCard.propTypes = {
  onNextQuestion: PropTypes.func,
  creditCardState: PropTypes.bool,
  basePriceCreditCard: PropTypes.any,
  payingInscription: PropTypes.bool,
  serviceToPay: PropTypes.object,
};

export default CreditCard;
