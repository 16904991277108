import React from 'react';
import PropTypes from 'prop-types';
import { Select, LocaleProvider } from 'antd';
import esEs from 'antd/lib/locale-provider/es_ES';
import { categoriesPromise } from '../../../../apis';

class Parametros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideParameters: null,
      showParameters: null,
      superficieSelected: false,
      alquilerSelected: false,
      energiaSelected: false,
      superficieValue: '',
      alquilerValue: '',
      energiaValue: '',
      superficie: [],
      alquiler: [],
      energia: [],
      categorias: {}
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.secondMount = this.secondMount.bind(this);
  }

  secondMount() {
    // If already has been answered then load the answer
    if (localStorage.getItem('Q5')) {
      // Setting the selected option to the localStorage
      // Then when we press the next button we merge all the data to only 1 answer with redux
      if (
        JSON.parse(localStorage.getItem('Q5')).superficie !== null &&
        JSON.parse(localStorage.getItem('Q5')).alquiler !== null &&
        JSON.parse(localStorage.getItem('Q5')).energia
      ) {
        localStorage.setItem(
          'superficie',
          JSON.parse(localStorage.getItem('Q5')).superficie,
        );
        localStorage.setItem(
          'alquiler',
          JSON.parse(localStorage.getItem('Q5')).alquiler,
        );
        localStorage.setItem(
          'energia',
          JSON.parse(localStorage.getItem('Q5')).energia,
        );
      }

      this.setState({
        hideParameters: !JSON.parse(localStorage.getItem('Q5'))
          .destinaEspacioFisico,
        showParameters: JSON.parse(localStorage.getItem('Q5'))
          .destinaEspacioFisico,
        superficieSelected:
          JSON.parse(localStorage.getItem('Q5')).superficie !== null,
        alquilerSelected:
          JSON.parse(localStorage.getItem('Q5')).alquiler !== null,
        energiaSelected:
          JSON.parse(localStorage.getItem('Q5')).energia !== null,
        superficieValue:
          JSON.parse(localStorage.getItem('Q5')).superficie === null
            ? ''
            : JSON.parse(localStorage.getItem('Q5')).superficie,
        alquilerValue:
          JSON.parse(localStorage.getItem('Q5')).alquiler === null
            ? ''
            : JSON.parse(localStorage.getItem('Q5')).alquiler,
        energiaValue:
          JSON.parse(localStorage.getItem('Q5')).energia === null
            ? ''
            : JSON.parse(localStorage.getItem('Q5')).energia,
      });

      
    }
  }

  componentWillMount() {

    categoriesPromise.then((categorias) => {
      const values = Object.values(categorias);
      const numObj = {
        'A':1,
        'B':2,
        'C':3,
        'D':4,
        'E':5,
        'F':6,
        'G':7,
        'H':8,
        'I':9,
        'J':10,
        'K':11
      };
      let present = [];
      let superficie = values.map((object) => {
        if (present.includes(object.sqft)) {
          return;
        }
        else {
          present.push(object.sqft);
        }
        return {name:object.name,value:object.sqft,label:object.sqft.toString()}});
      present = [];
      let alquiler = values.map((object) => {
        if (present.includes(object.rent)) {
          return;
        }
        else {
          present.push(object.rent);
        }
        return {name:object.name,value:object.rent,label:object.rent.toString()}});
      present = [];
      let energia = values.map((object) => {
        if (present.includes(object.kw)) {
          return;
        }
        else {
          present.push(object.kw);
        }
        return {name:object.name,value:object.kw,label:object.kw.toString()}});
      superficie = superficie.filter( Boolean ).sort(function(a,b) {return numObj[a.name] - numObj[b.name]});
      alquiler = alquiler.filter( Boolean ).sort(function(a,b) {return numObj[a.name] - numObj[b.name]});
      energia = energia.filter( Boolean ).sort(function(a,b) {return numObj[a.name] - numObj[b.name]});

      energia.unshift({value:"no",label:"no aplica para mi actividad"});
      alquiler.unshift({value:"no",label:"no alquilo"});
      this.setState({
        categorias,
        superficie,
        alquiler,
        energia,
      },()=>this.secondMount());
    })

  }

  handleCheckboxChange(e) {
    if (e.target.id === 'hideParameters') {
      this.setState(state => ({
        hideParameters: !state.hideParameters,
        showParameters: false,
        superficieSelected: false,
        alquilerSelected: false,
        energiaSelected: false,
        superficieValue: '',
        alquilerValue: '',
        energiaValue: '',
      }));

      localStorage.removeItem('superficie');
      localStorage.removeItem('alquiler');
      localStorage.removeItem('energia');
    } else {
      this.setState(state => ({
        hideParameters: false,
        showParameters: !state.showParameters,
      }));
    }
  }

  handleSelectChange(value, selectName) {
    const selectSelected = `${selectName}Selected`;
    const selectValue = `${selectName}Value`;

    this.setState({
      [selectSelected]: true,
      [selectValue]: value,
    });

    // Setting the selected option to the localStorage
    // Then when we press the next button we merge all the data to only 1 answer with redux
    localStorage.setItem(selectName, value);
  }

  getSelectOptions(select) {
    console.log(select);
    console.log(this.state[select]);
    return this.state[select].map(x => (
      <Select.Option key={Math.random()} value={x.value}>
        {x.label}
      </Select.Option>
    ));
  }

  renderSuperficieSelect() {
    return (
      <div className="select-wrapper">
        <div className="custom-select-styles--antd">
          <p>Superficie en m&sup2;</p>
          <LocaleProvider locale={esEs}>
            <Select
              className={this.state.superficieSelected ? 'valid' : null}
              showSearch
              placeholder="Seleccioná una opción"
              optionFilterProp="children"
              onChange={e => this.handleSelectChange(e, 'superficie')}
              value={this.state.superficieValue}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="" disabled>
                Seleccioná una opción
              </Select.Option>
              {this.getSelectOptions('superficie')}
            </Select>
          </LocaleProvider>
        </div>
      </div>
    );
  }

  renderAlquilerSelect() {
    return (
      <div className="select-wrapper">
        <div className="custom-select-styles--antd">
          <p>Monto del alquiler anual</p>
          <LocaleProvider locale={esEs}>
            <Select
              className={this.state.alquilerSelected ? 'valid' : null}
              showSearch
              placeholder="Seleccioná una opción"
              optionFilterProp="children"
              onChange={e => this.handleSelectChange(e, 'alquiler')}
              value={this.state.alquilerValue}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="" disabled>
                Seleccioná una opción
              </Select.Option>
              {this.getSelectOptions('alquiler')}
            </Select>
          </LocaleProvider>
        </div>
      </div>
    );
  }

  renderEnergiaSelect() {
    return (
      <div className="select-wrapper">
        <div className="custom-select-styles--antd">
          <p>Energía Eléctrica consumida anualmente</p>
          <LocaleProvider locale={esEs}>
            <Select
              className={this.state.energiaSelected ? 'valid' : null}
              showSearch
              placeholder="Seleccioná una opción"
              optionFilterProp="children"
              onChange={e => this.handleSelectChange(e, 'energia')}
              value={this.state.energiaValue}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="" disabled>
                Seleccioná una opción
              </Select.Option>
              {this.getSelectOptions('energia')}
            </Select>
          </LocaleProvider>
        </div>
      </div>
    );
  }

  renderNextButton() {
    if (this.state.hideParameters) {
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className={`${
              this.state.hideParameters ? 'remove-disable' : false
            } btn-next`}
            type="button"
            onClick={this.props.onNextQuestion}
            disabled={this.state.hideParameters ? null : true}
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    } else if (this.state.showParameters) {
      if (
        this.state.superficieSelected &&
        this.state.alquilerSelected &&
        this.state.energiaSelected
      ) {
        return (
          <div className="btns-next-back-wrapper">
            <button
              className="btn-back"
              type="button"
              onClick={this.props.onBackQuestion}
            >
              <i className="fas fa-chevron-left" />
              Volver
            </button>
            <button
              className={`${
                this.state.superficieSelected &&
                this.state.alquilerSelected &&
                this.state.energiaSelected
                  ? 'remove-disable'
                  : false
              } btn-next`}
              type="button"
              onClick={this.props.onNextQuestion}
              disabled={
                this.state.superficieSelected &&
                this.state.alquilerSelected &&
                this.state.energiaSelected
                  ? null
                  : true
              }
            >
              Siguiente
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        );
      }
      return (
        <div className="btns-next-back-wrapper">
          <button
            className="btn-back"
            type="button"
            onClick={this.props.onBackQuestion}
          >
            <i className="fas fa-chevron-left" />
            Volver
          </button>
          <button
            className="btn-next"
            type="button"
            onClick={this.props.onNextQuestion}
            disabled
          >
            Siguiente
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      );
    }
    return (
      <div className="btns-next-back-wrapper">
        <button
          className="btn-back"
          type="button"
          onClick={this.props.onBackQuestion}
        >
          <i className="fas fa-chevron-left" />
          Volver
        </button>
        <button
          className="btn-next"
          type="button"
          onClick={this.props.onNextQuestion}
          disabled
        >
          Siguiente
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }

  renderParametros() {
    return (
      <React.Fragment>
        <div className="checkbox-wrapper">
          <div className={this.state.hideParameters ? 'selected' : null}>
            <label htmlFor="hideParameters">
              <input
                type="checkbox"
                id="hideParameters"
                onClick={this.handleCheckboxChange}
              />
              No
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        <div className="checkbox-wrapper">
          <div className={this.state.showParameters ? 'selected' : null}>
            <label htmlFor="showParameters">
              <input
                type="checkbox"
                id="showParameters"
                onClick={this.handleCheckboxChange}
              />
              Si
              <i className="fas fa-check-circle" />
            </label>
          </div>
        </div>
        {this.state.showParameters ? (
          <React.Fragment>
            <hr />
            {this.renderSuperficieSelect()}
            {this.renderAlquilerSelect()}
            {this.renderEnergiaSelect()}
          </React.Fragment>
        ) : null}
        {this.renderNextButton()}
      </React.Fragment>
    );
  }

  render() {
    return this.renderParametros();
  }
}

Parametros.propTypes = {
  onBackQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

export default Parametros;
