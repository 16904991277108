import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question/index';

class EmpresasEQ extends React.Component {
  renderCard() {
    return (
      <div className="container">
        <div className="EQ">
          <div className="card box-styles">
            <div className="card-body">
              <Question
                questionData={this.props.questionData}
                onNextQuestion={this.props.onNextQuestion}
                onBackQuestion={this.props.onBackQuestion}
                showNextButton={this.props.showNextButton}
                showBackButton={this.props.showBackButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return this.renderCard();
  }
}

EmpresasEQ.propTypes = {
  questionData: PropTypes.object,
  onNextQuestion: PropTypes.func,
  onBackQuestion: PropTypes.func,
  showNextButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

export default EmpresasEQ;
