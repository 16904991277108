import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserInfoDropdown: false,
    };

    this.timeOutId = null;
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
  }

  onBlurHandler() {
    this.timeOutId = setTimeout(() => {
      this.setState({
        showUserInfoDropdown: false,
      });
    });
  }

  onFocusHandler() {
    clearTimeout(this.timeOutId);
  }

  toggleDropdown() {
    this.setState(currentState => ({
      showUserInfoDropdown: !currentState.showUserInfoDropdown,
    }));
  }

  render() {
    if (this.props.loggedIn) {
      return (
        <div
          className="user-info"
          onBlur={this.onBlurHandler}
          onFocus={this.onFocusHandler}
        >
          <button
            type="btn"
            className="welcome"
            onClick={this.toggleDropdown}
            aria-haspopup="true"
            aria-expanded={this.state.showUserInfoDropdown}
          >
            Hola, <span>{this.props.firstname}</span>
          </button>
          <p className="contact-us">
            <i className="fas fa-phone" />
            <a href="tel:1152635945">(011) 5263-5945</a>
            <br />
            <span>de L a V de 9:30 a 18:00 hs</span>
          </p>
          <div
            className={`dropdown ${
              this.state.showUserInfoDropdown ? 'd-block' : false
            }`}
          >
            <p>{this.props.username}</p>
            <button type="btn" onClick={this.props.initLogout}>
              Salir
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="user-info">
        <Link className="btn" to="/ingresar">
          <i className="fas fa-lock" /> Ingresar
        </Link>
      </div>
    );
  }
}

UserInfo.propTypes = {
  loggedIn: PropTypes.bool,
  username: PropTypes.string,
  firstname: PropTypes.string,
  initLogout: PropTypes.func,
};

export default UserInfo;
