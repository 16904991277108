// this arrays should not be modified before checking
// where we use them, add more enterprises to the array should no cause troubles.
export const enterprises = ["beat", "timejobs"];
export const products = [
  "AbonoBEAT",
  "AbonoBEAT-IN",
  "AbonoTimeJobs",
  "AbonoTimeJobs-IN"
];
export const freeProducts = ["AbonoBEAT", "AbonoTimeJobs"];
export const inscriptionsProducts = ["AbonoBEAT-IN", "AbonoTimeJobs-IN"];
export const nonPayableProducts = [
  "AbonoBEAT",
  "AbonoBEAT-IN",
  "AbonoTimeJobs"
];
export const newPlans = [
  "plan_facturacion",
  "plan_premium",
  "plan_monotributo"
];
export const afipConditions = {
  'MONOTRIBUTO': 'MONOTRIBUTO',
  'Inactivo': 'INACTIVO',
  'M-SOCIAL': 'MONOTRIBUTO SOCIAL',
  'M-PROMOVIDO': 'MONOTRIBUTO PROMOVIDO',
  'R-I': 'RESPONSABLE INSCRIPTO',
  'IVA EXENTO': 'IVA EXENTO',
  'IVA NO R': 'IVA NO RESPONSABLE',
  'NO': 'NO INSCRIPTO'
}