import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { categoriesPromise } from '../../../apis';

class CategoriesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data :  []
    }

    this.renderCategoriesModal = this.renderCategoriesModal.bind(this);
  }
  componentDidMount() {
    let data;
    categoriesPromise.then((categorias) => {
      if (this.props.isRelacionDependencia) {
        data = [
          {
            key: '1',
            categoria: 'A',
            cuota: '$ ' + categorias["A"].servicios,
            limite: '$ ' + categorias["A"].iibb,
          },
          {
            key: '2',
            categoria: 'B',
            cuota: '$ ' + categorias["B"].servicios,
            limite: '$ ' + categorias["B"].iibb,
          },
          {
            key: '3',
            categoria: 'C',
            cuota: '$ ' + categorias["C"].servicios,
            limite: '$ ' + categorias["C"].iibb,
          },
          {
            key: '4',
            categoria: 'D',
            cuota: '$ ' + categorias["D"].servicios,
            limite: '$ ' + categorias["D"].iibb,
          },
          {
            key: '5',
            categoria: 'E',
            cuota: '$ ' + categorias["E"].servicios,
            limite: '$ ' + categorias["E"].iibb,
          },
          {
            key: '6',
            categoria: 'F',
            cuota: '$ ' + categorias["F"].servicios,
            limite: '$ ' + categorias["F"].iibb,
          },
          {
            key: '7',
            categoria: 'G',
            cuota: '$ ' + categorias["G"].servicios,
            limite: '$ ' + categorias["G"].iibb,
          },
          {
            key: '8',
            categoria: 'H',
            cuota: '$ ' + categorias["H"].servicios,
            limite: '$ ' + categorias["H"].iibb,
          },
        ];
      } else {
        data = [
          {
            key: '1',
            categoria: 'A',
            cuota: '$ ' + categorias["A"].total,
            limite: '$ ' + categorias["A"].iibb,
          },
          {
            key: '2',
            categoria: 'B',
            cuota: '$ ' + categorias["B"].total,
            limite: '$ ' + categorias["B"].iibb,
          },
          {
            key: '3',
            categoria: 'C',
            cuota: '$ ' + categorias["C"].total,
            limite: '$ ' + categorias["C"].iibb,
          },
          {
            key: '4',
            categoria: 'D',
            cuota: '$ ' + categorias["D"].total,
            limite: '$ ' + categorias["D"].iibb,
          },
          {
            key: '5',
            categoria: 'E',
            cuota: '$ ' + categorias["E"].total,
            limite: '$ ' + categorias["E"].iibb,
          },
          {
            key: '6',
            categoria: 'F',
            cuota: '$ ' + categorias["F"].total,
            limite: '$ ' + categorias["F"].iibb,
          },
          {
            key: '7',
            categoria: 'G',
            cuota: '$ ' + categorias["G"].total,
            limite: '$ ' + categorias["G"].iibb,
          },
          {
            key: '8',
            categoria: 'H',
            cuota: '$ ' + categorias["H"].total,
            limite: '$ ' + categorias["H"].iibb,
          },
        ];
      }
  
      this.setState({data});
    
    });
  }

  renderCategoriesModal() {
    let { data } = this.state;
    const columns = [
      {
        title: 'Categoría',
        dataIndex: 'categoria',
        width: 80,
      },
      {
        title: 'Cuota mensual AFIP',
        dataIndex: 'cuota',
      },
      {
        title: 'Límite de facturación de tu categoría',
        dataIndex: 'limite',
      },
    ];
    return (
      <Table
        pagination={false}
        columns={columns}
        dataSource={data}
        size="small"
      />
    );
    
  }

  render() {
    return this.renderCategoriesModal();
  }
}

CategoriesModal.propTypes = {
  isRelacionDependencia: PropTypes.bool,
};

export default CategoriesModal;
