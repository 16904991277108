import React from 'react';
import PropTypes from 'prop-types';
import { Select, LocaleProvider } from 'antd';
import esEs from 'antd/lib/locale-provider/es_ES';

class SelectEQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({ value });

    this.props.handleDisablingOfNextButton(false);
  }

  getOptions() {
    return this.props.question.selectOptions.map(s => (
      <Select.Option key={Math.random()} value={s.value}>
        {s.label}
      </Select.Option>
    ));
  }

  render() {
    return (
      <React.Fragment>
        <hr />
        <div className="select-wrapper">
          <div className="custom-select-styles--antd">
            <LocaleProvider locale={esEs}>
              <Select
                className={this.state.value !== '' ? 'valid' : null}
                showSearch
                placeholder={this.props.question.placeholder}
                optionFilterProp="children"
                onChange={this.handleChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.getOptions()}
              </Select>
            </LocaleProvider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SelectEQ.propTypes = {
  question: PropTypes.object,
  handleDisablingOfNextButton: PropTypes.func,
};

export default SelectEQ;
