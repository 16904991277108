import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import CategoriesModal from '../../Partials/CategoriesModal';
import {
  VALOR_OBRA_SOCIAL,
} from '../../../../utils/constants';
import { categoriesPromise } from '../../../../apis';

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      category: '',
      categoryCuotaMensual: '',
      categoryFacturacionAnual: '',
      categorias: {}
    };

    this.showModal = this.showModal.bind(this);
    this.handleModalOk = this.handleModalOk.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
  }

  componentWillMount() {
    categoriesPromise.then((categorias) => {
      this.setState({categorias}, ()=>this.getCategory())
    })
  }

  isRelacionDependencia() {
    // Recibis ingresos por otra actividad pregunta
    const question7 = this.props.questions.filter(
      questionData => questionData.id === 7,
    )[0];

    // La opcion 2 es relacion dependencia
    return (
      question7.posibleAnswers.filter(answer => answer.selected)[0].id === 2
    );
  }

  isCajaProfesionales() {
    // Recibis ingresos por otra actividad pregunta
    const question7 = this.props.questions.filter(
      questionData => questionData.id === 7,
    )[0];

    // La opcion 3 es caja de profesionales
    return (
      question7.posibleAnswers.filter(answer => answer.selected)[0].id === 3
    );
  }

  isJubilacion() {
    // Recibis ingresos por otra actividad pregunta
    const question7 = this.props.questions.filter(
      questionData => questionData.id === 7,
    )[0];

    // La opcion 4 es jubilacion
    return (
      question7.posibleAnswers.filter(answer => answer.selected)[0].id === 4
    );
  }

  getCategory() {
    let { categorias } = this.state;
    const numObj = {
      'A':'1',
      'B':'2',
      'C':'3',
      'D':'4',
      'E':'5',
      'F':'6',
      'G':'7',
      'H':'8',
      'I':'9',
      'J':'10',
      'K':'11'
    };
    if (!categorias.hasOwnProperty('A')) {
      this.setState({
        category:"Cargando",
        categoryCuotaMensual:"0,00",
        categoryFacturacionAnual:"0,00",
      });
    };
    let category;
    let categoryCuotaMensual;
    let categoryFacturacionAnual;
    let categoriasArray = Object.values(categorias);
    categoriasArray.push({rent:"no",kw:"no",name:"A"});
    let categoriasKeyArray = Object.keys(categorias);

    if (this.props.question.id === 11) {
      // Actividad principal
      const Q3 = localStorage.getItem('Q3');

      // Espacio fisico pregunta
      const Q5 = localStorage.getItem('Q5');

      // Estimas facturar pregunta
      const Q6 = localStorage.getItem('Q6');

      // Adherentes quantity pregunta
      const Q9 = localStorage.getItem('Q9');

      const prestacionDeServicios = JSON.parse(Q3).id === 1;
      const ventaCosasMuebles = JSON.parse(Q3).id === 2;
      const alquilerPropiedades = JSON.parse(Q3).id === 3;
      const { destinaEspacioFisico } = JSON.parse(Q5);
      const { superficie } = JSON.parse(Q5);
      let superficieCategoria;
      const { alquiler } = JSON.parse(Q5);
      let alquilerCategoria;
      const { energia } = JSON.parse(Q5);
      let energiaCategoria;
      const { facturacionEstimada } = JSON.parse(Q6);
      const adherentesQuantity = JSON.parse(Q9);

      if (destinaEspacioFisico) {
        // Get the value numbers that later will be the parameter to search the correct category on the CATEGORIES array
        superficieCategoria = categoriasArray.filter(
          x => x.sqft === superficie,
        )[0];
        superficieCategoria = superficieCategoria.name;

        alquilerCategoria = categoriasArray.filter(
          x => x.rent === alquiler,
        )[0];
        alquilerCategoria = alquilerCategoria.name;

        energiaCategoria = categoriasArray.filter(
          x => x.kw === energia,
        )[0];
        energiaCategoria = energiaCategoria.name;

        category = categoriasKeyArray.filter(
          x =>
            numObj[x] ===
            Math.max(
              numObj[superficieCategoria],
              numObj[alquilerCategoria],
              numObj[energiaCategoria],
              facturacionEstimada,
            ).toString(),
        )[0];
        categoryFacturacionAnual = categorias[category].iibb

        // If the activity is not alquilerPropiedades
        if (!alquilerPropiedades) {
          // If is prestacionDeServicios
          // If not then is ventaCosasMuebles
          if (prestacionDeServicios) {
            if (this.isJubilacion()) {
              categoryCuotaMensual = categorias[category].jubilados;
            } else if (this.isRelacionDependencia()) {
              categoryCuotaMensual = categorias[category].servicios;
            } else if (this.isCajaProfesionales()) {
              categoryCuotaMensual = categorias[category].jubilados;
            } else {
              categoryCuotaMensual = categorias[category].total;
            }
          } else if (ventaCosasMuebles) {
            if (this.isJubilacion()) {
              categoryCuotaMensual = categorias[category].vjubilados;
            } else if (this.isRelacionDependencia()) {
              categoryCuotaMensual = categorias[category].ventas;
            } else if (this.isCajaProfesionales()) {
              categoryCuotaMensual = categorias[category].ventas;
            } else {
              categoryCuotaMensual = categorias[category].vtotal;
            }
          }
        } else {
          categoryCuotaMensual = categorias[category].servicios;
        }
      } else {
        category = categoriasKeyArray.filter(
          x =>
            numObj[x] === facturacionEstimada
        )[0];
        categoryFacturacionAnual = categorias[category].iibb;

        // If the activity is not alquilerPropiedades
        if (!alquilerPropiedades) {
          // If is prestacionDeServicios
          // If not then is ventaCosasMuebles
          if (prestacionDeServicios) {
            if (this.isJubilacion()) {
              categoryCuotaMensual = categorias[category].jubilados;
            } else if (this.isRelacionDependencia()) {
              categoryCuotaMensual = categorias[category].servicios;
            } else if (this.isCajaProfesionales()) {
              categoryCuotaMensual = categorias[category].jubilados;
            } else {
              categoryCuotaMensual = categorias[category].total;
            }
          } else if (ventaCosasMuebles) {
            if (this.isJubilacion()) {
              categoryCuotaMensual = categorias[category].vjubilados;
            } else if (this.isRelacionDependencia()) {
              categoryCuotaMensual = categorias[category].ventas;
            } else if (this.isCajaProfesionales()) {
              categoryCuotaMensual = categorias[category].ventas;
            } else {
              categoryCuotaMensual = categorias[category].vtotal;
            }
          }
        } else {
          categoryCuotaMensual = categorias[category].servicios;
        }
      }

      // Adding adherentes price
      if (adherentesQuantity !== null) {
        if (adherentesQuantity.selected > 0) {
          categoryCuotaMensual +=
            VALOR_OBRA_SOCIAL * adherentesQuantity.selected;
        }
      }

      this.setState({
        category,
        categoryCuotaMensual,
        categoryFacturacionAnual,
      });
    }
  }

  getSimulation() {
    return (
      <React.Fragment>
        <li>
          Categor&iacute;a <span>{this.state.category}</span>
        </li>
        <li>
          Cuota mensual de <span>$ {this.state.categoryCuotaMensual}</span>
        </li>
        <li>
          Límite de facturaci&oacute;n anual de{' '}
          <span>{this.state.categoryFacturacionAnual}</span>
        </li>
      </React.Fragment>
    );
  }

  getDescription(description) {
    let obj = {};

    if (description.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, '<br />'),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: description.replace(/\n/g, '<br /><br />'),
      };
    }

    if (description.match(/(<span>)/g)) {
      // Replace the '<span>' to <span> HTML tag
      // Replace the '</span>' to </span> HTML tag
      obj = {
        __html: description
          .replace(/(<span>)/g, '<span>')
          .replace(/(<\/span>)/g, '</span>'),
      };
    }

    return obj;
  }

  getTitle(title) {
    let obj;

    if (title.match(/\n{1}/g)) {
      // Replace when it finds just 1 '\n' to <br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, '<br />'),
      };
    } else {
      // Replace when its finds 2 '\n' to <br /><br /> HTML tag
      obj = {
        __html: title.replace(/\n/g, '<br /><br />'),
      };
    }

    return obj;
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  handleModalOk() {
    this.setState({
      showModal: false,
    });
  }

  handleModalCancel() {
    this.setState({
      showModal: false,
    });
  }

  renderResponse() {
    const { question } = this.props;
    const { title, description } = question;
    const isTitle = 'title' in question;
    const isDescription = 'description' in question;
    const isCategoryResult = 'categoryResult' in question;
    let response;

    if (isCategoryResult) {
      response = (
        <React.Fragment>
          <h5
            className="card-title"
            dangerouslySetInnerHTML={this.getTitle(title)}
          />
          <p className="card-subtitle">Categoría del Monotributo:</p>
          <div className="category-result-wrapper">
            <div>
              <ul>{this.getSimulation()}</ul>
            </div>
            <button
              type="button"
              className="open-modal-categories-btn"
              onClick={this.showModal}
            >
              ¿Quer&eacute;s saber mas sobre la categor&iacute;a en la que
              est&aacute;s?
            </button>
            <Modal
              className="categories-modal"
              visible={this.state.showModal}
              centered
              closable={false}
              title="Tu categoría"
              onCancel={this.handleModalCancel}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleModalOk}
                >
                  Entendido
                </Button>,
              ]}
            >
              <CategoriesModal
                isRelacionDependencia={this.isRelacionDependencia()}
              />
            </Modal>
          </div>
        </React.Fragment>
      );
    } else {
      if (isTitle && !isDescription) {
        response = (
          <h5
            className="card-title"
            dangerouslySetInnerHTML={this.getTitle(title)}
          />
        );
      } else if (isTitle && isDescription) {
        response = (
          <div>
            <h5
              className="card-title card-title--show-info-on-mobile"
              dangerouslySetInnerHTML={this.getTitle(title)}
            />
            <p
              className="card-subtitle hide-on-mobile"
              dangerouslySetInnerHTML={this.getDescription(description)}
            />
            <button type="button" onClick={this.showModal}>
              <i className="fas fa-question-circle" />
            </button>
            <Modal
              visible={this.state.showModal}
              centered
              closable={false}
              title={question.title}
              onCancel={this.handleModalCancel}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleModalOk}
                >
                  Entendido
                </Button>,
              ]}
            >
              <p dangerouslySetInnerHTML={this.getDescription(description)} />
            </Modal>
          </div>
        );
      }
      if (!isTitle && isDescription) {
        response = (
          <p
            className="card-subtitle"
            dangerouslySetInnerHTML={this.getDescription(description)}
          />
        );
      }
    }

    return <div>{response}</div>;
  }

  render() {
    return this.renderResponse();
  }
}

Response.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  question: PropTypes.object,
};

export default Response;
