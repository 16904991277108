import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigatorOnline } from '../../actions/Dashboard/generalStatus/creators';

class InternetConnectionMessage extends React.Component {
  state = {
    onlineContent: <p>De nuevo en línea</p>,
    offlineContent: <p>Sin conexión</p>,
    content: null,
    hideMessageAfterOn: false,
    internetIsOnline: this.props.internetIsOnline,
  };

  componentDidMount() {
    window.addEventListener('load', () => {
      // 1st, we set the correct status when the page loads
      this.props.navigatorOnline();

      // now we listen for network status changes
      window.addEventListener('online', () => {
        this.props.navigatorOnline();
      });

      window.addEventListener('offline', () => {
        this.props.navigatorOnline();
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.internetIsOnline !== nextProps.internetIsOnline) {
      this.setState(prevState => ({
        hideMessageAfterOn: this.state.content === this.state.offlineContent,
        internetIsOnline: nextProps.internetIsOnline,
        content: nextProps.internetIsOnline ? this.state.content === this.state.offlineContent ? prevState.onlineContent : prevState.offlineContent : prevState.offlineContent,
      }));
    }
  }

  render() {
    const { content, internetIsOnline, hideMessageAfterOn } = this.state;

    if (internetIsOnline !== null) {
      return (
        <div className={`internet-connection-message ${hideMessageAfterOn ? 'hideOnAfter3Sec' : 'hideNow'} ${internetIsOnline ? 'on' : 'off'}`}>
          {content}
        </div>
      );
    } else {
      return null;
    }
  }
}

InternetConnectionMessage.propTypes = {
  internetIsOnline: PropTypes.bool,
  navigatorOnline: PropTypes.func,
};

const mapStateToProps = state => ({
  internetIsOnline: state.generalStatus.navigatorOnline,
});

const mapDispatchToProps = dispatch => ({
  navigatorOnline: () => dispatch(navigatorOnline()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternetConnectionMessage)