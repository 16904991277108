import React from "react";
import PropTypes from "prop-types";
import MethodButtons from "./MethodButtons";
import SecureServicesText from "./SecureServicesText";
import HeaderTitle from "./HeaderTitle";
import CreditCard from "./CreditCard";
import OtherPayments from "./OtherPayments";
import { Icon } from "antd";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCard: true,
      otherPayment: false,
      showSelectAnOptionTitle: false,
    };

    this.toggleClass = this.toggleClass.bind(this);
    this.renderSelectAnOption = this.renderSelectAnOption.bind(this);
  }

  renderSelectAnOption(debitCardSelected, cashPaymentSelected) {
    if (this.state.otherPayment && !debitCardSelected && !cashPaymentSelected) {
      this.setState({ showSelectAnOptionTitle: false });
    }
  }

  toggleClass(e) {
    const buttonId = e.currentTarget.id;

    if (buttonId === "btn-credit-card") {
      this.setState({
        creditCard: true,
        otherPayment: false,
      });
    } else {
      this.setState({
        creditCard: false,
        otherPayment: true,
        showSelectAnOptionTitle: true,
      });
    }

    this.props.handleBasePrice(buttonId);
  }

  renderBody() {
    return (
      <div className="card-body">
        <div className="button-title-header">
          <button
            onClick={this.props.goBackArrow}
            className="go-back-payment-button"
          >
            <Icon type="arrow-left" />
          </button>{" "}
          <h5 className="text-center">Seleccioná una opción</h5>
        </div>
        <MethodButtons
          creditCardState={this.state.creditCard}
          otherPaymentState={this.state.otherPayment}
          toggleClass={this.toggleClass}
          basePriceCreditCard={this.props.basePriceCreditCard}
          basePriceOtherPayment={this.props.basePriceOtherPayment}
        />
        <CreditCard
          onNextQuestion={this.props.onNextQuestion}
          creditCardState={this.state.creditCard}
          basePriceCreditCard={this.props.basePriceCreditCard}
          payingInscription={this.props.payingInscription}
          serviceToPay={this.props.serviceToPay}
        />
        <OtherPayments
          basePriceCreditCard={this.props.basePriceCreditCard}
          basePriceOtherPayment={this.props.basePriceOtherPayment}
          handleTicket={this.props.handleTicket}
          otherPaymentState={this.state.otherPayment}
          paymentMethod={this.props.paymentMethod}
          onNextQuestion={this.props.onNextQuestion}
          monthsOfServiceToOffer={this.props.monthsOfServiceToOffer}
          renderSelectAnOption={this.renderSelectAnOption}
          payingInscription={this.props.payingInscription}
          serviceToPay={this.props.serviceToPay}
        />
        <HeaderTitle
          classDisplay="d-md-none"
          logoMercadopago={this.props.logoMercadopago}
        />
        <SecureServicesText />
      </div>
    );
  }

  render() {
    return this.renderBody();
  }
}

Body.propTypes = {
  handleTicket: PropTypes.func,
  handleBasePrice: PropTypes.func,
  onNextQuestion: PropTypes.func,
  basePriceCreditCard: PropTypes.any,
  basePriceOtherPayment: PropTypes.string,
  logoMercadopago: PropTypes.string,
  paymentMethod: PropTypes.string,
  monthsOfServiceToOffer: PropTypes.any,
  payingInscription: PropTypes.bool,
  serviceToPay: PropTypes.object,
};

export default Body;
