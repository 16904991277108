import React from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { handleNotificationMessage } from '../../actions/Dashboard/generalStatus/creators';

const duration = 7.5;

class NotificationMessage extends React.Component {
  generateNotification(
    notificationType,
    notificationMessage,
    notificationTitle,
  ) {
    notification[notificationType]({
      message: notificationTitle,
      description: notificationMessage,
      onClose: this.closeNotification,
      duration,
      placement: window.innerWidth > 767 ? 'bottomRight' : 'topRight',
    });
  }

  componentWillReceiveProps(nextProps) {
    let notificationType = null;
    let generateNotification = false;

    if (
      this.props.notificationMessage.error.show !==
        nextProps.notificationMessage.error.show &&
      nextProps.notificationMessage.error.show
    ) {
      notificationType = 'error';
      generateNotification = true;
    } else if (
      this.props.notificationMessage.warning.show !==
        nextProps.notificationMessage.warning.show &&
      nextProps.notificationMessage.warning.show
    ) {
      notificationType = 'warning';
      generateNotification = true;
    } else if (
      this.props.notificationMessage.success.show !==
        nextProps.notificationMessage.success.show &&
      nextProps.notificationMessage.success.show
    ) {
      notificationType = 'success';
      generateNotification = true;
    } else if (
      this.props.notificationMessage.info.show !==
        nextProps.notificationMessage.info.show &&
      nextProps.notificationMessage.info.show
    ) {
      notificationType = 'info';
      generateNotification = true;
    }

    if (generateNotification) {
      this.generateNotification(
        notificationType,
        nextProps.notificationMessage[notificationType].message,
        nextProps.notificationMessage[notificationType].title,
      );

      setTimeout(() => {
        this.props.handleNotificationMessage(false, notificationType, '');
      }, duration);
    }
  }

  render() {
    return (
      <div className="container">
        <h1>notificatio</h1>
      </div>
    );
  }
}

NotificationMessage.propTypes = {
  notificationMessage: PropTypes.object.isRequired,
  handleNotificationMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notificationMessage: state.generalStatus.notificationMessage,
});

const mapDispatchToProps = dispatch => ({
  handleNotificationMessage: (show, notificationType, notificationMessage) =>
    dispatch(
      handleNotificationMessage(show, notificationType, notificationMessage),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationMessage);
